import { Keyword } from "./Types";

export const LOADING_KEYWORDS = "LOADING_KEYWORDS";
export const SET_KEYWORDS = "SET_KEYWORDS";
export const SET_KEYWORD = "SET_KEYWORD";
export const UPDATE_KEYWORDS = "UPDATE_KEYWORDS";
export const CHANGING_KEYWORD = "CHANGING_KEYWORD";
export const CHANGING_KEYWORD_FINISHED = "CHANGING_KEYWORD_FINISHED";
export const DELETE_KEYWORD_FINISHED = "DELETE_KEYWORD_FINISHED";
export const HIDE_KEYWORD_MODAL = "HIDE_KEYWORD_MODAL";
export const SHOW_KEYWORD_MODAL_FOR_CREATE = "SHOW_KEYWORD_MODAL_FOR_CREATE";
export const SHOW_KEYWORD_MODAL_FOR_EDIT = "SHOW_KEYWORD_MODAL_FOR_EDIT";
export const FILTER_KEYWORDS = "FILTER_KEYWORDS";

export const loadingKeywords = () => ({ type: LOADING_KEYWORDS });

export const setKeywords = (keywords: Keyword[]) => ({
  type: SET_KEYWORDS,
  keywords,
});
export const setKeyword = (keyword: Keyword | undefined) => ({
  type: SET_KEYWORD,
  keyword,
});

export const updateKeywords = () => ({
  type: UPDATE_KEYWORDS,
});

export const changingKeyword = () => ({ type: CHANGING_KEYWORD });

export const changingKeywordFinished = (result: boolean) => ({
  type: CHANGING_KEYWORD_FINISHED,
  result,
});

export const deleteKeywordFinished = (result: boolean) => ({
  type: DELETE_KEYWORD_FINISHED,
  result,
});

export const hideKeywordModal = () => ({ type: HIDE_KEYWORD_MODAL });

export const showKeywordModalForCreate = () => ({
  type: SHOW_KEYWORD_MODAL_FOR_CREATE,
});

export const showKeywordModalForEdit = (keyword: Keyword) => ({
  type: SHOW_KEYWORD_MODAL_FOR_EDIT,
  keyword,
});

export const filterKeywords = (value: string) => ({
  type: FILTER_KEYWORDS,
  value,
});
