import React from "react";
import "./ProviderModal.css";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Typography,
  Select,
  Skeleton,
  DatePicker,
} from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../utils";
import {
  Provider,
  ProviderState,
  hideProviderModal,
} from "../../store/provider";
import { ProviderModalProps } from "./Types";
import { useSelector, useDispatch } from "react-redux";

const FormStateless: React.FC<ProviderModalProps> = (props) => {
  const { form, loading, modalType, onCreate, showModal } = props;
  const { providerState } = useSelector(mapState);
  const { provider } = providerState;
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();
  const nameof = nameofFactory<Provider>();

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const professionalRequest = {
        ...values,
        ProfessionalID: provider?.ProviderID,
      };

      if (modalType === "create") onCreate(professionalRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideProviderModal());
  };

  const genders = [
    {
      value: "M",
      text: <Trans id="Common.GenderMasculineLabel">Masculine</Trans>,
    },
    {
      value: "F",
      text: <Trans id="Common.GenderFeminineLabel">Feminine</Trans>,
    },
  ];
  const titles = [
    {
      value: "Dr.",
      label: "Dr."
    },
    {
      value: "Dra.",
      label: "Dra."
    },
    {
      value: "Lic.",
      label: "Lic."
    },
    {
      value: "Licda.",
      label: "Licda."

    },
    {
      value: "Master",
      label: "Master"
    },
    {
      value: " ",
      label: "No especificado"
    }
  ];

  return (
    <React.Fragment>
      <Modal
        centered
        className="provider-modal"
        destroyOnClose
        visible={showModal}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button loading={loading} onClick={handleSubmit} type="primary">
                <Trans
                  render="span"
                  id="ProfessionalModal.CreateProfessionalFormOkButton"
                >
                  Create
                </Trans>
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="ProfessionalModal.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          <Trans id="ProfessionalModal.CreateProfessionalFormTitle">
            Create provider
          </Trans>
        }
      >
        <Skeleton loading={providerState.loading}>
          <Form colon={false}>
            <Row type="flex">
              <Typography.Title level={4}>
                <Trans id="ProfessionalModal.BasicsTitle">Professional</Trans>
              </Typography.Title>
            </Row>

            <Row type="flex" gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={<Trans id="ProfessionalModal.TitleLable">Title</Trans>}
                >
                  {getFieldDecorator(nameof("Title"), {
                    initialValue: provider?.Title,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="Common.RequiredFieldError">
                            This field is required
                          </Trans>
                        ),
                      },
                    ],
                  })(
                    <Select>
                      {titles.map((title, index) => (
                        <Select.Option key={index} value={title.value}>
                          {title.label}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProfessionalModal.FirstNameLabel">
                      First name
                    </Trans>
                  }
                >
                  {getFieldDecorator(nameof("FirstName"), {
                    initialValue: provider?.FirstName,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProfessionalModal.LastNameLabel">Lastname</Trans>
                  }
                >
                  {getFieldDecorator(nameof("LastName"), {
                    initialValue: provider?.LastName,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProfessionalModal.SecondLastNameLabel">
                      Second Lastname
                    </Trans>
                  }
                >
                  {getFieldDecorator(nameof("SecondLastName"), {
                    initialValue: provider?.SecondLastName,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProfessionalModal.GenderLabel">Gender</Trans>
                  }
                >
                  {getFieldDecorator(nameof("Gender"), {
                    initialValue: provider?.Gender,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(
                    <Select>
                      {genders.map((gender, index) => (
                        <Select.Option key={index} value={gender.value}>
                          {gender.text}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProfessionalModal.BirthdateLabel">
                      Birthdate
                    </Trans>
                  }
                >
                  {getFieldDecorator(nameof("DateofBirth"), {
                    initialValue: provider?.DateofBirth,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<DatePicker format="L" />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProfessionalModal.ProfessionalCodeLabel">
                      Professional code
                    </Trans>
                  }
                >
                  {getFieldDecorator(nameof("PublicProviderID"), {
                    initialValue: provider?.PublicProviderID,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Modal>
    </React.Fragment>
  );
};

const mapState = (state: any): State => ({
  providerState: state.providerState,
});

interface State {
  providerState: ProviderState;
}

export default Form.create<ProviderModalProps>()(FormStateless);
