import React from "react";
import "./DirectorySelector.css";
import { Select } from "antd";
import { DirectorySelectorProps } from "./Types";
import { useDispatch, useSelector } from "react-redux";
import { changeDirectory, DirectoryState } from "../../store/directory";

const { Option } = Select;

export const DirectorySelector: React.FC<DirectorySelectorProps> = () => {
  const directoriesState = useSelector(mapState);
  const dispatch = useDispatch();

  const onChangeDirectory = (directoryTenantId: string) => {
    dispatch(changeDirectory(directoryTenantId));
  };

  return (
    <React.Fragment>
      <img
        src={directoriesState.selectorDirectory?.HeaderBannerUrl}
        alt=""
        height="48px"
        style={{ marginRight: "5px" }}
      />
      <Select
        className="directory-selector"
        onChange={onChangeDirectory}
        loading={directoriesState.selectorLoading}
        value={directoriesState.selectorDirectory?.DirectoryTenantID}
      >
        {directoriesState.directories.map((directory, index) => (
          <Option key={index} value={directory.DirectoryTenantID}>
            {directory.BaseURL}
          </Option>
        ))}
      </Select>
    </React.Fragment>
  );
};

const mapState = (state: any): DirectoryState => state.directoryState;
