import React from "react";
import { EmptyLayoutRouteProps } from "./Types";
import { Route } from "react-router-dom";
import { EmptyLayout } from "./EmptyLayout";

export const EmptyLayoutRoute: React.FC<EmptyLayoutRouteProps> = ({
  component: Component,
  ...rest
}) => (
  <Route
    {...rest}
    render={matchProps => (
      <EmptyLayout>
        <Component {...matchProps} />
      </EmptyLayout>
    )}
  />
);
