import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import {
  State,
  loadingStates,
  setStates,
  loadingCounties,
  County,
  setCounties,
  loadingCities,
  setCities,
  City,
  NewLocation,
  updatingLocation,
  updatedLocation,
} from "../../store/location";
import store from "../../store";
import { getDirectoryTenantId } from "../../utils";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadCountyCities = async (stateId: number, countyId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`search/cities/${stateId}/${countyId}`;

  try {
    store.dispatch(loadingCities());
    const response = await httpClient.get<GenericApiResponse<City[]>>(url);

    store.dispatch(setCities(response.data.Data));
    return true;
  } catch {
    store.dispatch(setCities([]));
    return false;
  }
};

export const loadStateCounties = async (stateId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`search/counties/${stateId}`;

  try {
    store.dispatch(loadingCounties());
    const response = await httpClient.get<GenericApiResponse<County[]>>(url);

    store.dispatch(setCounties(response.data.Data));
    return true;
  } catch {
    store.dispatch(setCounties([]));
    return false;
  }
};

export const loadStates = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "search/states";

  try {
    store.dispatch(loadingStates());
    const response = await httpClient.get<GenericApiResponse<State[]>>(url);

    store.dispatch(setStates(response.data.Data));
    return true;
  } catch {
    store.dispatch(setStates([]));
    return false;
  }
};

export const createNewLocation = async (
  providerID: string,
  request: NewLocation
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/location`;

  try {
    store.dispatch(updatingLocation());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(updatedLocation(true));
    return true;
  } catch {
    store.dispatch(updatedLocation(false));
    return false;
  }
};
