import React, { useEffect, useState } from "react";
import "./StandardLayout.css";
import { Layout, Menu, Icon, Row, Col } from "antd";
import { LocaleProvider } from "../../components/utils";
import { AuthState, logout } from "../../store/auth";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { DirectorySelector } from "../../components/directory-selector";
import { userDirectories } from "../../services/directory";

const { Content, Header, Sider } = Layout;

export const StandardLayout: React.FC = (props) => {
  const { children } = props;
  const location = useLocation();
  const { authState, updateDirectories } = useSelector(mapStateToProps);
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authState.isAuthenticated) userDirectories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDirectories]);

  const onLogout = (e: React.MouseEvent) => {
    e.preventDefault();
    dispatch(logout());
  };

  const onToggleSider = () => {
    setCollapsed(!collapsed);
  };

  return (
    <LocaleProvider>
      <Layout className="standard-layout">
        <Sider trigger={null} collapsible collapsed={collapsed}>
          <Link to="/">
            <Row type="flex" className="logo" justify="center">
              {collapsed ? (
                <img src="/assets/images/SikuBlueS.png" alt="Siku" />
              ) : (
                <img src="/assets/images/SikuWhiteLogo.png" alt="Siku" />
              )}
            </Row>
          </Link>
          <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
            {authState.info?.permissions.canSearch && (
              <Menu.Item key="/search">
                <Link to="/search">
                  <Icon type="calendar" />
                  <Trans render="span" id="StandardLayout.Sider.SearchItem">
                    Search
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {authState.info?.permissions.canSearch && (
              <Menu.Item key="/advanced-search">
                <Link to="/advanced-search">
                  <Icon type="search" />
                  <Trans
                    render="span"
                    id="StandardLayout.Sider.AdvancedSearchItem"
                  >
                    Advanced Search
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {authState.info?.permissions.canSearch && (
              <Menu.Item key="/patients">
                <Link to="/patients">
                  <Icon type="team" />
                  <Trans render="span" id="StandardLayout.Sider.PatientsItem">
                    Patients
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/professionals">
                <Link to="/professionals">
                  <Icon type="user" />
                  <Trans
                    render="span"
                    id="StandardLayout.Sider.ProfessionalsItem"
                  >
                    Professionals
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/reviews">
                <Link to="/reviews">
                  <Icon type="message" />
                  <Trans
                    render="span"
                    id="StandardLayout.Sider.ReviewsItem"
                  >
                    Reviews
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/specialties">
                <Link to="/specialties">
                  <Icon type="solution" />
                  <Trans
                    render="span"
                    id="StandardLayout.Sider.SpecialtiesItem"
                  >
                    Specialties
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/procedures">
                <Link to="/procedures">
                  <Icon type="tool" />
                  <Trans render="span" id="StandardLayout.Sider.ProceduresItem">
                    Procedures
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/keywords">
                <Link to="/keywords">
                  <Icon type="key" />
                  <Trans render="span" id="StandardLayout.Sider.KeywordsItem">
                    Keywords
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/insurances">
                <Link to="/insurances">
                  <Icon type="bank" />
                  <Trans render="span" id="StandardLayout.Sider.InsurancesItem">
                    Insurances
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/clinics">
                <Link to="/clinics">
                  <Icon type="medicine-box" />
                  <Trans render="span" id="StandardLayout.Sider.ClinicsItem">
                    Clinics
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/customProfile">
                <Link to="/customProfile">
                  <Icon type="shop" />
                  <Trans
                    render="span"
                    id="StandardLayout.Sider.OtherservicesItem"
                  >
                    Other services
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/directories">
                <Link to="/directories">
                  <Icon type="database" />
                  <Trans
                    render="span"
                    id="StandardLayout.Sider.DirectoriesItem"
                  >
                    Directories
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            {(authState.info?.isAdmin ||
              authState.info?.permissions.canSetup) && (
              <Menu.Item key="/users">
                <Link to="/users">
                  <Icon type="contacts" />
                  <Trans render="span" id="StandardLayout.Sider.UsersItem">
                    Users
                  </Trans>
                </Link>
              </Menu.Item>
            )}
            <Menu.Divider />
            <Menu.Item key="b1">
              <Link to="/logout" onClick={onLogout}>
                <Icon type="logout" />
                <Trans render="span" id="StandardLayout.Sider.LogoutItem">
                  Logout
                </Trans>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header className="nav-header">
            <Col span={2}>
              <Icon
                className="trigger"
                type={collapsed ? "menu-unfold" : "menu-fold"}
                onClick={onToggleSider}
              />
            </Col>
            <Col span={21}>
              <Row type="flex" justify="end" align="middle" gutter={24}>
                <Col className="white-text">
                  <Link to="/profile">{authState.info?.username}</Link>
                </Col>
                <Col>
                  <DirectorySelector />
                </Col>
              </Row>
            </Col>
          </Header>
          <Content>{children}</Content>
        </Layout>
      </Layout>
    </LocaleProvider>
  );
};

const mapStateToProps = (
  state: any
): { authState: AuthState; updateDirectories: boolean } => ({
  authState: state.authState,
  updateDirectories: state.directoryState.updateDirectories,
});
