import { AdvancedSearchState } from "./Types";
import {
  BEGIN_ADVANCED_SEARCH,
  COMPLETE_ADVANCED_SPECIALTY_SEARCH,
  CHANGE_ADVANCED_SEARCH_TYPE,
  COMPLETE_ADVANCED_DATE_SEARCH
} from "./Actions";
import { SELECT_STATE, CLEAN_FILTERS } from "../search";
import moment from "moment";

const specialtyDates = (): [moment.Moment, moment.Moment] => [
  moment(),
  moment().add(process.env.REACT_APP_SPECIALTY_DATES_RANGE, "d")
];

const dateDates = (): [moment.Moment, moment.Moment] => [
  moment(),
  moment().add(process.env.REACT_APP_DATE_DATES_RANGE, "d")
];

const initialState: AdvancedSearchState = {
  dates: [],
  hasSearched: false,
  params: {
    dates: specialtyDates(),
    type: "specialty"
  },
  providers: [],
  searching: false
};

export const advancedSearchReducer = (
  state = initialState,
  action: any
): AdvancedSearchState => {
  switch (action.type) {
    case BEGIN_ADVANCED_SEARCH:
      return {
        ...state,
        dates: [],
        hasSearched: false,
        params: action.params,
        providers: [],
        searching: true
      };

    case CHANGE_ADVANCED_SEARCH_TYPE:
      return {
        ...state,
        dates: [],
        hasSearched: false,
        params: {
          ...state.params,
          dates:
            action.searchType === "specialty" ? specialtyDates() : dateDates(),
          type: action.searchType
        },
        providers: []
      };

    case CLEAN_FILTERS:
      return {
        ...state,
        params: initialState.params
      };

    case COMPLETE_ADVANCED_DATE_SEARCH:
      return {
        ...state,
        dates: action.dates,
        hasSearched: true,
        searching: false
      };

    case COMPLETE_ADVANCED_SPECIALTY_SEARCH:
      return {
        ...state,
        providers: action.providers,
        hasSearched: true,
        searching: false
      };

    case SELECT_STATE:
      return {
        ...state,
        params: {
          ...state.params,
          stateId: action.stateId
        }
      };

    default:
      return state;
  }
};
