import React, { ReactElement } from "react";
import "./SearchSider.css";
import { SearchSiderProps, ListHeaderProps, ListItemProps } from "./Types";
import { List, Input, Checkbox, Empty, Row, Col, Tooltip, Icon } from "antd";
import { t, Trans } from "@lingui/macro";
import { i18n } from "../../../utils";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SearchProvider } from "./../../../store/search/Types";

export const SearchSider: React.FC<SearchSiderProps> = (props) => {
  const PAGE_SIZE = 20;

  const {
    calendarState,
    onChecked,
    onSearch,
    onUncheckAll,
    onUnchecked,
    onCheckFourProviders,
    searchState,
  } = props;

  return (
    <React.Fragment>
      <List
        size="small"
        header={
          <ListHeader
            checkedCount={searchState.providersCheckedCount}
            hasSearched={searchState.hasSearched}
            loading={
              searchState.searching ||
              calendarState.loadingEvents ||
              calendarState.loadingResources
            }
            onSearch={onSearch}
            onUncheckAll={onUncheckAll}
            onCheckFourProviders={onCheckFourProviders}
          />
        }
        itemLayout="horizontal"
        loading={
          searchState.searching ||
          calendarState.loadingEvents ||
          calendarState.loadingResources
        }
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <Trans id="SearchSider.NoProvidersFoundMessage">
                  No professionals
                </Trans>
              }
            />
          ),
        }}
        dataSource={searchState.filteredProviders}
        pagination={
          searchState.providers.length > PAGE_SIZE
            ? {
                position: "bottom",
                total: searchState.providers.length,
                pageSize: PAGE_SIZE,
              }
            : false
        }
        renderItem={(provider) => (
          <ListItem
            onChecked={onChecked}
            onUnchecked={onUnchecked}
            provider={provider}
          />
        )}
      />
    </React.Fragment>
  );
};

const ListHeader: React.FC<ListHeaderProps> = (props) => {
  const { checkedCount, hasSearched, loading, onSearch, onUncheckAll, onCheckFourProviders } = props;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    if(checkedCount > 0){
      if (!e.target.checked) onUncheckAll();
    }
    else{
      onCheckFourProviders();
    }
  };

  return (
    <Row type="flex" align="middle" justify="space-between">
      <Col span={21}>
        <Input.Search
          disabled={!hasSearched || loading}
          onChange={onChange}
          onSearch={onSearch}
          placeholder={i18n._(
            t("SearchSider.FilterProvidersPlaceHolder")`Search profesional`
          )}
        />
      </Col>
      <Col>
        <Tooltip
          placement="top"
          title={ checkedCount > 0 ?
            <Trans id="SearchSider.UncheckAllProvidersTooltip">
              Uncheck all
            </Trans>
            : 
            <Trans id="SearchSider.CheckFourProvidersTooltip">
              Check four providers
            </Trans>
          }
        >
          <Checkbox
            checked={checkedCount > 0}
            disabled={loading}
            onChange={onCheckboxChange}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};

const ListItem: React.FC<ListItemProps> = (props) => {
  const { onChecked, onUnchecked, provider } = props;

  const toggleProvider = (uId: string, isChecked?: boolean) => {
    if (!isChecked) onChecked(uId);
    else onUnchecked(uId);
  };

  const renderSpecialties = (list: string[]) => {
    if (list === undefined) return <span>Sin especialidades</span>;
    var spams: ReactElement[] = [];
    var i = 0;
    list.forEach((element) => {
      spams.push(<span key={i}>{element}</span>);
      i++;
      spams.push(<span key={i}>-</span>);
      i++;
    });
    spams.pop();
    return spams;
  };

  const renderToolTip = (provider: SearchProvider) => {
    const address = (
      <p>
        <Icon type="pushpin" /> {provider.Address}
      </p>
    );
    const phone = (
      <p>
        <Icon type="phone" /> {provider.Phone}
      </p>
    );

    return (
      
      <div className="searchsider-providerInfo-tooltip">
        <p>
          <strong>
            <Trans id="SearchSider.ProviderTooltipTitle">Details</Trans>
          </strong>
        </p>
        {address}
        {phone}
      </div>
    );
  };

  return (
    <React.Fragment>
      {provider.IsOffline === false ? (
        <List.Item
          extra={
            <Checkbox
              checked={provider.IsChecked}
              onClick={(_) => toggleProvider(provider.UID, provider.IsChecked)}
            />
          }
        >
          <Tooltip placement="top" title={renderToolTip(provider)}>
            <List.Item.Meta
              title={provider.FullName}
              description={
                <div>
                  {provider.AgendaName}
                  <p>{renderSpecialties(provider.SpecialtiesNames)}</p>
                </div>
              }
            />
          </Tooltip>
        </List.Item>
      ) : (
        <List.Item>
          <Tooltip placement="top" title={renderToolTip(provider)}>
            <List.Item.Meta
              title={provider.FullName}
              description={
                <div>
                  <span>{renderSpecialties(provider.SpecialtiesNames)}</span>
                  <br />
                  <Icon
                    type="exclamation-circle"
                    style={{ color: "#FF0000" }}
                  />{" "}
                  <span>
                    <Trans id="SearchSider.NoAgendasFoundMessage">
                      No Agendas
                    </Trans>
                  </span>
                </div>
              }
            />
          </Tooltip>
        </List.Item>
      )}
    </React.Fragment>
  );
};
