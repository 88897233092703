import React, { useEffect } from "react";
import "./RegistrationPage.css";
import { useParams, useHistory } from "react-router-dom";
import { verifyInviteUid, setupUserPassword } from "../../services/user";
import { useSelector } from "react-redux";
import { Row, Col, Form, Input, Button, Typography, Layout } from "antd";
import { UserState, User } from "../../store/user";
import { Trans, t } from "@lingui/macro";
import { RegistrationPageProps } from "./Types";
import { nameofFactory, i18n } from "../../utils";

const RegistrationPage: React.FC<RegistrationPageProps> = ({ form }) => {
  const history = useHistory();
  const { inviteUid } = useParams();
  const userState = useSelector(mapStateToProps);
  const { getFieldDecorator, getFieldValue, validateFields } = form;
  const nameof = nameofFactory<User>();

  useEffect(() => {
    if (inviteUid)
      verifyInviteUid(inviteUid).then((response) => {
        if (!response) history.push("/auth");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteUid]);

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) return;

      const request = {
        ...values,
        inviteUID: inviteUid,
        UserID: userState.user?.UserID,
      };

      setupUserPassword(request).then((response) => {
        if (response) history.push("/auth");
      });
    });
  };

  const validateToFirstPassword = (rule: any, value: any, callback: any) => {
    if (value && value !== getFieldValue(nameof("Password"))) {
      callback(
        i18n._(
          t("RegistrationPage.MismatchPasswordsError")`The passwords must match`
        )
      );
    } else {
      callback();
    }
  };

  const validateToNextPassword = (rule: any, value: any, callback: any) => {
    if (value && getFieldValue(nameof("PasswordConfirmation"))?.length > 0) {
      form.validateFields([nameof("PasswordConfirmation")], { force: true });
    }
    callback();
  };

  return (
    <Layout className="auth-page">
      <Layout.Header>
        <Row type="flex" align="middle" className="logo-container">
          <a href="/">
            <img
              src="/assets/images/SikuWhiteLogoLogin.png"
              alt="Siku logo"
              className="logo"
            />
          </a>
        </Row>
      </Layout.Header>
      <Layout.Content className="content-container">
        <Row className="registration-page registration-page-container">
          <Row
            className="margin-top-30 margin-botton-30 text-center"
            justify="center"
          >
            <Col span={24}>
              <h3>
                <Typography.Text strong>
                  <Trans render="h3" id="RegistrationPage.Title">
                    Password reset
                  </Trans>
                </Typography.Text>
              </h3>
            </Col>
          </Row>

          <Row type="flex" justify="center">
            <Col span={14}>
              <Col span={11}>
                <Form onSubmit={onSubmit}>
                  <Form.Item
                    label={
                      <Trans id="RegistrationPage.EmailLabel">Email</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("Email"), {
                      initialValue: userState.user?.Email,
                      rules: [
                        {
                          required: true,
                        },
                      ],
                    })(<Input disabled />)}
                  </Form.Item>
                  <Form.Item
                    label={
                      <Trans id="RegistrationPage.PasswordLabel">
                        Password
                      </Trans>
                    }
                  >
                    {getFieldDecorator(nameof("Password"), {
                      rules: [
                        {
                          required: true,
                          message: (
                            <Trans id="RegistrationPage.PasswordRequiredError">
                              The password field is required
                            </Trans>
                          ),
                        },
                        {
                          validator: validateToNextPassword,
                        },
                      ],
                    })(<Input.Password />)}
                  </Form.Item>
                  <Form.Item
                    label={
                      <Trans id="RegistrationPage.ConfirmPasswordLabel">
                        Confirm password
                      </Trans>
                    }
                  >
                    {getFieldDecorator(nameof("PasswordConfirmation"), {
                      rules: [
                        {
                          required: true,
                          message: (
                            <Trans id="RegistrationPage.ConfirmPasswordRequiredError">
                              The confirm password field is required
                            </Trans>
                          ),
                        },
                        {
                          validator: validateToFirstPassword,
                        },
                      ],
                    })(<Input.Password />)}
                  </Form.Item>
                  <Row type="flex" justify="end">
                    <Button
                      htmlType="submit"
                      loading={userState.changing}
                      size="large"
                      type="primary"
                      className="registration-page-button"
                    >
                      <Trans render="span" id="RegistrationPage.SendButton">
                        Send
                      </Trans>
                    </Button>
                  </Row>
                </Form>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <h4>
                  <Typography.Text strong>
                    <Trans
                      render="h4"
                      id="RegistrationPage.PasswordRequirements"
                    >
                      Password requirements
                    </Trans>
                  </Typography.Text>
                </h4>
                <ul className="margin-top-30">
                  <li>
                    <Trans id="RegistrationPage.ListMinimunCaracters">
                      The password must have a minimum length of 8 characters
                    </Trans>
                  </li>
                  <li>
                    <Trans id="RegistrationPage.ListCapLetter">
                      Password must contain at least 1 capital letter
                    </Trans>
                  </li>
                  <li>
                    <Trans id="RegistrationPage.ListLowercase">
                      Password must contain at least 1 lowercase
                    </Trans>
                  </li>
                  <li>
                    <Trans id="RegistrationPage.ListSpecialChars">
                      Password must contain at least 1 special character
                    </Trans>
                  </li>
                </ul>
              </Col>
            </Col>
          </Row>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default Form.create<RegistrationPageProps>()(RegistrationPage);

const mapStateToProps = (state: any): UserState => state.userState;
