import React, { useEffect } from "react";
import "./DirectoryModal.css";
import { Form, Input, Modal, Checkbox, Row, Col, Button } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../utils";
import {
  Directory,
  hideDirectoryModal,
  DirectoryState,
  setDirectory,
} from "../../store/directory";
import { DirectoryModalProps } from "./Types";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDirectory,
  createDirectory,
  updateDirectory,
} from "../../services/directory";

const { TextArea } = Input;

const FormStateless: React.FC<DirectoryModalProps> = (props) => {
  const { form, directoryId } = props;
  const { getFieldDecorator, validateFields } = form;
  const directoryState = useSelector(mapState);
  const directory = directoryState.directory;
  const dispatch = useDispatch();
  const nameof = nameofFactory<Directory>();

  useEffect(() => {
    if (directoryId) loadDirectory(directoryId);

    return () => {
      dispatch(setDirectory(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryId]);

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const directoryRequest = {
        ...values,
        DirectoryTenantID: directory?.DirectoryTenantID,
      };

      if (directoryState.modalType === "create")
        createDirectory(directoryRequest);
      else updateDirectory(directoryRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideDirectoryModal());
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="directory-modal"
        destroyOnClose
        visible={directoryState.showModal}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button
                loading={directoryState.loading}
                onClick={handleSubmit}
                type="primary"
              >
                {directoryState.modalType === "create" ? (
                  <Trans
                    render="span"
                    id="DirectoryModal.CreateDirectoryFormOkButton"
                  >
                    Create
                  </Trans>
                ) : (
                  <Trans
                    render="span"
                    id="DirectoryModal.EditDirectoryFormOkButton"
                  >
                    Update
                  </Trans>
                )}
              </Button>
            </Col>
            <Col>
              <Button
                disabled={directoryState.loading}
                onClick={onModalDismiss}
              >
                <Trans render="span" id="DirectoryModal.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          directoryState.modalType === "create" ? (
            <Trans id="DirectoryModal.CreateDirectoryFormTitle">
              Create directory
            </Trans>
          ) : (
            <Trans id="DirectoryModal.EditDirectoryFormTitle">
              Edit directory
            </Trans>
          )
        }
      >
        <Form colon={false}>
          <Form.Item label={<Trans id="DirectoryModal.NameLabel">Name</Trans>}>
            {getFieldDecorator(nameof("Name"), {
              initialValue: directory?.Name,
              rules: [
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ],
            })(<Input maxLength={60} />)}
          </Form.Item>
          <Row type="flex" gutter={16}>
            <Col span={16}>
              <Form.Item
                label={
                  <Trans id="DirectoryModal.ContactCenterPhoneNumberLabel">
                    Phone
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("ContactCenterPhoneNumber"), {
                  initialValue: directory?.ContactCenterPhoneNumber,
                  rules: [
                    {
                      required: false,
                      message: <Trans id="Common.RequiredFieldError" />,
                    },
                  ],
                })(<Input />)}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={
                  <Trans id="DirectoryModal.IndEnabledLabel">Enabled</Trans>
                }
              >
                {getFieldDecorator(nameof("IndEnabled"), {
                  valuePropName: "checked",
                  initialValue: directory?.IndEnabled,
                })(<Checkbox />)}
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label={<Trans id="DirectoryModal.BaseURLLabel">Base URL</Trans>}
          >
            {getFieldDecorator(nameof("BaseURL"), {
              initialValue: directory?.BaseURL,
              rules: [
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ],
            })(<TextArea />)}
          </Form.Item>

          <Form.Item
            label={
              <Trans id="DirectoryModal.HeaderBannerUrlLabel">
                Header banner URL
              </Trans>
            }
          >
            {getFieldDecorator(nameof("HeaderBannerUrl"), {
              initialValue: directory?.HeaderBannerUrl,
              rules: [
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ],
            })(<TextArea />)}
          </Form.Item>
          <Form.Item
            label={
              <Trans id="DirectoryModal.FooterFacebookUrlLabel">
                Footer Facebook URL
              </Trans>
            }
          >
            {getFieldDecorator(nameof("FooterFacebookUrl"), {
              initialValue: directory?.FooterFacebookUrl,
              rules: [
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ],
            })(<TextArea />)}
          </Form.Item>

          <Form.Item
            label={
              <Trans id="DirectoryModal.FacebookPixelIdLabel">
                Facebook Pixel Id
              </Trans>
            }
          >
            {getFieldDecorator(nameof("FacebookPixelId"), {
              initialValue: directory?.FacebookPixelId,
              rules: [
                {
                  required: false,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ],
            })(<Input />)}
          </Form.Item>

          <Form.Item
            label={
              <Trans id="DirectoryModal.FooterInstagramUrlLabel">
                Footer Instagram URL
              </Trans>
            }
          >
            {getFieldDecorator(nameof("FooterInstagramUrl"), {
              initialValue: directory?.FooterInstagramUrl,
              rules: [
                {
                  required: false,
                  message: <Trans id="Common.RequiredFieldError" />,
                },
              ],
            })(<TextArea />)}
          </Form.Item>
          <Row type="flex" gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <Trans id="DirectoryModal.HideChatLabel">Hide chat</Trans>
                }
              >
                {getFieldDecorator(nameof("HideChat"), {
                  valuePropName: "checked",
                  initialValue: directory?.HideChat,
                })(<Checkbox />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Trans id="DirectoryModal.HideContactWidgetLabel">
                    Hide contact widget
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("HideContactWidget"), {
                  valuePropName: "checked",
                  initialValue: directory?.HideContactWidget,
                })(<Checkbox />)}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" gutter={16}>
            <Col span={12}>
              <Form.Item
                label={
                  <Trans id="DirectoryModal.HideProviderContactPhoneNumberLabel">
                    Hide provider contact phone
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("HideProviderContactPhoneNumber"), {
                  valuePropName: "checked",
                  initialValue: directory?.HideProviderContactPhoneNumber,
                })(<Checkbox />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={
                  <Trans id="DirectoryModal.HideProviderRegisterButtonLabel">
                    Hide provider register button
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("HideProviderRegisterButton"), {
                  valuePropName: "checked",
                  initialValue: directory?.HideProviderRegisterButton,
                })(<Checkbox />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

const mapState = (state: any): DirectoryState => state.directoryState;

export default Form.create<DirectoryModalProps>()(FormStateless);
