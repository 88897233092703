import { ProviderState } from "./Types";
import {
  LOADING_PROVIDERS,
  SET_PROVIDERS,
  SET_PROVIDER,
  ADD_PROVIDER_SPECIALTIES,
  PROVIDER_SAVED,
  SAVING_PROVIDER,
  ADD_PROVIDER_LOCATIONS,
  LOADING_PROVIDER_LOCATIONS,
  LOADING_PROVIDER_EDUCATION_COURSE,
  LOADING_PROVIDER_EDUCATION_DEGREE,
  ADD_PROVIDER_EDUCATION_COURSE,
  ADD_PROVIDER_EDUCATION_DEGREE,
  SET_PROVIDER_AVATAR,
  SET_PROVIDER_ADDITIONAL_PICTURE,
  SET_PROVIDER_PICTURES,
  SET_PROVIDER_AVATAR_CAN_SAVE,
  SET_PROVIDER_AVATAR_CAN_DELETE,
  HIDE_PROVIDER_MODAL,
  SHOW_PROVIDER_CREATE_MODAL,
  LOADING_PROVIDER_APPOINTMENT_REASON,
  ADD_PROVIDER_APPOINTMENT_REASON,
  LOADING_PROVIDER,
  SET_PROVIDERS_CLINIC
} from "./Actions";
import { EDIT_LOCATION, STOP_EDIT, UPDATED_LOCATION } from "../location";

import {
  EDIT_EDUCATION_COURSE,
  STOP_EDIT_EDUCATION_COURSE,
} from "../providerEducationCourse";

import {
  EDIT_EDUCATION_DEGREE,
  STOP_EDIT_EDUCATION_DEGREE,
} from "../providerEducationDegree";

const initialState: ProviderState = {
  loading: false,
  loadingLocations: false,
  loadingproviderEducationCourse: false,
  loadingProviderEducationDegree: false,
  providers: [],
  clinicProviders: [],
  saving: false,
  providerAvatarBase64: "",
  providerAdditionalPicturesBase64: "",
  canSaveNewProviderAvatar: false,
  canDeleteProviderAvatar: false,
  modalType: "create",
  showModal: false,
  loadingAppointmentReason: false
};

export const providerReducer = (
  state = initialState,
  action: any
): ProviderState => {
  switch (action.type) {
    case ADD_PROVIDER_LOCATIONS:
      if (state.provider) {
        return {
          ...state,
          loadingLocations: false,
          provider: {
            ...state.provider,
            Locations: action.locations,
          },
        };
      }

      return state;

    case ADD_PROVIDER_EDUCATION_COURSE:
      if (state.provider) {
        return {
          ...state,
          loadingproviderEducationCourse: false,
          provider: {
            ...state.provider,
            ProviderEducationCourse: action.providerEducationCourse,
          },
        };
      }

      return state;

    case ADD_PROVIDER_EDUCATION_DEGREE:
      if (state.provider) {
        return {
          ...state,
          loadingProviderEducationDegree: false,
          provider: {
            ...state.provider,
            ProviderEducationDegree: action.providerEducationDegree,
          },
        };
      }

      return state;

    case ADD_PROVIDER_SPECIALTIES:
      if (state.provider) {
        return {
          ...state,
          provider: {
            ...state.provider,
            Specialties: action.specialties,
          },
        };
      }

      return state;

    case ADD_PROVIDER_APPOINTMENT_REASON:
      if (state.provider) {
        return {
          ...state,
          loadingAppointmentReason: false,
          provider: {
            ...state.provider,
            AppointmentReason: action.providerAppointmentReason,
          },
        };
      }

      return state;
    case EDIT_LOCATION:
      if (state.provider && state.provider.Locations)
        return {
          ...state,
          provider: {
            ...state.provider,
            Locations: state.provider.Locations.map((x) => {
              if (x.LocationID === action.location.LocationID) x.editing = true;
              return x;
            }),
          },
        };

      return state;

    case STOP_EDIT:
      if (state.provider && state.provider.Locations)
        return {
          ...state,
          provider: {
            ...state.provider,
            Locations: state.provider.Locations.map((x) => {
              if (x.LocationID === action.locationId) x.editing = false;
              return x;
            }),
          },
        };

      return state;

    case EDIT_EDUCATION_COURSE:
      if (state.provider && state.provider.ProviderEducationCourse)
        return {
          ...state,
          provider: {
            ...state.provider,
            ProviderEducationCourse: state.provider.ProviderEducationCourse.map(
              (x) => {
                if (
                  x.ProviderEducationCourseID ===
                  action.providerEducationCourse.ProviderEducationCourseID
                )
                  x.editing = true;
                return x;
              }
            ),
          },
        };

      return state;

    case STOP_EDIT_EDUCATION_COURSE:
      if (state.provider && state.provider.ProviderEducationCourse)
        return {
          ...state,
          provider: {
            ...state.provider,
            ProviderEducationCourse: state.provider.ProviderEducationCourse.map(
              (x) => {
                if (
                  x.ProviderEducationCourseID ===
                  action.providerEducationCourseID
                )
                  x.editing = false;
                return x;
              }
            ),
          },
        };

      return state;

    case EDIT_EDUCATION_DEGREE:
      if (state.provider && state.provider.ProviderEducationDegree)
        return {
          ...state,
          provider: {
            ...state.provider,
            ProviderEducationDegree: state.provider.ProviderEducationDegree.map(
              (x) => {
                if (
                  x.ProviderEducationDegreeID ===
                  action.providerEducationDegree.ProviderEducationDegreeID
                )
                  x.editing = true;
                return x;
              }
            ),
          },
        };

      return state;

    case STOP_EDIT_EDUCATION_DEGREE:
      if (state.provider && state.provider.ProviderEducationDegree)
        return {
          ...state,
          provider: {
            ...state.provider,
            ProviderEducationDegree: state.provider.ProviderEducationDegree.map(
              (x) => {
                if (
                  x.ProviderEducationDegreeID ===
                  action.providerEducationDegreeID
                )
                  x.editing = false;
                return x;
              }
            ),
          },
        };

      return state;

    case LOADING_PROVIDERS:
      return {
        ...state,
        loading: true,
      };

    case LOADING_PROVIDER_LOCATIONS:
      return {
        ...state,
        loadingLocations: true,
      };

    case LOADING_PROVIDER_EDUCATION_COURSE:
      return {
        ...state,
        loadingproviderEducationCourse: true,
      };

    case LOADING_PROVIDER_EDUCATION_DEGREE:
      return {
        ...state,
        loadingProviderEducationDegree: true,
      };

    case PROVIDER_SAVED:
      return {
        ...state,
        saving: false,
      };

    case SAVING_PROVIDER:
      return {
        ...state,
        saving: true,
      };

    case SET_PROVIDER:
      const providerAvatar = action.provider?.Pictures?.find(
        (x: any) => x.IndAvatar
      );
      let providerAvatarBase64 = "";
      if (providerAvatar) providerAvatarBase64 = providerAvatar.Url;

      return {
        ...state,
        loading: false,
        provider: action.provider,
        providerAvatarBase64: providerAvatarBase64,
        providerAdditionalPicturesBase64: "",
        canDeleteProviderAvatar: providerAvatarBase64 === "" ? false : true,
      };

    case SET_PROVIDERS:
      return {
        ...state,
        loading: false,
        providers: action.providers,
      };

    case UPDATED_LOCATION:
      if (state.provider && state.provider.Locations) {
        return {
          ...state,
          provider: {
            ...state.provider,
            Locations: action.result ? [] : state.provider.Locations,
          },
        };
      }
      return state;

    case SET_PROVIDER_AVATAR:
      return {
        ...state,
        providerAvatarBase64: action.base64,
        canSaveNewProviderAvatar: true,
        canDeleteProviderAvatar: false,
      };

    case SET_PROVIDER_ADDITIONAL_PICTURE:
      return {
        ...state,
        providerAdditionalPicturesBase64: action.base64,
      };

    case SET_PROVIDER_PICTURES:
      if (state.provider) {
        return {
          ...state,
          provider: {
            ...state.provider,
            Pictures: action.pictures,
          },
        };
      }

      return state;

    case SET_PROVIDER_AVATAR_CAN_SAVE:
      return {
        ...state,
        canSaveNewProviderAvatar: action.canSaveProviderAvatar,
      };

    case SET_PROVIDER_AVATAR_CAN_DELETE:
      return {
        ...state,
        canDeleteProviderAvatar: action.canDeleteProviderAvatar,
      };

    case HIDE_PROVIDER_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case SHOW_PROVIDER_CREATE_MODAL:
      return {
        ...state,
        modalType: "create",
        showModal: true,
        provider: undefined,
      };

    case LOADING_PROVIDER_APPOINTMENT_REASON:
      return {
        ...state,
        loadingAppointmentReason: true,
      };
    case LOADING_PROVIDER:
      return {
        ...state,
        loading: true,
      };
    case SET_PROVIDERS_CLINIC:
      return {
        ...state,
        loading: false,
        clinicProviders: action.providers,
      };
    default:
      return state;
  }
};
