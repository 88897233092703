import { Procedure } from "./Types";

export const CHANGING_PROCEDURE = "CHANGING_PROCEDURE";
export const CHANGING_PROCEDURE_FINISHED = "CHANGING_PROCEDURE_FINISHED";
export const HIDE_PROCEDURE_MODAL = "HIDE_PROCEDURE_MODAL";
export const LOADING_PROCEDURES = "LOADING_PROCEDURES";
export const SET_PROCEDURES = "SET_PROCEDURES";
export const SHOW_PROCEDURE_CREATE_MODAL = "SHOW_PROCEDURE_CREATE_MODAL";
export const SHOW_PROCEDURE_EDIT_MODAL = "SHOW_PROCEDURE_EDIT_MODAL";
export const UPDATE_PROCEDURES = "UPDATE_PROCEDURES";
export const FILTER_PROCEDURES = "FILTER_PROCEDURES";

export const changingProcedure = () => ({ type: CHANGING_PROCEDURE });

export const changingProcedureFinished = (result: boolean) => ({
  type: CHANGING_PROCEDURE_FINISHED,
  result,
});

export const hideProcedureModal = () => ({ type: HIDE_PROCEDURE_MODAL });

export const loadingProcedures = () => ({ type: LOADING_PROCEDURES });

export const setProcedures = (procedures: Procedure[]) => ({
  type: SET_PROCEDURES,
  procedures,
});

export const showProcedureCreateModal = () => ({
  type: SHOW_PROCEDURE_CREATE_MODAL,
});

export const showProcedureEditModal = (procedure: Procedure) => ({
  type: SHOW_PROCEDURE_EDIT_MODAL,
  procedure,
});

export const updateProcedures = () => ({
  type: UPDATE_PROCEDURES,
});
export const filterProcedures = (value: string) => ({
  type: FILTER_PROCEDURES,
  value,
});
