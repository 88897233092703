import { ProviderEducationCourseState } from "./Types";
import {
  LOADING_PROVIDER_EDUCATION_COURSE,
  SET_PROVIDER_EDUCATION_COURSE,
  EDIT_EDUCATION_COURSE,
  STOP_EDIT_EDUCATION_COURSE,
  CHANGE_PROVIDER_EDUCATION_COURSE_FIELD,
  SET_CALENDAR_DATE,
  UPDATING_PROVIDER_EDUCATION_COURSE,
  UPDATED_PROVIDER_EDUCATION_COURSE,
  HIDE_NEW_PROVIDER_EDUCATION_COURSE_MODAL,
  SHOW_NEW_PROVIDER_EDUCATION_COURSE_CREATE_MODAL,
} from "./Actions";

const initialState: ProviderEducationCourseState = {
  updatingProviderEducationCourse: false,
  canEdit: true,
  loading: false,
  showModal: false,
  modalType: "create",
};

export const providerEducationCourseReducer = (
  state = initialState,
  action: any
): ProviderEducationCourseState => {
  switch (action.type) {
    case CHANGE_PROVIDER_EDUCATION_COURSE_FIELD:
      if (state.providerEducationCourse) {
        return {
          ...state,
          providerEducationCourse: {
            ...state.providerEducationCourse,
            [action.name]: action.value,
          },
        };
      }
      return state;

    case SET_CALENDAR_DATE:
      if (state.providerEducationCourse) {
        return {
          ...state,
          providerEducationCourse: {
            ...state.providerEducationCourse,
            Date: action.date,
          },
        };
      }
      return state;

    case EDIT_EDUCATION_COURSE:
      return {
        ...state,
        canEdit: false,
        providerEducationCourse: action.providerEducationCourse,
      };

    case STOP_EDIT_EDUCATION_COURSE:
      return {
        ...state,
        canEdit: true,
        providerEducationCourse: undefined,
      };

    case SET_PROVIDER_EDUCATION_COURSE:
      return {
        ...state,
        providerEducationCourse: action.providerEducationCourse,
        loading: false,
      };

    case LOADING_PROVIDER_EDUCATION_COURSE:
      return {
        ...state,
        loading: true,
      };

    case UPDATING_PROVIDER_EDUCATION_COURSE:
      return {
        ...state,
        updatingProviderEducationCourse: true,
      };

    case UPDATED_PROVIDER_EDUCATION_COURSE:
      return {
        ...state,
        canEdit: action.result,
        loading: false,
        updatingProviderEducationCourse: false,
        providerEducationCourse: action.result
          ? undefined
          : state.providerEducationCourse,
      };

    case HIDE_NEW_PROVIDER_EDUCATION_COURSE_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case SHOW_NEW_PROVIDER_EDUCATION_COURSE_CREATE_MODAL:
      return {
        ...state,
        modalType: "create",
        showModal: true,
        providerEducationCourse: undefined,
      };

    default:
      return state;
  }
};
