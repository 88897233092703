import React from "react";
import "./AuthPasswordResetComplete.css";
import { Row, Col, Form, Input, Button, Icon, Typography } from "antd";
import { AuthPasswordResetProps } from "./Types";
import { nameofFactory } from "../../../utils";
import { UserPasswordResetComplete } from "../../../store/passwordReset";
import { Trans } from "@lingui/macro";

const AuthPasswordReset: React.FC<AuthPasswordResetProps> = (props) => {
  const { form, onPasswordResetComplete, userPasswordResetState } = props;
  const nameof = nameofFactory<UserPasswordResetComplete>();
  const { getFieldDecorator, validateFields } = form;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (!err) {
        onPasswordResetComplete(values);
      }
    });
  };

  return (
    <Row className="passwordResetComplete-container">
      <Row
        className="margin-top-30 margin-botton-30 text-center"
        justify="center"
      >
        <Col span={24}>
          <h3>
            <Typography.Text strong>
              <Trans render="h3" id="PasswordResetComplete.Title">
                Password reset
              </Trans>
            </Typography.Text>
          </h3>
        </Col>
      </Row>

      <Row type="flex" justify="center">
        <Col span={14}>
          <Col span={11}>
            <Form colon={false} onSubmit={handleSubmit}>
              <Form.Item
                label={
                  <Trans id="PasswordResetComplete.PasswordLabel">
                    Password
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("Password"), {
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="PasswordResetComplete.PasswordRequiredError">
                          The password field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Input.Password
                    prefix={<Icon type="lock" />}
                    size="large"
                    disabled={userPasswordResetState.loading}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={
                  <Trans id="PasswordResetComplete.PasswordConfirmationLabel">
                    Password confirmation
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("PasswordVerification"), {
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="PasswordResetComplete.PasswordConfirmationRequiredError">
                          The password confirmation field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Input.Password
                    prefix={<Icon type="lock" />}
                    size="large"
                    disabled={userPasswordResetState.loading}
                  />
                )}
              </Form.Item>
              <Row type="flex" justify="end">
                <Button
                  htmlType="submit"
                  loading={userPasswordResetState.loading}
                  size="large"
                  type="primary"
                  className="passwordResetComplete-button"
                >
                  <Trans
                    render="span"
                    id="PasswordResetComplete.RecoverButtonLabel"
                  >
                    Update
                  </Trans>
                  <Icon type="arrow-right" />
                </Button>
              </Row>
            </Form>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <h4>
              <Typography.Text strong>
                <Trans
                  render="h4"
                  id="PasswordResetComplete.PasswordRequirements"
                >
                  Password requirements
                </Trans>
              </Typography.Text>
            </h4>
            <ul className="margin-top-30">
              <li>
                <Trans id="PasswordResetComplete.ListMinimunCaracters">
                  The password must have a minimum length of 10 characters
                </Trans>
              </li>
              <li>
                <Trans id="PasswordResetComplete.ListCapLetter">
                  Password must contain at least 1 capital letter
                </Trans>
              </li>
              <li>
                <Trans id="PasswordResetComplete.ListLowercase">
                  Password must contain at least 1 lowercase
                </Trans>
              </li>
              <li>
                <Trans id="PasswordResetComplete.ListSpecialChars">
                  Password must contain at least 1 special character
                </Trans>
              </li>
            </ul>
          </Col>
        </Col>
      </Row>
    </Row>
  );
};

export default Form.create<AuthPasswordResetProps>()(AuthPasswordReset);
