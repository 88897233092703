import React from "react";
import "./SpecialtyModal.css";
import {
  Form,
  Input,
  Modal,
  Checkbox,
  InputNumber,
  Row,
  Col,
  Button
} from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../utils";
import { Specialty, hideSpecialtyModal } from "../../store/specialty";
import { SpecialtyModalProps } from "./Types";
import { useDispatch } from "react-redux";

const { TextArea } = Input;

const FormStateless: React.FC<SpecialtyModalProps> = props => {
  const {
    form,
    loading,
    modalType,
    onCreate,
    onUpdate,
    showModal,
    specialty
  } = props;
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();
  const nameof = nameofFactory<Specialty>();

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const specialtyRequest = {
        ...values,
        SpecialtyID: specialty?.SpecialtyID
      };

      if (modalType === "create") onCreate(specialtyRequest);
      else onUpdate(specialtyRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideSpecialtyModal());
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="specialty-modal"
        destroyOnClose
        visible={showModal}
        okButtonProps={{ loading: loading }}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button loading={loading} onClick={handleSubmit} type="primary">
                {modalType === "create" ? (
                  <Trans
                    render="span"
                    id="Specialties.CreateSpecialtyFormOkButton"
                  >
                    Create
                  </Trans>
                ) : (
                  <Trans
                    render="span"
                    id="Specialties.EditSpecialtyFormOkButton"
                  >
                    Update
                  </Trans>
                )}
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="Specialties.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          modalType === "create" ? (
            <Trans id="Specialties.CreateSpecialtyFormTitle">
              Create specialty
            </Trans>
          ) : (
            <Trans id="Specialties.EditSpecialtyFormTitle">
              Edit specialty
            </Trans>
          )
        }
      >
        <Form colon={false}>
          <Form.Item
            label={<Trans id="Specialties.Form.NameLabel">Name</Trans>}
          >
            {getFieldDecorator(nameof("Name"), {
              initialValue: specialty?.Name,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Specialties.Form.NameRequiredError">
                      This field is required
                    </Trans>
                  )
                }
              ]
            })(<Input maxLength={60} />)}
          </Form.Item>

          <Form.Item
            label={
              <Trans id="Specialties.Form.PictureURLLabel">Picture URL</Trans>
            }
          >
            {getFieldDecorator(nameof("PictureURL"), {
              initialValue: specialty?.PictureURL,
              rules: [
                {
                  required: false,
                  message: (
                    <Trans id="Specialties.Form.PictureURLRequiredError">
                      This field is required
                    </Trans>
                  )
                }
              ]
            })(<TextArea />)}
          </Form.Item>

          <Form.Item
            label={
              <Trans id="Specialties.Form.DescriptionLabel">Description</Trans>
            }
          >
            {getFieldDecorator(nameof("Description"), {
              initialValue: specialty?.Description
            })(<TextArea />)}
          </Form.Item>
          <Form.Item
            label={
              <Trans id="Specialties.Form.IsMainLabel">Show in home</Trans>
            }
          >
            {getFieldDecorator(nameof("IsMain"), {
              valuePropName: "checked",
              initialValue: specialty?.IsMain
            })(<Checkbox />)}
          </Form.Item>
          <Form.Item
            label={<Trans id="Specialties.Form.OrderLabel">Order</Trans>}
            /*  help={
              <Trans id="Specialties.Form.NegativeInfoMesaggeLabel">
                It cannot contains negatives
              </Trans>
            } */
          >
            {getFieldDecorator(nameof("Order"), {
              initialValue: specialty?.Order,
              rules: [
                {
                  required: true,
                  type: "regexp",
                  pattern: new RegExp("^[0-9]*$"),
                  message: (
                    <Trans id="Specialties.Form.OrderRequiredError">
                      This field is required
                    </Trans>
                  )
                }
              ]
            })(<InputNumber min={0} maxLength={4} />)}
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Form.create<SpecialtyModalProps>()(FormStateless);
