import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import {
  loadingOrganizationFailed,
  loadingOrganization,
  Organization,
  setOrganization,
  importingDoctor,
  importedDoctor,
  stopImporting,
} from "../../store/import-provider";
import store from "../../store";
import {
  ImportCnlpProfessionalsRequest,
  ImportProfessionalsRequest,
  ImportZHProfessionalsRequest,
} from "./Types";

import { notification } from "antd";
import { i18n } from "../../utils";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";

export const searchProvidersToImport = async (apiKey: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/hooli/import";

  try {
    store.dispatch(loadingOrganization());
    const response = await httpClient.get<GenericApiResponse<Organization>>(
      url,
      {
        params: { apiKey },
      }
    );
    store.dispatch(setOrganization(response.data.Data));
    return true;
  } catch (ex) {
    store.dispatch(loadingOrganizationFailed(ex));
    return false;
  }
};

export const importProvider = async (request: ImportProfessionalsRequest) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/hooli/import";

  try {
    store.dispatch(importingDoctor(request.DoctorId));
    await httpClient.post<GenericApiResponse<string>>(url, request);

    notification.success({
      message: i18n._(
        t(
          "ImportProfessionalService.ImportHuliProviderSuccess"
        )`The provider has been imported`
      ),
    });

    store.dispatch(importedDoctor(request.DoctorId));
    return true;
  } catch {
    store.dispatch(stopImporting(request.DoctorId));
    return false;
  }
};

export const searchZHProvidersToImport = async (facility: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/zh/import";

  try {
    store.dispatch(loadingOrganization());
    const response = await httpClient.get<GenericApiResponse<Organization>>(
      url,
      {
        params: { facility },
      }
    );

    var organization = {
      Name: facility,
      Id: facility,
      Doctors: response.data.Data,
    };

    store.dispatch(setOrganization(organization));
    return true;
  } catch (ex) {
    store.dispatch(loadingOrganizationFailed(ex));
    return false;
  }
};

export const importzhProvider = async (
  request: ImportZHProfessionalsRequest
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/zh/import";

  try {
    store.dispatch(importingDoctor(request.DoctorId));
    await httpClient.post<GenericApiResponse<string>>(url, request);

    notification.success({
      message: i18n._(
        t(
          "ImportProfessionalService.ImportzhProviderSuccess"
        )`The provider has been imported`
      ),
    });

    store.dispatch(importedDoctor(request.DoctorId));
    return true;
  } catch {
    store.dispatch(stopImporting(request.DoctorId));
    return false;
  }
};

export const searchCnlpProvidersToImport = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/cnlp/import";

  try {
    store.dispatch(loadingOrganization());
    const response = await httpClient.get<GenericApiResponse<Organization>>(
      url     
    );

    var organization = {    
      Doctors: response.data.Data,
    };

    store.dispatch(setOrganization(organization));
    return true;
  } catch (ex) {
    store.dispatch(loadingOrganizationFailed(ex));
    return false;
  }
};
export const importCnlpProvider = async (
  request: ImportCnlpProfessionalsRequest
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/cnlp/import";

  try {
    store.dispatch(importingDoctor(request.Id));
    await httpClient.post<GenericApiResponse<string>>(url, request);

    notification.success({
      message: i18n._(
        t(
          "ImportProfessionalService.ImportCnlpProviderSuccess"
        )`The provider has been imported`
      ),
    });

    store.dispatch(importedDoctor(request.Id));
    return true;
  } catch {
    store.dispatch(stopImporting(request.Id));
    return false;
  }
};
