import React from "react";
import "./ProviderEducationCourseModal.css";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Skeleton,
  DatePicker,
} from "antd";

import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";

import {
  NewProviderEducationCourse,
  hideNewProviderEducationCourseModal,
  ProviderEducationCourseState,
} from "../../../store/providerEducationCourse";

import { ProviderEducationCourseModalProps } from "./Types";
import { useSelector, useDispatch } from "react-redux";

const FormStateless: React.FC<ProviderEducationCourseModalProps> = (props) => {
  const { MonthPicker } = DatePicker;
  const { form, onCreate, showModal } = props;
  const { providerEducationCourseState } = useSelector(mapState);
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();

  const newProviderEducationCourseNameOf = nameofFactory<
    NewProviderEducationCourse
  >();

  const onModalDismiss = () => {
    dispatch(hideNewProviderEducationCourseModal());
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const request = {
        ...values,
      };

      onCreate(request);
    });
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="provider-modal"
        destroyOnClose
        visible={showModal}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button
                loading={
                  providerEducationCourseState.updatingProviderEducationCourse
                }
                onClick={onSubmit}
                type="primary"
              >
                <Trans
                  render="span"
                  id="ProviderEducationCourseModal.CreateButton"
                >
                  Create
                </Trans>
              </Button>
            </Col>
            <Col>
              <Button
                disabled={
                  providerEducationCourseState.updatingProviderEducationCourse
                }
                onClick={onModalDismiss}
              >
                <Trans
                  render="span"
                  id="ProviderEducationCourseModal.CancelButton"
                >
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          <Trans id="ProviderEducationCourseModal.CreateFormTitle">
            Create course
          </Trans>
        }
      >
        <Skeleton
          loading={providerEducationCourseState.updatingProviderEducationCourse}
        >
          <Form colon={false}>
            <Row type="flex" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Trans id="ProviderEducationCourseModal.ProfessionalCourseNameLabel">
                      Name
                    </Trans>
                  }
                >
                  {getFieldDecorator(
                    newProviderEducationCourseNameOf(
                      "ProviderEducationCourseName"
                    ),
                    {
                      rules: [
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ],
                    }
                  )(
                    <Input
                      name={newProviderEducationCourseNameOf(
                        "ProviderEducationCourseName"
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Trans id="ProviderEducationCourseModal.CourseInstituteNameLabel">
                      Institute name
                    </Trans>
                  }
                >
                  {getFieldDecorator(
                    newProviderEducationCourseNameOf(
                      "ProviderEducationCourseInstituteName"
                    ),
                    {
                      rules: [
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ],
                    }
                  )(
                    <Input
                      name={newProviderEducationCourseNameOf(
                        "ProviderEducationCourseInstituteName"
                      )}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Trans id="ProviderEducationCourseModal.CourseDateLabel">
                      Date
                    </Trans>
                  }
                >
                  {getFieldDecorator(
                    newProviderEducationCourseNameOf(
                      "ProviderEducationCourseDate"
                    ),
                    {
                      rules: [
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ],
                    }
                  )(<MonthPicker />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Modal>
    </React.Fragment>
  );
};

const mapState = (state: any): State => ({
  providerEducationCourseState: state.providerEducationCourseState,
});

interface State {
  providerEducationCourseState: ProviderEducationCourseState;
}

export default Form.create<ProviderEducationCourseModalProps>()(FormStateless);
