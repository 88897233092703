import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import {
  User,
  loadingUsers,
  setUsers,
  loadingUserDirectories,
  setUserDirectories,
  changingUser,
  deleteUserFinished,
  changingUserFinished,
  changingUserPasswordFinished,
  setUser,
} from "../../store/user";

import {
  resetingPassword,
  resetingPasswordFinished,
  UserPasswordResetComplete,
  UserPasswordReset,
} from "../../store/passwordReset";

import { getDirectoryTenantId } from "../../utils";
import store from "../../store";
import { Directory } from "../../store/directory";
import { notification } from "antd";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadUsers = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/user";

  try {
    store.dispatch(loadingUsers());
    const response = await httpClient.get<GenericApiResponse<User[]>>(url);
    store.dispatch(setUsers(response.data.Data));
    return true;
  } catch {
    store.dispatch(setUsers([]));
    return false;
  }
};

export const loadUserDirectories = async (userId: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/user/DirectoryTenantUser/${userId}`;

  try {
    store.dispatch(loadingUserDirectories());
    const response = await httpClient.get<GenericApiResponse<Directory[]>>(url);
    store.dispatch(setUserDirectories(response.data.Data));
    return true;
  } catch {
    store.dispatch(setUserDirectories([]));
    return false;
  }
};

export const createUser = async (request: User) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"admin/user";

  try {
    store.dispatch(changingUser());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingUserFinished(true));
    return true;
  } catch {
    store.dispatch(changingUserFinished(false));
    return false;
  }
};

export const updateUser = async (request: User) => {
  const { UserID, Directories, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/user/${UserID}`;

  try {
    store.dispatch(changingUser());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId(),
      UserDirectories: Directories.map(
        (directory) => directory.DirectoryTenantID
      ),
    });
    store.dispatch(changingUserFinished(true));
    return true;
  } catch {
    store.dispatch(changingUserFinished(false));
    return false;
  }
};

export const deleteUser = async (userID: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/user/${userID}`;

  try {
    store.dispatch(changingUser());
    await httpClient.delete(url);
    store.dispatch(deleteUserFinished(true));
    return true;
  } catch {
    store.dispatch(deleteUserFinished(false));
    return false;
  }
};

export const updateUserPassword = async (request: User) => {
  const { UserID, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/user/${UserID}/password`;

  try {
    store.dispatch(changingUser());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingUserPasswordFinished(true));
    return true;
  } catch (err) {
    store.dispatch(changingUserPasswordFinished(false));
    return false;
  }
};

export const setupUserPassword = async (request: User) => {
  const { UserID, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/user/${UserID}/setupPassword`;

  try {
    store.dispatch(changingUser());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingUserPasswordFinished(true));
    return true;
  } catch (err) {
    store.dispatch(changingUserPasswordFinished(false));
    return false;
  }
};

export const verifyInviteUid = async (inviteUid: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/user/verifyInviteUID/${inviteUid}`;

  try {
    store.dispatch(loadingUsers());
    const response = await httpClient.get<GenericApiResponse<User>>(url);

    if (response.data.Data) {
      store.dispatch(setUser(response.data.Data));
      return true;
    } else {
      store.dispatch(setUser(undefined));
      return false;
    }
  } catch {
    store.dispatch(setUser(undefined));
    return false;
  }
};

export const sendPasswordResetEmail = async (request: UserPasswordReset) => {
  const { ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/user/PasswordResetRequest`;

  try {
    store.dispatch(resetingPassword());
    const response = await httpClient.post(url, {
      ...rest,
    });

    notification.info({
      message: response.data.Data,
    });

    store.dispatch(resetingPasswordFinished());
    return true;
  } catch (err) {
    store.dispatch(resetingPasswordFinished());
    return false;
  }
};

export const sendPasswordResetEmailComplete = async (
  resetCode: string,
  request: UserPasswordResetComplete
) => {
  const { ...rest } = request;

  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/user/PasswordResetRequest/${resetCode}/complete`;

  try {
    store.dispatch(resetingPassword());
    const response = await httpClient.put(url, {
      ...rest,
    });

    notification.info({
      message: response.data.Data,
    });

    store.dispatch(resetingPasswordFinished());
    return true;
  } catch (err) {
    store.dispatch(resetingPasswordFinished());
    return false;
  }
};
