import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import { loadingLanguages, Language, setLanguages } from "../../store/language";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadLanguagesFromAdmin = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"admin/language";

  try {
    store.dispatch(loadingLanguages());
    const response = await httpClient.get<GenericApiResponse<Language[]>>(url);
    store.dispatch(setLanguages(response.data.Data));
    return true;
  } catch {
    store.dispatch(setLanguages([]));
    return false;
  }
};
