import { AuthInfo } from "./Types";

export const AUTH_CONNECTION_FAILED = "AUTH_CONNECTION_FAILED";
export const AUTH_FAILED = "AUTH_FAILED";
export const AUTH_SUCCEDED = "AUTH_SUCCEDED";
export const BEGIN_AUTH = "BEGIN_AUTH";
export const LOGOUT = "LOGOUT";
export const REQUESTING_EXTERNAL_ACCESS_TOKEN =
  "REQUESTING_EXTERNAL_ACCESS_TOKEN";
export const SET_EXTERNAL_ACCESS_TOKEN = "SET_EXTERNAL_ACCESS_TOKEN";

export const authConnectionFailed = () => ({ type: AUTH_CONNECTION_FAILED });

export const beginAuth = () => ({ type: BEGIN_AUTH });

export const completeFailureAuth = (errorMessage: string) => ({
  type: AUTH_FAILED,
  errorMessage
});

export const completeSuccessAuth = (authInfo: AuthInfo) => ({
  type: AUTH_SUCCEDED,
  authInfo
});

export const logout = () => ({
  type: LOGOUT
});

export const requestingExternalAccessToken = () => ({
  type: REQUESTING_EXTERNAL_ACCESS_TOKEN
});

export const setExternalAccessToken = (
  externalAccessToken: string | undefined
) => ({
  type: SET_EXTERNAL_ACCESS_TOKEN,
  externalAccessToken
});
