import React from "react";
import "./ReviewHeader.css";
import {
    Form,
    Select,
    Row,
    Col,
    Button,
    Tooltip,
    Spin,
    DatePicker
} from "antd";
import { ReviewHeaderProps, ReviewStatus, SearchReviewParams } from "./Types";
import { Trans, t } from "@lingui/macro";
import { nameofFactory, i18n } from "../../../utils";
import { debounce } from "lodash";
import { searchProvidersForAutocomplete } from "../../../services/provider";

const ReviewHeader: React.FC<ReviewHeaderProps> = props => {
    const {
        form,
        onCleanFilters,
        onSearch,
        providerState,
        isLoading
    } = props;

    const nameof = nameofFactory<SearchReviewParams>();
    const { Option } = Select;

    const { getFieldDecorator, getFieldsValue, resetFields } = form;

    const status: ReviewStatus[] = [
        { Value: 0, Text: i18n._(t("ReviewStatus.Status1")`Pending`) },
        { Value: 1, Text: i18n._(t("ReviewStatus.Status2")`Verified`) },
        { Value: 2, Text: i18n._(t("ReviewStatus.Status3")`Deleted`) }

    ];

    const onLocalCleanFilters = () => {
        resetFields();
        onCleanFilters();
    };

    const onSearchProviders = debounce((value: string) => {
        if (!value || value === "") return;

        searchProvidersForAutocomplete(value);
    }, 800);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const values = getFieldsValue();

        onSearch(values);

    };


    return (
        <React.Fragment>
            <Form layout="vertical" className="review-header" onSubmit={onSubmit}>
                <Row type="flex" gutter={8}>
                    <Col span={4}>
                        <Form.Item label={<Trans id="Common.Status" />}>
                            {getFieldDecorator(nameof("status"), {
                                initialValue: 0,
                                rules: [
                                    {
                                        required: true,
                                        message: (
                                            <Trans id="Common.StateRequiredError">
                                                The state field is required
                                            </Trans>
                                        )
                                    }
                                ]
                            })(
                                <Select>
                                    {status.map((state) => (
                                        <Option key={state.Value} value={state.Value}>
                                            {state.Text}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label={<Trans id="Common.ProviderLabel">Professional</Trans>}
                        >
                            {getFieldDecorator(nameof("providerID"))(
                                <Select
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    loading={providerState.loading}
                                    notFoundContent={
                                        providerState.loading ? <Spin size="small" /> : null
                                    }
                                    onSearch={onSearchProviders}>
                                    {providerState.providers.map((provider, index) => (
                                        <Option key={index} value={provider.ProviderID}>
                                            {provider.FullName}
                                        </Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={4}>
                        <Form.Item label={<Trans id="Common.DatesLabel">Dates</Trans>}>
                            {getFieldDecorator(nameof("dates"), {
                            })(
                                <DatePicker.RangePicker
                                    separator="-"
                                    allowClear={false}
                                    format={["L"]}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Row
                            type="flex"
                            align="bottom"
                            className="review-button-container"
                        >
                            <Form.Item>
                                <Button.Group>
                                    <Button
                                        type="primary"
                                        icon="search"
                                        htmlType="submit"
                                        loading={isLoading}
                                    >
                                        <Trans render="span" id="Common.SearchButton">
                                            Search
                                        </Trans>
                                    </Button>
                                    <Tooltip
                                        placement="topLeft"
                                        title={
                                            <Trans render="span" id="Common.CleanFiltersTooltip">
                                                Clean filters
                                            </Trans>
                                        }
                                    >
                                        <Button
                                            disabled={isLoading}
                                            icon="close"
                                            onClick={onLocalCleanFilters}
                                        />
                                    </Tooltip>
                                </Button.Group>
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    );
};

export default Form.create<ReviewHeaderProps>()(ReviewHeader);
