import React, { useEffect } from "react";
import "./UsersPage.css";
import {
  Table,
  Divider,
  Button,
  Row,
  Tooltip,
  Col,
  Input,
  Typography,
} from "antd";
import { Trans, t } from "@lingui/macro";
import { YesNoCell, Container } from "../../components/utils";
import {
  User,
  UserState,
  showUserModalForCreate,
  showUserModalForEdit,
  showUserPasswordModal,
  filterUsers,
} from "../../store/user";
import { useSelector, useDispatch } from "react-redux";
import { i18n } from "../../utils";
import {
  loadUsers,
  updateUser,
  createUser,
  updateUserPassword,
} from "../../services/user";
import { UserModal } from "../../components/user/user-modal";
import { DirectoryState } from "../../store/directory";
import UserPasswordModal from "../../components/user/user-password-modal/UserPasswordModal";

export const UsersPage: React.FC = () => {
  const pageSize = 20;
  const { directoryState, userState } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    loadUsers();
  }, [userState.updateUsers]);

  const onCreateUser = () => {
    dispatch(showUserModalForCreate());
  };

  const onCreate = (user: User) => {
    createUser(user);
  };

  const onPasswordChange = (user: User) => {
    updateUserPassword(user);
  };

  const onUpdate = (user: User) => {
    updateUser(user);
  };

  const onUpdateUser = (user: User) => {
    dispatch(showUserModalForEdit(user));
  };

  const onUpdateUserPassword = (user: User) => {
    dispatch(showUserPasswordModal(user));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Users.EmailColumnTitle">
          Email
        </Trans>
      ),
      dataIndex: "Email",
      key: "Email",
    },
    {
      title: (
        <Trans render="strong" id="Users.IsAdministratorColumnTitle">
          Is administrator
        </Trans>
      ),
      dataIndex: "IsAdmin",
      key: "IsAdmin",
      render: (IsAdmin: boolean) => <YesNoCell value={IsAdmin} showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Users.CanSetupColumnTitle">
          Can setup
        </Trans>
      ),
      dataIndex: "CanSetup",
      key: "CanSetup",
      render: (CanSetup: boolean) => <YesNoCell value={CanSetup} showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Users.CanSearchColumnTitle">
          Can search
        </Trans>
      ),
      dataIndex: "CanSearch",
      key: "CanSearch",
      render: (CanSearch: boolean) => <YesNoCell value={CanSearch} showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Users.IsEnabledColumnTitle">
          Is enabled
        </Trans>
      ),
      dataIndex: "IsEnabled",
      key: "IsEnabled",
      render: (IsEnabled: boolean) => <YesNoCell value={IsEnabled} showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Users.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (user: User) => (
        <span>
          <Tooltip
            title={<Trans id="Users.EditActionTooltip">Edit user</Trans>}
          >
            <Button icon="edit" onClick={() => onUpdateUser(user)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Users.EditPasswordActionTooltip">Edit Password</Trans>
            }
          >
            <Button icon="lock" onClick={() => onUpdateUserPassword(user)} />
          </Tooltip>
        </span>
      ),
    },
  ];

  const filterFoundUsers = (value: string) => {
    dispatch(filterUsers(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundUsers(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography.Title level={2}>
          <Trans id="Users.Title">Users</Trans>
        </Typography.Title>
        <Table
          dataSource={userState.filteredUsers}
          columns={columns}
          loading={userState.loading}
          bodyStyle={{ background: "#fff" }}
          rowKey="UserID"
          pagination={
            userState.count > pageSize && {
              pageSize,
              position: "bottom",
            }
          }
          title={() => (
            <Row type="flex" align="middle">
              <Col span={6}>
                <Input
                  onChange={onChange}
                  placeholder={i18n._(
                    t("Users.FilterUsersPlaceholder")`Search users`
                  )}
                />
              </Col>
              <Col span={18}>
                <Row type="flex" justify="end">
                  <Button type="primary" size="large" onClick={onCreateUser}>
                    <Trans id="Users.InviteUserButton">Invite User</Trans>
                  </Button>
                </Row>
              </Col>
            </Row>
          )}
        />
      </Container>
      <UserModal
        directories={directoryState.directories}
        loading={userState.changing}
        modalType={userState.modalType}
        onCreate={onCreate}
        onUpdate={onUpdate}
        showModal={userState.showModal}
        user={userState.user}
      />
      <UserPasswordModal
        loading={userState.changing}
        onPasswordChange={onPasswordChange}
        showModal={userState.showPasswordModal}
        user={userState.user}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  directoryState: state.directoryState,
  userState: state.userState,
});

interface StateToProps {
  directoryState: DirectoryState;
  userState: UserState;
}
