import React, { useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  getProviderProfile,
  createProvider,
  updateProvider,
  updateProviderLocation,
  loadFullProviderLocations,
  updateProviderEducationCourse,
  loadProviderEducationCourses,
  updateProviderEducationDegree,
  loadProviderEducationDegree,
  deleteProvider,
  updateProviderAvatar,
  addProviderAdditionalPicture,
  deleteProviderAdditionalPicture,
  deleteProviderLocation,
  loadAppointmentReason,
} from "../../services/provider";
import { Container, YesNoCell } from "../../components/utils";
import moment from "moment";
import {
  Typography,
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Select,
  Skeleton,
  DatePicker,
  Button,
  Divider,
  Avatar,
  Upload,
  message,
  Icon,
  Table,
  Popconfirm,
  Tabs,
  InputNumber
} from "antd";

import { Trans, t } from "@lingui/macro";
import { useSelector, useDispatch } from "react-redux";
import { FormComponentProps } from "antd/lib/form";
import { nameofFactory, filterByValueAndText, i18n } from "../../utils";
import {
  Provider,
  ProviderState,
  setProvider,
  setNewProviderAvatar,
  setProviderAdditionalPicture,
  setProviderPictures,
  setProviderAvatarCanSave,
  setProviderAvatarCanDelete,
} from "../../store/provider";

import { SpecialtyState } from "../../store/specialty";
import { loadSpecialties } from "../../services/specialty";
import { ProcedureState } from "../../store/procedure";
import { loadProceduresFromAdmin } from "../../services/procedure";
import { LanguageState } from "../../store/language";
import { loadLanguagesFromAdmin } from "../../services/language";
import { KeywordState } from "../../store/keyword";
import { ProviderFocusState } from "../../store/providerFocus";
import { loadKeywordsFromAdmin } from "../../services/keyword";
import { loadproviderFocus } from "../../services/providerFocus";
import {
  Location,
  NewLocation,
  LocationState,
  editLocation,
  showNewLocationCreateModal,
  hideNewLocationModal,
} from "../../store/location";

import {
  ProviderEducationCourse,
  NewProviderEducationCourse,
  ProviderEducationCourseState,
  editProviderEducationCourse,
  stopEditEducationCourse,
  changeProviderEducationCourseField,
  setCalendarDateEducationCourse,
  showNewProviderEducationCourseCreateModal,
  hideNewProviderEducationCourseModal,
} from "../../store/providerEducationCourse";

import {
  ProviderEducationDegree,
  NewProviderEducationDegree,
  ProviderEducationDegreeState,
  editProviderEducationDegree,
  stopEditEducationDegree,
  changeProviderEducationDegreeField,
  setCalendarDateEducationDegreeDateFrom,
  setCalendarDateEducationDegreeDateTo,
  showNewProviderEducationDegreeCreateModal,
  hideNewProviderEducationDegreeModal,
} from "../../store/providerEducationDegree";

import {
  ProviderAppointmentReason,
  ProviderAppointmentReasonState,
  stopEditProviderAppointmentReason,
  showProviderAppointmentReasonCreateModal,
  hideProviderAppointmentReasonModal,
  showProviderAppointmentReasonEditModal,
} from "../../store/providerAppointmentReason";

import { ColumnProps } from "antd/lib/table";
import {
  loadStates,
  loadCountyCities,
  loadStateCounties,
  createNewLocation,
} from "../../services/location";

import {
  deleteProviderEducationCourse,
  createProviderEducationCourse,
} from "../../services/providerEducationCourse";

import {
  deleteProviderEducationDegree,
  createProviderEducationDegree,
} from "../../services/providerEducationDegree";

import {
  createProviderAppointmentReason,
  updateProviderAppointmentReason,
  deleteProviderAppointmentReason,
} from "../../services/providerAppointmentReason";

import Axios from "axios";

import { Picture } from "./../../store/provider/Types";
import { ProviderNewAgendaModal } from "../../components/provider-modal/provider-new-agenda-modal";
import { ProviderEditAgendaModal } from "../../components/provider-modal/provider-edit-agenda-modal";
import { ProviderEducationCourseModal } from "../../components/provider-modal/provider-new-educationCourse-modal";
import { ProviderEducationDegreeModal } from "../../components/provider-modal/provider-new-educationDegree-modal";
import { ProviderAppointmentReasonModal } from "../../components/provider-modal/provider-new-appointmentReason-modal";
import { InsuranceState } from "../../store/insurance";
import { loadInsurancesFromAdmin } from "../../services/insurance";
import { PaymentMethodState } from "../../store/paymentMethod";
import { loadPaymentMethodsAdmin } from "../../services/paymentMethod/PaymentMethodService";
import { currencys } from "../../utils/Constants";
import IframeFileGenerator from "../../components/iframe-generator";
import QrCodeDonwloader from "../../components/qr-code-downloader";

const ProfessionalPage: React.FC<FormComponentProps> = (props) => {
  const { MonthPicker } = DatePicker;
  const { form } = props;
  const { providerId } = useParams();
  const history = useHistory();

  const {
    keywordState,
    languageState,
    locationState,
    providerState,
    procedureState,
    specialtyState,
    providerFocusState,
    providerEducationCourseState,
    providerEducationDegreeState,
    providerAppointmentReasonState,
    insuranceState,
    paymentMethodState
  } = useSelector(mapState);

  const dispatch = useDispatch();

  const { provider } = providerState;

  const { getFieldDecorator, validateFields } = form;

  const { TabPane } = Tabs;

  const nameof = nameofFactory<Provider>();

  const providerEducationCourseNameOf =
    nameofFactory<ProviderEducationCourse>();

  const providerEducationDegreeNameOf =
    nameofFactory<ProviderEducationDegree>();

  const titles = [
    {
      value: "Dr.",
      label: "Dr.",
    },
    {
      value: "Dra.",
      label: "Dra.",
    },
    {
      value: "Lic.",
      label: "Lic.",
    },
    {
      value: "Licda.",
      label: "Licda.",
    },
    {
      value: "Master",
      label: "Master",
    },
    {
      value: " ",
      label: "No especificado",
    },
  ];

  const avatar = provider?.Pictures?.find((x) => x.IndAvatar);
  const scheduleFlows = [
    { value: 0, displayName: "Genérico" },
    { value: 1, displayName: "Con condiciones por especialidad" },
  ];

  const qrName = `amd-qr-${provider?.FirstName.trim()}-${provider?.LastName.trim()}.png`;
  useEffect(() => {
    if (providerId === undefined) history.goBack();
    else if (providerId !== "new") getProviderProfile(providerId);

    return () => {
      dispatch(setProvider(undefined));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  useEffect(() => {
    loadSpecialties();
  }, [specialtyState.updateSpecialties]);

  useEffect(() => {
    loadProceduresFromAdmin();
  }, [procedureState.updateProcedures]);

  useEffect(() => {
    loadLanguagesFromAdmin();
  }, [languageState.updateLanguages]);

  useEffect(() => {
    loadKeywordsFromAdmin();
  }, [keywordState.updateKeywords]);

  useEffect(() => {
    loadproviderFocus();
  }, [providerFocusState.updateProviderFocus]);

  useEffect(() => {
    loadStates();
  }, []);

  useEffect(() => {
    if (locationState.location?.Address1ID) {
      loadStateCounties(locationState.location.Address1ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState.location?.Address1ID]);

  useEffect(() => {
    if (
      locationState.location?.Address1ID &&
      locationState.location.Address2ID
    ) {
      loadCountyCities(
        locationState.location.Address1ID,
        locationState.location.Address2ID
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationState.location?.Address2ID]);

  useEffect(() => {
    loadInsurancesFromAdmin();
  }, [insuranceState.updateInsurances]);

  useEffect(() => {
    loadPaymentMethodsAdmin();
  }, [paymentMethodState.updatePaymentMethods]);

  const onSaveChanges = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) return;

      const currency = currencys.find(x => x.CurrencyCode === values.Currency);
      if (providerId === "new") {
        values.Currency = currency;
        createProvider(values).then((result) => {
          if (result) history.push("/professionals");
        });
      } else {
        const request = {
          ...values,
          ProviderID: providerId,
          Currency: currency
        };

        if (
          (request.AllowPrivateMessage === true && request.Email === null) ||
          request.Email === ""
        ) {
          message.error(
            i18n._(
              t(
                "ProfessionalPage.EmailRequired"
              )`The email is required if private message checked`
            )
          );
        } else {
          updateProvider(request).then((result) => {
            if (result) history.go(0);
          });
        }
      }
    });
  };

  const onEditLocation = (location: Location) => {
    Axios.all([
      loadStateCounties(location.Address1ID),
      loadCountyCities(location.Address1ID, location.Address2ID),
    ]).then(() => dispatch(editLocation(location)));
  };

  const onDeleteProviderLocation = (locationID: number, agendaID: number) => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      message.loading(
        i18n._(
          t("ProfessionalPage.DeletingProviderLocation")`Deleting Location...`
        ),
        0.5,
        () =>
          deleteProviderLocation(providerId, locationID, agendaID).then(
            (result) => {
              if (result) loadFullProviderLocations(providerId);
            }
          )
      );
    }
  };

  const onUpdate = (location: Location) => {
    if (providerState.provider && locationState.location) {
      const providerId = providerState.provider.ProviderID;
      const request = {
        Address1ID: location.Address1ID,
        Address2ID: location.Address2ID,
        Address3ID: location.Address3ID,
        AgendaName: location.AgendaName,
        Phone: location.Phone,
        Phone2: location.Phone2,
        Lattitude: location.Lattitude,
        Longitude: location.Longitude,
        AgendaID: locationState.location.AgendaID,
        Address: location.Address,
        AppName: locationState.location.AppName,
        LocationID: locationState.location.LocationID,
        IsOffline: location.IsOffline,
        IsHiddenOnWeb: location.IsHiddenOnWeb,
        AppointmentIntervalMinutes: location.IsOffline
          ? 0
          : location.AppointmentIntervalMinutes,
        Order: location.Order,
        Clinics: location.Clinics,
        AllowFutureAppointment: location.AllowFutureAppointment
      };

      updateProviderLocation(providerId, request).then((result) => {
        if (result) loadFullProviderLocations(providerId);
      });
    }
  };

  const onCreateNewLocation = () => {
    dispatch(showNewLocationCreateModal());
  };

  const onCreateLocationCallBack = (location: NewLocation) => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;
      const request = {
        AgendaName: location.AgendaName,
      };
      createNewLocation(providerId, request).then((result) => {
        if (result) {
          loadFullProviderLocations(providerId);
          dispatch(hideNewLocationModal());
        }
      });
    }
  };

  const onEditProviderEducationCourse = (
    providerEducationCourse: ProviderEducationCourse
  ) => {
    dispatch(editProviderEducationCourse(providerEducationCourse));
  };

  const onStopEditProviderEducationCourse = (
    providerEducationCourseID: number
  ) => {
    dispatch(stopEditEducationCourse(providerEducationCourseID));
  };

  const onProviderEducationCourseFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    dispatch(changeProviderEducationCourseField(name, value));
  };

  const onCreateNewProviderEducationCourse = () => {
    dispatch(showNewProviderEducationCourseCreateModal());
  };

  const onSaveProviderEducationCourse = () => {
    if (
      providerState.provider &&
      providerEducationCourseState.providerEducationCourse
    ) {
      const providerId = providerState.provider.ProviderID;
      const request = {
        Name: providerEducationCourseState.providerEducationCourse.Name,
        InstituteName:
          providerEducationCourseState.providerEducationCourse.InstituteName,
        ProviderEducationCourseID:
          providerEducationCourseState.providerEducationCourse
            .ProviderEducationCourseID,
        Date: providerEducationCourseState.providerEducationCourse.Date,
      };

      updateProviderEducationCourse(providerId, request).then((result) => {
        if (result) loadProviderEducationCourses(providerId);
      });
    }
  };

  const onSaveChangesEducationCourse = (
    ProviderEducationCourse: NewProviderEducationCourse
  ) => {
    const formattedDate = new Date(
      moment
        .parseZone(ProviderEducationCourse.ProviderEducationCourseDate)
        .format("YYYY-MM-DD")
    );

    const request = {
      Name: ProviderEducationCourse.ProviderEducationCourseName,
      ProviderEducationCourseID: 0,
      InstituteName:
        ProviderEducationCourse.ProviderEducationCourseInstituteName,
      Date: formattedDate,
      editing: false,
    };

    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;
      createProviderEducationCourse(providerId, request).then((result) => {
        if (result === true) {
          loadProviderEducationCourses(providerId);
          dispatch(hideNewProviderEducationCourseModal());
        }
      });
    }
  };

  function onChangeProviderEducationCourse(value: any, dateString: any) {
    dispatch(setCalendarDateEducationCourse(dateString));
  }

  const onDeleteProviderEducationCourse = (
    providerEducationCourseID: number
  ) => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      message.loading(
        i18n._(
          t(
            "ProfessionalPage.DeletingProviderEducationCourse"
          )`Deleting Education course...`
        ),
        0.5,
        () =>
          deleteProviderEducationCourse(
            providerId,
            providerEducationCourseID
          ).then((result) => {
            if (result) loadProviderEducationCourses(providerId);
          })
      );
    }
  };

  const onEditProviderEducationDegree = (
    providerEducationDegree: ProviderEducationDegree
  ) => {
    dispatch(editProviderEducationDegree(providerEducationDegree));
  };

  const onStopEditProviderEducationDegree = (
    providerEducationDegreeID: number
  ) => {
    dispatch(stopEditEducationDegree(providerEducationDegreeID));
  };

  const onProviderEducationDegreeFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    dispatch(changeProviderEducationDegreeField(name, value));
  };

  const onCreateNewProviderEducationDegree = () => {
    dispatch(showNewProviderEducationDegreeCreateModal());
  };

  const onSaveProviderEducationDegree = () => {
    if (
      providerState.provider &&
      providerEducationDegreeState.providerEducationDegree
    ) {
      const providerId = providerState.provider.ProviderID;
      const request = {
        Title: providerEducationDegreeState.providerEducationDegree.Title,
        InstituteName:
          providerEducationDegreeState.providerEducationDegree.InstituteName,
        ProviderEducationDegreeID:
          providerEducationDegreeState.providerEducationDegree
            .ProviderEducationDegreeID,
        DateFrom: providerEducationDegreeState.providerEducationDegree.DateFrom,
        DateTo: providerEducationDegreeState.providerEducationDegree.DateTo,
      };

      updateProviderEducationDegree(providerId, request).then((result) => {
        if (result) loadProviderEducationDegree(providerId);
      });
    }
  };

  function onChangeProviderEducationDegreeDateFrom(
    value: any,
    dateString: any
  ) {
    dispatch(setCalendarDateEducationDegreeDateFrom(dateString));
  }

  function onChangeProviderEducationDegreeDateTo(value: any, dateString: any) {
    dispatch(setCalendarDateEducationDegreeDateTo(dateString));
  }

  const onDeleteProviderEducationDegree = (
    providerEducationDegreeID: number
  ) => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      message.loading(
        i18n._(
          t(
            "ProfessionalPage.DeletingProviderEducationDegree"
          )`Deleting Education degree...`
        ),
        0.5,
        () =>
          deleteProviderEducationDegree(
            providerId,
            providerEducationDegreeID
          ).then((result) => {
            if (result) loadProviderEducationDegree(providerId);
          })
      );
    }
  };

  const onDeleteProvider = () => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      message.loading(
        i18n._(t("ProfessionalPage.DeletingProvider")`Deleting provider...`),
        0.5,
        () =>
          deleteProvider(providerId).then((result) => {
            if (result) history.push("/professionals");
          })
      );
    }
  };

  const onSaveChangesEducationDegree = (
    providerEducationDegree: NewProviderEducationDegree
  ) => {
    const formattedDateFrom = new Date(
      moment
        .parseZone(providerEducationDegree.ProviderEducationDegreeDateFrom)
        .format("YYYY-MM-DD")
    );
    const formattedDateTo = new Date(
      moment
        .parseZone(providerEducationDegree.ProviderEducationDegreeDateTo)
        .format("YYYY-MM-DD")
    );

    const request = {
      Title: providerEducationDegree.ProviderEducationDegreeTitle,
      ProviderEducationDegreeID: 0,
      InstituteName:
        providerEducationDegree.ProviderEducationDegreeInstituteName,
      DateFrom: formattedDateFrom,
      DateTo: formattedDateTo,
      editing: false,
    };

    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;
      createProviderEducationDegree(providerId, request).then((result) => {
        if (result === true) {
          loadProviderEducationDegree(providerId);
          dispatch(hideNewProviderEducationDegreeModal());
        }
      });
    }
  };

  const onCreateNewAppointmentReason = () => {
    dispatch(
      showProviderAppointmentReasonCreateModal(
        provider?.Locations ? provider?.Locations : []
      )
    );
  };

  const onSaveChangesAppointmentReason = (
    providerAppointmentReason: ProviderAppointmentReason
  ) => {
    const request = {
      Name: providerAppointmentReason.Name,
      DurationMinutes: providerAppointmentReason.DurationMinutes,
      editing: false,
      ProviderAppointmentReasonID:
        providerAppointmentReason.ProviderAppointmentReasonID,
      LocationsSelected: providerAppointmentReason.LocationsSelected,
      BasePricing: providerAppointmentReason.BasePricing
    };

    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;
      if (request.ProviderAppointmentReasonID === 0) {
        createProviderAppointmentReason(providerId, request).then((result) => {
          if (result === true) {
            loadAppointmentReason(providerId);
            dispatch(hideProviderAppointmentReasonModal());
            dispatch(stopEditProviderAppointmentReason());
          }
        });
      } else {
        updateProviderAppointmentReason(providerId, request).then((result) => {
          if (result === true) {
            loadAppointmentReason(providerId);
            dispatch(hideProviderAppointmentReasonModal());
            dispatch(stopEditProviderAppointmentReason());
          }
        });
      }
    }
  };

  const onEditAppointmentReason = (item: ProviderAppointmentReason) => {
    item.Locations = provider?.Locations ? provider?.Locations : [];
    dispatch(showProviderAppointmentReasonEditModal(item));
  };

  const onDeleteAppointmentReason = (appointmentReasonId: number) => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      message.loading(
        i18n._(
          t(
            "ProfessionalPage.DeletingProviderAppointmentReason"
          )`Deleting Appointment Reason...`
        ),
        0.5,
        () =>
          deleteProviderAppointmentReason(providerId, appointmentReasonId).then(
            (result) => {
              if (result) loadAppointmentReason(providerId);
            }
          )
      );
    }
  };

  const genders = [
    {
      value: "M",
      text: <Trans id="Common.GenderMasculineLabel">Masculine</Trans>,
    },
    {
      value: "F",
      text: <Trans id="Common.GenderFeminineLabel">Feminine</Trans>,
    },
  ];

  const locationColumns: ColumnProps<Location>[] = [
    {
      title: (
        <Trans render="strong" id="ProfessionalPage.Location.StateColumnTitle">
          State
        </Trans>
      ),
      key: "Address1",
      dataIndex: "Address1",
    },
    {
      title: (
        <Trans render="strong" id="ProfessionalPage.Location.CountyColumnTitle">
          County
        </Trans>
      ),
      key: "Address2",
      dataIndex: "Address2",
    },
    {
      title: (
        <Trans render="strong" id="ProfessionalPage.Location.CityColumnTitle">
          City
        </Trans>
      ),
      key: "Address3",
      dataIndex: "Address3",
    },
    {
      title: (
        <Trans render="strong" id="ProfessionalPage.Location.AgendaColumnTitle">
          Agenda
        </Trans>
      ),
      key: "AgendaName",
      dataIndex: "AgendaName",
    },
    {
      title: (
        <Trans render="strong" id="ProfessionalPage.Location.PhoneColumnTitle">
          Phone
        </Trans>
      ),
      key: "Phone",
      dataIndex: "Phone",
    },
    {
      title: (
        <Trans render="strong" id="ProfessionalPage.Location.Phone2ColumnTitle">
          Phone2
        </Trans>
      ),
      key: "Phone2",
      dataIndex: "Phone2",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.LongitudeColumnTitle"
        >
          Longitude
        </Trans>
      ),
      key: "Longitude",
      dataIndex: "Longitude",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.LattitudeColumnTitle"
        >
          Lattitude
        </Trans>
      ),
      key: "Lattitude",
      dataIndex: "Lattitude",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.AddressColumnTitle"
        >
          Address
        </Trans>
      ),
      key: "Address",
      dataIndex: "Address",
    },
    {
      title: (
        <Trans
          render="strong"
          id="Professional.AppointmentMinutesIntervalLabel"
        >
          Appointment Interval Minutes
        </Trans>
      ),
      key: "AppointmentIntervalMinutes",
      dataIndex: "AppointmentIntervalMinutes",
    },
    {
      title: (
        <Trans
          render="strong"
          id="Professional.Order"
        >
          Order
        </Trans>
      ),
      key: "Order",
      dataIndex: "Order",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.AppNameColumnTitle"
        >
          App
        </Trans>
      ),
      key: "AppName",
      dataIndex: "AppName",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.IsOfflineColumnTitle"
        >
          Offline
        </Trans>
      ),
      dataIndex: "IsOffline",
      key: "IsOffline",
      render: (IsOffline: boolean) => <YesNoCell value={!IsOffline} showIcon={false} />,
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.IsHiddenOnWebColumnTitle"
        >
          Hidden on Web
        </Trans>
      ),
      dataIndex: "IsHiddenOnWeb",
      key: "IsHiddenOnWeb",
      render: (IsHiddenOnWeb: boolean) => <YesNoCell value={IsHiddenOnWeb} showIcon={false} />,
    },
    {
      fixed: "right",
      width: 150,
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.Location.ActionsColumnTitle"
        >
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (location: Location) => {
        return (
          <div>
            <Button icon="edit" onClick={(_) => onEditLocation(location)} />
            <Divider type="vertical" />
            <Button
              icon="delete"
              type="danger"
              onClick={(_) =>
                onDeleteProviderLocation(location.LocationID, location.AgendaID)
              }
            />
          </div>
        );
      },
    },
  ];

  const providerEducationCourseColumns: ColumnProps<ProviderEducationCourse>[] =
    [
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationCourse.NameColumnTitle"
          >
            Name
          </Trans>
        ),
        key: "Name",
        render: (providerEducationCourse: ProviderEducationCourse) =>
          providerEducationCourse.editing ? (
            <Input
              name={providerEducationCourseNameOf("Name")}
              value={providerEducationCourseState.providerEducationCourse?.Name}
              onChange={onProviderEducationCourseFieldChange}
            />
          ) : (
            <div>{providerEducationCourse.Name}</div>
          ),
      },
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationCourse.InstituteNameColumnTitle"
          >
            InstituteName
          </Trans>
        ),
        key: "InstituteName",
        render: (providerEducationCourse: ProviderEducationCourse) =>
          providerEducationCourse.editing ? (
            <Input
              name={providerEducationCourseNameOf("InstituteName")}
              value={
                providerEducationCourseState.providerEducationCourse
                  ?.InstituteName
              }
              onChange={onProviderEducationCourseFieldChange}
            />
          ) : (
            <div>{providerEducationCourse.InstituteName}</div>
          ),
      },
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationCourse.DateColumnTitle"
          >
            Date
          </Trans>
        ),
        key: "Date",
        render: (providerEducationCourse: ProviderEducationCourse) =>
          providerEducationCourse.editing ? (
            <MonthPicker
              name={providerEducationCourseNameOf("Date")}
              onChange={onChangeProviderEducationCourse}
              defaultValue={moment(providerEducationCourse.Date, "YYYY-MM-DD")}
            />
          ) : (
            <div>
              {moment
                .parseZone(providerEducationCourse.Date)
                .locale("es")
                .format("MMMM YYYY")}
            </div>
          ),
      },
      {
        fixed: "right",
        width: 150,
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationCourse.ActionsColumnTitle"
          >
            Actions
          </Trans>
        ),
        key: "Actions",
        render: (providerEducationCourse: ProviderEducationCourse) => {
          return providerEducationCourse.editing ? (
            <div>
              <Button
                icon="save"
                type="primary"
                loading={
                  providerEducationCourseState.updatingProviderEducationCourse
                }
                onClick={onSaveProviderEducationCourse}
              />
              <Divider type="vertical" />
              <Button
                icon="close"
                disabled={
                  providerEducationCourseState.updatingProviderEducationCourse
                }
                onClick={(_) =>
                  onStopEditProviderEducationCourse(
                    providerEducationCourse.ProviderEducationCourseID
                  )
                }
              />
            </div>
          ) : providerEducationCourseState.canEdit ? (
            <div>
              <Button
                icon="edit"
                onClick={(_) =>
                  onEditProviderEducationCourse(providerEducationCourse)
                }
              />
              <Divider type="vertical" />
              <Button
                icon="delete"
                type="danger"
                loading={
                  providerEducationCourseState.updatingProviderEducationCourse
                }
                onClick={(_) =>
                  onDeleteProviderEducationCourse(
                    providerEducationCourse.ProviderEducationCourseID
                  )
                }
              />
            </div>
          ) : null;
        },
      },
    ];

  const providerEducationDegreeColumns: ColumnProps<ProviderEducationDegree>[] =
    [
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationDegree.TitleColumnTitle"
          >
            Title
          </Trans>
        ),
        key: "Title",
        render: (providerEducationDegree: ProviderEducationDegree) =>
          providerEducationDegree.editing ? (
            <Input
              name={providerEducationDegreeNameOf("Title")}
              value={
                providerEducationDegreeState.providerEducationDegree?.Title
              }
              onChange={onProviderEducationDegreeFieldChange}
            />
          ) : (
            <div>{providerEducationDegree.Title}</div>
          ),
      },
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationDegree.InstituteNameColumnTitle"
          >
            InstituteName
          </Trans>
        ),
        key: "InstituteName",
        render: (providerEducationDegree: ProviderEducationDegree) =>
          providerEducationDegree.editing ? (
            <Input
              name={providerEducationDegreeNameOf("InstituteName")}
              value={
                providerEducationDegreeState.providerEducationDegree
                  ?.InstituteName
              }
              onChange={onProviderEducationDegreeFieldChange}
            />
          ) : (
            <div>{providerEducationDegree.InstituteName}</div>
          ),
      },
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationDegree.DateFromColumnTitle"
          >
            DateFrom
          </Trans>
        ),
        key: "DateFrom",
        render: (providerEducationDegree: ProviderEducationDegree) =>
          providerEducationDegree.editing ? (
            <MonthPicker
              name={providerEducationDegreeNameOf("DateFrom")}
              onChange={onChangeProviderEducationDegreeDateFrom}
              defaultValue={moment(
                providerEducationDegree.DateFrom,
                "YYYY-MM-DD"
              )}
            />
          ) : (
            <div>
              {moment
                .parseZone(providerEducationDegree.DateFrom)
                .locale("es")
                .format("MMMM YYYY")}
            </div>
          ),
      },
      {
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationDegree.DateToColumnTitle"
          >
            DateTo
          </Trans>
        ),
        key: "DateTo",
        render: (providerEducationDegree: ProviderEducationDegree) =>
          providerEducationDegree.editing ? (
            <MonthPicker
              name={providerEducationDegreeNameOf("DateTo")}
              onChange={onChangeProviderEducationDegreeDateTo}
              defaultValue={moment(
                providerEducationDegree.DateTo,
                "YYYY-MM-DD"
              )}
            />
          ) : (
            <div>
              {moment
                .parseZone(providerEducationDegree.DateTo)
                .locale("es")
                .format("MMMM YYYY")}
            </div>
          ),
      },
      {
        fixed: "right",
        width: 150,
        title: (
          <Trans
            render="strong"
            id="ProfessionalPage.ProviderEducationDegree.ActionsColumnTitle"
          >
            Actions
          </Trans>
        ),
        key: "Actions",
        render: (providerEducationDegree: ProviderEducationDegree) => {
          return providerEducationDegree.editing ? (
            <div>
              <Button
                icon="save"
                type="primary"
                loading={
                  providerEducationDegreeState.updatingProviderEducationDegree
                }
                onClick={onSaveProviderEducationDegree}
              />
              <Divider type="vertical" />
              <Button
                icon="close"
                disabled={
                  providerEducationDegreeState.updatingProviderEducationDegree
                }
                onClick={(_) =>
                  onStopEditProviderEducationDegree(
                    providerEducationDegree.ProviderEducationDegreeID
                  )
                }
              />
            </div>
          ) : providerEducationDegreeState.canEdit ? (
            <div>
              <Button
                icon="edit"
                onClick={(_) =>
                  onEditProviderEducationDegree(providerEducationDegree)
                }
              />
              <Divider type="vertical" />
              <Button
                icon="delete"
                type="danger"
                loading={
                  providerEducationDegreeState.updatingProviderEducationDegree
                }
                onClick={(_) =>
                  onDeleteProviderEducationDegree(
                    providerEducationDegree.ProviderEducationDegreeID
                  )
                }
              />
            </div>
          ) : null;
        },
      },
    ];
  const AppointmentReasonsColumns: ColumnProps<ProviderAppointmentReason>[] = [
    {
      title: (
        <Trans render="strong" id="ProviderAppointmentReason.Name">
          Reason
        </Trans>
      ),
      key: "Name",
      dataIndex: "Name",
    },
    {
      title: (
        <Trans render="strong" id="ProviderAppointmentReason.DurationMinutes">
          Duration minutes
        </Trans>
      ),
      key: "DurationMinutes",
      dataIndex: "DurationMinutes",
    },
    {
      fixed: "right",
      width: 150,
      title: (
        <Trans
          render="strong"
          id="ProfessionalPage.AppointmentReason.ActionsColumnTitle"
        >
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (item: ProviderAppointmentReason) => {
        return (
          <div>
            <Button
              icon="edit"
              onClick={(_) => onEditAppointmentReason(item)}
            />
            <Divider type="vertical" />
            <Button
              icon="delete"
              type="danger"
              onClick={(_) =>
                onDeleteAppointmentReason(item.ProviderAppointmentReasonID)
              }
            />
          </div>
        );
      },
    },
  ];

  const beforeAvatarUpload = (_: any) => {
    return false;
  };

  const getBase64 = (img: any, callback: (result: any) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleAvatarChange = (info: any) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(
        i18n._(
          t(
            "ClinicsModal.OnlyImagesAllowedError"
          )`You can only upload JPG/PNG file!`
        )
      );
    }

    const isLt2M = info.file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        i18n._(
          t(
            "ClinicsModal.FileMustBeLessThan2MBError"
          )`  Image must smaller than 2MB!`
        )
      );
    }

    if (isJpgOrPng && isLt2M) {
      getBase64(info.file, (logoURL) =>
        dispatch(setNewProviderAvatar(logoURL))
      );
    }
  };

  const onSaveAvatar = () => {
    if (providerState.provider && providerState.providerAvatarBase64) {
      const providerId = providerState.provider.ProviderID;
      let base64 = providerState.providerAvatarBase64.replace(
        "data:image/png;base64,",
        ""
      );
      base64 = base64.replace("data:image/jpeg;base64,", "");

      const request = {
        Base64Image: base64,
      };

      updateProviderAvatar(providerId, request).then((response) => {
        if (response.success === true) {
          const newPicture = {
            Url: response.data.Data.Url,
            PictureID: response.data.Data.PictureID,
            IndAvatar: true,
          } as Picture;

          let pictures = [] as Picture[];
          if (providerState.provider)
            pictures = providerState.provider.Pictures;
          pictures.push(newPicture);
          dispatch(setProviderPictures(pictures));
          dispatch(setProviderAvatarCanSave(false));
          dispatch(setProviderAvatarCanDelete(true));
        }
      });
    }
  };

  const beforeAdditionalPicturesUpload = (_: any) => {
    return false;
  };

  const handleAdditionalPicturesChange = (info: any) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(
        i18n._(
          t(
            "ClinicsModal.OnlyImagesAllowedError"
          )`You can only upload JPG/PNG file!`
        )
      );
    }

    const isLt3M = info.file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      message.error(
        i18n._(
          t(
            "ClinicsModal.FileMustBeLessThan3MBError"
          )`  Image must smaller than 3MB!`
        )
      );
    }

    if (isJpgOrPng && isLt3M) {
      getBase64(info.file, (logoURL) =>
        dispatch(setProviderAdditionalPicture(logoURL))
      );
    }
  };

  const onSaveAdditionalPictures = () => {
    if (
      providerState.provider &&
      providerState.providerAdditionalPicturesBase64
    ) {
      const providerId = providerState.provider.ProviderID;
      let base64 = providerState.providerAdditionalPicturesBase64.replace(
        "data:image/png;base64,",
        ""
      );
      base64 = base64.replace("data:image/jpeg;base64,", "");

      const request = {
        Base64Image: base64,
      };

      addProviderAdditionalPicture(providerId, request).then((response) => {
        if (response.success === true) {
          dispatch(setProviderAdditionalPicture(""));

          const newPicture = {
            Url: response.data.Data.Url,
            PictureID: response.data.Data.PictureID,
          } as Picture;

          let pictures = [] as Picture[];
          if (providerState.provider)
            pictures = providerState.provider.Pictures;
          pictures.push(newPicture);
          dispatch(setProviderPictures(pictures));
        }
      });
    }
  };

  const onDeleteProviderAdditionalPictures = (pictureId: string) => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      message.loading(
        i18n._(
          t(
            "ProfessionalPage.DeletingProviderAdditionalPicture"
          )`Deleting provider additional picture...`
        ),
        0.5,
        () =>
          deleteProviderAdditionalPicture(providerId, pictureId).then(
            (result) => {
              if (result) {
                let pictures = [] as Picture[];
                if (providerState.provider) {
                  pictures = providerState.provider.Pictures.filter(
                    (item) => item.PictureID !== pictureId
                  );
                }
                dispatch(setProviderPictures(pictures));
              }
            }
          )
      );
    }
  };

  const onDeleteProviderAvatar = () => {
    if (providerState.provider) {
      const providerId = providerState.provider.ProviderID;

      const picture = providerState.provider.Pictures?.find((x) => x.IndAvatar);
      if (picture !== undefined) {
        message.loading(
          i18n._(
            t(
              "ProfessionalPage.DeletingProviderAdditionalPicture"
            )`Deleting provider additional picture...`
          ),
          0.5,
          () =>
            deleteProviderAdditionalPicture(providerId, picture.PictureID).then(
              (result) => {
                if (result) {
                  dispatch(setNewProviderAvatar(""));
                  let pictures = [] as Picture[];
                  if (providerState.provider) {
                    pictures = providerState.provider.Pictures.filter(
                      (item) => item.PictureID !== picture.PictureID
                    );
                  }
                  dispatch(setProviderPictures(pictures));
                  dispatch(setProviderAvatarCanSave(false));
                }
              }
            )
        );
      }
    }
  };

  const validateDomains = (rule: any, value: string[], callback: any, source?: any, options?: any) => {
    const invalidDomains = value.filter((x) => !x.startsWith('@'));

    if (invalidDomains.length > 0) {

      callback(<Trans id="ProfessionalPage.DomainError"> Domains must start with "@"
      </Trans>);
    } else {
      callback();
    }
  };

  return (
    <Container>
      <Tabs type="card">
        <TabPane tab="Perfil del profesional" key="1">
          <Skeleton loading={providerState.loading}>
            <Form colon={false} layout="horizontal" onSubmit={onSaveChanges}>
              <Row>
                <Col span={4}>
                  <Avatar
                    shape="square"
                    size={150}
                    icon="user"
                    src={avatar?.Url}
                  />
                  <Row>
                    <Link
                      to={{
                        pathname: providerState.provider?.UrlSlug,
                      }}
                      target="_blank"
                    >
                      <Trans id="ProfessionalPage.ViewProfileLink">
                        View public profile
                      </Trans>
                    </Link>
                  </Row>
                </Col>
                <Col span={20}>
                  <Row type="flex" gutter={16}>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <Trans id="Professional.TitleLable">Title</Trans>
                        }
                      >
                        {getFieldDecorator(nameof("Title"), {
                          initialValue: provider?.Title,
                          rules: [
                            {
                              required: true,
                              message: (
                                <Trans id="Common.RequiredFieldError">
                                  This field is required
                                </Trans>
                              ),
                            },
                          ],
                        })(
                          <Select>
                            {titles.map((title, index) => (
                              <Select.Option key={index} value={title.value}>
                                {title.label}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <Trans id="Professional.FirstNameLabel">
                            First name
                          </Trans>
                        }
                      >
                        {getFieldDecorator(nameof("FirstName"), {
                          initialValue: provider?.FirstName,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <Trans id="Professional.LastNameLabel">
                            Lastname
                          </Trans>
                        }
                      >
                        {getFieldDecorator(nameof("LastName"), {
                          initialValue: provider?.LastName,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <Trans id="Professional.SecondLastNameLabel">
                            Second Lastname
                          </Trans>
                        }
                      >
                        {getFieldDecorator(nameof("SecondLastName"), {
                          initialValue: provider?.SecondLastName,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row type="flex" gutter={16}>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <Trans id="Professional.GenderLabel">Gender</Trans>
                        }
                      >
                        {getFieldDecorator(nameof("Gender"), {
                          initialValue: provider?.Gender,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(
                          <Select>
                            {genders.map((gender, index) => (
                              <Select.Option key={index} value={gender.value}>
                                {gender.text}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <Trans id="Professional.ProfessionalCodeLabel">
                            Professional code
                          </Trans>
                        }
                      >
                        {getFieldDecorator(nameof("PublicProviderID"), {
                          initialValue: provider?.PublicProviderID,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <Trans id="Professional.BasePricingLabel">Base Price</Trans>
                        }
                      >
                        {getFieldDecorator(nameof("BasePricing"), {
                          initialValue: provider?.BasePricing,
                        })(
                          <InputNumber min={0} style={{ width: "100%" }} />
                        )}

                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <Trans id="Professional.BirthdateLabel">
                            Birthdate
                          </Trans>
                        }
                      >
                        {getFieldDecorator(nameof("DateofBirth"), {
                          initialValue: provider?.DateofBirth,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<DatePicker format="L" />)}
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <Trans id="Professional.PhoneCodeLabel">
                            Phone code
                          </Trans>
                        }
                      >
                        {getFieldDecorator(nameof("PhoneAreacode"), {
                          initialValue: provider?.PhoneAreacode,
                          rules: [
                            {
                              required: true,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input prefix="+" />)}
                      </Form.Item>
                    </Col>
                    <Col span={3}>
                      <Form.Item
                        label={
                          <Trans id="Professional.PhoneLabel">Phone</Trans>
                        }
                      >
                        {getFieldDecorator(nameof("PhoneNumber"), {
                          initialValue: provider?.PhoneNumber,
                          rules: [
                            {
                              required: false,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label={
                          <Trans id="Professional.EmailLabel">Email</Trans>
                        }
                      >
                        {getFieldDecorator(nameof("Email"), {
                          initialValue: provider?.Email,
                          rules: [
                            {
                              required: false,
                              message: <Trans id="Common.RequiredFieldError" />,
                            },
                          ],
                        })(<Input />)}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row type="flex" gutter={24}>
                <Col span={12}>
                  <Form.Item
                    label={
                      <Trans id="Professional.SummaryLabel">Summary</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("Summary"), {
                      initialValue: provider?.Summary,
                      rules: [
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ],
                    })(<Input.TextArea rows={5} />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.FeaturesTitle">Features</Trans>
                </Typography.Title>
              </Row>
              <Row type="flex" gutter={16}>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("IsEnabled"), {
                      initialValue: provider?.IsEnabled,
                      valuePropName: "checked",
                    })(<Checkbox>
                      <Trans id="Professional.IsEnabledLabel">Enabled</Trans></Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("IsPrivate"), {
                      initialValue: provider?.IsPrivate,
                      valuePropName: "checked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.IsPrivateLabel">Private</Trans>
                      </Checkbox>)}
                  </Form.Item>
                </Col>

                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("AllowPrivateMessage"), {
                      initialValue: provider?.AllowPrivateMessage,
                      valuePropName: "checked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.AllowPrivateMessageLabel">
                          Private Message
                        </Trans>
                      </Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("IsEmployee"), {
                      initialValue: provider?.IsEmployee,
                      valuePropName: "checked",
                    })(<Checkbox>
                      <Trans id="Professional.IsEmployeeLabel">
                        Is Employee
                      </Trans>
                    </Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    {getFieldDecorator(nameof("AllowOnlyExistingPatients"), {
                      initialValue:
                        provider?.AllowOnlyExistingPatients === true
                          ? true
                          : false,
                      valuePropName:
                        provider?.AllowOnlyExistingPatients === true
                          ? "checked"
                          : "unchecked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.AllowOnlyExistingPatients">
                          Allow Only Existing Patients
                        </Trans>
                      </Checkbox>)}

                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    {getFieldDecorator(nameof("AllowPatientComment"), {
                      initialValue:
                        provider?.AllowPatientComment === true
                          ? true
                          : false,
                      valuePropName:
                        provider?.AllowPatientComment === true
                          ? "checked"
                          : "unchecked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.AllowPatientComment">
                          Allow patient comment on new appointments
                        </Trans>
                      </Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    {getFieldDecorator(nameof("AllowMultipleAppointmentsSameDayPerPatient"), {
                      initialValue:
                        provider?.AllowMultipleAppointmentsSameDayPerPatient === true
                          ? true
                          : false,
                      valuePropName:
                        provider?.AllowMultipleAppointmentsSameDayPerPatient === true
                          ? "checked"
                          : "unchecked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.AllowMultipleAppointmentsSameDayPerPatient">
                          Allow multiple appointments same day per patient
                        </Trans>
                      </Checkbox>)}

                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item>
                    {getFieldDecorator(nameof("CanPatientScheduledAppointmentsWithoutAccount"), {
                      initialValue:
                        provider?.CanPatientScheduledAppointmentsWithoutAccount === true
                          ? true
                          : false,
                      valuePropName:
                        provider?.CanPatientScheduledAppointmentsWithoutAccount === true
                          ? "checked"
                          : "unchecked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.CanPatientScheduledAppointmentsWithoutAccount">
                          Enable scheduling without creating a patient account
                        </Trans>
                      </Checkbox>)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
              <Col span={8}>
                  <Form.Item>
                    {getFieldDecorator(nameof("HideSecondaryAccountScheduleOption"), {
                      initialValue:
                        provider?.HideSecondaryAccountScheduleOption === true
                          ? true
                          : false,
                      valuePropName:
                        provider?.HideSecondaryAccountScheduleOption === true
                          ? "checked"
                          : "unchecked",
                    })(
                      <Checkbox>
                        <Trans id="Professional.HideSecondaryAccountScheduleOption">
                          Hide option to schedule an appointment for another person
                        </Trans>
                      </Checkbox>)}
                  </Form.Item>
                </Col>
              </Row>

              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.AttentionTitle">Attention</Trans>
                </Typography.Title>
              </Row>

              <Row type="flex" gutter={16}>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("HasOnSiteCare"), {
                      initialValue: provider?.HasOnSiteCare,
                      valuePropName: "checked",
                    })(<Checkbox>
                      <Trans id="Professional.HasOnSiteCareLabel">
                        On-site care
                      </Trans>
                    </Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item                  >
                    {getFieldDecorator(nameof("HasHomeCare"), {
                      initialValue: provider?.HasHomeCare,
                      valuePropName: "checked",
                    })(<Checkbox>
                      <Trans id="Professional.HomeCareLabel">Home care</Trans>
                    </Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("HasTeleHealth"), {
                      initialValue: provider?.HasTeleHealth,
                      valuePropName: "checked",
                    })(<Checkbox>
                      <Trans id="Professional.TeleHealthLabel">
                        TeleHealth
                      </Trans>
                    </Checkbox>)}
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item>
                    {getFieldDecorator(nameof("Has247Attention"), {
                      initialValue: provider?.Has247Attention,
                      valuePropName: "checked",
                    })(<Checkbox>24/7</Checkbox>)}
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.LocationsTitle">Agendas</Trans>
                </Typography.Title>
              </Row>
              <Row type="flex" align="middle">
                <Col span={18}>
                  <Button
                    icon="plus"
                    type="primary"
                    onClick={onCreateNewLocation}
                  >
                    <Trans
                      render="span"
                      id="ProfessionalsPage.NewLocationButton"
                    >
                      Agenda
                    </Trans>
                  </Button>
                </Col>
              </Row>

              <Table
                rowKey={(loc) =>
                  loc.LocationID.toString() + "-" + loc.AgendaName
                }
                dataSource={provider?.Locations}
                columns={locationColumns}
                pagination={false}
                scroll={{ x: 1500 }}
                style={{ marginTop: 10 }}
                loading={providerState.loadingLocations}
              />
              <Divider />
              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.AppointmentReasonsTitle">
                    Appointment reasons
                  </Trans>
                </Typography.Title>
              </Row>
              <Row type="flex" align="middle">
                <Col span={18}>
                  <Button
                    icon="plus"
                    type="primary"
                    onClick={onCreateNewAppointmentReason}
                  >
                    <Trans
                      render="span"
                      id="ProfessionalPage.NewAppointmentReason"
                    >
                      Reason
                    </Trans>
                  </Button>
                </Col>
              </Row>
              <Table
                rowKey={(item) => item.ProviderAppointmentReasonID.toString()}
                dataSource={provider?.AppointmentReason}
                columns={AppointmentReasonsColumns}
                pagination={false}
                scroll={{ x: 1500 }}
                style={{ marginTop: 10 }}
                loading={providerState.loadingAppointmentReason}
              />
              <Divider />
              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.OthersTitle">Others</Trans>
                </Typography.Title>
              </Row>
              <Row type="flex" gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Common.MainSpecialtyLabel">Main specialty</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("MainSpecialty"), {
                      initialValue: provider?.Specialties?.find(x => x.IsMain)?.SpecialtyID,
                      rules: [
                        {
                          required: true,
                          message: (
                            <Trans id="Common.RequiredFieldError">
                              This field is required
                            </Trans>
                          ),
                        },
                      ],
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="default"
                        loading={specialtyState.loading}
                        showSearch
                      >
                        {specialtyState.specialties.map((specialty, index) => (
                          <Select.Option
                            key={index}
                            value={specialty.SpecialtyID}
                          >
                            {specialty.Name}
                          </Select.Option>
                        ))}
                      </Select>)}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Common.SpecialtiesLabel">Specialties</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("Specialties"), {
                      initialValue: provider?.Specialties?.map(
                        (x) => x.SpecialtyID
                      ),
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={specialtyState.loading}
                        showSearch
                      >
                        {specialtyState.specialties.map((specialty, index) => (
                          <Select.Option
                            key={index}
                            value={specialty.SpecialtyID}
                          >
                            {specialty.Name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Common.ProceduresLabel">Procedures</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("Procedures"), {
                      initialValue: provider?.Procedures?.map(
                        (x) => x.ProcedureID
                      ),
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={procedureState.loading}
                        showSearch
                      >
                        {procedureState.procedures.map((procedure, index) => (
                          <Select.Option
                            key={index}
                            value={procedure.ProcedureID}
                          >
                            {procedure.Name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={<Trans id="Common.LanguagesLabel">Languages</Trans>}
                  >
                    {getFieldDecorator(nameof("Languages"), {
                      initialValue: provider?.Languages?.map(
                        (x) => x.LanguageCode
                      ),
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={specialtyState.loading}
                        showSearch
                      >
                        {languageState.languages.map((language, index) => (
                          <Select.Option
                            key={index}
                            value={language.LanguageCode}
                          >
                            {language.Name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Common.ProviderFocusLabel">
                        Provider Focus
                      </Trans>
                    }
                  >
                    {getFieldDecorator(nameof("ProviderFocus"), {
                      initialValue: provider?.ProviderFocus?.map(
                        (x) => x.FocusID
                      ),
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={providerFocusState.loading}
                        showSearch
                      >
                        {providerFocusState.providerFocus.map(
                          (providerFocus, index) => (
                            <Select.Option
                              key={index}
                              value={providerFocus.FocusID}
                            >
                              {providerFocus.Name}
                            </Select.Option>
                          )
                        )}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={<Trans id="Common.KeywordsLabel">Keywords</Trans>}
                  >
                    {getFieldDecorator(nameof("Keywords"), {
                      initialValue: provider?.Keywords?.map((x) => x.KeywordID),
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={keywordState.loading}
                        showSearch
                      >
                        {keywordState.keywords.map((keyword, index) => (
                          <Select.Option key={index} value={keyword.KeywordID}>
                            {keyword.Term}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Common.PaymentMethodsLabel">Payment methods</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("PaymentMethods"), {
                      initialValue: provider?.PaymentMethods?.map(
                        (x) => x.PaymentMethodID
                      ),

                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={paymentMethodState.loading}
                        showSearch
                      >
                        {paymentMethodState.paymentMethods.map((item, index) => (
                          <Select.Option
                            key={index}
                            value={item.PaymentMethodID}
                          >
                            {item.Name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Professional.ScheduleFlow">
                        Scheduling flow
                      </Trans>
                    }
                  >
                    {getFieldDecorator(nameof("ScheduleFlow"), {
                      initialValue: provider?.ScheduleFlow,
                    })(
                      <Select>
                        {scheduleFlows.map((flow, index) => (
                          <Select.Option key={index} value={flow.value}>
                            {flow.displayName}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Professional.Currency">
                        Moneda
                      </Trans>
                    }
                  >
                    {getFieldDecorator(nameof("Currency"), {
                      initialValue: provider?.Currency?.CurrencyCode,
                    })(
                      <Select>
                        {currencys.map((item, index) => (
                          <Select.Option key={index} value={item.CurrencyCode}>
                            {item.CurrencyName}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={
                    <Trans id="ProfessionalPage.Insuraces">
                      Insurances
                    </Trans>
                  }>
                    {getFieldDecorator(nameof("Insurances"), {
                      initialValue: provider?.Insurances?.map(
                        (x) => x.InsuranceID
                      ),
                    })(
                      <Select
                        filterOption={filterByValueAndText}
                        mode="multiple"
                        loading={insuranceState.loading}
                        showSearch
                      >
                        {insuranceState.insurances.map((insurance, index) => (
                          <Select.Option
                            key={index}
                            value={insurance.InsuranceID}
                          >
                            {insurance.Name}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={
                      <Trans id="Professional.AllowedDomainsForAppointmentScheduling">allowed domains for appointment scheduling</Trans>
                    }
                  >
                    {getFieldDecorator(nameof("AllowedDomainsForAppointmentScheduling"), {
                      initialValue: provider?.AllowedDomainsForAppointmentScheduling ?? [],
                      rules: [
                        {
                          required: false,
                          message: 'Please enter at least one domain',
                        },
                        {
                          validator: validateDomains, // Custom validator function
                        },
                      ],
                    })(
                      <Select
                        mode="tags"
                        style={{ width: '100%' }}
                        tokenSeparators={[',']}>
                        {provider?.AllowedDomainsForAppointmentScheduling?.map((value, index) => (
                          <Select.Option key={index} value={value}>
                            {value}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>

                </Col>
              </Row>
              <Row type="flex" justify="end">
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={providerState.saving}
                  style={{ marginTop: 26 }}
                >
                  <Trans render="span" id="Professional.SaveChangesButton">
                    Save changes
                  </Trans>
                </Button>

                <Popconfirm
                  title={
                    <Trans id="Professional.DeleteActionConfirmationText">
                      Do you wish to delete this provider?
                    </Trans>
                  }
                  onConfirm={() => onDeleteProvider()}
                  okText={
                    <Trans id="Professional.DeleteActionButton">Delete</Trans>
                  }
                >
                  <Button
                    className="margin-left-10"
                    type="danger"
                    style={{ marginTop: 26 }}
                  >
                    <Trans render="span" id="Professional.DeleteButton">
                      Delete
                    </Trans>
                  </Button>
                </Popconfirm>
              </Row>
              <Divider />

              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.ProviderEducationCoursesTitle">
                    Education courses
                  </Trans>
                </Typography.Title>
              </Row>
              <Row type="flex" align="middle">
                <Col span={18}>
                  <Button
                    icon="plus"
                    type="primary"
                    onClick={onCreateNewProviderEducationCourse}
                  >
                    <Trans
                      render="span"
                      id="ProfessionalsPage.NewProviderEducationCourseButton"
                    >
                      Course
                    </Trans>
                  </Button>
                </Col>
              </Row>
              <Table
                rowKey="ProviderEducationCourseID"
                dataSource={provider?.ProviderEducationCourse}
                columns={providerEducationCourseColumns}
                pagination={false}
                scroll={{ x: 1500 }}
                style={{ marginTop: 10 }}
                loading={providerState.loadingproviderEducationCourse}
              />
              <Divider />
              <Row type="flex">
                <Typography.Title level={4}>
                  <Trans id="ProfessionalPage.ProviderEducationDegreeTitle">
                    Education degrees
                  </Trans>
                </Typography.Title>
              </Row>
              <Row type="flex" align="middle">
                <Col span={18}>
                  <Button
                    icon="plus"
                    type="primary"
                    onClick={onCreateNewProviderEducationDegree}
                  >
                    <Trans
                      render="span"
                      id="ProfessionalsPage.NewProviderEducationDegreeButton"
                    >
                      Degree
                    </Trans>
                  </Button>
                </Col>
              </Row>
              <Table
                rowKey="ProviderEducationDegreeID"
                dataSource={provider?.ProviderEducationDegree}
                columns={providerEducationDegreeColumns}
                pagination={false}
                scroll={{ x: 1500 }}
                style={{ marginTop: 10 }}
                loading={providerState.loadingProviderEducationDegree}
              />
              <Divider />
              {provider?.PlanName &&
                <>
                  <Row>
                    <Col span={24}>
                      <Typography.Title level={4}>
                        <Trans id="ProfessionalPage.PlanFeatures">Plan features</Trans>
                      </Typography.Title>

                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Typography.Text strong style={{ fontSize: "16px", color: "black" }} >
                        <Trans id="ProfessionalPage.PlanTitle">Name: </Trans> {provider.PlanName}
                      </Typography.Text>
                    </Col>
                  </Row>
                  {provider.HasWidget &&
                    <Row>
                      <Col span={6} style={{ marginTop: "10px" }}>
                        <IframeFileGenerator src={provider.WidgetUrl} />
                      </Col>
                    </Row>
                  }
                  <Divider />

                </>
              }

              {providerState.provider?.QRCodeBase64 !== "" && providerState.provider?.QRCodeBase64 !== undefined &&
                <>
                  <Row>
                    <Col span={24}>
                      <Typography.Title level={4}>
                        <Trans id="ProfessionalPage.QRCode">QR code</Trans>
                      </Typography.Title>

                    </Col>
                  </Row>

                  <Row>
                    <Col span={6}>
                      <QrCodeDonwloader
                        qrCodeBase64={providerState.provider?.QRCodeBase64}
                        qrName={qrName} />
                    </Col>
                  </Row>
                  <Divider />
                </>
              }



            </Form>
            {locationState.showModal && (
              <ProviderNewAgendaModal
                loading={locationState.loading}
                showModal={locationState.showModal}
                modalType={"create"}
                newLocation={locationState.newLocation}
                onCreate={onCreateLocationCallBack}
              />
            )}
            {locationState.showEditModal && (
              <ProviderEditAgendaModal
                loading={locationState.loading}
                showEditModal={locationState.showEditModal}
                modalType={"edit"}
                location={locationState.location}
                onUpdate={onUpdate}
              />
            )}
            {providerEducationCourseState.showModal && (
              <ProviderEducationCourseModal
                loading={providerEducationCourseState.loading}
                showModal={providerEducationCourseState.showModal}
                modalType={providerEducationCourseState.modalType}
                newProviderEducationCourse={
                  providerEducationCourseState.newProviderEducationCourse
                }
                onCreate={onSaveChangesEducationCourse}
              />
            )}
            {providerEducationDegreeState.showModal && (
              <ProviderEducationDegreeModal
                loading={providerEducationDegreeState.loading}
                showModal={providerEducationDegreeState.showModal}
                modalType={providerEducationDegreeState.modalType}
                newProviderEducationDegree={
                  providerEducationDegreeState.newProviderEducationDegree
                }
                onCreate={onSaveChangesEducationDegree}
              />
            )}
            {providerAppointmentReasonState.showModal && (
              <ProviderAppointmentReasonModal
                loading={providerAppointmentReasonState.loading}
                showModal={providerAppointmentReasonState.showModal}
                modalType={
                  providerAppointmentReasonState.isEdit ? "create" : "edit"
                }
                newProviderAppointmentReason={
                  providerAppointmentReasonState.newProviderAppointmentReason
                }
                onCreate={onSaveChangesAppointmentReason}
              />
            )}
          </Skeleton>
        </TabPane>
        <TabPane tab="Imágenes" key="2">
          <Skeleton loading={providerState.loading}>
            <Row type="flex" gutter={16}>
              <Col span={6}>
                <Form.Item>
                  <Typography.Title level={4}>
                    <Trans id="ProfessionalPage.AvatarPictureTitle">
                      Profile picture
                    </Trans>
                  </Typography.Title>

                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeAvatarUpload}
                    onChange={handleAvatarChange}
                  >
                    <div>
                      {providerState.providerAvatarBase64 ? (
                        <div>
                          <div>
                            <img
                              src={providerState.providerAvatarBase64}
                              alt="Avatar"
                              style={{ width: "150px" }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <Icon type="plus" />
                          <Trans
                            render="div"
                            id="ProfessionalPage.Form.AddAvatarLabel"
                          >
                            Add avatar
                          </Trans>
                        </div>
                      )}
                    </div>
                  </Upload>
                  {providerState.canDeleteProviderAvatar === true ? (
                    <div>
                      <Button
                        icon="delete"
                        type="danger"
                        loading={providerState.loading}
                        onClick={() => onDeleteProviderAvatar()}
                      />
                    </div>
                  ) : null}

                  {providerState.canSaveNewProviderAvatar === true ? (
                    <Button
                      icon="save"
                      type="primary"
                      loading={providerState.saving}
                      onClick={onSaveAvatar}
                    >
                      <Trans
                        render="span"
                        id="ProfessionalPage.SaveAvatarChangesButton"
                      >
                        Save changes
                      </Trans>
                    </Button>
                  ) : null}
                </Form.Item>
              </Col>

              <Col span={18}>
                <Form.Item>
                  <Row>
                    <Typography.Title level={4}>
                      <Trans id="ProfessionalPage.AdditionalPictureTitle">
                        Additional pictures
                      </Trans>
                    </Typography.Title>
                  </Row>
                  <Row type="flex" gutter={16}>
                    {provider?.Pictures?.filter(
                      (pictures) => pictures.IndAvatar !== true
                    ).map((picture, index) => {
                      return (
                        <Col
                          span={8}
                          key={picture.PictureID}
                          className="text-center"
                        >
                          <div>
                            <img
                              alt=""
                              className="provider-profile-pictures"
                              src={picture.Url}
                            />
                          </div>
                          <div>
                            <Button
                              icon="delete"
                              type="danger"
                              loading={providerState.loading}
                              onClick={() =>
                                onDeleteProviderAdditionalPictures(
                                  picture.PictureID
                                )
                              }
                            />
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                  <Row type="flex" gutter={16}>
                    <Col span={24}>
                      <Upload
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeAdditionalPicturesUpload}
                        onChange={handleAdditionalPicturesChange}
                      >
                        <div>
                          {providerState.providerAdditionalPicturesBase64 ? (
                            <div>
                              <img
                                src={
                                  providerState.providerAdditionalPicturesBase64
                                }
                                alt="Avatar"
                                style={{ width: "200px" }}
                              />
                            </div>
                          ) : (
                            <div>
                              <Icon type="plus" />
                              <Trans
                                render="div"
                                id="ProfessionalPage.Form.AddAdditionalPicturesLabel"
                              >
                                Add additional pictures
                              </Trans>
                            </div>
                          )}
                        </div>
                      </Upload>
                    </Col>
                  </Row>
                  {providerState.providerAdditionalPicturesBase64 ? (
                    <Row type="flex" gutter={16}>
                      <Col span={24}>
                        <Button
                          icon="save"
                          type="primary"
                          loading={providerState.saving}
                          onClick={onSaveAdditionalPictures}
                        >
                          <Trans
                            render="span"
                            id="ProfessionalPage.SaveAdditionalPicturesChangesButton"
                          >
                            Save picture
                          </Trans>
                        </Button>
                        <Divider></Divider>
                      </Col>
                    </Row>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
          </Skeleton>
        </TabPane>
      </Tabs>
    </Container >
  );
};

const mapState = (state: any): State => ({
  keywordState: state.keywordState,
  languageState: state.languageState,
  locationState: state.locationState,

  providerState: state.providerState,
  procedureState: state.procedureState,
  specialtyState: state.specialtyState,
  providerFocusState: state.providerFocusState,
  providerEducationCourseState: state.providerEducationCourseState,
  providerEducationDegreeState: state.providerEducationDegreeState,
  providerAppointmentReasonState: state.providerAppointmentReasonState,
  insuranceState: state.insuranceState,
  paymentMethodState: state.paymentMethodState
});

interface State {
  keywordState: KeywordState;
  languageState: LanguageState;
  locationState: LocationState;

  providerState: ProviderState;
  procedureState: ProcedureState;
  specialtyState: SpecialtyState;
  providerFocusState: ProviderFocusState;
  providerEducationCourseState: ProviderEducationCourseState;
  providerEducationDegreeState: ProviderEducationDegreeState;
  providerAppointmentReasonState: ProviderAppointmentReasonState;
  insuranceState: InsuranceState,
  paymentMethodState: PaymentMethodState
}

export default Form.create()(ProfessionalPage);
