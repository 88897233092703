import React from "react";
import "./EmptyLayout.css";
import { Layout } from "antd";
import { LocaleProvider } from "../../components/utils";

const { Content } = Layout;

export const EmptyLayout: React.FC = props => {
  const { children } = props;

  return (
    <LocaleProvider>
      <Layout className="empty-layout">
        <Content>{children}</Content>
      </Layout>
    </LocaleProvider>
  );
};
