import { ImportProviderState } from "./Types";
import {
  LOADING_ORGANIZATION_FAILED,
  LOADING_ORGANIZATION,
  SET_ORGANIZATION,
  IMPORTING_DOCTOR,
  IMPORTED_DOCTOR,
  STOP_IMPORTING,
  IS_HULI_SELECTED,
  IS_ZH_SELECTED,
  IS_CNLP_SELECTED,
} from "./Actions";

const initialState: ImportProviderState = {
  loading: false,
  organization: {
    Id: "",
    Name: "",
    Doctors: [],
  },
  errorMessage: "",
  isHuliSelected: false,
  isZHSelected: false,
  isCnlpSelected:false
};

export const importProviderReducer = (
  state = initialState,
  action: any
): ImportProviderState => {
  switch (action.type) {
    case IMPORTING_DOCTOR:
      return {
        ...state,
        organization: {
          ...state.organization,
          Doctors: state.organization.Doctors.map((x) => {
            if (x.Id === action.doctorId) {
              x.Loading = true;
              return x;
            }
            return x;
          }),
        },
      };

    case IMPORTED_DOCTOR:
      return {
        ...state,
        organization: {
          ...state.organization,
          Doctors: state.organization.Doctors.filter(
            (x) => x.Id !== action.doctorId
          ),
        },
      };

    case LOADING_ORGANIZATION:
      return {
        ...state,
        loading: true,
      };

    case LOADING_ORGANIZATION_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };

    case SET_ORGANIZATION:
      return {
        ...state,
        loading: false,
        organization: action.organization,
      };

    case STOP_IMPORTING:
      return {
        ...state,
        organization: {
          ...state.organization,
          Doctors: state.organization.Doctors.map((x) => {
            if (x.Id === action.doctorId) {
              x.Loading = false;
              return x;
            }
            return x;
          }),
        },
      };

    case IS_HULI_SELECTED:
      return {
        ...state,
        isHuliSelected: true,
        isZHSelected: false,
        isCnlpSelected:false
      };
    case IS_ZH_SELECTED:
      return {
        ...state,
        isZHSelected: true,
        isHuliSelected: false,
        isCnlpSelected:false
      };
      case IS_CNLP_SELECTED:
        return {
          ...state,
          isZHSelected: false,
          isHuliSelected: false,
          isCnlpSelected:true
        };

    default:
      return state;
  }
};
