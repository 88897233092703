import { LocationState } from "./Types";
import {
  SET_STATES,
  LOADING_STATES,
  LOADING_COUNTIES,
  SET_COUNTIES,
  LOADING_CITIES,
  SET_CITIES,
  EDIT_LOCATION,
  STOP_EDIT,
  CHANGE_LOCATION_FIELD,
  UPDATING_LOCATION,
  UPDATED_LOCATION,
  CHANGING_LOCATION,
  CHANGING_LOCATION_FINISHED,
  HIDE_NEW_LOCATION_MODAL,
  SHOW_NEW_LOCATION_CREATE_MODAL,
  SHOW_EDIT_LOCATION_MODAL,
  HIDE_EDIT_LOCATION_MODAL

} from "./Actions";

const initialState: LocationState = {
  updatingLocation: false,
  canEdit: true,
  cities: [],
  counties: [],
  loading: false,
  loadingCities: false,
  loadingCounties: false,
  loadingStates: false,
  states: [],
  modalType: "create",
  showModal: false,
  showEditModal: false,
};

export const locationReducer = (
  state = initialState,
  action: any
): LocationState => {
  switch (action.type) {
    case CHANGE_LOCATION_FIELD:
      if (state.location) {
        return {
          ...state,
          location: {
            ...state.location,
            [action.name]: action.value,
          },
        };
      }

      return state;

    case EDIT_LOCATION:
      return {
        ...state,
        modalType: "edit",
        showEditModal: true,
        location: action.location,
      };

    case STOP_EDIT:
      return {
        ...state,
        canEdit: true,
        location: undefined,
      };

    case LOADING_CITIES:
      return {
        ...state,
        cities: [],
        loadingCities: true,
      };

    case SET_CITIES:
      return {
        ...state,
        cities: action.cities,
        loadingCities: false,
      };

    case LOADING_COUNTIES:
      return {
        ...state,
        cities: [],
        counties: [],
        loadingCounties: true,
      };

    case SET_COUNTIES:
      return {
        ...state,
        counties: action.counties,
        loadingCounties: false,
      };

    case LOADING_STATES:
      return {
        ...state,
        cities: [],
        counties: [],
        loadingStates: true,
        states: [],
      };

    case SET_STATES:
      return {
        ...state,
        loadingStates: false,
        states: action.states,
      };

    case UPDATING_LOCATION:
      return {
        ...state,
        updatingLocation: true,
      };

      case CHANGING_LOCATION:
        return {
          ...state,
          updatingLocation: true,
          loading:false,
        };
  

      case CHANGING_LOCATION_FINISHED:
        return {
          ...state,
          loading:false,
          showEditModal: !action.result,
          updatingLocation: action.result
            ? !state.updatingLocation
            : state.updatingLocation,
        };

    case UPDATED_LOCATION:
      return {
        ...state,
        canEdit: action.result,
        updatingLocation: false,
        location: action.result ? undefined : state.location,
      };

    case HIDE_NEW_LOCATION_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case SHOW_EDIT_LOCATION_MODAL:
      return {
        ...state,
        modalType: "edit",
        showEditModal:false,
        location: undefined,
      };

      case HIDE_EDIT_LOCATION_MODAL:
        return {
          ...state,
          showEditModal:false,
        };
  
      case SHOW_NEW_LOCATION_CREATE_MODAL:
        return {
          ...state,
          modalType: "create",
          showModal: true,
          showEditModal:false,
          location: undefined,
        };

    default:
      return state;
  }
};
