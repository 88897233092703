import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingKeywords,
  Keyword,
  setKeywords,
  changingKeyword,
  changingKeywordFinished,
  deleteKeywordFinished,
  setKeyword,
} from "../../store/keyword";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadKeywordsFromAdmin = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/keyword/${getDirectoryTenantId()}`;

  try {
    store.dispatch(loadingKeywords());
    const response = await httpClient.get<GenericApiResponse<Keyword[]>>(url);
    store.dispatch(setKeywords(response.data.Data));
    return true;
  } catch {
    store.dispatch(setKeywords([]));
    return false;
  }
};

export const loadKeyword = async (keywordId: number) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/keyword/${keywordId}/profile`;

  try {
    store.dispatch(changingKeyword());
    const response = await httpClient.get<GenericApiResponse<Keyword>>(url);
    store.dispatch(setKeyword(response.data.Data));
    return true;
  } catch {
    store.dispatch(setKeyword(undefined));
    return false;
  }
};

export const createKeyword = async (request: Keyword) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"admin/keyword";

  try {
    store.dispatch(changingKeyword());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingKeywordFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingKeywordFinished(false));
    return false;
  }
};

export const updateKeyword = async (request: Keyword) => {
  const { KeywordID, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/keyword/${KeywordID}`;

  try {
    store.dispatch(changingKeyword());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingKeywordFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingKeywordFinished(false));
    return false;
  }
};

export const deleteKeyword = async (keywordId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/keyword/${keywordId}`;

  try {
    store.dispatch(changingKeyword());
    await httpClient.delete(url);
    store.dispatch(deleteKeywordFinished(true));
    return true;
  } catch (message) {
    notification.error({
      message: i18n._(
        t("KeywordService.CouldNotDeleteKeywordErrorTitle")`Keyword not deleted`
      ),
      description: i18n._(
        t(
          "KeywordService.CouldNotDeleteKeywordErrorDescription"
        )`The keyword could not be deleted.`
      ),
    });
    store.dispatch(deleteKeywordFinished(false));
    return false;
  }
};
