import React from "react";
import { IframeGeneratorProps } from "./types";
import { Trans } from "@lingui/macro";
import { Button } from "antd";

const IframeFileGenerator = ({ src }: IframeGeneratorProps) => {
  const generateIframeContent = () => {
      const iframeCode = `
      <!DOCTYPE html>
      <html>
      <head>
        <title>Downloadable Iframe</title>
      </head>
      <body>
	    <!--Con la etiqueta iframe puedes incrustrar el perfil del profesional de la salud en su pagina web,  
		    A continuacion en la etiqueta style puedes modificar el min-height para que tenga un tamaño minimo  -->
        
        <iframe id="resizing-iframe" sandbox="allow-same-origin allow-scripts" src="${src}" frameborder="0" style="width: 100%; min-height: 450px; border: none;"></iframe>
    
		  <!--A continuacion dejamos este script base que tiene la logica que permite hacer crecer dinamicamente al iframe, sin embargo puede ser que debas hacer algunos ajustes 
			dependiendo de la tecnología que uses -->
        <script>
          function resizeIframe(obj) {
            obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
          }

          const iframe = document.getElementById('resizing-iframe');
          iframe.onload = function() {
            resizeIframe(iframe);
          };
        </script>
      </body>
      </html>
    `;
    return iframeCode;
  };

  const handleDownload = () => {
    const iframeCode = generateIframeContent();

    const blob = new Blob([iframeCode], { type: 'text/html' });
    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = blobUrl;
    a.download = 'iframe_code.html';
    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(blobUrl);
    document.body.removeChild(a);
  };

  return (
    <Button
      icon="download"
      type="primary"
      onClick={handleDownload}
    >
      <Trans render="span" id="ProfessionalsPage.DownloadWidget">
        Download widget for embedding in website
      </Trans>
    </Button>
  );
};


export default IframeFileGenerator;