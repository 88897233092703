import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import { getDirectoryTenantId } from "../../utils";
import { PaymentMethod, loadingPaymentMethods, setPaymentMethods } from '../../store/paymentMethod';
import { LocalStorageKeys } from "../../utils/Constants";

export const loadPaymentMethodsAdmin = async () => {
    const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/paymentMethods/directory/${getDirectoryTenantId()}`;

    try {
        store.dispatch(loadingPaymentMethods());
        const response = await httpClient.get<GenericApiResponse<PaymentMethod[]>>(url);
        store.dispatch(setPaymentMethods(response.data.Data));
        return true;
    } catch {
        store.dispatch(setPaymentMethods([]));
        return false;
    }
};