import React from "react";
import "./InsuranceModal.css";
import { Form, Input, Modal, Row, Col, Button, Checkbox } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../utils";
import { Insurance, hideInsuranceModal } from "../../store/insurance";
import { InsuranceModalProps } from "./Types";
import { useDispatch } from "react-redux";
import TextArea from "antd/lib/input/TextArea";

const FormStateless: React.FC<InsuranceModalProps> = (props) => {
  const {
    form,
    loading,
    modalType,
    onCreate,
    onUpdate,
    showModal,
    insurance,
    insuranceNames
  } = props;
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();
  const nameof = nameofFactory<Insurance>();

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const insuranceRequest = {
        ...values,
        InsuranceID: insurance?.InsuranceID,
      };

      if (modalType === "create") onCreate(insuranceRequest);
      else onUpdate(insuranceRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideInsuranceModal());
  };

  const noNameRepeat = (rule: any, value: any, callback: any) => {
    try {
      if (insuranceNames.filter(x => x !== insurance?.Name).find(e => e.toLowerCase() === value.toLowerCase())) {
        throw new Error();
      }
      callback()
    } catch (err) {
      callback(err);
    }
  }

  return (
    <React.Fragment>
      <Modal
        centered
        className="insurance-modal"
        destroyOnClose
        visible={showModal}
        okButtonProps={{ loading: loading }}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button loading={loading} onClick={handleSubmit} type="primary">
                {modalType === "create" ? (
                  <Trans render="span" id="Insurance.CreateInsuranceFormOkButton">
                    Create
                  </Trans>
                ) : (
                  <Trans render="span" id="Insurance.EditInsuranceFormOkButton">
                    Update
                  </Trans>
                )}
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="Insurance.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          modalType === "create" ? (
            <Trans id="Insurance.CreateInsuranceFormTitle">Create insurance</Trans>
          ) : (
            <Trans id="Insurance.EditInsuranceFormTitle">Edit insurance</Trans>
          )
        }
      >
        <Form colon={false}>
          <Form.Item label={<Trans id="Insurance.Form.NameLabel">Name</Trans>}>
            {getFieldDecorator(nameof("Name"), {
              initialValue: insurance?.Name,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Insurance.Form.NameRequiredError">
                      This field is required
                    </Trans>
                  ),
                },
                {
                  validator: noNameRepeat,
                  message: (
                    <Trans id="Insurance.Form.NameRepeat">
                      There's one insurance with that name
                    </Trans>
                  ),
                }
              ],
            })(<Input maxLength={128} autoComplete="off" />)}
          </Form.Item>
          <Form.Item
            label={
              <Trans id="Insurance.Form.Disclaimer">
                Disclaimer
              </Trans>
            }
          >
            {getFieldDecorator(nameof("Disclaimer"), {
              initialValue: insurance?.Disclaimer,
            })(<TextArea rows={3} maxLength={512} />)}
          </Form.Item>
          {modalType === "create" ? (null) : (
            <Form.Item label={<Trans id="Insurance.Form.IsActiveLabel">Is active</Trans>}>
              {getFieldDecorator(nameof("IsActive"), {
                valuePropName: "checked",
                initialValue: insurance?.IsActive
              })(<Checkbox />)}
            </Form.Item>
          )}
        </Form>

      </Modal>
    </React.Fragment >
  );
};

export default Form.create<InsuranceModalProps>()(FormStateless);
