import { ProviderFocusState } from "./Types";
import {
  LOADING_PROVIDER_FOCUS,
  SET_PROVIDER_FOCUS,
  UPDATE_PROVIDER_FOCUS
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: ProviderFocusState = {
  providerFocus: [],
  loading: false,
  updateProviderFocus: false
};

export const providerFocusReducer = (
  state = initialState,
  action: any
): ProviderFocusState => {
  switch (action.type) {
    case LOADING_PROVIDER_FOCUS:
      return {
        ...state,
        loading: true
      };

    case SET_PROVIDER_FOCUS:
      return {
        ...state,
        providerFocus: action.providerFocus,
        loading: false
      };

    case CHANGE_DIRECTORY:
    case UPDATE_PROVIDER_FOCUS:
      return {
        ...state,
        providerFocus: [],
        updateProviderFocus: !state.updateProviderFocus
      };

    default:
      return state;
  }
};
