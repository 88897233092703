import React from "react";
import "./AppointmentsTable.css";
import { Trans } from "@lingui/macro";
import { AppointmentsTableProps } from "./Types";
import { Table } from "antd";
import { AppointmentSearch } from "../../../../store/apointment";

export const AppointmentsTable: React.FC<AppointmentsTableProps> = (props) => {
  const pageSize = 20;
  const { appointments, loading, onAppointmentClick } = props;

  const columns = [
    {
      title: (
        <Trans
          render="strong"
          id="AppointmentsTable.PatientFullNameColumnLabel"
        >
          Patient FullName
        </Trans>
      ),
      dataIndex: "PatientFullName",
      key: "PatientFullName",
    },
    {
      title: (
        <Trans
          render="strong"
          id="AppointmentsTable.PatientIdentificationNumberColumnLabel"
        >
          Patient Identification Number
        </Trans>
      ),
      dataIndex: "PatientIdentificationNumber",
      key: "PatientIdentificationNumber",
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.DateColumnLabel">
          Date
        </Trans>
      ),
      key: "Date",
      render: (appointment: AppointmentSearch) => appointment.Date.format("ll"),
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.StartTimeColumnLabel">
          Start Time
        </Trans>
      ),
      key: "StartTime",
      render: (appointment: AppointmentSearch) =>
        appointment.StartTime.format("hh:mm a"),
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.EndTimeColumnLabel">
          End Time
        </Trans>
      ),
      key: "EndTime",
      render: (appointment: AppointmentSearch) =>
        appointment.EndTime.format("hh:mm a"),
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.StatusColumnLabel">
          Status
        </Trans>
      ),
      key: "IsCancelled",
      render: (appointment: AppointmentSearch) =>
        appointment.IsCancelled ? (
          <Trans
            render="span"
            id="AppointmentsTable.AppointmentStatusCancelled"
          >
            Cancelled
          </Trans>
        ) : (
          <Trans render="span" id="AppointmentsTable.AppointmentStatusActive">
            Active
          </Trans>
        ),
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.ProfessionalColumnLabel">
          Professional
        </Trans>
      ),
      dataIndex: "ProviderName",
      key: "ProviderName",
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.SpecialtyColumnLabel">
          Specialty
        </Trans>
      ),
      dataIndex: "SpecialtyName",
      key: "SpecialtyName",
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.AgendaColumnLabel">
          Agenda
        </Trans>
      ),
      dataIndex: "AgendaName",
      key: "AgendaName",
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.AppNameColumnLabel">
          App name
        </Trans>
      ),
      dataIndex: "AppName",
      key: "AppName",
    },
    {
      title: (
        <Trans render="strong" id="AppointmentsTable.UserColumnLabel">
          User
        </Trans>
      ),
      dataIndex: "UserID",
      key: "UserID",
    },
  ];

  const onRowClick = (appointment: AppointmentSearch) => {
    var request = {
      id: appointment.ID,
      locationId: appointment.LocationID,
      agendaId: appointment.AgendaID,
      providerId: appointment.ProviderID,
      date: appointment.Date,
      startTime: appointment.StartTime,
      endTime: appointment.EndTime,
      notes: "",
      allowReSchedule: appointment.AllowReSchedule,
      isTelehealth: true,
      specialtyId: appointment.SpecialtyID,
      modality: appointment.Modality
    };

    onAppointmentClick(request, appointment.IsCancelled);
  };

  return (
    <div className="appointments-table">
      <Table
        dataSource={appointments}
        columns={columns}
        rowKey="ID"
        bodyStyle={{ background: "#fff" }}
        loading={loading}
        onRowClick={onRowClick}
        pagination={
          appointments
            ? appointments.length > pageSize && {
              pageSize,
              position: "bottom",
            }
            : false
        }
      />
    </div>
  );
};
