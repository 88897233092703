import axios, { AxiosResponse, AxiosError } from "axios";
import {
  isNullOrEmpty,
  getDirectoryTenantId,
  getAccessToken,
  i18n,
} from "../utils";
import { GenericApiResponse } from "./ServicesTypes";
import { t } from "@lingui/macro";
import { notification } from "antd";
import store from "../store";
import { logout } from "../store/auth";

const httpClient = axios.create({
  //baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 60000,
  timeoutErrorMessage: i18n._(
    t(
      "Network.TimeoutError"
    )`The request has exceded its waiting time, check your internet connection and try again.`
  ),
});

httpClient.interceptors.request.use((request) => {
  var accessToken = getAccessToken();
  var directoryTenantId = getDirectoryTenantId();

  if (!isNullOrEmpty(accessToken))
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${accessToken}`,
    };

  if (!isNullOrEmpty(directoryTenantId))
    request.headers = {
      ...request.headers,
      "x-directory": directoryTenantId,
    };

  return request;
});

httpClient.interceptors.response.use(
  (response: AxiosResponse<GenericApiResponse<any>>) => {
    const { Error } = response.data;

    if (Error.HasError) {
      if (Error.ErrorCode === "APPERR001")
        notification.error({
          message: Error.Message,
        });
      else {
        notification.error({
          message: i18n._(t("Error.ErrorTitle")`Something happened`),
          description: Error.Message,
        });
      }

      return Promise.reject(Error.Message);
    }

    return response;
  },
  (error: AxiosError<any>) => {
    if (error.response) {
      if (error.response.status === 401) store.dispatch(logout());
      else {
        if (error.response.status === 400) {
          if (error.response.data?.Error?.HasError) {
            notification.error({
              message: "Unexpected error",
              description: error.response.data?.Error.Message,
            });
          } else {
            notification.error({
              message: "Unexpected error: " + error.response.status,
              description:
                "Our server was unable to process your request, please try again!",
            });
          }
        }

        if (error.response.data?.Error?.HasError)
          return Promise.reject(error.response.data.Error.Message);
      }
    } else if (error.request)
      notification.error({
        message: i18n._(t("Network.NetworkErrorTitle")`Network error`),
        description: i18n._(
          t(
            "Network.NetworkErrorMessage"
          )`There has been an error contacting the server, check your internet connection and try again. If the error persists it might be a problem with our servers.`
        ),
      });
    else
      notification.error({
        message: i18n._(t("Network.UnexpectedErrorTitle")`Unexpected error`),
        description: i18n._(
          t(
            "Network.UnexpectedErrorMessage"
          )`There has been an unknown error, please try again later.`
        ),
      });

    return Promise.reject();
  }
);

export default httpClient;
