import React, { useEffect, useState } from "react";
import "./ProviderEditAgendaModal.css";
import {
  Form,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Skeleton,
  Select,
  Checkbox,
  InputNumber,
  Transfer,
} from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";

import {
  Location,
  LocationState,
  changeLocationField,
  hideEditLocationModal,
} from "../../../store/location";

import { ProviderEditAgendaModalProps } from "./Types";
import { useSelector, useDispatch } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ClinicState } from "../../../store/clinic";
import { loadClinics } from "../../../services/clinic";

const FormStateless: React.FC<ProviderEditAgendaModalProps> = (props) => {
  const { form, loading, onUpdate, showEditModal, location } = props;
  const { locationState, clinicState } = useSelector(mapState);
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();

  const editLocationNameOf = nameofFactory<Location>();
  const onModalDismiss = () => {
    dispatch(hideEditLocationModal());
  };

  const [isOffline, setIsOffline] = useState<boolean>(location?.IsOffline !== undefined ? location?.IsOffline : true);

  useEffect(() => {
    loadClinics();
  }, []);

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const request = {
        ...values,
        Clinics: values.Clinics.map((x: string) => ({ ClinicID: x }))
      };

      onUpdate(request);
    });
  };

  const onLocationSelectChange = (name: string, value: number) => {
    dispatch(changeLocationField(name, value));

    switch (name) {
      case "Address1ID":
        dispatch(changeLocationField("Address2ID", undefined));
        dispatch(changeLocationField("Address3ID", undefined));
        break;

      case "Address2ID":
        dispatch(changeLocationField("Address3ID", undefined));
        break;

      default:
        return;
    }
  };

  const onIsOfflineChange = (e: CheckboxChangeEvent) => {
    setIsOffline(e.target.checked);
  };

  const data =
    clinicState.clinics.map((x) => ({
      key: x.ClinicID.toString(),
      title: x.Name
    })) || [];

  const targetKeys = location?.Clinics?.map((x) => x.ClinicID.toString()) || [];

  const filterOption = (inputValue: any, data: any) =>
    data.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;

  return (
    <React.Fragment>
      <Modal
        centered
        className="provider-modal"
        destroyOnClose
        visible={showEditModal}

        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button
                loading={locationState.updatingLocation}
                onClick={onSubmit}
                type="primary"
              >
                <Trans
                  render="span"
                  id="ProviderEditAgendaModal.UpdateAgendaButton"
                >
                  Update
                </Trans>
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="ProviderEditAgendaModal.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          <Trans id="ProviderEditAgendaModal.EditAgendaFormTitle">
            Edit agenda
          </Trans>
        }
      >
        <Skeleton loading={locationState.loading}>
          <Form colon={false}>
            <Row type="flex" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.AgendaColumnTitle">
                      Agenda
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("AgendaName"), {
                    initialValue: location?.AgendaName,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.StateColumnTitle">
                      State
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Address1ID"), {
                    initialValue: location?.Address1ID,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(
                    <Select
                      loading={locationState.loadingStates}
                      onChange={(value: number) =>
                        onLocationSelectChange(
                          editLocationNameOf("Address1ID"),
                          value
                        )
                      }
                    >
                      {locationState.states.map((x, index) => (
                        <Select.Option key={index} value={x.StateID}>
                          {x.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.CountyColumnTitle">
                      County
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Address2ID"), {
                    initialValue: location?.Address2ID,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(
                    <Select
                      loading={locationState.loadingCounties}
                      onChange={(value: number) =>
                        onLocationSelectChange(
                          editLocationNameOf("Address2ID"),
                          value
                        )
                      }
                    >
                      {locationState.counties.map((x, index) => (
                        <Select.Option key={index} value={x.CountyID}>
                          {x.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.CityColumnTitle">
                      City
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Address3ID"), {
                    initialValue: location?.Address3ID,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(
                    <Select
                      loading={locationState.loadingCities}
                      onChange={(value: number) =>
                        onLocationSelectChange(
                          editLocationNameOf("Address3ID"),
                          value
                        )
                      }
                    >
                      {locationState.cities.map((x, index) => (
                        <Select.Option key={index} value={x.CityID}>
                          {x.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>

              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.PhoneColumnTitle">
                      Phone
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Phone"), {
                    initialValue: location?.Phone,
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}> 
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.Phone2ColumnTitle">
                      Phone2
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Phone2"), {
                    initialValue: location?.Phone2,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.LattitudeColumnTitle">
                      Lattitude
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Lattitude"), {
                    initialValue: location?.Lattitude,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.LongitudeColumnTitle">
                      Longitude
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Longitude"), {
                    initialValue: location?.Longitude,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={16}>
              <Col span={24}>
                <Form.Item
                  label={
                    <Trans id="ProviderEditAgendaModal.Location.AddressColumnTitle">
                      Address
                    </Trans>
                  }
                >
                  {getFieldDecorator(editLocationNameOf("Address"), {
                    initialValue: location?.Address,
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<TextArea maxLength={500} />)}
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex" gutter={16}>
              {isOffline ?
                null
                :
                <Col span={12}>
                  <Form.Item
                    label={
                      <Trans id="Professional.AppointmentMinutesIntervalLabel">
                        Appointment Interval Minutes
                      </Trans>
                    }
                  >
                    {getFieldDecorator(editLocationNameOf("AppointmentIntervalMinutes"), {
                      initialValue: location?.AppointmentIntervalMinutes || 5,
                      rules: [
                        {
                          required: true,
                          message: <Trans id="Common.RequiredFieldError" />,
                        },
                      ],
                    })(<InputNumber min={5} max={180} style={{ width: "100%" }} />)}
                  </Form.Item>
                </Col>
              }
              <Col span={12}>
                <Form.Item
                  label={<Trans id="ProviderEditAgendaModal.Location.OrderLabel">Order</Trans>}
                >
                  {getFieldDecorator(editLocationNameOf("Order"), {
                    initialValue: locationState.location?.Order,
                    rules: [
                      {
                        required: true,
                        type: "regexp",
                        pattern: new RegExp("^[0-9]*$"),
                        message: <Trans id="Common.RequiredFieldError" />,
                      }
                    ]
                  })(<InputNumber min={0} maxLength={4} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" gutter={24}>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator(editLocationNameOf("IsOffline"), {
                    valuePropName: "checked",
                    initialValue: locationState.location?.IsOffline,
                  })(
                    <Checkbox onChange={onIsOfflineChange}>  <Trans id="ProviderEditAgendaModal.Location.IsOfflineColumnTitle">
                      Offline
                    </Trans>
                    </Checkbox>)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  {getFieldDecorator(editLocationNameOf("IsHiddenOnWeb"), {
                    valuePropName: "checked",
                    initialValue: locationState.location?.IsHiddenOnWeb,
                  })(<Checkbox >
                    <Trans id="ProviderEditAgendaModal.Location.IsHiddenOnWebColumnTitle">
                      Hidden on Web
                    </Trans>
                  </Checkbox>)}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item                >
                  {getFieldDecorator(editLocationNameOf("AllowFutureAppointment"), {
                    valuePropName: "checked",
                    initialValue: locationState.location?.AllowFutureAppointment,
                  })(<Checkbox>
                    <Trans id="ProviderEditAgendaModal.Location.AllowFutureAppointment">
                      Allow future appointments
                    </Trans>
                  </Checkbox>)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label={
                  <Trans id="ProviderEditAgendaModal.Clinics">
                    Clinics
                  </Trans>
                }>
                  {getFieldDecorator("Clinics", {
                    valuePropName: "targetKeys",
                    initialValue: targetKeys,
                  })(
                    <Transfer
                      dataSource={data}
                      showSearch
                      filterOption={filterOption}
                      render={(item) => item.title || ""}
                      style={{ textAlign: "center" }}
                      listStyle={{
                        width: 210,
                        height: 250,
                      }}
                    />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Modal>
    </React.Fragment>
  );
};

const mapState = (state: any): State => ({
  locationState: state.locationState,
  clinicState: state.clinicState
});

interface State {
  locationState: LocationState;
  clinicState: ClinicState
}

export default Form.create<ProviderEditAgendaModalProps>()(FormStateless);
