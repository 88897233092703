import React, { useEffect } from "react";
import "./UserModal.css";
import { Form, Input, Col, Row, Modal, Checkbox, Select, Button } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";
import { UserModalProps } from "./Types";
import { User, hideUserModal } from "../../../store/user";
import { useDispatch } from "react-redux";
import { loadUserDirectories } from "../../../services/user/UserService";

const { Option } = Select;

const UserModal: React.FC<UserModalProps> = props => {
  const {
    directories,
    form,
    loading,
    modalType,
    onCreate,
    onUpdate,
    showModal,
    user
  } = props;

  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();
  const userNameof = nameofFactory<User>();

  useEffect(() => {
    if (user) loadUserDirectories(user?.UserID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.UserID]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) {
        return;
      }

      const userRequest = {
        ...values,
        Directories: values.Directories?.map((directory: string) => ({
          DirectoryTenantID: directory
        })),
        UserID: user?.UserID
      };

      if (modalType === "create") onCreate(userRequest);
      else onUpdate(userRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideUserModal());
  };

  return (
    <React.Fragment>
      <Modal
        centered
        /*         className="user-modal"
        destroyOnClose
      
        okText={
          modalType === "create" ? (
            <Trans render="span" id="Users.CreateUserFormOkButton">
              Create
            </Trans>
          ) : (
            <Trans render="span" id="Users.EditUserFormOkButton">
              Save
            </Trans>
          )
        }
        onOk={handleSubmit} */
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button loading={loading} onClick={handleSubmit} type="primary">
                {modalType === "create" ? (
                  <Trans render="span" id="Users.CreateUserFormOkButton">
                    Create
                  </Trans>
                ) : (
                  <Trans render="span" id="Users.EditUserFormOkButton">
                    Update
                  </Trans>
                )}
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="Users.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          modalType === "create" ? (
            <Trans id="Users.CreateUserFormTitle">Create user</Trans>
          ) : (
            <Trans id="Users.EditUserFormTitle">Edit user</Trans>
          )
        }
        visible={showModal}
        okButtonProps={{ loading: loading }}
      >
        <Form colon={false}>
          <Form.Item label={<Trans id="Users.Form.EmailLabel">Email</Trans>}>
            {getFieldDecorator(userNameof("Email"), {
              initialValue: user?.Email,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Users.Form.EmailRequiredError">
                      This field is required
                    </Trans>
                  )
                }
              ]
            })(<Input maxLength={100} />)}
          </Form.Item>
          <Row type="flex" gutter={8}>
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                label={<Trans id="Users.Form.IsAdminLabel">Is Admin</Trans>}
              >
                {getFieldDecorator(userNameof("IsAdmin"), {
                  valuePropName: "checked",
                  initialValue: user?.IsAdmin
                })(<Checkbox />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                label={<Trans id="Users.Form.CanSetupLabel">Can Setup</Trans>}
              >
                {getFieldDecorator(userNameof("CanSetup"), {
                  valuePropName: "checked",
                  initialValue: user?.CanSetup
                })(<Checkbox />)}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={12}>
              <Form.Item
                label={<Trans id="Users.Form.CanSearchLabel">Can Search</Trans>}
              >
                {getFieldDecorator(userNameof("CanSearch"), {
                  valuePropName: "checked",
                  initialValue: user?.CanSearch
                })(<Checkbox />)}
              </Form.Item>
            </Col>
            {modalType === "edit" && (
              <Col xs={24} sm={12} md={8} lg={12}>
                <Form.Item
                  label={
                    <Trans id="Users.Form.IsEnabledLabel">Is Enabled?</Trans>
                  }
                >
                  {getFieldDecorator(userNameof("IsEnabled"), {
                    valuePropName: "checked",
                    initialValue: user?.IsEnabled
                  })(<Checkbox />)}
                </Form.Item>
              </Col>
            )}
          </Row>
          {modalType === "edit" ? (
            <Form.Item
              label={
                <Trans id="Users.Form.DirectorySelectLabel">
                  Directory Access
                </Trans>
              }
            >
              {getFieldDecorator(userNameof("Directories"), {
                initialValue: user?.Directories?.map(
                  userdirectory => userdirectory.DirectoryTenantID
                )
              })(
                <Select className="directories-user-select" mode="multiple">
                  {directories.map((directory, index) => (
                    <Option key={index} value={directory.DirectoryTenantID}>
                      {directory.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          ) : null}
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default Form.create<UserModalProps>()(UserModal);
