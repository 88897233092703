import { LocaleState } from "./Types";
import { CHANGE_LOCALE } from "./Actions";
import { locales } from "../../config";
import { LocalStorageKeys } from "../../utils/Constants";
import { isNullOrEmpty, i18n } from "../../utils";
import moment from "moment";

i18n.load(locales.catalogs);

const changeLocale = (locale: LocaleState) => {
  moment.locale(locale.value);
  i18n.activate(locale.value);
};

const findLocale = (locale: string) =>
  locales.locales.find(x => x.value === locale) || locales.locales[0];

const getLocale = (): LocaleState => {
  const localeFromLocalStorage = localStorage.getItem(
    LocalStorageKeys.SelectedLocale
  );
  let locale: LocaleState;

  if (!isNullOrEmpty(localeFromLocalStorage))
    locale = JSON.parse(String(localeFromLocalStorage));
  else locale = locales.locales[0];

  changeLocale(locale);
  saveLocaleToLocalStorage(locale);

  return locale;
};

const saveLocaleToLocalStorage = (locale: LocaleState) => {
  localStorage.setItem(LocalStorageKeys.SelectedLocale, JSON.stringify(locale));
};

const initialState: LocaleState = getLocale();

export const localeReducer = (
  state = initialState,
  action: any
): LocaleState => {
  switch (action.type) {
    case CHANGE_LOCALE:
      const newLocale = findLocale(action.locale);
      saveLocaleToLocalStorage(newLocale);
      changeLocale(newLocale);

      return newLocale;

    default:
      return state;
  }
};
