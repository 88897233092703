import React, { useEffect } from "react";
import "./SearchHeader.css";
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  Tooltip,
  Spin,
  notification
} from "antd";
import { SearchHeaderProps } from "./Types";
import { Trans, t } from "@lingui/macro";
import { SearchParams } from "../../../store/search";
import { nameofFactory, i18n } from "../../../utils";
import { debounce } from "lodash";
import { searchProvidersForAutocomplete } from "../../../services/provider";
import { searchClinicsForAutocomplete } from "../../../services/clinic";

const SearchHeader: React.FC<SearchHeaderProps> = props => {
  const {
    clinicState,
    directoryTenantId,
    form,
    locationState,
    onCleanFilters,
    onCountyChange,
    onSearch,
    onStateChange,
    providerState,
    searchState,
    specialtyState
  } = props;

  const nameof = nameofFactory<SearchParams>();
  const { Option } = Select;

  const { getFieldDecorator, getFieldsValue, resetFields } = form;
  const { params } = searchState;

  useEffect(() => {
    onCleanFilters();
    resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryTenantId]);

  useEffect(() => {
    resetFields([nameof("countyId"), nameof("cityId")]);
    // eslint-disable-next-line
  }, [params.stateId]);

  useEffect(() => {
    resetFields([nameof("cityId")]);
    // eslint-disable-next-line
  }, [params.countyId]);

  const onLocalCleanFilters = () => {
    resetFields();
    onCleanFilters();
  };

  const onSearchProviders = debounce((value: string) => {
    if (!value || value === "") return;

    searchProvidersForAutocomplete(value);
  }, 800);

  const onSearchClinics = debounce((value: string) => {
    if (!value || value === "") return;

    searchClinicsForAutocomplete(value);
  }, 800);

   
  const specialtyOptions = specialtyState.specialties.map((specialty, index) => (
    <Option key={index} value={specialty.SpecialtyID}>
      {specialty.Name}
    </Option>
  ));

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const values = getFieldsValue();

    if (areValid(values)) onSearch(values);
    else
      notification.info({
        message: i18n._(t("SearchHeader.CannotSearchTitle")`Cannot search`),
        description: i18n._(
          t(
            "SearchHeader.CannotSearchDescription"
          )`You must fill at least one field to begin searching`
        )
      });
  };

  const areValid = (values: any) => {
    if (
      values.clinicId ||
      values.providerId ||
      values.stateId ||
      values.specialtyId
    )
      return true;

    return false;
  };


  return (
    <React.Fragment>
      <Form layout="vertical" className="search-header" onSubmit={onSubmit}>
        <Row type="flex" justify="space-between" gutter={8}>
          <Col span={3}>
            <Form.Item label={<Trans id="Common.StateLabel">State</Trans>}>
              {getFieldDecorator(nameof("stateId"))(
                <Select
                  allowClear
                  loading={locationState.loadingStates}
                  onChange={onStateChange}
                >
                  {locationState.states.map((state, index) => (
                    <Option key={index} value={state.StateID}>
                      {state.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={<Trans id="Common.CountyLabel">County</Trans>}>
              {getFieldDecorator(nameof("countyId"))(
                <Select
                  allowClear
                  disabled={params.stateId === undefined}
                  loading={locationState.loadingCounties}
                  onChange={onCountyChange}
                >
                  {locationState.counties.map((county, index) => (
                    <Option key={index} value={county.CountyID}>
                      {county.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={<Trans id="Common.CityLabel">City</Trans>}>
              {getFieldDecorator(nameof("cityId"))(
                <Select
                  allowClear
                  disabled={params.countyId === undefined}
                  loading={locationState.loadingCities}
                >
                  {locationState.cities.map((city, index) => (
                    <Option key={index} value={city.CityID}>
                      {city.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={<Trans id="Common.SpecialtyLabel">Specialty</Trans>}
            >
                {getFieldDecorator(nameof("specialtyId"))(
                <Select 
                  showSearch
                  allowClear                  
                  defaultActiveFirstOption={false}
                  filterOption={(input, option) => 
                    {
                      if(option.props.children){
                        return option.props.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0 ;
                      }
                      else{
                        return false;
                      }
                    }
                  }                  
                  notFoundContent={null}
                  >
                    {specialtyOptions}
                </Select>
                )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={<Trans id="Common.ClinicLabel">Clinic</Trans>}>
              {getFieldDecorator(nameof("clinicId"))(
                <Select
                  showSearch
                  allowClear
                  filterOption={false}
                  loading={clinicState.loading}
                  notFoundContent={
                    providerState.loading ? <Spin size="small" /> : null
                  }
                  onSearch={onSearchClinics}
                >
                  {clinicState.clinics.map((clinic, index) => (
                    <Option key={index} value={clinic.ClinicID}>
                      {clinic.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label={<Trans id="Common.ProviderLabel">Professional</Trans>}
            >
              {getFieldDecorator(nameof("providerId"))(
                <Select
                  showSearch
                  allowClear
                  filterOption={false}
                  loading={providerState.loading}
                  notFoundContent={
                    providerState.loading ? <Spin size="small" /> : null
                  }
                  onSearch={onSearchProviders}>
                  {providerState.providers.map((provider, index) => (
                    <Option key={index} value={provider.ProviderID}>
                      {provider.FullName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Row
              type="flex"
              justify="end"
              align="bottom"
              className="search-button-container"
            >
              <Button.Group>
                <Button
                  type="primary"
                  icon="search"
                  htmlType="submit"
                  loading={searchState.searching}
                >
                  <Trans render="span" id="Common.SearchButton">
                    Search
                  </Trans>
                </Button>
                <Tooltip
                  placement="topLeft"
                  title={
                    <Trans render="span" id="Common.CleanFiltersTooltip">
                      Clean filters
                    </Trans>
                  }
                >
                  <Button
                    disabled={searchState.searching}
                    icon="close"
                    onClick={onLocalCleanFilters}
                  />
                </Tooltip>
              </Button.Group>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default Form.create<SearchHeaderProps>()(SearchHeader);
