import { LanguageState } from "./Types";
import { LOADING_LANGUAGES, SET_LANGUAGES, UPDATE_LANGUAGES } from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: LanguageState = {
  languages: [],
  loading: false,
  updateLanguages: false
};

export const languageReducer = (
  state = initialState,
  action: any
): LanguageState => {
  switch (action.type) {
    case LOADING_LANGUAGES:
      return {
        ...state,
        loading: true
      };

    case SET_LANGUAGES:
      return {
        ...state,
        languages: action.languages,
        loading: false
      };

    case CHANGE_DIRECTORY:
    case UPDATE_LANGUAGES:
      return {
        ...state,
        languages: [],
        updateLanguages: !state.updateLanguages
      };

    default:
      return state;
  }
};
