import React from "react";
import "./ProfilePage.css";
import { Form, Input, Row, Col, Typography, Divider, Button } from "antd";
import { Trans } from "@lingui/macro";
import { AuthState } from "../../store/auth";
import { useSelector } from "react-redux";
import { Container } from "../../components/utils";
import { ProfileProps } from "./Types";
import { requestExternalAccessToken } from "../../services/auth";

const ProfilePage: React.FC<ProfileProps> = props => {
  const { form } = props;
  const authState = useSelector(mapStateToProps);
  const { getFieldDecorator, validateFields } = form;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) return;

      requestExternalAccessToken(values);
    });
  };

  return (
    <Container>
      <div className="generic-container">
        <Typography.Title level={4}>
          <Trans id="ProfilePage.Title">User profile</Trans>
        </Typography.Title>
        <Form colon={false} onSubmit={onSubmit}>
          <Row type="flex">
            <Col span={6}>
              <Form.Item label={<Trans id="Common.EmailLabel">Email</Trans>}>
                {getFieldDecorator("email", {
                  initialValue: authState.info?.username
                })(<Input type="email" disabled />)}
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          {authState.info?.isAdmin === true && (
            <Row type="flex" align="bottom">
              <Col span={6}>
                <Typography.Title level={4}>
                  <Trans id="ProfilePage.ExternalAccessTokenTitle">
                    External Access token
                  </Trans>
                </Typography.Title>
                <Form.Item
                  label={<Trans id="Common.PasswordLabel">Password</Trans>}
                >
                  {getFieldDecorator("password", {
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.PasswordRequiredError" />
                      }
                    ]
                  })(<Input.Password />)}
                </Form.Item>
              </Col>
              <Col offset={1} span={3} style={{ marginBottom: "4px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={authState.loading}
                >
                  <Trans
                    render="span"
                    id="ProfilePage.GenerateExternalAccessTokenButton"
                  >
                    Generate token
                  </Trans>
                </Button>
              </Col>
              {authState.externalAccessToken && (
                <Col offset={2}>
                  <Typography.Title
                    level={4}
                    copyable={{ text: authState.externalAccessToken }}
                  >
                    <Trans id="ProfilePage.GeneratedExternalAccessTokenLabel">
                      Your generated token
                    </Trans>
                  </Typography.Title>
                </Col>
              )}
            </Row>
          )}
        </Form>
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any): AuthState => state.authState;

export default Form.create<ProfileProps>()(ProfilePage);
