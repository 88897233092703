import { setupI18n } from "@lingui/core";
import { useLocation } from "react-router-dom";
import { LocalStorageKeys } from "./Constants";

export const isNullOrEmpty = (value: string | null): boolean =>
  value === null || value === "";

export const filterByValueAndText = (
  inputValue: string,
  option: React.ReactElement<any>
) => {
  return (
    String(option.key).includes(inputValue) ||
    String(option.props.children)
      .toLowerCase()
      .includes(inputValue.toLowerCase())
  );
};

export const getAccessToken = () =>
  localStorage.getItem(LocalStorageKeys.AccessToken);

export const getDirectoryTenantId = () =>
  localStorage.getItem(LocalStorageKeys.DirectoryTenantId);

export const nameofFactory = <T>() => (name: keyof T) => name;

export const i18n = setupI18n();

export const useQuery = () => new URLSearchParams(useLocation().search);
