import { UserState, User } from "./Types";
import {
  CHANGING_USER,
  CHANGING_USER_FINISHED,
  DELETE_USER_FINISHED,
  HIDE_USER_MODAL,
  LOADING_USER_DIRECTORIES,
  LOADING_USERS,
  SHOW_USER_MODAL_FOR_CREATE,
  SHOW_USER_MODAL_FOR_EDIT,
  SET_USERS,
  UPDATE_USERS,
  SET_USER_DIRECTORIES,
  SHOW_USER_PASSWORD_MODAL,
  HIDE_USER_PASSWORD_MODAL,
  CHANGING_USER_PASSWORD_FINISHED,
  SET_USER,
  FILTER_USERS,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: UserState = {
  filteredUsers: [],
  changing: false,
  count: 0,
  loading: false,
  loadingDirectories: false,
  modalType: "create",
  showModal: false,
  showPasswordModal: false,
  updateUsers: false,
  users: [],
};

export const userReducer = (state = initialState, action: any): UserState => {
  switch (action.type) {
    case CHANGING_USER:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_USER_FINISHED:
      return {
        ...state,
        changing: false,
        showModal: !action.result,
        updateUsers: action.result ? !state.updateUsers : state.updateUsers,
      };

    case CHANGING_USER_PASSWORD_FINISHED:
      return {
        ...state,
        changing: false,
        showPasswordModal: !action.result,
      };

    case DELETE_USER_FINISHED:
      return {
        ...state,
        changing: false,
        updateUsers: action.result ? !state.updateUsers : state.updateUsers,
      };

    case HIDE_USER_MODAL:
      return {
        ...state,
        user: undefined,
        showModal: false,
      };

    case HIDE_USER_PASSWORD_MODAL:
      return {
        ...state,
        user: undefined,
        showPasswordModal: false,
      };

    case LOADING_USER_DIRECTORIES:
      return {
        ...state,
        loadingDirectories: true,
      };

    case LOADING_USERS:
      return {
        ...state,
        count: 0,
        loading: true,
      };

    case SHOW_USER_MODAL_FOR_CREATE:
      return {
        ...state,
        user: undefined,
        modalType: "create",
        showModal: true,
      };

    case SHOW_USER_MODAL_FOR_EDIT:
      return {
        ...state,
        user: action.user,
        modalType: "edit",
        showModal: true,
      };

    case SHOW_USER_PASSWORD_MODAL:
      return {
        ...state,
        showPasswordModal: true,
        user: action.user,
      };

    case SET_USER_DIRECTORIES:
      return {
        ...state,
        loadingDirectories: false,
        user: {
          ...(state.user as User),
          Directories: action.directories,
        },
      };

    case SET_USER:
      return {
        ...state,
        user: action.user,
        loading: false,
      };

    case SET_USERS:
      return {
        ...state,
        users: action.users,
        filteredUsers: action.users,
        count: action.users.length,
        loading: false,
      };

    case CHANGE_DIRECTORY:
    case UPDATE_USERS:
      return {
        ...state,
        user: undefined,
        users: [],
        updateUsers: !state.updateUsers,
      };

    case FILTER_USERS:
      return {
        ...state,
        filteredUsers: filterUsers(action.value, state.users),
      };

    default:
      return state;
  }
};

const filterUsers = (value: string, users: User[]) => {
  if (value === "") return users;

  return users.filter((user) => isFilterMatch(value, user));
};

const isFilterMatch = (value: string, user: User) => {
  return user.Email.toLowerCase().includes(value.toLowerCase());
};
