import "moment/locale/es";
import enCatalog from "../locales/en/messages";
import esCatalog from "../locales/es/messages";
import { LocaleState } from "../store/locale/Types";

interface Locale {
  locales: LocaleState[];
  catalogs: { [key: string]: any };
}

export const locales: Locale = {
  locales: [
    {
      value: "es",
      name: "Español (Spanish)"
    },
    {
      value: "en",
      name: "English"
    }
  ],
  catalogs: {
    en: enCatalog,
    es: esCatalog
  }
};
