import React from "react";
import "./PasswordResetPage.css";
import { AuthPasswordReset } from "../../components/auth/passwordReset";
import { useSelector } from "react-redux";
import {
  UserPasswordResetState,
  UserPasswordReset,
} from "../../store/passwordReset";
import { sendPasswordResetEmail } from "../../services/user";
import { useHistory } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import { PoweredBySikuFooter } from "../../components/powered-by-siku-footer";

export const PasswordResetPage: React.FC = () => {
  const userPasswordResetState = useSelector(mapStateToProps);
  const history = useHistory();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  //useEffect(() => redirect(), [userPasswordResetState.loading]);

  const onPasswordReset = (userPasswordReset: UserPasswordReset) => {
    sendPasswordResetEmail(userPasswordReset).then((response) => {
      if (response) history.push("/");
    });
  };

  return (
    <Layout className="auth-page">
      <Layout.Header>
        <Row type="flex" align="middle" className="logo-container">
          <a href="/">
            <img
              src="/assets/images/SikuWhiteLogoLogin.png"
              alt="Siku logo"
              className="logo"
            />
          </a>
        </Row>
      </Layout.Header>
      <Layout.Content className="content-container">
        <Col span={24} className="login-side">
          <AuthPasswordReset
            userPasswordResetState={userPasswordResetState}
            onPasswordReset={onPasswordReset}
          />
          <Row
            type="flex"
            align="bottom"
            justify="center"
            className="login-side-footer"
          >
            <Layout.Footer>
              <PoweredBySikuFooter />
            </Layout.Footer>
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

const mapStateToProps = (state: any): UserPasswordResetState =>
  state.UserPasswordResetState;
