import { setSpecialtiesTypehead } from './../../store/specialty/Actions';
import { SpecialtyTypeahead } from './../../store/specialty/Types';
import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingSpecialties,
  Specialty,
  setSpecialties,
  changingSpecialty,
  changingSpecialtyFinished,
  deleteSpecialtyFinished,
  loadingSpecialtiesTypeahead
} from "../../store/specialty";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from '../../utils/Constants';

export const loadSpecialties = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) + "search/specialty";
  return loadSpecialtiesAux(url);
};

export const loadSpecialtiesFromAdmin = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/specialty/${getDirectoryTenantId()}`;
  return loadSpecialtiesAux(url);
};

const loadSpecialtiesAux = async (url: string) => {
  try {
    store.dispatch(loadingSpecialties());
    const response = await httpClient.get<GenericApiResponse<Specialty[]>>(url);
    store.dispatch(setSpecialties(response.data.Data));
    return true;
  } catch {
    store.dispatch(setSpecialties([]));
    return false;
  }
};

export const createSpecialty = async (request: Specialty) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/specialty";

  try {
    store.dispatch(changingSpecialty());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingSpecialtyFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingSpecialtyFinished(false));
    return false;
  }
};

export const updateSpecialty = async (request: Specialty) => {
  const { SpecialtyID, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/specialty/${SpecialtyID}`;

  try {
    store.dispatch(changingSpecialty());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingSpecialtyFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingSpecialtyFinished(false));
    return false;
  }
};

export const deleteSpecialty = async (specialtyId: number) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/specialty/${specialtyId}`;

  try {
    store.dispatch(changingSpecialty());
    await httpClient.delete(url);
    store.dispatch(deleteSpecialtyFinished(true));
    return true;
  } catch (message) {
    notification.error({
      message: i18n._(
        t(
          "SpecialtyService.CouldNotDeleteSpecialtyErrorTitle"
        )`Specialty not deleted`
      ),
      description: i18n._(
        t(
          "SpecialtyService.CouldNotDeleteSpecialtyErrorDescription"
        )`The specialty could not be deleted.`
      )
    });
    store.dispatch(deleteSpecialtyFinished(false));
    return false;
  }
};

export const loadSpecialtiesTypeahead = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "search/specialtyTypeahead";
  try {
    store.dispatch(loadingSpecialtiesTypeahead());
    const response = await httpClient.get<GenericApiResponse<SpecialtyTypeahead[]>>(url);
    store.dispatch(setSpecialtiesTypehead(response.data.Data));
    return true;
  } catch {
    store.dispatch(setSpecialtiesTypehead([]));
    return false;
  }
};
