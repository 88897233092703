import React, { useEffect } from "react";
import "./AdvancedSearchHeader.css";
import {
  Form,
  Select,
  Row,
  Col,
  Button,
  Tooltip,
  Radio,
  DatePicker
} from "antd";
import { AdvancedSearchHeaderProps } from "./Types";
import { Trans, t } from "@lingui/macro";
import { nameofFactory, i18n } from "../../../utils";
import {
  AdvancedSearchParams,
  changeAdvancedSearchType
} from "../../../store/advanced-search";
import moment from "moment";
import { useDispatch } from "react-redux";
import { RadioChangeEvent } from "antd/lib/radio";

const { Option } = Select;
const SPECIALTY_DATES_RANGE = Number(
  process.env.REACT_APP_SPECIALTY_DATES_RANGE
);
const DATE_DATES_RANGE = Number(process.env.REACT_APP_DATE_DATES_RANGE);
const MILISECONDS_TO_DAYS_DIVISOR = 86400000;

const AdvancedAdvancedSearchHeader: React.FC<AdvancedSearchHeaderProps> = props => {
  const {
    directoryTenantId,
    form,
    locationState,
    onCleanFilters,
    onSearch,
    onStateChange,
    advancedSearchState,
    specialtyState
  } = props;
  const nameof = nameofFactory<AdvancedSearchParams>();
  const dispatch = useDispatch();

  const {
    getFieldDecorator,
    resetFields,
    setFieldsValue,
    validateFields
  } = form;
  const { params } = advancedSearchState;

  useEffect(() => {
    onCleanFilters();
    resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryTenantId]);

  useEffect(() => {
    resetFields([nameof("countyId")]);
    // eslint-disable-next-line
  }, [params.stateId]);

  useEffect(() => {
    setFieldsValue({ [nameof("dates")]: params.dates });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.dates]);

  const datesRangeValidator = (
    rule: any,
    value: moment.Moment[],
    callback: any
  ) => {
    if (value && value.length === 2) {
      const [startDate, endDate] = value;
      const datesDiffInMiliseconds = endDate.diff(startDate);
      const datesDiffInDays = Math.floor(
        datesDiffInMiliseconds / MILISECONDS_TO_DAYS_DIVISOR
      );
      const maxDatesDiff =
        advancedSearchState.params.type === "specialty"
          ? SPECIALTY_DATES_RANGE
          : DATE_DATES_RANGE;
      const daysLimit = maxDatesDiff + 1;

      if (datesDiffInDays > maxDatesDiff)
        callback(
          i18n._(
            t(
              "Common.DatesRangeExcededError"
            )`The dates range difference has to be less or equal to ${daysLimit} days`
          )
        );
      else callback();
    } else {
      callback();
    }
  };

  const onLocalCleanFilters = () => {
    resetFields();
    onCleanFilters();
  };

  const onSearchTypeChange = (e: RadioChangeEvent) => {
    const { value } = e.target;
    dispatch(changeAdvancedSearchType(value));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validateFields((err, values) => {
      if (err) return;

      onSearch(values);
    });
  };

  return (
    <React.Fragment>
      <Form
        layout="vertical"
        className="advanced-search-header"
        onSubmit={onSubmit}
      >
        <Row type="flex" justify="space-around" gutter={8}>
          <Col span={4}>
            <Form.Item
              label={
                <Trans id="AdvancedSearchHeader.SearchTypeLabel">
                  Search type
                </Trans>
              }
            >
              {getFieldDecorator(nameof("type"), {
                initialValue: params.type,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="AdvancedSearchHeader.SearchTypeRequiredError">
                        The search type field is required
                      </Trans>
                    )
                  }
                ]
              })(
                <Radio.Group onChange={onSearchTypeChange}>
                  <Radio.Button value="specialty">
                    <Trans id="AdvancedSearchHeader.SearchTypeSpecialty">
                      Specialty
                    </Trans>
                  </Radio.Button>
                  <Radio.Button value="date">
                    <Trans id="AdvancedSearchHeader.SearchTypeDate">Date</Trans>
                  </Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={<Trans id="Common.StateLabel" />}>
              {getFieldDecorator(nameof("stateId"), {
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Common.StateRequiredError">
                        The state field is required
                      </Trans>
                    )
                  }
                ]
              })(
                <Select
                  allowClear
                  loading={locationState.loadingStates}
                  onChange={onStateChange}
                >
                  {locationState.states.map((state, index) => (
                    <Option key={index} value={state.StateID}>
                      {state.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label={<Trans id="Common.CountyLabel" />}>
              {getFieldDecorator(nameof("countyId"), {
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Common.CountyRequiredError">
                        The county field is required
                      </Trans>
                    )
                  }
                ]
              })(
                <Select
                  allowClear
                  disabled={params.stateId === undefined}
                  loading={locationState.loadingCounties}
                >
                  {locationState.counties.map((county, index) => (
                    <Option key={index} value={county.CountyID}>
                      {county.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label={<Trans id="Common.SpecialtyLabel" />}>
              {getFieldDecorator(nameof("specialtyId"), {
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Common.SpecialtyRequiredError">
                        The specialty field is required
                      </Trans>
                    )
                  }
                ]
              })(
                <Select allowClear loading={specialtyState.loading}>
                  {specialtyState.specialties.map((specialty, index) => (
                    <Option key={index} value={specialty.SpecialtyID}>
                      {specialty.Name}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label={<Trans id="Common.DatesLabel">Dates</Trans>}>
              {getFieldDecorator(nameof("dates"), {
                initialValue: params.dates,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="Common.DatesRequiredError">
                        The dates field is required
                      </Trans>
                    )
                  },
                  {
                    validator: datesRangeValidator
                  }
                ]
              })(
                <DatePicker.RangePicker
                  separator="-"
                  allowClear={false}
                  format={["L"]}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={3}>
            <Row
              type="flex"
              justify="end"
              align="bottom"
              className="advanced-search-button-container"
            >
              <Button.Group>
                <Button
                  type="primary"
                  icon="search"
                  htmlType="submit"
                  loading={advancedSearchState.searching}
                >
                  <Trans render="span" id="Common.SearchButton" />
                </Button>
                <Tooltip
                  placement="topLeft"
                  title={
                    <Trans render="span" id="Common.CleanFiltersTooltip" />
                  }
                >
                  <Button
                    disabled={advancedSearchState.searching}
                    icon="close"
                    onClick={onLocalCleanFilters}
                  />
                </Tooltip>
              </Button.Group>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
};

export default Form.create<AdvancedSearchHeaderProps>()(
  AdvancedAdvancedSearchHeader
);
