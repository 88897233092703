import React from "react";
import { Trans } from "@lingui/macro";
import { Container, YesNoCell } from "../../components/utils";
import { Table, Row, Button, Tooltip, Typography } from "antd";
import {
  DirectoryState,
  Directory,
  openDirectoryModal
} from "../../store/directory";
import { useSelector, useDispatch } from "react-redux";
import { DirectoryModal } from "../../components/directory-modal";

export const DirectoriesPage: React.FC = () => {
  const pageSize = 20;
  const directoryState = useSelector(mapState);
  const dispatch = useDispatch();

  const onCreateDirectory = () => {
    dispatch(openDirectoryModal("create"));
  };

  const onEditDirectory = (directoryId: string) => {
    dispatch(openDirectoryModal("edit", directoryId));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="DirectoriesPage.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name"
    },
    {
      title: (
        <Trans render="strong" id="DirectoriesPage.BaseURLColumnTitle">
          Base URL
        </Trans>
      ),
      dataIndex: "BaseURL",
      key: "BaseURL"
    },
    {
      title: (
        <Trans render="strong" id="DirectoriesPage.EnabledColumnTitle">
          Enabled
        </Trans>
      ),
      key: "IndEnabled",
      render: (directory: Directory) => (
        <YesNoCell value={directory.IndEnabled} showIcon={true} />
      )
    },
    {
      title: (
        <Trans render="strong" id="DirectoriesPage.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (directory: Directory) => (
        <span>
          <Tooltip
            title={
              <Trans id="DirectoriesPage.EditActionTooltip">
                Edit directory
              </Trans>
            }
          >
            <Button
              icon="edit"
              onClick={() => onEditDirectory(directory.DirectoryTenantID)}
            />
          </Tooltip>
        </span>
      )
    }
  ];

  return (
    <Container>
      <Typography.Title level={2}>
        <Trans id="DirectoriesPage.Title">Directories</Trans>
      </Typography.Title>
      <Table
        dataSource={directoryState.directories}
        columns={columns}
        loading={directoryState.selectorLoading}
        bodyStyle={{ background: "#fff" }}
        rowKey="UserID"
        pagination={
          directoryState.directories.length > pageSize && {
            pageSize,
            position: "bottom"
          }
        }
        title={() => (
          <Row type="flex" justify="end">
            <Button type="primary" size="large" onClick={onCreateDirectory}>
              <Trans id="DirectoriesPage.CreateDirectoryButton">Create</Trans>
            </Button>
          </Row>
        )}
      />
      {directoryState.showModal && (
        <DirectoryModal directoryId={directoryState.directoryId} />
      )}
    </Container>
  );
};

const mapState = (state: any): DirectoryState => state.directoryState;
