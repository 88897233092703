import { AccountState, Account } from "./Types";
import {
  LOADING_ACCOUNTS,
  LOADED_ACCOUNTS,
  SET_ACCOUNT,
  SHOW_MODAL_FOR_ACCOUNT_CREATION,
  SHOW_MODAL_FOR_ACCOUNT_UPDATE,
  HIDE_ACCOUNT_MODAL,
  SEARCHING_ACCOUNT,
  SET_ACCOUNT_FROM_TSE,
  SELECT_ACCOUNT,
  SET_IS_LINKED_ACCOUNT,
  LOADED_LINKED_ACCOUNTS,
  SET_IS_PARENT_ACCOUNT_ID,
  ADD_ACCOUNT_INSURANCES,
  DELETE_ACCOUNT_INSURANCES
} from "./Actions";
import { SHOW_MODAL_FOR_SCHEDULE } from "../apointment";

const initialState: AccountState = {
  accounts: [],
  accountLinkedAccountTable: [],
  hasSearched: false,
  loading: false,
  modalType: "create",
  searching: false,
  showModal: false,
  isLinkedAccount: false,
  parentAccountID: "",
  accountLinked: undefined
};

export const accountReducer = (
  state = initialState,
  action: any
): AccountState => {
  switch (action.type) {
    case HIDE_ACCOUNT_MODAL:
      return {
        ...state,
        modalType: "create",
        showModal: false,
        accountLinkedAccountTable: [],
        isLinkedAccount: false,
        account: undefined,
      };

    case LOADED_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts,
        loading: false,
      };

    case LOADING_ACCOUNTS:
      return {
        ...state,
        account: undefined,
        accounts: [],
        loading: true,
      };

    case SELECT_ACCOUNT:
      return {
        ...state,
        account: state.accounts.find(
          (account) =>
            account.IdentificationNumber === action.identificationNumber
        ),
      };

    case SET_ACCOUNT:
      return {
        ...state,
        account: action.account,
        hasSearched: true,
        searching: false,
      };

    case SET_ACCOUNT_FROM_TSE:
      return {
        ...state,
        account: action.account,
        accounts: [
          ...filterAccounts(action.account.UID, state.accounts),
          action.account,
        ],
        modalType: "create",
        searching: false,
        showModal: false,
      };

    case SEARCHING_ACCOUNT:
      return {
        ...state,
        hasSearched: false,
        searching: true,
      };

    case SHOW_MODAL_FOR_ACCOUNT_CREATION:
      return {
        ...state,
        account: undefined,
        modalType: "create",
        showModal: true,
        accountLinkedAccountTable: [],
      };

    case SHOW_MODAL_FOR_ACCOUNT_UPDATE:
      return {
        ...state,
        account: action.account,
        modalType: "edit",
        showModal: true,
        accountLinkedAccountTable: [],
      };

    case SHOW_MODAL_FOR_SCHEDULE:
      return {
        ...state,
        account: undefined,
      };

    case SET_IS_LINKED_ACCOUNT:
      return {
        ...state,
        isLinkedAccount: action.isLinkedAccount,
      };

    case LOADED_LINKED_ACCOUNTS:
      return {
        ...state,
        accountLinkedAccountTable: action.accountLinkedAccountTable,
        loading: false,
      };

    case SET_IS_PARENT_ACCOUNT_ID:
      return {
        ...state,
        parentAccountID: action.parentAccountID,
      };

      case ADD_ACCOUNT_INSURANCES:
      if (state.account) {
        return {
          ...state,
          account: {
            ...state.account,
            Insurances: state.account.Insurances?.concat(action.insurance),
          },
        };
      }
      return state;

      case DELETE_ACCOUNT_INSURANCES:
      if (state.account) {
        return {
          ...state,
          account: {
            ...state.account,
            Insurances: state.account.Insurances?.filter(insurance => insurance !== action.insurance),
          },
        };
      }
      return state;

    default:
      return state;
  }
};

const filterAccounts = (uId: string, accounts: Account[]) =>
  accounts.filter((account) => account.UID !== uId);
