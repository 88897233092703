import React, { useEffect } from "react";
import "./CustomProfileModal.css";
import {
  Form,
  Modal,
  Upload,
  Icon,
  Input,
  message,
  Row,
  Col,
  Button,
  Select,
} from "antd";
import { CustomProfileModalProps } from "./Types";
import { useDispatch, useSelector } from "react-redux";
import {
  hideCustomProfileModal,
  CustomProfile,
  setCustomProfileLogo,
} from "../../store/customProfile";
import { nameofFactory, i18n } from "../../utils";
import { Trans, t } from "@lingui/macro";
import TextArea from "antd/lib/input/TextArea";
import { loadCustomProfile } from "../../services/customProfile";
import { ProviderState } from "../../store/provider";
import { loadProviders } from "../../services/provider";

const CustomProfileModal: React.FC<CustomProfileModalProps> = (props) => {
  const providerState = useSelector(mapState);
  const dispatch = useDispatch();
  const {
    customProfile,
    form,
    loading,
    modalType,
    onCreate,
    onUpdate,
    showModal,
  } = props;
  const { getFieldDecorator, validateFields } = form;
  const nameof = nameofFactory<CustomProfile>();

  useEffect(() => {
    loadProviders();

    if (customProfile?.CustomProfileID) {
      loadCustomProfile(customProfile.CustomProfileID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customProfile?.CustomProfileID]);

  const beforeLogoUpload = (_: any) => {
    return false;
  };

  const getBase64 = (img: any, callback: (result: any) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleLogoChange = (info: any) => {
    const isJpgOrPng =
      info.file.type === "image/jpeg" || info.file.type === "image/png";
    if (!isJpgOrPng) {
      message.error(
        i18n._(
          t(
            "CustomProfilesModal.OnlyImagesAllowedError"
          )`You can only upload JPG/PNG file!`
        )
      );
    }

    const isLt2M = info.file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(
        i18n._(
          t(
            "CustomProfilesModal.FileMustBeLessThan2MBError"
          )`  Image must smaller than 2MB!`
        )
      );
    }

    if (isJpgOrPng && isLt2M) {
      getBase64(info.file, (logoURL) =>
        dispatch(setCustomProfileLogo(logoURL))
      );
    }
  };

  const onModalDismiss = () => {
    dispatch(hideCustomProfileModal());
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const customProfileRequest = {
        ...values,
        URLLogo: String(customProfile?.URLLogo),
        CustomProfileID: Number(customProfile?.CustomProfileID),
      };

      if (modalType === "create") onCreate(customProfileRequest);
      else onUpdate(customProfileRequest);
    });
  };

  const types = [
    {
      value: "P",
      text: <Trans id="Common.TypePharmacyLabel">Pharmacy</Trans>,
    },
    {
      value: "L",
      text: <Trans id="Common.TypeLaboratoryLabel">Laboratory</Trans>,
    },
    {
      value: "V",
      text: <Trans id="Common.TypeVeterinaryLabel">Vet</Trans>,
    },
  ];

  return (
    <Modal
      centered
      className="customProfile-modal"
      destroyOnClose
      okButtonProps={{ loading: loading }}
      visible={showModal}
      footer={
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Button
              loading={providerState.loading}
              onClick={onSubmit}
              type="primary"
            >
              {modalType === "create" ? (
                <Trans render="span" id="CustomProfileModal.CreateButton">
                  Create
                </Trans>
              ) : (
                <Trans render="span" id="CustomProfileModal.UpdateButton">
                  Update
                </Trans>
              )}
            </Button>
          </Col>
          <Col>
            <Button disabled={providerState.loading} onClick={onModalDismiss}>
              <Trans render="span" id="CustomProfileModal.CancelButton">
                Cancel
              </Trans>
            </Button>
          </Col>
        </Row>
      }
      onCancel={onModalDismiss}
      title={
        modalType === "create" ? (
          <Trans id="CustomProfileModal.CreateTitle">
            Create custom Profile
          </Trans>
        ) : (
          <Trans id="CustomProfileModal.EditTitle">Edit custom profile</Trans>
        )
      }
    >
      <Form colon={false}>
        <Row type="flex" justify="center">
          <Form.Item>
            {getFieldDecorator(nameof("URLLogo"), {
              valuePropName: "fileList",
              initialValue: customProfile?.URLLogo
                ? customProfile.URLLogo !== ""
                  ? [{}]
                  : undefined
                : undefined,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="CustomProfiles.Form.LogoRequiredError">
                      Logo is required
                    </Trans>
                  ),
                },
              ],
            })(
              <Upload
                className="form-customProfile-logo"
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeLogoUpload}
                onChange={handleLogoChange}
                style={{ maxWidth: "300px" }}
              >
                {customProfile?.URLLogo !== "" ? (
                  <img src={customProfile?.URLLogo} alt="CustomProfile logo" />
                ) : (
                  <div>
                    <Icon type="plus" />
                    <Trans render="div" id="CustomProfiles.Form.LogoLabel">
                      Add logo
                    </Trans>
                  </div>
                )}
              </Upload>
            )}
          </Form.Item>
        </Row>
        <Form.Item
          label={<Trans id="CustomProfiles.Form.NameLabel">Name</Trans>}
        >
          {getFieldDecorator(nameof("Name"), {
            initialValue: customProfile?.Name,
            rules: [
              {
                required: true,
                message: (
                  <Trans id="CustomProfiles.Form.NameRequiredError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(<Input maxLength={60} />)}
        </Form.Item>
        <Form.Item
          label={
            <Trans id="CustomProfileModal.Form.UrlSlugLabel">UrlSlug</Trans>
          }
        >
          {getFieldDecorator(nameof("UrlSlug"), {
            initialValue: customProfile?.UrlSlug,
            rules: [
              {
                required: true,
                message: (
                  <Trans id="CustomProfiles.Form.UrlSlugRequiredError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(<Input maxLength={60} />)}
        </Form.Item>
        <Form.Item
          label={<Trans id="CustomProfiles.Form.TypeLabel">Type</Trans>}
        >
          {getFieldDecorator(nameof("Type"), {
            initialValue: customProfile?.Type,
            rules: [
              {
                required: true,
                message: (
                  <Trans id="CustomProfiles.Form.TypeRequiredError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(
            <Select>
              {types.map((type, index) => (
                <Select.Option key={index} value={type.value}>
                  {type.text}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          label={<Trans id="CustomProfiles.Form.AddressLabel">Address</Trans>}
        >
          {getFieldDecorator(nameof("Address"), {
            initialValue: customProfile?.Address,
            rules: [
              {
                required: true,
                message: (
                  <Trans id="CustomProfiles.Form.AddressRequiredError">
                    This field is required
                  </Trans>
                ),
              },
            ],
          })(<TextArea maxLength={500} />)}
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item
              label={
                <Trans id="CustomProfiles.Form.LattitudeLabel">Lattitude</Trans>
              }
            >
              {getFieldDecorator(nameof("Lattitude"), {
                initialValue: customProfile?.Lattitude,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.LattitudeRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label={
                <Trans id="CustomProfiles.Form.LongitudeLabel">Longitude</Trans>
              }
            >
              {getFieldDecorator(nameof("Longitude"), {
                initialValue: customProfile?.Longitude,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.LongitudeRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label={<Trans id="CustomProfiles.Form.Phone1Label">Phone1</Trans>}
            >
              {getFieldDecorator(nameof("Phone1"), {
                initialValue: customProfile?.Phone1,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.Phone1RequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label={<Trans id="CustomProfiles.Form.Phone2Label">Phone2</Trans>}
            >
              {getFieldDecorator(nameof("Phone2"), {
                initialValue: customProfile?.Phone2,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.Phone2RequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Form.Item
              label={
                <Trans id="CustomProfiles.Form.EmailAddressLabel">
                  EmailAddress
                </Trans>
              }
            >
              {getFieldDecorator(nameof("EmailAddress"), {
                initialValue: customProfile?.EmailAddress,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.EmailAddressRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Form.Item
              label={
                <Trans id="CustomProfiles.Form.URLWebSiteLabel">
                  WebSite Url
                </Trans>
              }
            >
              {getFieldDecorator(nameof("URLWebSite"), {
                initialValue: customProfile?.URLWebSite,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.URLWebSiteRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={
                <Trans id="CustomProfiles.Form.URLFacebookLabel">
                  Facebook URL
                </Trans>
              }
            >
              {getFieldDecorator(nameof("URLFacebook"), {
                initialValue: customProfile?.URLFacebook,
                rules: [
                  {
                    required: false,
                    message: (
                      <Trans id="CustomProfiles.Form.URLFacebookRequiredError">
                        This field is required
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={60} />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapState = (state: any): ProviderState => state.providerState;
export default Form.create<CustomProfileModalProps>()(CustomProfileModal);
