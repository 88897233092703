import React, { useEffect, useState } from "react";
import "./AppointmentCancelModal.css";
import { AppointmentModalProps } from "./Types";
import {
  Modal,
  Form,
  Input,
  Col,
  DatePicker,
  Select,
  Row,
  Button,
  Typography,
  Dropdown,
  Icon,
  Popover,
  Radio,
  Divider,
  Collapse,
  Timeline,
} from "antd";
import { Trans } from "@lingui/macro";
import {
  Appointment,
  AppointmentState,
  hideCancelAppointmentModal,
  showModalForReschedule,
} from "../../../store/apointment";
import { nameofFactory } from "../../../utils";
import { searchAccountForAutocomplete } from "../../../services/account";
import { getProvider } from "../../../services/provider";
import { AccountState, selectAccount } from "../../../store/account";
import { ProviderState } from "../../../store/provider";
import { useSelector, useDispatch } from "react-redux";
import {
  cancelAppointment,
  resendAppointmentNotification,
  getAppointmentLog,
} from "../../../services/appointment";
import { Link } from "react-router-dom";

const AppointmentCancelModal: React.FC<AppointmentModalProps> = (props) => {
  const { accountState, appointmentState, providerState } =
    useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const { form } = props;
  const nameof = nameofFactory<Appointment>();
  const { getFieldDecorator } = form;
  const { appointment } = appointmentState;
  const readonly = appointmentState.readonly;

  useEffect(() => {
    if (appointment?.patientId)
      searchAccountForAutocomplete(appointment?.patientId).then(() =>
        dispatch(selectAccount(String(appointment?.patientId)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointment?.providerId) getProvider(appointment?.providerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dismissModal = () => {
    if (!appointmentState.loading) dispatch(hideCancelAppointmentModal());
  };

  const onCancel = (data: any) => {
    cancelAppointment({
      ...data,
      id: String(appointment?.id),
      patiendId: String(accountState.account?.UID),
    });
  };

  const onReschedule = () => {
    if (appointment) dispatch(showModalForReschedule(appointment));
  };

  const onResendNotification = () => {
    if (appointment?.id)
      resendAppointmentNotification(appointment.id).then((result) => {
        if (result && appointment.id) getAppointmentLog(appointment.id);
      });
  };

  const toggleLog = (key: string | string[]) => {
    let shouldRequestLog = false;

    if (key instanceof Array) {
      if (key.length > 0) shouldRequestLog = true;
    } else {
      if (key !== undefined && key !== null && key !== "")
        shouldRequestLog = true;
    }

    if (shouldRequestLog && appointment?.id && !appointment.logs) {
      getAppointmentLog(appointment.id);
    }
  };

  const getModality = () => {
    if (appointment?.modality?.toLocaleUpperCase() === "P")
      return (
        <h4>
          {" "}
          <Trans id="Common.ModalityPresentialLabel">On site</Trans>
        </h4>
      );
    if (appointment?.modality?.toLocaleUpperCase() === "D")
      return (
        <h4>
          {" "}
          <Trans id="Common.ModalityHomeCareLabel">Home care</Trans>
        </h4>
      );
    if (appointment?.modality?.toLocaleUpperCase() === "T")
      return (
        <h4>
          {" "}
          <Trans id="Common.ModalityTeleHealtLabel">Telehealth</Trans>
        </h4>
      );
  };

  return (
    <Modal
      centered
      className="appointment-cancel-modal"
      destroyOnClose
      footer={
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Dropdown
              overlay={
                <ModalMainButtonMenu
                  onCancel={onCancel}
                  onReschedule={onReschedule}
                  onResendNotification={onResendNotification}
                  readonly={readonly}
                  allowReSchedule={appointment?.allowReSchedule}
                />
              }
            >
              <Button type="primary" loading={appointmentState.loading}>
                <Trans render="span" id="AppointmentCancelModal.ActionsButton">
                  Actions
                </Trans>
                <Icon type="down" />
              </Button>
            </Dropdown>
          </Col>
          <Col>
            <Button disabled={appointmentState.loading} onClick={dismissModal}>
              <Trans render="span" id="AppointmentCancelModal.DismissButton">
                Dismiss
              </Trans>
            </Button>
          </Col>
        </Row>
      }
      onCancel={dismissModal}
      title={
        appointment?.readOnly ? (
          <Trans id="AppointmentCancelModal.ReadOnlyTitle">
            Appointment information
          </Trans>
        ) : (
          <Link
            to={{
              pathname: "/patients",
              search: `?id=${accountState.account?.IdentificationNumber}`,
            }}
            target="_blank"
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            <Trans id="AppointmentCancelModal.CancelTitle">
              Edit{" "}
              {`${accountState.account?.FirstName} ${accountState.account?.LastName} ${accountState.account?.SecondLastName}'s `}{" "}
              appointment
            </Trans>{" "}
            <Icon type="search" />
          </Link>
        )
      }
      visible={appointmentState.showCancelModal}
    >
      <Row type="flex">
        <Typography.Title level={4}>
          {providerState.provider?.FullName}
        </Typography.Title>
      </Row>
      <Form colon={false} layout="vertical">
        <Input.Group>
          <Col span={10}>
            <Form.Item
              label={<Trans id="AppointmentCancelModal.DateLabel">Date</Trans>}
            >
              {getFieldDecorator(nameof("date"), {
                initialValue: appointment?.date,
              })(<DatePicker disabled format="L" allowClear={false} />)}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={
                <Trans id="AppointmentCancelModal.StartTimeLabel">
                  Start time
                </Trans>
              }
            >
              {getFieldDecorator(nameof("startTime"), {
                initialValue: appointment?.startTime?.format("hh:mm a"),
              })(<Select style={{ width: "100%" }} disabled />)}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={
                <Trans id="AppointmentCancelModal.EndTimeLabel">End time</Trans>
              }
            >
              {getFieldDecorator(nameof("endTime"), {
                initialValue: appointment?.endTime?.format("hh:mm a"),
              })(<Select style={{ width: "100%" }} disabled />)}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={<Trans id="Common.ModalityLabel">Modality</Trans>}
            >
              {getModality()}
            </Form.Item>
          </Col>
        </Input.Group>
      </Form>
      <Row type="flex">
        <Collapse
          bordered={false}
          style={{ width: "100%" }}
          onChange={toggleLog}
        >
          <Collapse.Panel
            key="1"
            header={<Trans id="AppointmentCancelModal.LogLabel">Log</Trans>}
            style={{ border: 0 }}
          >
            {appointment?.logs && (
              <Timeline>
                {appointment.logs.map((log, index) => (
                  <Timeline.Item key={index}>
                    <p>
                      <b>{`(${log.Event}) ${log.Date.format("LLLL")} ${
                        log.UserID
                      }`}</b>
                    </p>
                    <p>{log.Notes}</p>
                  </Timeline.Item>
                ))}
              </Timeline>
            )}
          </Collapse.Panel>
        </Collapse>
      </Row>
    </Modal>
  );
};

const ModalMainButtonMenu = Form.create<any>()((props: any) => {
  const { form } = props;
  const { getFieldDecorator, validateFields } = form;
  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const hide = () => {
    setVisible(false);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err: any, values: any) => {
      if (err) return;

      hide();
      props.onCancel(values);
    });
  };

  return (
    <React.Fragment>
      <Row className="actions-button-option">
        <Popover
          arrowPointAtCenter
          content={
            <React.Fragment>
              <Form onSubmit={onSubmit}>
                <Form.Item
                  label={
                    <Trans id="AppointmentCancelModal.WhoCancelLabel">
                      Who cancelled?
                    </Trans>
                  }
                >
                  {getFieldDecorator("whoCancells", {
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(
                    <Radio.Group>
                      <Radio value="Patient">
                        <Trans id="AppointmentCancelModal.PacientCancelOption">
                          Patient
                        </Trans>
                      </Radio>
                      <Radio value="Provider">
                        <Trans id="AppointmentCancelModal.ProviderCancelOption">
                          Professional
                        </Trans>
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item
                  label={
                    <Trans id="AppointmentCancelModal.NotesLabel">Notes</Trans>
                  }
                >
                  {getFieldDecorator("notes")(<Input.TextArea rows={3} />)}
                </Form.Item>
                <Divider />
                <Form.Item>
                  <Button type="danger" block htmlType="submit">
                    <Trans id="AppointmentCancelModal.DeleteActionButton">
                      Cancel
                    </Trans>
                  </Button>
                  <Button block onClick={hide}>
                    <Trans id="AppointmentCancelModal.DismissActionButton">
                      Dismiss
                    </Trans>
                  </Button>
                </Form.Item>
              </Form>
            </React.Fragment>
          }
          onVisibleChange={handleVisibleChange}
          placement="top"
          title={
            <Typography.Title level={4}>
              <Trans id="AppointmentCancelModal.DeleteActionConfirmationText">
                Do you wish to cancel this appointment?
              </Trans>
            </Typography.Title>
          }
          trigger="click"
          visible={visible}
        >
          <Button block disabled={props.readonly}>
            <Trans
              render="span"
              id="AppointmentCancelModal.CancelAppointmentButton"
            >
              Cancel
            </Trans>
          </Button>
        </Popover>
      </Row>
      <Row className="actions-button-option">
        <Button
          block
          disabled={props.readonly || props.allowReSchedule === false}
          onClick={props.onReschedule}
        >
          <Trans
            render="span"
            id="AppointmentCancelModal.RescheduleAppointmentButton"
          >
            Re-schedule
          </Trans>
        </Button>
      </Row>
      <Row className="actions-button-option">
        <Button
          block
          disabled={props.readonly}
          onClick={props.onResendNotification}
        >
          <Trans
            render="span"
            id="AppointmentCancelModal.ResendNotificationAppointmentButton"
          >
            Resend notification
          </Trans>
        </Button>
      </Row>
    </React.Fragment>
  );
});

const mapStateToProps = (state: any): StateToProps => ({
  accountState: state.accountState,
  appointmentState: state.appointmentState,
  providerState: state.providerState,
  specialtyId: state.searchState.params.specialtyId,
});

interface StateToProps {
  accountState: AccountState;
  appointmentState: AppointmentState;
  providerState: ProviderState;
  specialtyId: number;
}

export default Form.create<AppointmentModalProps>()(AppointmentCancelModal);
