import React from "react";
import "./ProcedureModal.css";
import { Form, Input, Modal, Row, Col, Button } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../utils";
import { Procedure, hideProcedureModal } from "../../store/procedure";
import { ProcedureModalProps } from "./Types";
import { useDispatch } from "react-redux";

const { TextArea } = Input;

const FormStateless: React.FC<ProcedureModalProps> = props => {
  const {
    form,
    loading,
    modalType,
    onCreate,
    onUpdate,
    showModal,
    procedure
  } = props;
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();
  const nameof = nameofFactory<Procedure>();

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const procedureRequest = {
        ...values,
        ProcedureID: procedure?.ProcedureID
      };

      if (modalType === "create") onCreate(procedureRequest);
      else onUpdate(procedureRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideProcedureModal());
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="procedure-modal"
        destroyOnClose
        visible={showModal}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button loading={loading} onClick={handleSubmit} type="primary">
                {modalType === "create" ? (
                  <Trans
                    render="span"
                    id="Procedures.CreateProcedureFormOkButton"
                  >
                    Create
                  </Trans>
                ) : (
                  <Trans
                    render="span"
                    id="Procedures.EditProcedureFormOkButton"
                  >
                    Update
                  </Trans>
                )}
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="Procedures.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          modalType === "create" ? (
            <Trans id="Procedures.CreateProcedureFormTitle">
              Create procedure
            </Trans>
          ) : (
            <Trans id="Procedures.EditProcedureFormTitle">Edit procedure</Trans>
          )
        }
      >
        <Form colon={false}>
          <Form.Item label={<Trans id="Procedures.Form.NameLabel">Name</Trans>}>
            {getFieldDecorator(nameof("Name"), {
              initialValue: procedure?.Name,
              rules: [
                {
                  required: true,
                  message: <Trans id="Common.RequiredFieldError" />
                }
              ]
            })(<Input maxLength={60} />)}
          </Form.Item>
          <Form.Item
            label={
              <Trans id="Procedures.Form.DescriptionLabel">Description</Trans>
            }
          >
            {getFieldDecorator(nameof("Description"), {
              initialValue: procedure?.Description,
              rules: [
                {
                  required: false,
                  message: <Trans id="Common.RequiredFieldError" />
                }
              ]
            })(<TextArea />)}
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Form.create<ProcedureModalProps>()(FormStateless);
