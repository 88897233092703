import { ProviderAppointmentReason } from './../../store/providerAppointmentReason/Types';
import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  Provider,
  loadingProviders,
  setProviders,
  setProvider,
  addProviderSpecialties,
  savingProvider,
  providerSaved,
  addProviderLocations,
  loadingProviderLocations,
  loadingProviderEducationCourse,
  addProviderEducationCourse,
  loadingProviderEducationDegree,
  addProviderEducationDegree,
  loadingProviderAppointmentReason,
  addProviderAppointmentReason,
  setProvidersClinic
} from "../../store/provider";
import {
  TimeSlotsRequest,
  LocationTimeSlotResponse,
  ProviderRequest,
  ProviderPictureRequest,
} from "./Types";
import { loadingTimeSlots, loadedTimeSlots } from "../../store/apointment";
import moment from "moment";
import { Specialty } from "../../store/specialty";
import { notification } from "antd";
import { i18n } from "../../utils";
import { t } from "@lingui/macro";
import {
  Location,
  changingLocationFinished,
  changingLocation,
} from "../../store/location";

import {
  ProviderEducationCourse,
  updatedProviderEducationCourse,
  updatingProviderEducationCourse,
} from "../../store/providerEducationCourse";

import {
  ProviderEducationDegree,
  updatedProviderEducationDegree,
  updatingProviderEducationDegree,
} from "../../store/providerEducationDegree";
import { LocalStorageKeys } from '../../utils/Constants';

export const getProvider = async (providerId: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerId}`;

  try {
    const response = await httpClient.get<GenericApiResponse<Provider>>(url);
    store.dispatch(setProvider(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProvider(undefined));
    return false;
  }
};

export const getProviderProfile = async (providerId: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerId}/profile`;

  try {
    store.dispatch(loadingProviders());
    const response = await httpClient.get<GenericApiResponse<any>>(url);
    const profile = mapProfile(response.data.Data);
    store.dispatch(setProvider(profile));
    return true;
  } catch {
    store.dispatch(setProvider(undefined));
    return false;
  }
};

export const loadProviders = async (value?: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/list/${value || ""}`;

  if (value === null || value === "" || value === undefined) return false;

  try {
    store.dispatch(loadingProviders());
    const response = await httpClient.get<GenericApiResponse<Provider[]>>(url);
    store.dispatch(setProviders(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProviders([]));
    return false;
  }
};

export const loadEnabledProviders = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/state/enabled`;

  try {
    store.dispatch(loadingProviders());
    const response = await httpClient.get<GenericApiResponse<Provider[]>>(url);
    store.dispatch(setProviders(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProviders([]));
    return false;
  }
};

export const loadProviderTimeSlots = async (request: TimeSlotsRequest) => {
  const { providerId, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerId}/timeslots`;
  const params = {
    ...rest,
    from: rest.from.format("YYYY-MM-DD"),
    to: rest.to.format("YYYY-MM-DD"),
  };

  try {
    store.dispatch(loadingTimeSlots());
    const response = await httpClient.get<
      GenericApiResponse<LocationTimeSlotResponse[]>
    >(url, {
      params,
    });

    const locationTimeSlots = response.data.Data[0];
    const timeSlots = {
      AppointmentIntervalMinutes: locationTimeSlots.AppointmentIntervalMinutes,
      TimeSlots: locationTimeSlots.TimeSlots.map((timeSlot) => ({
        Date: moment(timeSlot.Date),
        Hours: timeSlot.Hours.map((hour) => moment(hour, "HH:mm:ss")),
      })),
    };

    store.dispatch(loadedTimeSlots(timeSlots));
    return true;
  } catch {
    return false;
  }
};

export const searchProvidersForAutocomplete = async (value: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/provider/autocomplete";

  try {
    store.dispatch(loadingProviders());
    const response = await httpClient.get<GenericApiResponse<Provider[]>>(url, {
      params: { value },
    });
    store.dispatch(setProviders(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProviders([]));
    return false;
  }
};

export const loadProviderSpecialties = async (providerId: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerId}/specialties`;

  try {
    const response = await httpClient.get<GenericApiResponse<Specialty[]>>(url);
    store.dispatch(addProviderSpecialties(response.data.Data));
    return true;
  } catch {
    store.dispatch(addProviderSpecialties([]));
    return false;
  }
};

export const createProvider = async (request: ProviderRequest) => {
  const { DateofBirth, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "provider";
  const newRequest = {
    ...rest,
    DateofBirth: DateofBirth.format("YYYY-MM-DD"),
  };

  try {
    store.dispatch(savingProvider());
    let response = await httpClient.post(url, newRequest);
    store.dispatch(providerSaved());

    notification.success({
      message: i18n._(
        t(
          "ProviderService.ProviderCreatedMessage"
        )`The provider has been created`
      ),
    });
    return { success: true, data: response.data };
  } catch {
    return false;
  }
};

export const updateProvider = async (request: ProviderRequest) => {
  const { ProviderID, DateofBirth, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${ProviderID}`;
  const newRequest = {
    ...rest,
    DateofBirth: DateofBirth.format("YYYY-MM-DD"),
  };

  try {
    store.dispatch(savingProvider());
    await httpClient.put(url, newRequest);
    store.dispatch(providerSaved());

    notification.success({
      message: i18n._(
        t(
          "ProviderService.ProviderUpdatedMessage"
        )`The provider has been updated`
      ),
    });

    return true;
  } catch {
    store.dispatch(providerSaved());
    return false;
  }
};

export const loadBasicProviderLocations = async (providerId: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerId}/basicLocations`;

  try {
    store.dispatch(loadingProviderLocations());
    const response = await httpClient.get<GenericApiResponse<Location[]>>(url);
    store.dispatch(addProviderLocations(response.data.Data));
    return true;
  } catch {
    store.dispatch(addProviderLocations([]));
    return false;
  }
};

export const loadFullProviderLocations = async (providerId: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerId}/fullLocations`;

  try {
    store.dispatch(loadingProviderLocations());
    const response = await httpClient.get<GenericApiResponse<Location[]>>(url);
    store.dispatch(addProviderLocations(response.data.Data));
    return true;
  } catch {
    store.dispatch(addProviderLocations([]));
    return false;
  }
};

export const updateProviderLocation = async (
  providerId: string,
  request: any
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerId}/location`;

  try {
    store.dispatch(changingLocation());
    await httpClient.put(url, request);
    store.dispatch(changingLocationFinished(true));
    return true;
  } catch {
    store.dispatch(changingLocationFinished(false));
    return false;
  }
};

export const deleteProviderLocation = async (
  providerID: string,
  locationID: number,
  agendaID: number
) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/Location/${locationID}/agenda/${agendaID}`;

  try {
    await httpClient.delete(url);
    return true;
  } catch {
    return false;
  }
};

export const loadProviderEducationCourses = async (providerID: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/educationCourse`;

  try {
    store.dispatch(loadingProviderEducationCourse());
    const response = await httpClient.get<
      GenericApiResponse<ProviderEducationCourse[]>
    >(url);
    store.dispatch(addProviderEducationCourse(response.data.Data));
    return true;
  } catch {
    store.dispatch(addProviderEducationCourse([]));
    return false;
  }
};

export const updateProviderEducationCourse = async (
  providerID: string,
  request: any
) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/educationCourse/${request.ProviderEducationCourseID}`;

  try {
    store.dispatch(updatingProviderEducationCourse());
    await httpClient.put(url, request);
    store.dispatch(updatedProviderEducationCourse(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationCourse(false));
    return false;
  }
};

export const loadProviderEducationDegree = async (providerID: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/EducationDegree`;

  try {
    store.dispatch(loadingProviderEducationDegree());
    const response = await httpClient.get<
      GenericApiResponse<ProviderEducationDegree[]>
    >(url);
    store.dispatch(addProviderEducationDegree(response.data.Data));
    return true;
  } catch {
    store.dispatch(addProviderEducationDegree([]));
    return false;
  }
};

export const updateProviderEducationDegree = async (
  providerID: string,
  request: any
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/EducationDegree/${request.ProviderEducationDegreeID}`;

  try {
    store.dispatch(updatingProviderEducationDegree());
    await httpClient.put(url, request);
    store.dispatch(updatedProviderEducationDegree(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationDegree(false));
    return false;
  }
};

const mapProfile = (response: any) => {
  const { Lastname, SecondLastname, DateofBirth, Pictures, ...rest } = response;
  const avatar = Pictures.find((x: any) => x.IndAvatar);
  const newPictures = Pictures.filter((x: any) => !x.IndAvatar);

  if (avatar) newPictures.unshift(avatar);

  return {
    ...rest,
    LastName: Lastname,
    SecondLastName: SecondLastname,
    DateofBirth: moment(DateofBirth, "YYYY-MM-DD"),
    Pictures: newPictures,
  };
};

export const deleteProvider = async (providerID: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}`;

  try {
    await httpClient.delete(url);
    return true;
  } catch {
    return false;
  }
};

export const updateProviderAvatar = async (
  providerID: string,
  request: ProviderPictureRequest
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/avatar`;

  try {
    store.dispatch(savingProvider());

    let response = await httpClient.post(url, request);

    store.dispatch(providerSaved());

    return { success: true, data: response.data };
  } catch {
    store.dispatch(providerSaved());
    return { success: false };
  }
};
export const addProviderAdditionalPicture = async (
  providerID: string,
  request: ProviderPictureRequest
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/picture`;

  try {
    store.dispatch(savingProvider());

    let response = await httpClient.post(url, request);

    store.dispatch(providerSaved());
    return { success: true, data: response.data };
  } catch {
    store.dispatch(providerSaved());
    return { success: false };
  }
};

export const deleteProviderAdditionalPicture = async (
  providerID: string,
  PictureID: string
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/Picture/${PictureID}`;

  try {
    store.dispatch(savingProvider());
    await httpClient.delete(url);
    store.dispatch(providerSaved());
    return true;
  } catch {
    store.dispatch(providerSaved());
    return false;
  }
};

export const loadAppointmentReason = async (providerID: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/listAppointmentReason`;

  try {
    store.dispatch(loadingProviderAppointmentReason());
    const response = await httpClient.get<
      GenericApiResponse<ProviderAppointmentReason[]>
    >(url);
    store.dispatch(addProviderAppointmentReason(response.data.Data));
    return true;
  } catch {
    store.dispatch(addProviderAppointmentReason([]));
    return false;
  }
  
};

export const loadEnabledProvidersClinic = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/state/enabled/clinic`;

  try {
    store.dispatch(loadingProviders());
    const response = await httpClient.get<GenericApiResponse<Provider[]>>(url);
    store.dispatch(setProvidersClinic(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProvidersClinic([]));
    return false;
  }
};
