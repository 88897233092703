import { SpecialtyState, Specialty } from "./Types";
import {
  LOADING_SPECIALTIES,
  SET_SPECIALTIES,
  CHANGING_SPECIALTY,
  CHANGING_SPECIALTY_FINISHED,
  SHOW_SPECIALTY_MODAL_FOR_CREATE,
  SHOW_SPECIALTY_MODAL_FOR_EDIT,
  HIDE_SPECIALTY_MODAL,
  DELETE_SPECIALTY_FINISHED,
  UPDATE_SPECIALTIES,
  FILTER_SPECIALTIES,
  LOADING_SPECIALTIES_TYPEAHEAD,
  SET_SPECIALTIES_TYPEHEAD
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: SpecialtyState = {
  filteredSpecialties: [],
  specialties: [],
  specialtiesTypeahead: [],
  changing: false,
  count: 0,
  loading: false,
  modalType: "create",
  showModal: false,
  updateSpecialties: false
};

export const specialtyReducer = (
  state = initialState,
  action: any
): SpecialtyState => {
  switch (action.type) {
    case CHANGING_SPECIALTY:
      return {
        ...state,
        changing: true
      };

    case CHANGING_SPECIALTY_FINISHED:
      return {
        ...state,
        changing: false,
        specialty: undefined,
        showModal: !action.result,
        updateSpecialties: action.result
          ? !state.updateSpecialties
          : state.updateSpecialties
      };

    case DELETE_SPECIALTY_FINISHED:
      return {
        ...state,
        changing: false,
        updateSpecialties: action.result
          ? !state.updateSpecialties
          : state.updateSpecialties
      };

    case HIDE_SPECIALTY_MODAL:
      return {
        ...state,
        specialty: undefined,
        showModal: false
      };

    case LOADING_SPECIALTIES:
      return {
        ...state,
        count: 0,
        loading: true
      };

    case SHOW_SPECIALTY_MODAL_FOR_CREATE:
      return {
        ...state,
        specialty: {
          Order: 0,
          SpecialtyID: 0,
          Description: "",
          IsMain: false,
          Name: "",
          PictureURL: ""
        },
        modalType: "create",
        showModal: true
      };

    case SHOW_SPECIALTY_MODAL_FOR_EDIT:
      return {
        ...state,
        specialty: action.specialty,
        modalType: "edit",
        showModal: true
      };

    case SET_SPECIALTIES:
      return {
        ...state,
        specialties: action.specialties,
        filteredSpecialties: action.specialties,
        count: action.specialties.length,
        loading: false
      };

    case CHANGE_DIRECTORY:
    case UPDATE_SPECIALTIES:
      return {
        ...state,
        specialty: undefined,
        specialties: [],
        filteredSpecialties: [],
        updateSpecialties: !state.updateSpecialties
      };

    case FILTER_SPECIALTIES:
      return {
        ...state,
        filteredSpecialties: filterSpecialties(action.value, state.specialties)
      };
    case LOADING_SPECIALTIES_TYPEAHEAD:
      return {
        ...state,
        count: 0,
        loading: true
      };
    case SET_SPECIALTIES_TYPEHEAD:
      return {
        ...state,
        specialtiesTypeahead: action.specialtiesTypeahead,
        count: action.specialtiesTypeahead.length,
        loading: false
      };

    default:
      return state;
  }
};

const filterSpecialties = (value: string, specialties: Specialty[]) => {
  if (value === "") return specialties;

  return specialties.filter(provider => isFilterMatch(value, provider));
};

const isFilterMatch = (value: string, specialty: Specialty) => {
  return specialty.Name.toLowerCase().includes(value.toLowerCase());
};
