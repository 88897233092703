import { ProviderEducationDegreeState } from "./Types";
import {
  LOADING_PROVIDER_EDUCATION_DEGREE,
  SET_PROVIDER_EDUCATION_DEGREE,
  EDIT_EDUCATION_DEGREE,
  STOP_EDIT_EDUCATION_DEGREE,
  CHANGE_PROVIDER_EDUCATION_DEGREE_FIELD,
  SET_CALENDAR_DATE_FROM,
  SET_CALENDAR_DATE_TO,
  UPDATING_PROVIDER_EDUCATION_DEGREE,
  UPDATED_PROVIDER_EDUCATION_DEGREE,
  HIDE_NEW_PROVIDER_EDUCATION_DEGREE_MODAL,
  SHOW_NEW_PROVIDER_EDUCATION_DEGREE_CREATE_MODAL,
} from "./Actions";

const initialState: ProviderEducationDegreeState = {
  updatingProviderEducationDegree: false,
  canEdit: true,
  loading: false,
  showModal: false,
  modalType: "create",
};

export const providerEducationDegreeReducer = (
  state = initialState,
  action: any
): ProviderEducationDegreeState => {
  switch (action.type) {
    case CHANGE_PROVIDER_EDUCATION_DEGREE_FIELD:
      if (state.providerEducationDegree) {
        return {
          ...state,
          providerEducationDegree: {
            ...state.providerEducationDegree,
            [action.name]: action.value,
          },
        };
      }
      return state;

    case SET_CALENDAR_DATE_FROM:
      if (state.providerEducationDegree) {
        return {
          ...state,
          providerEducationDegree: {
            ...state.providerEducationDegree,
            DateFrom: action.date,
          },
        };
      }
      return state;
    case SET_CALENDAR_DATE_TO:
      if (state.providerEducationDegree) {
        return {
          ...state,
          providerEducationDegree: {
            ...state.providerEducationDegree,
            DateTo: action.date,
          },
        };
      }
      return state;

    case EDIT_EDUCATION_DEGREE:
      return {
        ...state,
        canEdit: false,
        providerEducationDegree: action.providerEducationDegree,
      };

    case STOP_EDIT_EDUCATION_DEGREE:
      return {
        ...state,
        canEdit: true,
        providerEducationDegree: undefined,
      };

    case SET_PROVIDER_EDUCATION_DEGREE:
      return {
        ...state,
        providerEducationDegree: action.providerEducationDegree,
        loading: false,
      };

    case LOADING_PROVIDER_EDUCATION_DEGREE:
      return {
        ...state,
        loading: true,
      };

    case UPDATING_PROVIDER_EDUCATION_DEGREE:
      return {
        ...state,
        updatingProviderEducationDegree: true,
      };

    case UPDATED_PROVIDER_EDUCATION_DEGREE:
      return {
        ...state,
        canEdit: action.result,
        updatingProviderEducationDegree: false,
        providerEducationDegree: action.result
          ? undefined
          : state.providerEducationDegree,
      };

    case HIDE_NEW_PROVIDER_EDUCATION_DEGREE_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case SHOW_NEW_PROVIDER_EDUCATION_DEGREE_CREATE_MODAL:
      return {
        ...state,
        modalType: "create",
        showModal: true,
        providerEducationDegree: undefined,
      };

    default:
      return state;
  }
};
