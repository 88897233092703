import React, { useEffect } from "react";
import "./KeywordsPage.css";
import {
  Table,
  Divider,
  Button,
  Row,
  Tooltip,
  Popconfirm,
  message,
  Col,
  Input,
  Typography,
} from "antd";
import { Trans, t } from "@lingui/macro";
import { Container } from "../../components/utils";
import {
  KeywordState,
  Keyword,
  showKeywordModalForCreate,
  showKeywordModalForEdit,
  filterKeywords,
} from "../../store/keyword";
import { useSelector, useDispatch } from "react-redux";
import {
  loadKeywordsFromAdmin,
  updateKeyword,
  createKeyword,
  deleteKeyword,
} from "../../services/keyword";
import { i18n } from "../../utils";
import { KeywordModal } from "../../components/keyword-modal";

export const KeywordsPage: React.FC = (props) => {
  const pageSize = 20;
  const keywordState = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    loadKeywordsFromAdmin();
  }, [keywordState.updateKeywords]);

  const onCreateKeyword = () => {
    dispatch(showKeywordModalForCreate());
  };

  const onCreate = (keyword: Keyword) => {
    createKeyword(keyword);
  };

  const onUpdate = (keyword: Keyword) => {
    updateKeyword(keyword);
  };

  const onDeleteKeyword = (keywordId: number) => {
    message.loading(
      i18n._(t("KeywordPage.DeletingKeyword")`Deleting keyword...`),
      0.5,
      () => deleteKeyword(keywordId)
    );
  };

  const onUpdateKeyword = (keyword: Keyword) => {
    dispatch(showKeywordModalForEdit(keyword));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Keywords.TermColumnTitle">
          Term
        </Trans>
      ),
      dataIndex: "Term",
      key: "Term",
    },
    {
      title: (
        <Trans render="strong" id="Keywords.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (keyword: Keyword) => (
        <span>
          <Tooltip
            title={<Trans id="Keywords.EditActionTooltip">Edit keyword</Trans>}
          >
            <Button icon="edit" onClick={() => onUpdateKeyword(keyword)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Keywords.DeleteActionTooltip">Delete keyword</Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="Keyword.DeleteActionConfirmationText">
                  Do you wish to delete this keyword?
                </Trans>
              }
              okText={<Trans id="Keyword.DeleteActionButton">Delete</Trans>}
              onConfirm={() => onDeleteKeyword(keyword.KeywordID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const filterFoundKeywords = (value: string) => {
    dispatch(filterKeywords(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundKeywords(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography.Title level={2}>
          <Trans id="Keywords.Title">Keywords</Trans>
        </Typography.Title>
        <Table
          dataSource={keywordState.filteredKeywords}
          columns={columns}
          loading={keywordState.loading}
          bodyStyle={{ background: "#fff" }}
          rowKey="KeywordID"
          pagination={
            keywordState.count > pageSize && {
              pageSize,
              position: "bottom",
            }
          }
          title={() => (
            <Row type="flex" align="middle">
              <Col span={6}>
                <Input
                  onChange={onChange}
                  placeholder={i18n._(
                    t(
                      "Keywords.FilterKeywordsPlaceHolder"
                    )`Search by keyword name`
                  )}
                />
              </Col>
              <Col span={18}>
                <Row type="flex" justify="end" gutter={8}>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={onCreateKeyword}
                    >
                      <Trans id="Keywords.CreateKeywordButton">
                        New Keyword
                      </Trans>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        />
      </Container>
      <KeywordModal
        loading={keywordState.changing}
        modalType={keywordState.modalType}
        onCreate={onCreate}
        onUpdate={onUpdate}
        showModal={keywordState.showModal}
        keyword={keywordState.keyword}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): KeywordState => state.keywordState;
