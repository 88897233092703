import { ProviderEducationCourse } from "./Types";

export const CHANGE_PROVIDER_EDUCATION_COURSE_FIELD =
  "CHANGE_PROVIDER_EDUCATION_COURSE_FIELD";
export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";
export const CHANGING_PROVIDER_EDUCATION_COURSE =
  "CHANGING_PROVIDER_EDUCATION_COURSE";
export const CHANGING_PROVIDER_EDUCATION_COURSE_FINISHED =
  "CHANGING_PROVIDER_EDUCATION_COURSE_FINISHED";
export const LOADING_PROVIDER_EDUCATION_COURSE =
  "LOADING_PROVIDER_EDUCATION_COURSE";
export const SET_PROVIDER_EDUCATION_COURSE = "SET_PROVIDER_EDUCATION_COURSE";
export const UPDATE_PROVIDER_EDUCATION_COURSE =
  "UPDATE_PROVIDER_EDUCATION_COURSE";
export const EDIT_EDUCATION_COURSE = "EDIT_EDUCATION_COURSE";
export const STOP_EDIT_EDUCATION_COURSE = "STOP_EDIT_EDUCATION_COURSE";

export const UPDATING_PROVIDER_EDUCATION_COURSE =
  "UPDATING_PROVIDER_EDUCATION_COURSE";

export const UPDATED_PROVIDER_EDUCATION_COURSE =
  "UPDATED_PROVIDER_EDUCATION_COURSE";

export const HIDE_NEW_PROVIDER_EDUCATION_COURSE_MODAL =
  "HIDE_NEW_PROVIDER_EDUCATION_COURSE_MODAL";

export const SHOW_NEW_PROVIDER_EDUCATION_COURSE_CREATE_MODAL =
  "SHOW_NEW_PROVIDER_EDUCATION_COURSE_CREATE_MODAL";

export const changeProviderEducationCourseField = (
  name: string,
  value: any
) => ({
  type: CHANGE_PROVIDER_EDUCATION_COURSE_FIELD,
  name,
  value,
});

export const setCalendarDateEducationCourse = (date: any) => ({
  type: SET_CALENDAR_DATE,
  date,
});

export const editProviderEducationCourse = (
  providerEducationCourse: ProviderEducationCourse
) => ({
  type: EDIT_EDUCATION_COURSE,
  providerEducationCourse,
});

export const stopEditEducationCourse = (providerEducationCourseID: number) => ({
  type: STOP_EDIT_EDUCATION_COURSE,
  providerEducationCourseID,
});

export const loadingProviderEducationCourse = () => ({
  type: LOADING_PROVIDER_EDUCATION_COURSE,
});

export const setProviderEducationCourse = (
  providerEducationCourse: ProviderEducationCourse[]
) => ({
  type: SET_PROVIDER_EDUCATION_COURSE,
  providerEducationCourse,
});

export const updateProviderEducationCourse = () => ({
  type: UPDATE_PROVIDER_EDUCATION_COURSE,
});

export const updatingProviderEducationCourse = () => ({
  type: UPDATING_PROVIDER_EDUCATION_COURSE,
});

export const updatedProviderEducationCourse = (result: boolean) => ({
  type: UPDATED_PROVIDER_EDUCATION_COURSE,
  result,
});

export const hideNewProviderEducationCourseModal = () => ({
  type: HIDE_NEW_PROVIDER_EDUCATION_COURSE_MODAL,
});

export const showNewProviderEducationCourseCreateModal = () => ({
  type: SHOW_NEW_PROVIDER_EDUCATION_COURSE_CREATE_MODAL,
});
