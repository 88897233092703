import axios from "axios";
import { LoginRequest } from "./Types";
import { i18n } from "../../utils";
import { t } from "@lingui/macro";
import store from "../../store";
import {
  completeFailureAuth,
  completeSuccessAuth,
  AuthInfo,
  beginAuth,
  authConnectionFailed,
  setExternalAccessToken,
  requestingExternalAccessToken
} from "../../store/auth";
import { notification } from "antd";
import { LocalStorageKeys } from "../../utils/Constants";

const createLoginBody = (request: LoginRequest) => {
  const body = new URLSearchParams();

  body.append("userName", request.email);
  body.append("password", request.password);
  body.append("grant_type", "password");

  return body;
};

export const login = async (request: LoginRequest) => {
  

  const body = createLoginBody(request);
  let url="";
  let baseUrl="";
  if (request.directory.toUpperCase()==="LOCAL" && process.env.REACT_APP_LOCAL === "true"){
    url = `${process.env.REACT_APP_AUTH_API_URL_LOCAL}/token`;
    baseUrl = process.env.REACT_APP_AUTH_API_URL_LOCAL || "";
  }else{
    if (request.directory.toUpperCase()==="QA" && process.env.REACT_APP_QA === "true"){
      url = `${process.env.REACT_APP_AUTH_API_URL_QA}/token`;
      baseUrl = process.env.REACT_APP_AUTH_API_URL_QA || "";
    }else{
      if (process.env.REACT_APP_PROD === "true"){
        var prodUrl =process.env.REACT_APP_AUTH_API_URL_PROD;
        prodUrl= prodUrl?.replace("{directory}",request.directory.toLocaleLowerCase());
        url = `${prodUrl}/token`;
        baseUrl = prodUrl || "";
      }
    }
    if (url===""){
      store.dispatch(
        completeFailureAuth("Directorio no válido")
      );
      return;
    }
  }

  localStorage.setItem(LocalStorageKeys.baseApiUrl, baseUrl + "/1/");
  localStorage.setItem(LocalStorageKeys.directoryName, request.directory);
  try {
    store.dispatch(beginAuth());
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    handleSuccessLogin(request.email, response.data);
    return true;
  } catch (error) {
    if (error.response)
      store.dispatch(
        completeFailureAuth(error.response.data.error_description)
      );
    else {
      if (error.request)
        notification.error({
          message: i18n._(t("Network.NetworkErrorTitle")`Network error`),
          description: i18n._(
            t(
              "Network.NetworkErrorMessage"
            )`There has been an error contacting the server, check your internet connection and try again. If the error persists it might be a problem with our servers.`
          )
        });
      else
        notification.error({
          message: i18n._(t("Network.UnexpectedErrorTitle")`Unexpected error`),
          description: i18n._(
            t(
              "Network.UnexpectedErrorMessage"
            )`There has been an unknown error, please try again later.`
          )
        });

      store.dispatch(authConnectionFailed());
    }

    return false;
  }
};

export const requestExternalAccessToken = async (request: LoginRequest) => {
  const body = createLoginBody(request);
  const url = `${process.env.REACT_APP_AUTH_API_URL}/externalusertoken`;

  try {
    store.dispatch(requestingExternalAccessToken());
    const response = await axios.post(url, body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    store.dispatch(setExternalAccessToken(response.data.access_token));
    return true;
  } catch (error) {
    if (error.response)
      notification.error({
        message: error.response.data.error_description
      });
    else {
      if (error.request)
        notification.error({
          message: i18n._("Network.NetworkErrorTitle"),
          description: i18n._("Network.NetworkErrorMessage")
        });
      else
        notification.error({
          message: i18n._("Network.UnexpectedErrorTitle"),
          description: i18n._("Network.UnexpectedErrorMessage")
        });
    }

    store.dispatch(setExternalAccessToken(undefined));
    return false;
  }
};

const createAuthInfo = (username: string, response: any): AuthInfo => {
  return {
    accessToken: response.access_token,
    isAdmin: response.isAdmin === "True",
    permissions: {
      canSearch: response.canSearch === "True",
      canSetup: response.canSetup === "True"
    },
    username
  };
};

const handleSuccessLogin = (username: string, response: any) => {
  const authInfo = createAuthInfo(username, response);
  store.dispatch(completeSuccessAuth(authInfo));
};
