import httpClient from "../HttpClient";
import {
  // AppointmentAccountRequest,
  // UpdateAppointmentRequest,
  CancelAppointmentRequest,
  AppointmentRequest,
  UpdateAppointmentRequest,
} from "./Types";
import store from "../../store";
import {
  cancellingAppointmentFinished,
  schedulingAppointmentFinished,
  loadingAppointment,
  loadedAppointment,
  AppointmentLog,
  addAppointmentLogs,
} from "../../store/apointment";
import { reloadCalendar } from "../../store/calendar";
import { notification } from "antd";
import { i18n } from "../../utils";
import { t } from "@lingui/macro";
import { GenericApiResponse } from "../ServicesTypes";
import moment from "moment";
import { LocalStorageKeys } from "../../utils/Constants";

export const scheduleAppointment = async (request: AppointmentRequest) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/appointment";

  try {
    store.dispatch(loadingAppointment());
    await httpClient.post(url, request);
    notification.success({
      message: i18n._(
        t(
          "AppointmentService.ScheduledAppointment"
        )`The appointment has been scheduled`
      ),
    });
    store.dispatch(reloadCalendar());
    store.dispatch(schedulingAppointmentFinished(true));
  } catch {
    store.dispatch(schedulingAppointmentFinished(false));
    return false;
  }
};

export const rescheduleAppointment = async (
  request: UpdateAppointmentRequest
) => {
  const { id, date, ...rest } = request;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`search/appointment/${id}/reschedule`;
  const formattedRequest = {
    ...rest,
    date: date.format("YYYY-MM-DD"),
  };

  try {
    store.dispatch(loadingAppointment());
    await httpClient.put(url, formattedRequest);
    notification.success({
      message: i18n._(
        t(
          "AppointmentService.RescheduledAppointment"
        )`The appointment has been re-scheduled`
      ),
    });
    store.dispatch(reloadCalendar());
    store.dispatch(schedulingAppointmentFinished(true));
    return true;
  } catch {
    store.dispatch(schedulingAppointmentFinished(false));
    return false;
  }
};

export const cancelAppointment = async (request: CancelAppointmentRequest) => {
  const { id, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `search/appointment/${id}/cancel`;

  try {
    store.dispatch(loadingAppointment());
    await httpClient.put(url, rest);
    notification.success({
      message: i18n._(
        t(
          "AppointmentService.CancelledAppointment"
        )`The appointment has been cancelled`
      ),
    });
    store.dispatch(reloadCalendar());
    store.dispatch(cancellingAppointmentFinished(true));
    return true;
  } catch {
    store.dispatch(cancellingAppointmentFinished(false));
    return false;
  }
};

export const resendAppointmentNotification = async (appointmentId: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`search/appointment/${appointmentId}/resendNotification`;

  try {
    store.dispatch(loadingAppointment());
    await httpClient.put(url);
    notification.success({
      message: i18n._(
        t(
          "AppointmentService.ResendAppointmentNotificationSuccess"
        )`The appointment notification has been sent`
      ),
    });
    return true;
  } catch {
    return false;
  } finally {
    store.dispatch(loadedAppointment());
  }
};

export const getAppointmentLog = async (appointmentId: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `account/appointment/${appointmentId}/log`;

  try {
    store.dispatch(loadingAppointment());
    const response = await httpClient.get<GenericApiResponse<AppointmentLog[]>>(
      url
    );
    const formattedResponse = response.data.Data.map((x) => {
      const { Date, ...rest } = x;
      return {
        ...rest,
        Date: moment(Date),
      };
    });

    store.dispatch(addAppointmentLogs(formattedResponse));
    return true;
  } catch {
    store.dispatch(addAppointmentLogs(undefined));
    return false;
  } finally {
    store.dispatch(loadedAppointment());
  }
};
