import { Clinic } from "./Types";

export const SET_CLINIC = "SET_CLINIC";
export const CHANGING_CLINIC = "CHANGING_CLINIC";
export const CHANGING_CLINIC_FINISHED = "CHANGING_CLINIC_FINISHED";
export const DELETE_CLINIC_FINISHED = "DELETE_CLINIC_FINISHED";
export const HIDE_CLINIC_MODAL = "HIDE_CLINIC_MODAL";
export const LOADING_CLINICS = "LOADING_CLINICS";
export const SHOW_CLINIC_MODAL_FOR_CREATE = "SHOW_CLINIC_MODAL_FOR_CREATE";
export const SHOW_CLINIC_MODAL_FOR_EDIT = "SHOW_CLINIC_MODAL_FOR_EDIT";
export const SET_CLINIC_LOGO = "SET_CLINIC_LOGO";
export const SET_CLINICS = "SET_CLINICS";
export const UPDATE_CLINICS = "UPDATE_CLINICS";
export const FILTER_CLINICS = "FILTER_CLINICS";
export const SET_CLINIC_BANNER = "SET_CLINIC_BANNER";

export const changingClinic = () => ({ type: CHANGING_CLINIC });

export const changingClinicFinished = (result: boolean) => ({
  type: CHANGING_CLINIC_FINISHED,
  result,
});
export const deleteClinicFinished = (result: boolean) => ({
  type: DELETE_CLINIC_FINISHED,
  result,
});

export const hideClinicModal = () => ({ type: HIDE_CLINIC_MODAL });

export const loadingClinics = () => ({ type: LOADING_CLINICS });

export const setClinic = (clinic: Clinic | undefined) => ({
  type: SET_CLINIC,
  clinic,
});

export const setClinicLogo = (URLLogo: string) => ({
  type: SET_CLINIC_LOGO,
  URLLogo,
});

export const setClinicBanner = (urlBanner: string) => ({
  type: SET_CLINIC_BANNER,
  urlBanner,
});
export const setClinics = (clinics: Clinic[]) => ({
  type: SET_CLINICS,
  clinics,
});

export const showClinicModalForCreate = () => ({
  type: SHOW_CLINIC_MODAL_FOR_CREATE,
});

export const showClinicModalForEdit = (clinic: Clinic) => ({
  type: SHOW_CLINIC_MODAL_FOR_EDIT,
  clinic,
});

export const updateClinics = () => ({
  type: UPDATE_CLINICS,
});
export const filterClinics = (value: string) => ({
  type: FILTER_CLINICS,
  value,
});
