import { User } from "./Types";
import { Directory } from "../directory";

export const CHANGING_USER = "CHANGING_USER";
export const CHANGING_USER_FINISHED = "CHANGING_USER_FINISHED";
export const CHANGING_USER_PASSWORD_FINISHED =
  "CHANGING_USER_PASSWORD_FINISHED";
export const DELETE_USER_FINISHED = "DELETE_USER_FINISHED";
export const HIDE_USER_MODAL = "HIDE_USER_MODAL";
export const HIDE_USER_PASSWORD_MODAL = "HIDE_USER_PASSWORD_MODAL";
export const LOADING_USER_DIRECTORIES = "LOADING_USER_DIRECTORIES";
export const LOADING_USERS = "LOADING_USERS";
export const SHOW_USER_MODAL_FOR_CREATE = "SHOW_USER_MODAL_FOR_CREATE";
export const SHOW_USER_MODAL_FOR_EDIT = "SHOW_USER_MODAL_FOR_EDIT";
export const SHOW_USER_PASSWORD_MODAL = "SHOW_USER_PASSWORD_MODAL";
export const SET_USER_DIRECTORIES = "SET_USER_DIRECTORIES";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const UPDATE_USERS = "UPDATE_USERS";
export const FILTER_USERS = "FILTER_USERS";

export const changingUser = () => ({ type: CHANGING_USER });

export const changingUserFinished = (result: boolean) => ({
  type: CHANGING_USER_FINISHED,
  result,
});

export const changingUserPasswordFinished = (result: boolean) => ({
  type: CHANGING_USER_PASSWORD_FINISHED,
  result,
});

export const deleteUserFinished = (result: boolean) => ({
  type: DELETE_USER_FINISHED,
  result,
});

export const hideUserModal = () => ({ type: HIDE_USER_MODAL });

export const hideUserPasswordModal = () => ({ type: HIDE_USER_PASSWORD_MODAL });

export const loadingUserDirectories = () => ({
  type: LOADING_USER_DIRECTORIES,
});

export const loadingUsers = () => ({ type: LOADING_USERS });

export const setUserDirectories = (directories: Directory[]) => ({
  type: SET_USER_DIRECTORIES,
  directories,
});

export const setUser = (user: User | undefined) => ({
  type: SET_USER,
  user,
});

export const setUsers = (users: User[]) => ({
  type: SET_USERS,
  users,
});

export const showUserModalForCreate = () => ({
  type: SHOW_USER_MODAL_FOR_CREATE,
});

export const showUserModalForEdit = (user: User) => ({
  type: SHOW_USER_MODAL_FOR_EDIT,
  user,
});

export const showUserPasswordModal = (user: User) => ({
  type: SHOW_USER_PASSWORD_MODAL,
  user,
});

export const updateUsers = () => ({
  type: UPDATE_USERS,
});

export const filterUsers = (value: string) => ({
  type: FILTER_USERS,
  value,
});
