import React, { useEffect } from "react";
import { Container } from "../../components/utils";
import {
  Table,
  Row,
  Button,
  Col,
  Typography,
  Tooltip,
  Popconfirm,
  Divider,
  message,
  Input,
} from "antd";
import { Trans, t } from "@lingui/macro";
import { useDispatch, useSelector } from "react-redux";
import { DirectoryState } from "../../store/directory";
import {
  Procedure,
  ProcedureState,
  showProcedureCreateModal,
  showProcedureEditModal,
  filterProcedures,
} from "../../store/procedure";
import {
  loadProceduresFromAdmin,
  deleteProcedure,
  createProcedure,
  updateProcedure,
} from "../../services/procedure";
import { ProcedureModal } from "../../components/procedure-modal";
import { i18n } from "../../utils";

export const ProceduresPage: React.FC = () => {
  const pageSize = 20;
  const dispatch = useDispatch();
  const { procedureState, directoryState } = useSelector(mapState);

  useEffect(() => {
    loadProceduresFromAdmin();
  }, [directoryState.selectorDirectory, procedureState.updateProcedures]);

  const columns = [
    {
      title: (
        <Trans render="strong" id="ProceduresPage.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="ProceduresPage.DescriptionColumnTitle">
          Description
        </Trans>
      ),
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: (
        <Trans render="strong" id="ProceduresPage.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (procedure: Procedure) => (
        <span>
          <Tooltip
            title={
              <Trans id="ProceduresPage.EditActionTooltip">
                Edit procedure
              </Trans>
            }
          >
            <Button icon="edit" onClick={(_) => onUpdateProcedure(procedure)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="ProceduresPage.DeleteActionTooltip">
                Delete procedure
              </Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="ProceduresPage.DeleteActionConfirmationText">
                  Do you wish to delete this procedure?
                </Trans>
              }
              okText={
                <Trans id="ProceduresPage.DeleteActionButton">Delete</Trans>
              }
              onConfirm={(_) => onDelete(procedure.ProcedureID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const onCreateProcedure = () => {
    dispatch(showProcedureCreateModal());
  };

  const onCreate = (procedure: Procedure) => {
    createProcedure(procedure);
  };

  const onDelete = (procedureId: number) => {
    message.loading(
      i18n._(t("ProceduresPage.DeletingProcedure")`Deleting procedure...`),
      0.5,
      () => deleteProcedure(procedureId)
    );
  };

  const onUpdate = (procedure: Procedure) => {
    updateProcedure(procedure);
  };

  const onUpdateProcedure = (procedure: Procedure) => {
    dispatch(showProcedureEditModal(procedure));
  };

  const filterFoundProcedures = (value: string) => {
    dispatch(filterProcedures(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundProcedures(e.target.value);
  };

  return (
    <Container>
      <Typography.Title level={2}>
        <Trans id="ProceduresPage.Title">Procedures</Trans>
      </Typography.Title>
      <Table
        dataSource={procedureState.filteredProcedures}
        columns={columns}
        loading={procedureState.loading}
        rowKey="ProviderID"
        pagination={
          procedureState.procedures.length > pageSize && {
            pageSize,
            position: "bottom",
          }
        }
        title={() => (
          <Row type="flex" align="middle">
            <Col span={6}>
              <Input
                onChange={onChange}
                placeholder={i18n._(
                  t(
                    "ProceduresPage.FilterProceduresPlaceHolder"
                  )`Search by procedure name`
                )}
              />
            </Col>
            <Col span={18}>
              <Row type="flex" justify="end">
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    onClick={onCreateProcedure}
                  >
                    <Trans id="ProceduresPage.CreateProcedureButton">
                      New procedure
                    </Trans>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      />
      {procedureState.showModal && (
        <ProcedureModal
          loading={procedureState.changing}
          showModal={procedureState.showModal}
          modalType={procedureState.modalType}
          procedure={procedureState.procedure}
          onCreate={onCreate}
          onUpdate={onUpdate}
        />
      )}
    </Container>
  );
};

const mapState = (state: any): State => ({
  directoryState: state.directoryState,
  procedureState: state.procedureState,
});

interface State {
  directoryState: DirectoryState;
  procedureState: ProcedureState;
}
