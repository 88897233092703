import { Review } from "./Types";

export const LOADING_REVIEWS = "LOADING_REVIEWS";
export const SET_REVIEWS = "SET_REVIEWS";
export const SET_REVIEW = "SET_REVIEW";
export const UPDATE_REVIEWS = "UPDATE_REVIEWS";
export const CHANGING_REVIEW = "CHANGING_REVIEW";
export const CHANGING_REVIEW_FINISHED = "CHANGING_REVIEW_FINISHED";
export const HIDE_REVIEW_MODAL = "HIDE_REVIEW_MODAL";
export const SHOW_REVIEW_MODAL_FOR_EDIT = "SHOW_REVIEW_MODAL_FOR_EDIT";

export const loadingReviews = () => ({ type: LOADING_REVIEWS });

export const setReviews = (reviews: Review[]) => ({
  type: SET_REVIEWS,
  reviews,
});

export const setReview = (review: Review | undefined) => ({
  type: SET_REVIEW,
  review,
});

export const updateReviews = () => ({
  type: UPDATE_REVIEWS,
});

export const changingReview = () => ({ type: CHANGING_REVIEW });

export const changingReviewFinished = (result: boolean) => ({
  type: CHANGING_REVIEW_FINISHED,
  result,
});

export const hideReviewModal = () => ({ type: HIDE_REVIEW_MODAL });

export const showReviewModalForEdit = (review: Review) => ({
  type: SHOW_REVIEW_MODAL_FOR_EDIT,
  review,
});
