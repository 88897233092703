import React from "react";
import "./ProviderNewAgendaModal.css";
import { Form, Input, Modal, Row, Col, Button, Skeleton } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";

import {
  Location,
  LocationState,
  hideNewLocationModal,
} from "../../../store/location";

import { ProviderNewAgendaModalProps } from "./Types";
import { useSelector, useDispatch } from "react-redux";

const FormStateless: React.FC<ProviderNewAgendaModalProps> = (props) => {
  const { form, loading, onCreate, showModal } = props;
  const { locationState } = useSelector(mapState);
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();

  const newLocationNameOf = nameofFactory<Location>();

  const onModalDismiss = () => {
    dispatch(hideNewLocationModal());
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const request = {
        ...values,
      };

      onCreate(request);
    });
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="provider-modal"
        destroyOnClose
        visible={showModal}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button
                loading={locationState.updatingLocation}
                onClick={onSubmit}
                type="primary"
              >
                <Trans
                  render="span"
                  id="ProviderAgendaModal.CreateAgendaButton"
                >
                  Create
                </Trans>
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="ProviderAgendaModal.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          <Trans id="ProviderAgendaModal.CreateAgendaFormTitle">
            Create agenda
          </Trans>
        }
      >
        <Skeleton loading={locationState.loading}>
          <Form colon={false}>
            <Row type="flex" gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <Trans id="ProviderAgendaModal.AgendaLabel">Agenda</Trans>
                  }
                >
                  {getFieldDecorator(newLocationNameOf("AgendaName"), {
                    rules: [
                      {
                        required: true,
                        message: <Trans id="Common.RequiredFieldError" />,
                      },
                    ],
                  })(<Input name={newLocationNameOf("AgendaName")} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </Modal>
    </React.Fragment>
  );
};

const mapState = (state: any): State => ({
  locationState: state.locationState,
});

interface State {
  locationState: LocationState;
}

export default Form.create<ProviderNewAgendaModalProps>()(FormStateless);
