import { Language } from "./Types";

export const LOADING_LANGUAGES = "LOADING_LANGUAGES";
export const SET_LANGUAGES = "SET_LANGUAGES";
export const UPDATE_LANGUAGES = "UPDATE_LANGUAGES";

export const loadingLanguages = () => ({ type: LOADING_LANGUAGES });

export const setLanguages = (languages: Language[]) => ({
  type: SET_LANGUAGES,
  languages
});

export const updateLanguages = () => ({
  type: UPDATE_LANGUAGES
});
