import React, { useRef, useEffect } from "react";
import "./PatientsHeader.css";
import { Form, Row, Col, Input, Button } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";
import { PatientsHeaderProps } from "./Types";
import { SearchAccountRequest } from "../../../services/account/Types";
import FormItem from "antd/lib/form/FormItem";
import { useLocation } from "react-router-dom";

const PatientsHeader: React.FC<PatientsHeaderProps> = props => {
  const { form, loading, onSearch, updateAccount, refreshByID } = props;
  const nameof = nameofFactory<SearchAccountRequest>();
  let id = new URLSearchParams(useLocation().search).get("id");
  const paramsRef = useRef({ email: "", identificationNumber: id ? id : "" });
  const { getFieldDecorator, validateFields } = form;


  useEffect(() => {
    if (
      paramsRef.current &&
      (paramsRef.current.email !== "" ||
        paramsRef.current.identificationNumber !== "")
    )
      onSearch(paramsRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAccount]);

  useEffect(() => {
    if (refreshByID) {
      form.setFields({
        identificationNumber: {
          value: refreshByID,
        },
      });
      onSearch({ email: "", identificationNumber: refreshByID });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshByID]);
  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (err) return;
      const request = {
        email: values.email || "",
        identificationNumber: values.identificationNumber || ""
      };

      if (request.email === "" && request.identificationNumber === "") return;

      paramsRef.current = request;
      onSearch(request);
    });
  }

  return (

    < React.Fragment >
      <Form layout="inline" className="patients-header" onSubmit={onSubmit}>
        <Row type="flex" gutter={8}>
          <Col span={5}>
            <Form.Item
              label={<Trans id="PatientsHeader.EmailLabel">Email</Trans>}
            >
              {getFieldDecorator(nameof("email"))(<Input />)}
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              label={
                <Trans id="PatientsHeader.IdentificationLabel">
                  Identification
                </Trans>
              }
            >
              {getFieldDecorator(nameof("identificationNumber"), {
                initialValue: id ? id : "",
              })(<Input />)}
            </Form.Item>
          </Col>
          <Col span={2}>
            <Row
              type="flex"
              align="bottom"
              className="patients-button-container"
            >
              <FormItem>
                <Button
                  type="primary"
                  icon="search"
                  htmlType="submit"
                  loading={loading}
                >
                  <Trans render="span" id="PatientsHeader.SearchButton">
                    Search
                  </Trans>
                </Button>
              </FormItem>
            </Row>
          </Col>
        </Row>
      </Form>
    </React.Fragment >
  );
};

export default Form.create<PatientsHeaderProps>()(PatientsHeader);
