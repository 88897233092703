import React from "react";

const Container: React.FC = props => {
  const { children } = props;

  return (
    <div style={{ padding: "12px 24px", background: "inherit" }}>
      {children}
    </div>
  );
};

export default Container;
