import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingProviderEducationDegree,
  ProviderEducationDegree,
  setProviderEducationDegree,
  updatingProviderEducationDegree,
  updatedProviderEducationDegree
} from "../../store/providerEducationDegree";
import { getDirectoryTenantId } from "../../utils";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadProviderEducationDegree = async (providerID: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`${providerID}/educationDegree`;

  try {
    store.dispatch(loadingProviderEducationDegree());
    const response = await httpClient.get<
      GenericApiResponse<ProviderEducationDegree[]>
    >(url);
    store.dispatch(setProviderEducationDegree(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProviderEducationDegree([]));
    return false;
  }
};

export const createProviderEducationDegree = async (
  providerID: string,
  request: ProviderEducationDegree
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/educationDegree`;

  try {
    store.dispatch(updatingProviderEducationDegree());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(updatedProviderEducationDegree(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationDegree(false));
    return false;
  }
};

export const updateProviderEducationDegree = async (
  providerID: string,
  providerEducationDegree: ProviderEducationDegree
) => {
  const { ProviderEducationDegreeID, ...rest } = providerEducationDegree;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`${providerID}/educationDegree/${ProviderEducationDegreeID}`;

  try {
    store.dispatch(updatingProviderEducationDegree());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(updatedProviderEducationDegree(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationDegree(false));
    return false;
  }
};

export const deleteProviderEducationDegree = async (
  providerID: string,
  providerEducationDegreeId: number
) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/EducationDegree/${providerEducationDegreeId}`;

  try {
    store.dispatch(updatingProviderEducationDegree());
    await httpClient.delete(url);
    store.dispatch(updatedProviderEducationDegree(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationDegree(false));
    return false;
  }
};
