import { SearchState, SearchProvider } from "./Types";
import {
  SELECT_STATE,
  SELECT_COUNTY,
  BEGIN_SEARCH,
  COMPLETE_SEARCH,
  FILTER_PROVIDERS,
  CHECK_PROVIDER,
  UNCHECK_PROVIDER,
  CLEAN_FILTERS,
  UNCHECK_ALL_PROVIDERS, CHECK_FOUR_PROVIDERS
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: SearchState = {
  filteredProviders: [],
  hasSearched: false,
  params: {},
  providers: [],
  providersCheckedCount: 0,
  searching: false
};

export const searchReducer = (
  state = initialState,
  action: any
): SearchState => {
  switch (action.type) {
    case BEGIN_SEARCH:
      return {
        ...state,
        filteredProviders: [],
        hasSearched: false,
        params: action.params,
        providers: [],
        searching: true,
        providersCheckedCount: 0
      };

    case CHECK_PROVIDER:
      return {
        ...state,
        filteredProviders: checkProvider(action.uId, state.filteredProviders),
        providersCheckedCount: state.providersCheckedCount + 1
      };

    case CHANGE_DIRECTORY:
    case CLEAN_FILTERS:
      return {
        ...state,
        filteredProviders: [],
        hasSearched: false,
        params: {},
        providers: [],
        providersCheckedCount: 0
      };

    case COMPLETE_SEARCH:
      return {
        ...state,
        filteredProviders: action.providers,
        hasSearched: true,
        providers: action.providers,
        searching: false
      };

    case FILTER_PROVIDERS:
      return {
        ...state,
        filteredProviders: filterProviders(action.value, state.providers)
      };

    case SELECT_COUNTY:
      return {
        ...state,
        params: {
          ...state.params,
          countyId: action.countyId || undefined
        }
      };

    case SELECT_STATE:
      return {
        ...state,
        params: {
          ...state.params,
          stateId: action.stateId || undefined
        }
      };

    case UNCHECK_PROVIDER:
      return {
        ...state,
        filteredProviders: uncheckProvider(action.uId, state.filteredProviders),
        providersCheckedCount: state.providersCheckedCount - 1
      };

    case UNCHECK_ALL_PROVIDERS:
      return {
        ...state,
        providers: uncheckAllProviders(state.providers),
        providersCheckedCount: 0
      };

    case CHECK_FOUR_PROVIDERS:
      return{
        ...state,
        providers: checkFourProviders(state.providers),
        providersCheckedCount: 4
      };

    default:
      return state;
  }
};

const checkProvider = (uId: string, providers: SearchProvider[]) => {
  providers.forEach(provider => {
    if (provider.UID === uId) provider.IsChecked = true;
  });

  return providers;
};

const filterProviders = (value: string, providers: SearchProvider[]) => {
  if (value === "") return providers;

  return providers.filter(provider => isFilterMatch(value, provider));
};

const isFilterMatch = (value: string, provider: SearchProvider) => {
  return provider.FullName.toLowerCase().includes(value.toLowerCase());
};

const uncheckProvider = (uId: string, providers: SearchProvider[]) => {
  providers.forEach(provider => {
    if (provider.UID === uId) provider.IsChecked = false;
  });

  return providers;
};

const uncheckAllProviders = (providers: SearchProvider[]) => {
  providers.forEach(provider => {
    provider.IsChecked = false;
  });

  return providers;
};

const checkFourProviders = (providers: SearchProvider[]) => {
  let count = 4;
  providers.forEach(provider => {
    if(count>0 && !provider.IsOffline){
      provider.IsChecked = true;
      count--;
    }
    else
    provider.IsChecked = false;
  });

  return providers;
};
