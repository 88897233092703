import { AuthState, AuthInfo } from "./Types";
import {
  BEGIN_AUTH,
  AUTH_SUCCEDED,
  AUTH_FAILED,
  LOGOUT,
  AUTH_CONNECTION_FAILED,
  REQUESTING_EXTERNAL_ACCESS_TOKEN,
  SET_EXTERNAL_ACCESS_TOKEN
} from "./Actions";
import { LocalStorageKeys } from "../../utils/Constants";
import { isNullOrEmpty } from "../../utils";

const deleteAuthInfoInLocalStorage = () => {
  localStorage.removeItem(LocalStorageKeys.AccessToken);
  localStorage.removeItem(LocalStorageKeys.AuthInfo);
};

const loadAuthInfoFromLocalStorage = (): AuthInfo | undefined => {
  const authInfoPreview = localStorage.getItem(LocalStorageKeys.AuthInfo);

  return isNullOrEmpty(authInfoPreview)
    ? undefined
    : JSON.parse(String(authInfoPreview));
};

const saveAuthIntoInLocalStorage = (authInfo: AuthInfo) => {
  localStorage.setItem(LocalStorageKeys.AccessToken, authInfo.accessToken);
  localStorage.setItem(LocalStorageKeys.AuthInfo, JSON.stringify(authInfo));
};

const initialState: AuthState = {
  isAuthenticated: loadAuthInfoFromLocalStorage() !== undefined,
  loading: false,
  info: loadAuthInfoFromLocalStorage(),
  loginError: "",
  loginFailed: false
};

export const authReducer = (state = initialState, action: any): AuthState => {
  switch (action.type) {
    case AUTH_CONNECTION_FAILED:
      return {
        ...state,
        loading: false
      };

    case AUTH_FAILED:
      return {
        ...state,
        loading: false,
        loginError: action.errorMessage,
        loginFailed: true
      };

    case AUTH_SUCCEDED:
      saveAuthIntoInLocalStorage(action.authInfo);

      return {
        ...state,
        info: action.authInfo,
        isAuthenticated: true,
        loading: false,
        loginError: "",
        loginFailed: false
      };

    case BEGIN_AUTH:
      return {
        ...state,
        loading: true,
        loginError: "",
        loginFailed: false
      };

    case LOGOUT:
      deleteAuthInfoInLocalStorage();
      return {
        ...initialState,
        isAuthenticated: false
      };

    case REQUESTING_EXTERNAL_ACCESS_TOKEN:
      return {
        ...state,
        externalAccessToken: undefined,
        loading: true
      };

    case SET_EXTERNAL_ACCESS_TOKEN:
      return {
        ...state,
        externalAccessToken: action.externalAccessToken,
        loading: false
      };

    default:
      return state;
  }
};
