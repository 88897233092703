export const IMPORTING_DOCTOR = "IMPORTING_DOCTOR";
export const IMPORTED_DOCTOR = "IMPORTED_DOCTOR";
export const LOADING_ORGANIZATION = "LOADING_ORGANIZATION";
export const LOADING_ORGANIZATION_FAILED = "LOADING_ORGANIZATION_FAILED";
export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const STOP_IMPORTING = "STOP_IMPORTING";
export const IS_HULI_SELECTED = "IS_HULI_SELECTED";
export const IS_ZH_SELECTED = "IS_ZH_SELECTED";
export const IS_CNLP_SELECTED = "IS_CNLP_SELECTED";

export const importingDoctor = (doctorId: string) => ({
  type: IMPORTING_DOCTOR,
  doctorId,
});

export const importedDoctor = (doctorId: string) => ({
  type: IMPORTED_DOCTOR,
  doctorId,
});

export const loadingOrganization = () => ({ type: LOADING_ORGANIZATION });

export const loadingOrganizationFailed = (errorMessage: string) => ({
  type: LOADING_ORGANIZATION_FAILED,
  errorMessage,
});

export const setOrganization = (organization: any) => ({
  type: SET_ORGANIZATION,
  organization,
});

export const stopImporting = (doctorId: string) => ({
  type: STOP_IMPORTING,
  doctorId,
});

export const isHuliSelected = () => ({
  type: IS_HULI_SELECTED,
});

export const isZHSelected = () => ({
  type: IS_ZH_SELECTED,
});

export const isCnlpSelected = () => ({
  type: IS_CNLP_SELECTED,
});