import { Specialty, SpecialtyTypeahead } from "./Types";

export const CHANGING_SPECIALTY = "CHANGING_SPECIALTY";
export const CHANGING_SPECIALTY_FINISHED = "CHANGING_SPECIALTY_FINISHED";
export const DELETE_SPECIALTY_FINISHED = "DELETE_SPECIALTY_FINISHED";
export const HIDE_SPECIALTY_MODAL = "HIDE_SPECIALTY_MODAL";
export const LOADING_SPECIALTIES = "LOADING_SPECIALTIES";
export const LOADING_SPECIALTIES_TYPEAHEAD = "LOADING_SPECIALTIES_TYPEAHEAD";
export const SHOW_SPECIALTY_MODAL_FOR_CREATE =
  "SHOW_SPECIALTY_MODAL_FOR_CREATE";
export const SHOW_SPECIALTY_MODAL_FOR_EDIT = "SHOW_SPECIALTY_MODAL_FOR_EDIT";
export const SET_SPECIALTIES = "SET_SPECIALTIES";
export const UPDATE_SPECIALTIES = "UPDATE_SPECIALTIES";
export const FILTER_SPECIALTIES = "FILTER_SPECIALTIES";
export const SET_SPECIALTIES_TYPEHEAD = "SET_SPECIALTIES_TYPEHEAD";

export const changingSpecialty = () => ({ type: CHANGING_SPECIALTY });

export const changingSpecialtyFinished = (result: boolean) => ({
  type: CHANGING_SPECIALTY_FINISHED,
  result
});
export const deleteSpecialtyFinished = (result: boolean) => ({
  type: DELETE_SPECIALTY_FINISHED,
  result
});

export const hideSpecialtyModal = () => ({ type: HIDE_SPECIALTY_MODAL });

export const loadingSpecialties = () => ({ type: LOADING_SPECIALTIES });

export const loadingSpecialtiesTypeahead = () => ({ type: LOADING_SPECIALTIES_TYPEAHEAD });

export const setSpecialties = (specialties: Specialty[]) => ({
  type: SET_SPECIALTIES,
  specialties
});

export const showSpecialtyModalForCreate = () => ({
  type: SHOW_SPECIALTY_MODAL_FOR_CREATE
});

export const showSpecialtyModalForEdit = (specialty: Specialty) => ({
  type: SHOW_SPECIALTY_MODAL_FOR_EDIT,
  specialty
});

export const updateSpecialties = () => ({
  type: UPDATE_SPECIALTIES
});

export const filterSpecialties = (value: string) => ({
  type: FILTER_SPECIALTIES,
  value
});

export const setSpecialtiesTypehead = (specialtiesTypeahead: SpecialtyTypeahead[]) => ({
  type: SET_SPECIALTIES_TYPEHEAD,
  specialtiesTypeahead
});
