import { SearchParams, SearchProvider } from "./Types";

export const BEGIN_SEARCH = "BEGIN_SEARCH";
export const CHECK_PROVIDER = "CHECK_PROVIDER";
export const CLEAN_FILTERS = "CLEAN_FILTERS";
export const COMPLETE_SEARCH = "COMPLETE_SEARCH";
export const FILTER_PROVIDERS = "FILTER_PROVIDERS";
export const SELECT_COUNTY = "SELECT_COUNTY";
export const SELECT_STATE = "SELECT_STATE";
export const UNCHECK_PROVIDER = "UNCHECK_PROVIDER";
export const UNCHECK_ALL_PROVIDERS = "UNCHECK_ALL_PROVIDERS";
export const CHECK_FOUR_PROVIDERS = "CHECK_FOUR_PROVIDERS";

export const beginSearch = (params: SearchParams) => ({
  type: BEGIN_SEARCH,
  params
});

export const cleanFilters = () => ({
  type: CLEAN_FILTERS
});

export const checkProvider = (uId: string) => ({
  type: CHECK_PROVIDER,
  uId
});

export const completeSearch = (providers: SearchProvider[]) => ({
  type: COMPLETE_SEARCH,
  providers
});

export const filterProviders = (value: string) => ({
  type: FILTER_PROVIDERS,
  value
});

export const selectCounty = (countyId: number) => ({
  type: SELECT_COUNTY,
  countyId
});

export const selectState = (stateId: number) => ({
  type: SELECT_STATE,
  stateId
});

export const uncheckProvider = (uId: string) => ({
  type: UNCHECK_PROVIDER,
  uId
});

export const uncheckAllProviders = () => ({
  type: UNCHECK_ALL_PROVIDERS
});

export const checkFourProviders = () => ({
  type: CHECK_FOUR_PROVIDERS
});
