import { ProviderAppointmentReasonState } from "./Types";
import {
  LOADING_PROVIDER_APPOINTMENT_REASON,
  EDIT_PROVIDER_APPOINTMENT_REASON,
  STOP_EDIT_PROVIDER_APPOINTMENT_REASON,
  UPDATING_PROVIDER_APPOINTMENT_REASON,
  UPDATED_PROVIDER_APPOINTMENT_REASON,
  HIDE_PROVIDER_APPOINTMENT_REASON_MODAL,
  SHOW_PROVIDER_APPOINTMENT_REASON_CREATE_MODAL,
  SHOW_PROVIDER_APPOINTMENT_REASON_EDIT_MODAL
} from "./Actions";

const initialState: ProviderAppointmentReasonState = {
  updatingProviderAppointmentReason: false,
  canEdit: true,
  loading: false,
  showModal: false,
  modalType: "create",
  isEdit: false,
};

export const providerAppointmentReasonReducer = (
  state = initialState,
  action: any
): ProviderAppointmentReasonState => {
  switch (action.type) {

    case EDIT_PROVIDER_APPOINTMENT_REASON:
      return {
        ...state,
        providerAppointmentReason: action.providerAppointmentReason,
      };

    case STOP_EDIT_PROVIDER_APPOINTMENT_REASON:
      return {
        ...state,
        canEdit: true,
        providerAppointmentReason: undefined,
        isEdit: false,
      };

    case LOADING_PROVIDER_APPOINTMENT_REASON:
      return {
        ...state,
        loading: true,
      };

    case UPDATING_PROVIDER_APPOINTMENT_REASON:
      return {
        ...state,
        updatingProviderAppointmentReason: true,
      };

    case UPDATED_PROVIDER_APPOINTMENT_REASON:
      return {
        ...state,
        canEdit: action.result,
        loading: false,
        updatingProviderAppointmentReason: false,
        providerAppointmentReason: action.result
          ? undefined
          : state.providerAppointmentReason,
      };

    case HIDE_PROVIDER_APPOINTMENT_REASON_MODAL:
      return {
        ...state,
        showModal: false,
        isEdit: false,
        providerAppointmentReason: undefined,
      };

    case SHOW_PROVIDER_APPOINTMENT_REASON_CREATE_MODAL:
      return {
        ...state,
        modalType: "create",
        showModal: true,
        newProviderAppointmentReason: { Name: "", DurationMinutes: 5, Locations: action.locations }
      };

    case SHOW_PROVIDER_APPOINTMENT_REASON_EDIT_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: "edit",
        isEdit: true,
        providerAppointmentReason: action.providerAppointmentReason
      };

    default:
      return state;
  }
};
