import { CalendarState } from "./Types";
import {
  LOADING_CALENDAR_RESOURCES,
  LOADED_CALENDAR_RESOURCES,
  LOADED_CALENDAR_EVENTS,
  LOADING_CALENDAR_EVENTS,
  RELOAD_CALENDAR,
  SET_CALENDAR_DATE,
  CHANGE_CALENDAR_VIEW
} from "./Actions";
import moment from "moment";

const initialState: CalendarState = {
  date: moment(),
  events: [],
  loadingEvents: false,
  loadingResources: false,
  reloadCalendar: false,
  resources: [],
  view: "resourceTimeGridSevenDay"
};

export const calendarReducer = (
  state = initialState,
  action: any
): CalendarState => {
  switch (action.type) {
    case CHANGE_CALENDAR_VIEW:
      return {
        ...state,
        view: action.view
      };

    case LOADED_CALENDAR_EVENTS:
      return {
        ...state,
        events: action.events,
        loadingEvents: false
      };

    case LOADING_CALENDAR_EVENTS:
      return {
        ...state,
        loadingEvents: true
      };

    case LOADED_CALENDAR_RESOURCES:
      return {
        ...state,
        loadingResources: false,
        resources: action.resources
      };

    case LOADING_CALENDAR_RESOURCES:
      return {
        ...state,
        loadingResources: true
      };

    case RELOAD_CALENDAR:
      return {
        ...state,
        reloadCalendar: !state.reloadCalendar
      };

    case SET_CALENDAR_DATE:
      return {
        ...state,
        date: action.date
      };

    default:
      return state;
  }
};
