import React, { useEffect } from "react";
import "./AccountModal.css";
import {
  Form,
  Modal,
  Radio,
  Input,
  Col,
  Icon,
  DatePicker,
  Button,
  Checkbox,
  Row,
  Select,
} from "antd";
import { AccountModalProps } from "./Types";
import { Trans, t } from "@lingui/macro";
import { nameofFactory, i18n, filterByValueAndText } from "../../utils";
import {
  Account,
  hideAccountModal,
  setAccountFromTSE,
  setIsLinkedAccount,
  AccountLinkedAccount,
  setParentAccountID,
  AccountState,
} from "../../store/account";
import {
  searchIdOnTSE,
  searchLinkedAccount,
  createNewPatientAccount,
  UpdatePatientAccount,
} from "../../services/account";
import { Insurance } from "../../store/insurance"
import { loadActiveInsurancesFromAdmin } from "../../services/insurance"
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setPatient } from "../../store/patient";

const AccountModal: React.FC<AccountModalProps> = (props) => {
  const dispatch = useDispatch();
  const accountState = useSelector(mapStateToProps);
  const insuranceState = useSelector(mapStateToPropsInsurance);
  const { form } = props;
  const {
    getFieldDecorator,
    getFieldValue,
    setFieldsValue,
    validateFields,
  } = form;
  const { account, accountLinked } = accountState;
  const isCreated = accountState.modalType === "create";
  useEffect(() => {
    loadActiveInsurancesFromAdmin();
  }, [insuranceState.updateInsurances]);

  const nameof = nameofFactory<Account>();

  const nameofLinkedAccounts = nameofFactory<AccountLinkedAccount>();

  const beginSearchOnTSE = async (value: string) => {
    const idType = getFieldValue(nameof("IdentificationType"));

    if (idType === "1" || idType === 1) {
      if (value.length === 9) {
        var account = await searchIdOnTSE(value);
        if (account) setFieldsValue(account);
      }
    }
  };

  const onModalDismiss = () => {
    dispatch(hideAccountModal());
  };

  const onSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      const { PhoneAreaCode, PhoneNumber, ...rest } = values;
      console.log(rest);
      if (isCreated) {
        const request = {
          ...rest,
          PrimaryPhoneNumber: {
            AreaCode: PhoneAreaCode,
            IsPrimary: true,
            Number: PhoneNumber,
          },
          UID: "new",
          ParentAccountID: accountState.parentAccountID,
          IsLinkedAccount: accountState.isLinkedAccount,
          Birthday: values.Birthday.format("YYYY-MM-DD"),
        };
        createNewPatientAccount(request).then((result) => {
          if (result.success) {
            request.UID = result.data.Data;
            dispatch(setParentAccountID(""));
            dispatch(setAccountFromTSE(account));
          }
        });
      } else {
        const request = {
          account: {
            ...rest,
            PrimaryPhoneNumber: {
              AreaCode: PhoneAreaCode,
              IsPrimary: true,
              Number: PhoneNumber,
            },
            UID: account?.UID
          }
        };
        var tempAccount: Account = {
          ...request.account,
          Appointments: account?.Appointments,
          Insurances: account?.Insurances
        };
        UpdatePatientAccount(tempAccount,request).then((result) => {
          if (result.success) {
           
            dispatch(setParentAccountID(""));
            dispatch(setAccountFromTSE(account));
            dispatch(setPatient(tempAccount))
          }
        });
      }

    });
  };

  const onCheckboxChange = (e: any) => {
    dispatch(setIsLinkedAccount(e.target.checked));
  };

  const onSearchRelatedAccounts = async () => {
    const id = getFieldValue(
      nameofLinkedAccounts("RelatedIdentificationNumber")
    );
    const email = getFieldValue(nameofLinkedAccounts("RelatedEmail"));

    if (
      (id === undefined || id === "" || id.length < 5) &&
      (email === undefined || email === "" || email.length < 5)
    )
      return;

    await searchLinkedAccount(id, email);
  };

  const onRadioChange = (e: any) => {
    dispatch(setParentAccountID(e.target.value));
  };

  return (
    <Modal
      centered
      width="900"
      destroyOnClose
      okText={
        <Trans render="span" id="AccountModal.SaveButton">
          Save
        </Trans>
      }
      onCancel={onModalDismiss}
      onOk={onSubmit}
      title={
        isCreated ? (
          <Trans id="AccountModal.CreateTitle">New patient</Trans>
        ) : (
          <Trans id="AccountModal.EditTitle">Edit patient</Trans>
        )
      }
      visible={accountState.showModal}
    >
      <Row>
        <Form colon={false} layout="vertical">
          <Col span={isCreated ? 11 : 24}>
            <Row>
              <Col>
                <h3>
                  {
                    <Trans id="AccountModal.AccountSectionTitle">
                      Account information
                    </Trans>
                  }
                </h3>
              </Col>
            </Row>

            <Form.Item
              label={<Trans id="AccountModal.IdTypeLabel">ID type</Trans>}
            >
              {getFieldDecorator(nameof("IdentificationType"), {
                initialValue: account?.IdentificationType || 1,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="AccountModal.IdTypeRequiredError">
                        This id type field is required
                      </Trans>
                    ),
                  },
                ],
              })(
                <Radio.Group>
                  <Radio key={1} value={1}>
                    <Trans id="AccountModal.NationalIdTypeLabel">
                      National
                    </Trans>
                  </Radio>
                  <Radio key={2} value={2}>
                    <Trans id="AccountModal.PassportIdTypeLabel">
                      Passport
                    </Trans>
                  </Radio>
                  <Radio key={3} value={3}>
                    <Trans id="AccountModal.DimexIdTypeLabel">DIMEX</Trans>
                  </Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label={<Trans id="AccountModal.IdLabel">ID</Trans>}>
              {getFieldDecorator(nameof("IdentificationNumber"), {
                initialValue: account?.IdentificationNumber,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="AccountModal.IdRequiredError">
                        This id field is required
                      </Trans>
                    ),
                  },
                  getFieldValue(nameof("IdentificationType")) === 1
                    ?
                    {
                      pattern: /^\d+$/,
                      message: (
                        <Trans id="AccountModal.IdNotANumberError">
                          Id must be a valid number
                        </Trans>
                      ),
                    } : {},
                  getFieldValue(nameof("IdentificationType")) === 1
                    ? {
                      len: 9,
                      message: (
                        <Trans id="AccountModal.NationalIDWrongLengthError">
                          The national ID must contain 9 numbers
                        </Trans>
                      ),
                    }
                    : {},
                ],
              })(
                <Input.Search
                  disabled={accountState.searching}
                  maxLength={512}
                  onSearch={beginSearchOnTSE}
                  enterButton={
                    <Button
                      type="primary"
                      disabled={
                        getFieldValue(nameof("IdentificationType")) !== 1
                      }
                      icon="search"
                      loading={accountState.searching}
                      style={{ width: "60px" }}
                    />
                  }
                />
              )}
            </Form.Item>
            <Input.Group>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="AccountModal.FirstNameLabel">First name</Trans>
                  }
                >
                  {getFieldDecorator(nameof("FirstName"), {
                    initialValue: account?.FirstName,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="AccountModal.FirstNameRequiredError">
                            The first name field is required
                          </Trans>
                        ),
                      },
                      {
                        min: 2,
                        message: (
                          <Trans id="AccountModal.FirstNameNotLongEnoughError">
                            First name must be longer than 2 characters
                          </Trans>
                        ),
                      },
                    ],
                  })(<Input maxLength={512} />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="AccountModal.LastNameLabel">Last name</Trans>
                  }
                >
                  {getFieldDecorator(nameof("LastName"), {
                    initialValue: account?.LastName,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="AccountModal.LastNameRequiredError">
                            The last name field is required
                          </Trans>
                        ),
                      },
                    ],
                  })(<Input maxLength={512} />)}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="AccountModal.SecondLastNameLabel">
                      Second last name
                    </Trans>
                  }
                >
                  {getFieldDecorator(nameof("SecondLastName"), {
                    initialValue: account?.SecondLastName,
                  })(<Input maxLength={512} />)}
                </Form.Item>
              </Col>
            </Input.Group>
            <Form.Item
              label={<Trans id="AccountModal.EmailLabel">Email</Trans>}
            >
              {getFieldDecorator(nameof("Email"), {
                initialValue: account?.Email,
                rules: [
                  {
                    required: true,
                    message: (
                      <Trans id="AccountModal.EmailRequiredError">
                        The email field is required
                      </Trans>
                    ),
                  },
                  {
                    type: "email",
                    message: (
                      <Trans id="AccountModal.EmailInvalidError">
                        Please enter a valid email
                      </Trans>
                    ),
                  },
                ],
              })(<Input maxLength={512} />)}
            </Form.Item>
            <Input.Group>
              <Col span={8}>
                <Form.Item
                  label={
                    <Trans id="AccountModal.PhoneAreaCodeLabel">
                      Country code
                    </Trans>
                  }
                >
                  {getFieldDecorator("PhoneAreaCode", {
                    initialValue: account?.PrimaryPhoneNumber?.AreaCode || 506,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="AccountModal.PhoneAreaCodeRequiredError">
                            The country code field is required
                          </Trans>
                        ),
                      },
                      {
                        pattern: /^\d+$/,
                        message: (
                          <Trans id="AccountModal.PhoneAreaCodeNotANumberError">
                            Country code must be a valid number
                          </Trans>
                        ),
                      },
                      {
                        pattern: /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[12][0-9]{4}|3[01][0-9]{3}|32[0-6][0-9]{2}|327[0-5][0-9]|3276[0-7])$/,
                        message: (
                          <Trans id="AccountModal.PhoneAreaCodeNotInRangeError">
                            Country code must be between 1 and 32767
                          </Trans>
                        ),
                      },
                    ],
                  })(<Input addonBefore={<Icon type="plus" />} />)}
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  label={
                    <Trans id="AccountModal.PhoneNumberLabel">
                      Phone number
                    </Trans>
                  }
                >
                  {getFieldDecorator("PhoneNumber", {
                    initialValue: account?.PrimaryPhoneNumber?.Number,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="AccountModal.PhoneNumberRequiredError">
                            The phone number field is required
                          </Trans>
                        ),
                      },
                      {
                        pattern: /^\d+$/,
                        message: (
                          <Trans id="AccountModal.PhoneNumberNotANumberError">
                            Phone number must be a valid number
                          </Trans>
                        ),
                      },
                      {
                        min: 8,
                        message: (
                          <Trans id="AccountModal.PhoneNumberMinLengthError">
                            Phone number must contain at least 8 digits
                          </Trans>
                        ),
                      },
                      {
                        validator: (_, value) =>
                          !value.startsWith(2) ? Promise.resolve() : Promise.reject((
                            <Trans id="AccountModal.PhoneStartsTwoError">
                              The phone number should not start with the digit 2
                            </Trans>
                          )),
                      },
                    ],
                  })(<Input maxLength={512} />)}
                </Form.Item>
              </Col>

            </Input.Group>
            {isCreated && (
              <>
                <Form.Item
                  label={<Trans id="AccountModal.GenderLabel">Gender</Trans>}
                >
                  {getFieldDecorator(nameof("Gender"), {
                    initialValue: account?.Gender,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="AccountModal.GenderRequiredError">
                            The gender field is required
                          </Trans>
                        ),
                      },
                    ],
                  })(
                    <Radio.Group>
                      <Radio key={1} value="M">
                        <Trans id="AccountModal.GenderMaleLabel">Male</Trans>
                      </Radio>
                      <Radio key={2} value="F">
                        <Trans id="AccountModal.GenderFemaleLabel">Female</Trans>
                      </Radio>
                      <Radio key={3} value="X">
                        <Trans id="AccountModal.GenderNoAnswerLabel">
                          No specified
                        </Trans>
                      </Radio>
                    </Radio.Group>
                  )}
                </Form.Item>
                <Form.Item
                  label={<Trans id="AccountModal.BirthdayLabel">Birthday</Trans>}
                >
                  {getFieldDecorator(nameof("Birthday"), {
                    initialValue:
                      account?.Birthday || moment("1990-01-01", "YYYY-MM-DD"),
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="AccountModal.BirthdayRequiredError">
                            This birthday field is required
                          </Trans>
                        ),
                      },
                    ],
                  })(<DatePicker format="L" />)}
                </Form.Item>

                <Form.Item
                  label={
                    <Trans id="AccountModal.InsurancePrimaryUIDLabel">
                      Affiliate number
                    </Trans>
                  }
                >
                  {getFieldDecorator(nameof("InsurancePrimaryUID"), {
                    initialValue: account?.InsurancePrimaryUID,
                  })(<Input maxLength={128} />)}
                </Form.Item>
              </>
            )
            }
          </Col>
          {isCreated && (
            <>
              <Col span={2}></Col>
              <Col span={11}>
                <Form.Item
                  label={<h3><Trans id="AccountModal.InsuranceLabel">Insurances</Trans></h3>}
                >
                  {getFieldDecorator(nameof("Insurances"), {
                    initialValue: account?.Insurances?.map(
                      (x: Insurance) => x.InsuranceID
                    ),
                  })(
                    <Select
                      filterOption={filterByValueAndText}
                      mode="multiple"
                      loading={insuranceState.loading}
                      showSearch
                    >
                      {insuranceState.insurances.map((insurance: Insurance, index: string | number | null | undefined) => (
                        <Select.Option
                          key={index}
                          value={insurance.InsuranceID}
                        >
                          {insurance.Name}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Row>
                  <Col>
                    <h3>
                      {
                        <Trans id="AccountModal.RelatedAccountSectionTitle">
                          Related account
                        </Trans>
                      }
                    </h3>
                  </Col>
                </Row>

                <Input.Group>
                  <Col span={24}>
                    <Form.Item>
                      <Checkbox
                        className="linked-account-checkbox"
                        onChange={onCheckboxChange}
                      >
                        <Trans id="AccountModal.IsLinkedAccountLabel">
                          Linked Account
                        </Trans>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Input.Group>

                <Input.Group>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator(nameofLinkedAccounts("RelatedEmail"), {
                        initialValue: accountLinked?.RelatedEmail,
                      })(
                        <Input
                          placeholder={i18n._(
                            t("AccountModal.RelatedEmailLabel")`Email`
                          )}
                          maxLength={64}
                          disabled={accountState?.isLinkedAccount === false}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      {getFieldDecorator(
                        nameofLinkedAccounts("RelatedIdentificationNumber"),
                        {
                          initialValue: accountLinked?.RelatedIdentificationNumber,
                        }
                      )(
                        <Input
                          maxLength={20}
                          disabled={accountState?.isLinkedAccount === false}
                          placeholder={i18n._(
                            t(
                              "AccountModal.RelatedIdentificationNumberLabel"
                            )`Identification number`
                          )}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Button
                    onClick={onSearchRelatedAccounts}
                    disabled={accountState?.isLinkedAccount === false}
                  >
                    <Trans
                      render="span"
                      id="AppointmentScheduleModal.SaveLinkedAccountButton"
                    >
                      Search linked account
                    </Trans>
                  </Button>
                </Input.Group>

                <Input.Group>
                  <Radio.Group
                    className="radioStyle"
                    onChange={onRadioChange}
                    value={accountState?.parentAccountID}
                  >
                    {accountState.accountLinkedAccountTable.map(
                      (item: any, index: any) => (
                        <Radio key={index} value={item.UID}>
                          {item.FullName}
                        </Radio>
                      )
                    )}
                  </Radio.Group>
                </Input.Group>

              </Col>
            </>

          )
          }

        </Form>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state: any): AccountState => state.accountState;
const mapStateToPropsInsurance = (state: any) => state.insuranceState;

export default Form.create<AccountModalProps>()(AccountModal);
