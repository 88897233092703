import React from 'react';
import { IQRCodeProps } from './types';
import { Button, Col, Row } from 'antd';
import { Trans } from '@lingui/react';

const QRCodeDownloader = ({ qrCodeBase64, qrName }: IQRCodeProps) => {

    const downloadQRCode = () => {
        const byteCharacters = atob(qrCodeBase64);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: 'image/png' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = qrName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            {qrCodeBase64 && (
                <Row>
                    <Col span={24} className='text-center'>
                        <img
                            width="150px"
                            height="150px"

                            src={`data:image/png;base64,${qrCodeBase64}`}
                            alt="Código QR" />
                    </Col>
                    <Col span={24}>
                        <Button
                            style={{ width: "100%" }}
                            icon="download"
                            type="primary"
                            onClick={downloadQRCode}
                        >
                            <Trans render="span" id="ProfessionalsPage.QRCodeDownloader.Download">
                                Download
                            </Trans>
                        </Button>
                    </Col>

                </Row>
            )}
        </>
    );
}

export default QRCodeDownloader;
