import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingCustomProfiles,
  CustomProfile,
  setCustomProfiles,
  changingCustomProfile,
  changingCustomProfileFinished,
  deleteCustomProfileFinished,
  setCustomProfile
} from "../../store/customProfile";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadCustomProfiles = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/customProfile";
  return loadCustomProfilesAux(url);
};

export const loadCustomProfilesFromAdmin = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/customProfile/${getDirectoryTenantId()}`;
  return loadCustomProfilesAux(url);
};

const loadCustomProfilesAux = async (url: string) => {
  try {
    store.dispatch(loadingCustomProfiles());
    const response = await httpClient.get<GenericApiResponse<CustomProfile[]>>(
      url
    );
    store.dispatch(setCustomProfiles(response.data.Data));
    return true;
  } catch {
    store.dispatch(setCustomProfiles([]));
    return false;
  }
};

export const loadCustomProfile = async (customProfileId: number) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/customProfile/${customProfileId}/profile`;

  try {
    store.dispatch(changingCustomProfile());
    const response = await httpClient.get<GenericApiResponse<CustomProfile>>(
      url
    );
    store.dispatch(setCustomProfile(response.data.Data));
    return true;
  } catch {
    store.dispatch(setCustomProfile(undefined));
    return false;
  }
};

export const createCustomProfile = async (request: CustomProfile) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"admin/customProfile";

  try {
    store.dispatch(changingCustomProfile());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingCustomProfileFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingCustomProfileFinished(false));
    return false;
  }
};

export const updateCustomProfile = async (request: CustomProfile) => {
  const { CustomProfileID, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/customProfile/${CustomProfileID}`;

  try {
    store.dispatch(changingCustomProfile());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingCustomProfileFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingCustomProfileFinished(false));
    return false;
  }
};

export const deleteCustomProfile = async (customProfileId: number) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/customProfile/${customProfileId}`;

  try {
    store.dispatch(changingCustomProfile());
    await httpClient.delete(url);
    store.dispatch(deleteCustomProfileFinished(true));
    return true;
  } catch (message) {
    notification.error({
      message: i18n._(
        t(
          "CustomProfileService.CouldNotDeleteCustomProfileErrorTitle"
        )`Custom profile not deleted`
      ),
      description: i18n._(
        t(
          "CustomProfileService.CouldNotDeleteCustomProfileErrorDescription"
        )`The custom profile could not be deleted`
      )
    });
    store.dispatch(deleteCustomProfileFinished(false));
    return false;
  }
};

export const searchCustomProfilesForAutocomplete = async (value: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "search/customProfile/autocomplete";

  try {
    store.dispatch(loadingCustomProfiles());
    const response = await httpClient.get<GenericApiResponse<CustomProfile[]>>(
      url,
      {
        params: { value }
      }
    );
    store.dispatch(setCustomProfiles(response.data.Data));
    return true;
  } catch {
    store.dispatch(setCustomProfiles([]));
    return false;
  }
};
