import { ProviderAppointmentReason } from "./Types";
import { Location } from "../location";

export const CHANGE_PROVIDER_APPOINTMENT_REASON_FIELD =
  "CHANGE_PROVIDER_APPOINTMENT_REASON_FIELD";
export const CHANGING_PROVIDER_APPOINTMENT_REASON =
  "CHANGING_PROVIDER_APPOINTMENT_REASON";
export const CHANGING_PROVIDER_APPOINTMENT_REASON_FINISHED =
  "CHANGING_PROVIDER_APPOINTMENT_REASON_FINISHED";
export const LOADING_PROVIDER_APPOINTMENT_REASON =
  "LOADING_PROVIDER_APPOINTMENT_REASON";

export const UPDATE_PROVIDER_APPOINTMENT_REASON =
  "UPDATE_PROVIDER_APPOINTMENT_REASON";
export const EDIT_PROVIDER_APPOINTMENT_REASON = "EDIT_PROVIDER_APPOINTMENT_REASON";
export const STOP_EDIT_PROVIDER_APPOINTMENT_REASON = "EDIT_PROVIDER_APPOINTMENT_REASON";

export const UPDATING_PROVIDER_APPOINTMENT_REASON =
  "UPDATING_PROVIDER_APPOINTMENT_REASON";

export const UPDATED_PROVIDER_APPOINTMENT_REASON =
  "UPDATED_PROVIDER_APPOINTMENT_REASON";

export const HIDE_PROVIDER_APPOINTMENT_REASON_MODAL =
  "HIDE_PROVIDER_APPOINTMENT_REASON_MODAL";

export const SHOW_PROVIDER_APPOINTMENT_REASON_CREATE_MODAL =
  "SHOW_PROVIDER_APPOINTMENT_REASON_CREATE_MODAL";

export const SHOW_PROVIDER_APPOINTMENT_REASON_EDIT_MODAL =
  "SHOW_PROVIDER_APPOINTMENT_REASON_EDIT_MODAL"



export const editProviderAppointmentReason = (
  providerAppointmentReason: ProviderAppointmentReason
) => ({
  type: EDIT_PROVIDER_APPOINTMENT_REASON,
  providerAppointmentReason,
});

export const stopEditProviderAppointmentReason = () => ({
  type: STOP_EDIT_PROVIDER_APPOINTMENT_REASON,
});

export const loadingProviderAppointmentReason = () => ({
  type: LOADING_PROVIDER_APPOINTMENT_REASON,
});



export const updateProviderAppointmentReason = () => ({
  type: UPDATE_PROVIDER_APPOINTMENT_REASON,
});

export const updatingProviderAppointmentReason = () => ({
  type: UPDATING_PROVIDER_APPOINTMENT_REASON,
});

export const updatedProviderAppointmentReason = (result: boolean) => ({
  type: UPDATED_PROVIDER_APPOINTMENT_REASON,
  result,
});

export const loadedProviderAppointmentReason = (result: boolean) => ({
  type: UPDATED_PROVIDER_APPOINTMENT_REASON,
  result,
});


export const hideProviderAppointmentReasonModal = () => ({
  type: HIDE_PROVIDER_APPOINTMENT_REASON_MODAL,
});

export const showProviderAppointmentReasonCreateModal = (locations: Location[]) => ({
  type: SHOW_PROVIDER_APPOINTMENT_REASON_CREATE_MODAL,
  locations
});

export const showProviderAppointmentReasonEditModal = (providerAppointmentReason: ProviderAppointmentReason) => ({
  type: SHOW_PROVIDER_APPOINTMENT_REASON_EDIT_MODAL,
  providerAppointmentReason
});

