import React, { useEffect, useState } from "react";
import "./PatientInfo.css";
import { PatientInfoProps } from "./Types";
import { PageHeader, Tabs, Descriptions, Select, Button } from "antd";
import { Trans } from "@lingui/macro";
import { AppointmentsTable } from "./appointments-table";
import { Appointment, showModalForCancel } from "../../../store/apointment";
import { useDispatch, useSelector } from "react-redux";
import { filterByValueAndText } from "../../../utils";
import { Insurance, InsuranceState } from "../../../store/insurance";
import { loadInsurancesFromAdmin } from "../../../services/insurance"
import { createNewAccountInsurace, deletePatientAccount } from "../../../services/account";
import { AccountState } from "../../../store/account/Types";
import { AccountModal } from "../../account-modal";
import {  showModalForAccountUpdate } from "../../../store/account";

const { TabPane } = Tabs;

export const PatientInfo: React.FC<PatientInfoProps> = props => {
  const {
    accountState,
    insuranceState
  } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const [insuranceValue, setInsuranceValue] = useState<number[]>([]);
  const { account, loading } = props;

  useEffect(() => {
    loadInsurancesFromAdmin();
  }, [insuranceState.updateInsurances]);

  useEffect(() => {
    let accountInsurences = account?.Insurances?.map(x => x.InsuranceID);
    if (accountInsurences) {
      setInsuranceValue(accountInsurences);
    } else {
      setInsuranceValue([]);
    }
  }, [account]);

  const onAppointmentClick = (
    appointment: Appointment,
    isCancelled: boolean = false
  ) => {
    appointment.patientId = account?.IdentificationNumber;
    dispatch(showModalForCancel(appointment, isCancelled));
  };

  const handleOnDeselect = (value: number) => {
    let deselectedInsurance = insuranceState?.insurances?.find((insurance: Insurance) => insurance.InsuranceID === value)
    if (account)
      deletePatientAccount(account.UID, deselectedInsurance!);
  }

  const handleOnSelect = (value: number) => {
    let selectedInsurance = insuranceState?.insurances?.find((insurance: Insurance) => insurance.InsuranceID === value)
    if (account)
      createNewAccountInsurace(account.UID, selectedInsurance!);
  }

  const showModal = () => {
    dispatch(showModalForAccountUpdate(account!));
  }

  return (
    <PageHeader
      className="patients-info"
      onBack={undefined}
      title={`${account?.FirstName} ${account?.LastName} ${account?.SecondLastName}`}
      subTitle={<>{account?.InsurancePrimaryUID}
        <Button
          type="primary"
          icon="edit"
          onClick={showModal}
        >
          <Trans render="span" id="PatientInfo.Edit">
            Edit
          </Trans>
        </Button></>}
      footer={
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={<Trans id="PatientInfo.AppointmentsLabel">Appointments</Trans>}
            key="1"
          >
            <AppointmentsTable
              appointments={account?.Appointments}
              onAppointmentClick={onAppointmentClick}
              loading={loading}
            />
          </TabPane>
        </Tabs>
      }
    >
      {accountState.showModal && <AccountModal />}
      <Content>
        <Descriptions size="default">
          <Descriptions.Item label={<Trans id="PatientInfo.IDLabel">ID</Trans>}>
            {account?.IdentificationNumber}
          </Descriptions.Item>
          <Descriptions.Item
            label={<Trans id="PatientInfo.EmailLabel">Email</Trans>}
          >
            {account?.Email}
          </Descriptions.Item>
          <Descriptions.Item
            label={<Trans id="PatientInfo.PhoneLabel">Phone</Trans>}
          >
            {`${account?.PrimaryPhoneNumber?.AreaCode} ${account?.PrimaryPhoneNumber?.Number}`}
          </Descriptions.Item>
          <Descriptions.Item
            label={<Trans id="PatientInfo.InsurancesLabel">Insurances</Trans>}
          >
            <Select
              filterOption={filterByValueAndText}
              mode="multiple"
              loading={insuranceState.loading}
              showSearch
              onChange={(value: any) => setInsuranceValue(value)}
              value={insuranceValue}
              style={{ minWidth: "150px" }}
              onDeselect={handleOnDeselect}
              onSelect={handleOnSelect}
            >
              {insuranceState.insurances.map((insurance: Insurance, index: string | number | null | undefined) => (
                <Select.Option
                  key={index}
                  value={insurance.InsuranceID}
                >
                  {insurance.Name}
                </Select.Option>
              ))}
            </Select>
          </Descriptions.Item>
        </Descriptions>
      </Content>
    </PageHeader>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  accountState: state.accountState,
  insuranceState: state.insuranceState
});

interface StateToProps {
  accountState: AccountState;
  insuranceState: InsuranceState;
}

const Content = (props: any) => {
  return (
    <div className="content">
      <div className="main">{props.children}</div>
      <div className="extra">{props.extra}</div>
    </div>
  );
};
