import React from "react";
import "./SearchCalendar.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import moment from "moment";
import { SearchCalendarProps } from "./Types";
import { i18n } from "../../../utils";
import { t, Trans } from "@lingui/macro";
import { Row, DatePicker, Button, Col, Tooltip } from "antd";
import { useDispatch } from "react-redux";
import {
  setCalendarDate,
  changeCalendarView,
  reloadCalendar,
} from "../../../store/calendar";
const esLocale = require("@fullcalendar/core/locales/es");

export const SearchCalendar: React.FC<SearchCalendarProps> = (props) => {
  const calendarRef = React.createRef<FullCalendar>();
  const dispatch = useDispatch();
  const { date, events, onDateSelect, onEventSelect, resources, view } = props;
  const onChangeView = (
    viewName: "resourceTimeGridOneDay" | "resourceTimeGridSevenDay"
  ) => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.changeView(viewName);
      dispatch(changeCalendarView(viewName));
    }
  };

  const onDateChange = (date: moment.Moment | null) => {
    if (!calendarRef.current || !date) return;

    dispatch(setCalendarDate(date));
    const api = calendarRef.current.getApi();
    api.gotoDate(date.toDate());
  };

  const onDateSelectPreprocess = (info: any) => {
    const { appointmentsMinutesInterval, ...rest } =
      info.resource.extendedProps;

    const newInfo = {
      ...rest,
      providerId: info.resource.id.split("@")[0],
      agendaName: info.resource.title.split(" - ")[1],
      date: moment(info.start),
      startTime: moment(info.start),
      endTime: moment(info.start).add(appointmentsMinutesInterval, "m"),
    };

    onDateSelect(newInfo);
  };

  const onEventSelectPreprocess = (info: any) => {
    const newInfo = {
      ...info.event.extendedProps,
      id: info.event.id,
      date: moment(info.event.start),
      startTime: moment(info.event.start),
      endTime: moment(info.event.end),
    };

    onEventSelect(newInfo);
  };

  const onNext = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.next();

      const date = moment(api.getDate());
      dispatch(setCalendarDate(date));
    }
  };

  const onPrev = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi();
      api.prev();

      const date = moment(api.getDate());
      dispatch(setCalendarDate(date));
    }
  };

  const onSync = () => {
    dispatch(reloadCalendar());
  };

  const onToday = () => {
    if (!calendarRef.current) return;

    const api = calendarRef.current.getApi();
    api.today();

    const date = moment(api.getDate());
    dispatch(setCalendarDate(date));
  };

  const slotDuration = () => {
    var interval = Math.min(
      ...resources.map((x) => x.appointmentsMinutesInterval)
    );

    if (
      interval === 25 ||
      interval === 35 ||
      interval === 45 ||
      interval === 55
    ) {
      interval = 5;
    }

    return resources.length > 0
      ? moment().startOf("day").minutes(interval).format("HH:mm") + ":00"
      : "00:30:00";
  };

  return (
    <div className="search-calendar">
      <Row
        type="flex"
        justify="end"
        align="middle"
        className="date-picker"
        gutter={16}
      >
        <Col>
          <Tooltip
            title={
              <Trans id="SearchCalendar.SyncCalendarTooltip">
                Sync calendar
              </Trans>
            }
          >
            <Button type="link" shape="circle" icon="sync" onClick={onSync} />
          </Tooltip>
        </Col>
        <Col>
          <DatePicker
            allowClear={false}
            format="L"
            onChange={onDateChange}
            value={date}
          />
        </Col>
      </Row>
      <FullCalendar
        allDaySlot={false}
        customButtons={{
          next: {
            text: "",
            click: () => onNext(),
          },
          prev: {
            text: "",
            click: () => onPrev(),
          },
          resourceTimeGridOneDay: {
            text: i18n._(t("SearchCalendar.DayViewButton")`Day`),
            click: () => onChangeView("resourceTimeGridOneDay"),
          },
          resourceTimeGridSevenDay: {
            text: i18n._(t("SearchCalendar.WeekViewButton")`Week`),
            click: () => onChangeView("resourceTimeGridSevenDay"),
          },
          today: {
            text: i18n._(t("SearchCalendar.TodayButton")`Today`),
            click: () => onToday(),
          },
        }}
        defaultDate={date.toDate()}
        defaultView={view}
        eventConstraint="businessHours"
        events={events}
        eventClick={onEventSelectPreprocess}
        eventTimeFormat="hh:mm a"
        header={{
          left: "resourceTimeGridOneDay,resourceTimeGridSevenDay",
          center: "title",
          right: "today prev,next",
        }}
        locale={i18n.language}
        locales={[esLocale]}
        nowIndicator={true}
        plugins={[
          dayGridPlugin,
          resourceTimeGridPlugin,
          interactionPlugin,
          momentPlugin,
        ]}
        ref={calendarRef}
        resources={resources}
        select={onDateSelectPreprocess}
        selectable
        scrollTime="12:00:00"
        slotDuration={slotDuration()}
        slotLabelFormat="hh:mm a"
        selectConstraint="businessHours"
        views={{
          resourceTimeGridOneDay: {
            type: "resourceTimeGrid",
            duration: { days: 1 },
            titleFormat: {
              // will produce something like "Tuesday, September 18, 2018"
              month: "long",
              year: "numeric",
              day: "numeric",
              weekday: "long",
            },
          },
          resourceTimeGridSevenDay: {
            type: "resourceTimeGrid",
            duration: { days: 7 },
            titleFormat: {
              // will produce something like "Tuesday, September 18, 2018"
              month: "long",
              year: "numeric",
              day: "2-digit",
            },
          },
        }}
      />
    </div>
  );
};
