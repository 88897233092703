import { ProcedureState, Procedure } from "./Types";
import {
  LOADING_PROCEDURES,
  SET_PROCEDURES,
  UPDATE_PROCEDURES,
  HIDE_PROCEDURE_MODAL,
  SHOW_PROCEDURE_CREATE_MODAL,
  SHOW_PROCEDURE_EDIT_MODAL,
  CHANGING_PROCEDURE,
  CHANGING_PROCEDURE_FINISHED,
  FILTER_PROCEDURES,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: ProcedureState = {
  filteredProcedures: [],
  changing: false,
  procedures: [],
  loading: false,
  modalType: "create",
  showModal: false,
  updateProcedures: false,
};

export const procedureReducer = (
  state = initialState,
  action: any
): ProcedureState => {
  switch (action.type) {
    case CHANGING_PROCEDURE:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_PROCEDURE_FINISHED:
      return {
        ...state,
        changing: false,
        showModal: !action.result,
        procedure: action.result ? undefined : state.procedure,
        updateProcedures: action.result
          ? !state.updateProcedures
          : state.updateProcedures,
      };

    case HIDE_PROCEDURE_MODAL:
      return {
        ...state,
        showModal: false,
      };

    case LOADING_PROCEDURES:
      return {
        ...state,
        loading: true,
      };

    case SET_PROCEDURES:
      return {
        ...state,
        procedures: action.procedures,
        filteredProcedures: action.procedures,
        loading: false,
      };

    case SHOW_PROCEDURE_CREATE_MODAL:
      return {
        ...state,
        modalType: "create",
        showModal: true,
        procedure: undefined,
      };

    case SHOW_PROCEDURE_EDIT_MODAL:
      return {
        ...state,
        modalType: "edit",
        showModal: true,
        procedure: action.procedure,
      };

    case CHANGE_DIRECTORY:
    case UPDATE_PROCEDURES:
      return {
        ...state,
        procedures: [],
        updateProcedures: !state.updateProcedures,
      };
    case FILTER_PROCEDURES:
      return {
        ...state,
        filteredProcedures: filterProcedures(action.value, state.procedures),
      };
    default:
      return state;
  }
};
const filterProcedures = (value: string, procedures: Procedure[]) => {
  if (value === "") return procedures;

  return procedures.filter((provider) => isFilterMatch(value, provider));
};

const isFilterMatch = (value: string, procedure: Procedure) => {
  return procedure.Name.toLowerCase().includes(value.toLowerCase());
};
