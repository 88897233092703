import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingClinics,
  Clinic,
  setClinics,
  changingClinic,
  changingClinicFinished,
  deleteClinicFinished,
  setClinic
} from "../../store/clinic";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadClinics = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/clinic";
  return loadClinicsAux(url);
};

export const loadClinicsFromAdmin = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/clinic/${getDirectoryTenantId()}`;
  return loadClinicsAux(url);
};

const loadClinicsAux = async (url: string) => {
  try {
    store.dispatch(loadingClinics());
    const response = await httpClient.get<GenericApiResponse<Clinic[]>>(url);
    store.dispatch(setClinics(response.data.Data));
    return true;
  } catch {
    store.dispatch(setClinics([]));
    return false;
  }
};

export const loadClinic = async (clinicId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/clinic/${clinicId}/profile`;

  try {
    store.dispatch(changingClinic());
    const response = await httpClient.get<GenericApiResponse<Clinic>>(url);
    store.dispatch(setClinic(response.data.Data));
    return true;
  } catch {
    store.dispatch(setClinic(undefined));
    return false;
  }
};

export const createClinic = async (request: Clinic) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"admin/clinic";

  try {
    store.dispatch(changingClinic());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingClinicFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingClinicFinished(false));
    return false;
  }
};

export const updateClinic = async (request: Clinic) => {
  const { ClinicID, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/clinic/${ClinicID}`;

  try {
    store.dispatch(changingClinic());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingClinicFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingClinicFinished(false));
    return false;
  }
};

export const deleteClinic = async (clinicId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/clinic/${clinicId}`;

  try {
    store.dispatch(changingClinic());
    await httpClient.delete(url);
    store.dispatch(deleteClinicFinished(true));
    return true;
  } catch (message) {
    notification.error({
      message: i18n._(
        t("ClinicService.CouldNotDeleteClinicErrorTitle")`Clinic not deleted`
      ),
      description: i18n._(
        t(
          "ClinicService.CouldNotDeleteClinicErrorDescription"
        )`The clinic could not be deleted, most likely because it has professionals assigned`
      )
    });
    store.dispatch(deleteClinicFinished(false));
    return false;
  }
};

export const searchClinicsForAutocomplete = async (value: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/clinic/autocomplete";

  try {
    store.dispatch(loadingClinics());
    const response = await httpClient.get<GenericApiResponse<Clinic[]>>(url, {
      params: { value }
    });
    store.dispatch(setClinics(response.data.Data));
    return true;
  } catch {
    store.dispatch(setClinics([]));
    return false;
  }
};
