import { CustomProfileState, CustomProfile } from "./Types";
import {
  LOADING_CUSTOM_PROFILES,
  SET_CUSTOM_PROFILES,
  CHANGING_CUSTOM_PROFILE,
  CHANGING_CUSTOM_PROFILE_FINISHED,
  SHOW_CUSTOM_PROFILE_MODAL_FOR_CREATE,
  SHOW_CUSTOM_PROFILE_MODAL_FOR_EDIT,
  HIDE_CUSTOM_PROFILE_MODAL,
  SET_CUSTOM_PROFILE_LOGO,
  DELETE_CUSTOM_PROFILE_FINISHED,
  UPDATE_CUSTOM_PROFILES,
  SET_CUSTOM_PROFILE,
  FILTER_CUSTOM_PROFILES,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: CustomProfileState = {
  filteredCustomProfiles: [],
  changing: false,
  customProfiles: [],
  count: 0,
  loading: false,
  modalType: "create",
  showModal: false,
  updateCustomProfiles: false,
};

export const customProfileReducer = (
  state = initialState,
  action: any
): CustomProfileState => {
  switch (action.type) {
    case CHANGING_CUSTOM_PROFILE:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_CUSTOM_PROFILE_FINISHED:
      return {
        ...state,
        changing: false,
        showModal: !action.result,
        updateCustomProfiles: action.result
          ? !state.updateCustomProfiles
          : state.updateCustomProfiles,
      };

    case DELETE_CUSTOM_PROFILE_FINISHED:
      return {
        ...state,
        changing: false,
        updateCustomProfiles: action.result
          ? !state.updateCustomProfiles
          : state.updateCustomProfiles,
      };

    case HIDE_CUSTOM_PROFILE_MODAL:
      return {
        ...state,
        customProfile: undefined,
        showModal: false,
      };

    case LOADING_CUSTOM_PROFILES:
      return {
        ...state,
        count: 0,
        loading: true,
      };

    case SHOW_CUSTOM_PROFILE_MODAL_FOR_CREATE:
      return {
        ...state,
        customProfile: {
          CustomProfileID: 0,
          Name: "",
          Type: "",
          Address: "",
          URLLogo: "",
          Lattitude: 0,
          Longitude: 0,
          Phone1: "",
          Phone2: "",
          EmailAddress: "",
          DirectoryTenantID: "",
          URLWebSite: "",
          UrlSlug: "",
          URLFacebook: "",
        },
        modalType: "create",
        showModal: true,
      };

    case SHOW_CUSTOM_PROFILE_MODAL_FOR_EDIT:
      return {
        ...state,
        customProfile: action.customProfile,
        modalType: "edit",
        showModal: true,
      };

    case SET_CUSTOM_PROFILE_LOGO:
      return {
        ...state,
        customProfile: {
          ...(state.customProfile as CustomProfile),
          URLLogo: action.URLLogo,
        },
      };

    case SET_CUSTOM_PROFILE:
      return {
        ...state,
        changing: false,
        customProfile: action.customProfile,
      };

    case SET_CUSTOM_PROFILES:
      return {
        ...state,
        customProfiles: action.customProfiles,
        filteredCustomProfiles: action.customProfiles,
        count: action.customProfiles.length,
        loading: false,
      };

    case CHANGE_DIRECTORY:
    case UPDATE_CUSTOM_PROFILES:
      return {
        ...state,
        customProfile: undefined,
        customProfiles: [],
        updateCustomProfiles: !state.updateCustomProfiles,
      };
    case FILTER_CUSTOM_PROFILES:
      return {
        ...state,
        filteredCustomProfiles: filterCustomProfiles(
          action.value,
          state.customProfiles
        ),
      };
    default:
      return state;
  }
};
const filterCustomProfiles = (
  value: string,
  customProfiles: CustomProfile[]
) => {
  if (value === "") return customProfiles;

  return customProfiles.filter((customProfile) =>
    isFilterMatch(value, customProfile)
  );
};

const isFilterMatch = (value: string, customProfile: CustomProfile) => {
  return customProfile.Name.toLowerCase().includes(value.toLowerCase());
};
