import React from "react";
import { Icon } from "antd";
import { Trans } from "@lingui/react";

export interface Props {
  value: boolean;
  showIcon: boolean;
}

const YesNoCell: React.FC<Props> = ({ value, showIcon }) => {
  if (value === true)
    return showIcon ?
      (<span>
        <Icon type="check" style={{ fontSize: "25px", color: "#008000" }} />
      </span>)
      :
      <p>{<Trans id="YesNoCell.yesLabel">Yes</Trans>}</p>
  else
    return showIcon ?
      (<span>
        <Icon type="close" style={{ fontSize: "25px", color: "#FF0000" }} />
      </span>)
      :
      <p>{<Trans id="YesNoCell.noLabel">No</Trans>}</p>
};

export default YesNoCell;
