import store from "../../store";
import { setProviderFocus } from "../../store/providerFocus";

export const loadproviderFocus = () => {
  const data = [
    {
      Name: "Bebés",
      FocusID: "B"
    },
    {
      Name: "Adultos",
      FocusID: "A"
    },
    {
      Name: "Niños(as)",
      FocusID: "K"
    },
    {
      Name: "Adultos mayores",
      FocusID: "E"
    },
    {
      Name: "Adolescentes",
      FocusID: "T"
    }
  ];

  store.dispatch(setProviderFocus(data));
  return true;
};
