import { InsuranceState, Insurance } from "./Types";
import {
  LOADING_INSURANCES,
  SET_INSURANCES,
  UPDATE_INSURANCES,
  CHANGING_INSURANCE,
  CHANGING_INSURANCE_FINISHED,
  SHOW_INSURANCE_MODAL_FOR_CREATE,
  SHOW_INSURANCE_MODAL_FOR_EDIT,
  HIDE_INSURANCE_MODAL,
  DELETE_INSURANCE_FINISHED,
  SET_INSURANCE,
  FILTER_INSURANCES,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: InsuranceState = {
  insurances: [],
  loading: false,
  updateInsurances: false,
  modalType: "create",
  showModal: false,
  changing: false,
  count: 0,
  filteredInsurances: [],
};

export const insuranceReducer = (
  state = initialState,
  action: any
): InsuranceState => {
  switch (action.type) {
    case CHANGING_INSURANCE:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_INSURANCE_FINISHED:
      return {
        ...state,
        changing: false,
        insurance: undefined,
        showModal: !action.result,
        updateInsurances: action.result
          ? !state.updateInsurances
          : state.updateInsurances,
      };

    case DELETE_INSURANCE_FINISHED:
      return {
        ...state,
        changing: false,
        updateInsurances: action.result
          ? !state.updateInsurances
          : state.updateInsurances,
      };

    case HIDE_INSURANCE_MODAL:
      return {
        ...state,
        insurance: undefined,
        showModal: false,
      };

    case LOADING_INSURANCES:
      return {
        ...state,
        loading: true,
      };

    case SHOW_INSURANCE_MODAL_FOR_CREATE:
      return {
        ...state,
        insurance: {
          InsuranceID: 0,
          Name: "",
          IsActive: true,
          Disclaimer: undefined
        },
        modalType: "create",
        showModal: true,
      };

    case SHOW_INSURANCE_MODAL_FOR_EDIT:
      return {
        ...state,
        insurance: action.insurance,
        modalType: "edit",
        showModal: true,
      };

    case SET_INSURANCES:
      return {
        ...state,
        insurances: action.insurances,
        filteredInsurances: action.insurances,
        loading: false,
      };

    case SET_INSURANCE:
      return {
        ...state,
        insurance: action.insurance,
        filteredInsurances: action.insurances,
        count: action.insurance.length,
        loading: false,
      };

    case CHANGE_DIRECTORY:
    case UPDATE_INSURANCES:
      return {
        ...state,
        insurance: undefined,
        insurances: [],
        filteredInsurances: [],
        updateInsurances: !state.updateInsurances,
      };

    case FILTER_INSURANCES:
      return {
        ...state,
        filteredInsurances: filterInsurances(action.value, state.insurances),
      };

    default:
      return state;
  }
};

const filterInsurances = (value: string, insurances: Insurance[]) => {
  if (value === "") return insurances;

  return insurances.filter((provider) => isFilterMatch(value, provider));
};

const isFilterMatch = (value: string, keyword: Insurance) => {
  return keyword.Name.toLowerCase().includes(value.toLowerCase());
};
