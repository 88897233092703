import { Insurance } from "./Types";

export const LOADING_INSURANCES = "LOADING_INSURANCES";
export const SET_INSURANCES = "SET_INSURANCES";
export const SET_INSURANCE = "SET_INSURANCE";
export const UPDATE_INSURANCES = "UPDATE_INSURANCES";
export const CHANGING_INSURANCE = "CHANGING_INSURANCE";
export const CHANGING_INSURANCE_FINISHED = "CHANGING_INSURANCE_FINISHED";
export const DELETE_INSURANCE_FINISHED = "DELETE_INSURANCE_FINISHED";
export const HIDE_INSURANCE_MODAL = "HIDE_INSURANCE_MODAL";
export const SHOW_INSURANCE_MODAL_FOR_CREATE = "SHOW_INSURANCE_MODAL_FOR_CREATE";
export const SHOW_INSURANCE_MODAL_FOR_EDIT = "SHOW_INSURANCE_MODAL_FOR_EDIT";
export const FILTER_INSURANCES = "FILTER_INSURANCES";

export const loadingInsurances = () => ({ type: LOADING_INSURANCES });

export const setInsurances = (insurances: Insurance[]) => ({
  type: SET_INSURANCES,
  insurances,
});

export const setInsurance = (insurance: Insurance | undefined) => ({
  type: SET_INSURANCE,
  insurance,
});

export const updateInsurances = () => ({
  type: UPDATE_INSURANCES,
});

export const changingInsurance = () => ({ type: CHANGING_INSURANCE });

export const changingInsuranceFinished = (result: boolean) => ({
  type: CHANGING_INSURANCE_FINISHED,
  result,
});

export const deleteInsuranceFinished = (result: boolean) => ({
  type: DELETE_INSURANCE_FINISHED,
  result,
});

export const hideInsuranceModal = () => ({ type: HIDE_INSURANCE_MODAL });

export const showInsuranceModalForCreate = () => ({
  type: SHOW_INSURANCE_MODAL_FOR_CREATE,
});

export const showInsuranceModalForEdit = (insurance: Insurance) => ({
  type: SHOW_INSURANCE_MODAL_FOR_EDIT,
  insurance,
});

export const filterInsurances = (value: string) => ({
  type: FILTER_INSURANCES,
  value,
});
