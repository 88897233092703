import React, { useEffect, useState } from "react";
import "./AppointmentScheduleModal.css";
import { AppointmentModalProps } from "./Types";
import {
  Modal,
  Form,
  Input,
  Col,
  DatePicker,
  Select,
  Row,
  Button,
  Typography,
  Divider,
  Skeleton,
} from "antd";
import { Trans } from "@lingui/macro";
import {
  Appointment,
  AppointmentState,
  hideAppointmentModal,
  reloadTimeSlots,
  changeTimeSlotIndex,
  changeAppointmentLocation,
  LocationTimeSlot,
} from "../../../store/apointment";
import { nameofFactory } from "../../../utils";
import { searchAccountForAutocomplete } from "../../../services/account";
import {
  loadProviderTimeSlots,
  getProvider,
  loadProviderSpecialties,
  loadBasicProviderLocations,
} from "../../../services/provider";
import moment from "moment";
import { TimeSlots } from "../time-slots/TimeSlots";
import { SelectValue } from "antd/lib/select";
import { AccountState, selectAccount } from "../../../store/account";
import { ProviderState } from "../../../store/provider";
import { useSelector, useDispatch } from "react-redux";
import { rescheduleAppointment } from "../../../services/appointment";
import { TimeChangedEvent } from "../time-slots/Types";
import { ProviderAppointmentReason } from "../../../store/providerAppointmentReason";

const { Option } = Select;
const TIME_SLOTS_TO_SHOW = 4;

const AppointmentScheduleModal: React.FC<AppointmentModalProps> = (props) => {
  const { specialtyId } = props;
  const { accountState, appointmentState, providerState } =
    useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const [appointment, setAppointment] = useState<Appointment>(
    appointmentState.appointment!
  );
  const [dateSelected, setDateSelected] = useState<moment.Moment>(
    appointment?.date!
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingTime, setIsLoadingTime] = useState<boolean>(true);
  const [isChangeDateSelected, setIsChangeDateSelected] =
    useState<boolean>(false);
  const [isOnTimeSelect, setIsOnTimeSelect] = useState<boolean>(false);

  const [reasonsByAgenda, setReasonByAgenda] = useState<
    ProviderAppointmentReason[]
  >(providerState?.provider?.AppointmentReason ?? []);
  const [reasonIdSelected, setReasonSelected] = useState<number>(
    (reasonsByAgenda.length > 0
      ? reasonsByAgenda.some(
        (x) => x.ProviderAppointmentReasonID === appointment.reasonID
      )
        ? appointment.reasonID
        : 0
      : 0)!
  );

  const [startTimes, setStartTimes] = useState<moment.Moment[]>([]);
  const [endTimes, setEndTimes] = useState<moment.Moment[]>([]);
  const [timeSlots, setTimeSlots] = useState<LocationTimeSlot>();

  const { form } = props;
  const nameof = nameofFactory<Appointment>();
  const { getFieldDecorator, getFieldValue, validateFields } = form;

  const modalities = [
    {
      id: "P",
      label: <Trans id="Common.ModalityPresentialLabel">On site</Trans>,
    },
    {
      id: "D",
      label: <Trans id="Common.ModalityHomeCareLabel">Home care</Trans>,
    },
    {
      id: "T",
      label: <Trans id="Common.ModalityTeleHealtLabel">Telehealth</Trans>,
    },
  ];

  const providerModalities = modalities
    .filter((r) => providerState.provider?.Modalities?.includes(r.id))
    .sort((a, b) => a.id.localeCompare(b.id));

  useEffect(() => {
    if (appointment?.patientId)
      searchAccountForAutocomplete(appointment?.patientId).then(() =>
        dispatch(selectAccount(String(appointment?.patientId)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function fetchMyAPI() {
      if (appointment?.providerId) {
        await getProvider(appointment?.providerId);
        await loadProviderSpecialties(appointment.providerId);
        await loadBasicProviderLocations(appointment.providerId);
        getReasonsByAgenda();
      }
    }

    fetchMyAPI();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoadingTime(true);
    if (reasonIdSelected > 0 && reasonsByAgenda.length > 0) {
      loadTimeSlots(getDurationTimeFromReason());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointmentState.timeSlots) {
      let time =
        appointmentState.timeSlots?.TimeSlots[appointmentState.timeSlotIndex]
          .Hours || [];
      const minutes = getInterval()!;
      if (time.length > 0 && appointmentState.timeSlots.AppointmentIntervalMinutes < minutes) {
        time.push(time[time.length - 1].clone().add(minutes, "m")); 
      }
      setStartTimes(time);
      setTimeSlots(appointmentState.timeSlots);

      if (time.length > 0) {
        const start = moment(time[0]);

        const end = moment(time[time.length - 1].clone().add(minutes, "m"));

        setEndTimes(getHoursBySchedule(start, end, Number(minutes)));
      } else {
        setEndTimes([]);
        setIsLoadingTime(false);
      }
    }

    // eslint-disable-next-line
  }, [appointmentState.timeSlots?.TimeSlots]);

  useEffect(() => {
    if (startTimes.length > 0) {
      setTime();
      setIsLoadingTime(false);
    } else {
      setAppointment({
        ...appointment,
        endTime: appointment.startTime.clone().add(getInterval(), "m"),
      } as Appointment);
    }

    // eslint-disable-next-line
  }, [startTimes]);

  useEffect(() => {
    if (isChangeDateSelected) {
      loadTimeSlots(
        reasonIdSelected > 0 && reasonsByAgenda.length > 0
          ? getDurationTimeFromReason()
          : 0
      );
    }else{
      console.log("hola")
    }

    // eslint-disable-next-line
  }, [isChangeDateSelected]);

  const loadTimeSlots = async (durationMinutes: number) => {
    if (appointment) {
      const request = {
        providerId: appointment.providerId,
        agendaId: appointment.agendaId,
        locationId: appointment.locationId,
        from: dateSelected,
        to: moment(dateSelected).add(TIME_SLOTS_TO_SHOW - 1, "d"),
        duration: durationMinutes ? durationMinutes : 0,
      };

      let success = await loadProviderTimeSlots(request);

      if (!success) {
        clearSelects();
      }
    }
  };

  const getReasonsByAgenda = () => {
    if (providerState.provider?.AppointmentReason) {
      if (providerState.provider?.AppointmentReason.length > 0) {
        let reasonWithoutAgenda =
          providerState.provider?.AppointmentReason?.filter(
            (x) => x.LocationsSelected?.length === 0
          );
        let reasonWithAgenda =
          providerState.provider?.AppointmentReason?.filter(
            (x) => x.LocationsSelected?.length! > 0
          );
        reasonWithAgenda = reasonWithAgenda?.filter((x) =>
          x.LocationsSelected?.filter(
            (y) =>
              y.AgendaID === appointment?.agendaId &&
              y.LocationID === appointment?.locationId
          )
        );

        setReasonByAgenda(
          [...reasonWithoutAgenda, ...reasonWithAgenda].sort((a, b) =>
            a.Name.localeCompare(b.Name)
          )
        );
        setIsLoading(false);
      } else {
        loadTimeSlots(0);
        setIsLoading(false);
      }
    }
  };

  const disableEndTimeOption = (start: string, endTime: moment.Moment) => {
    const startTime = moment(start, "HH:mm");
    return startTime.valueOf() >= endTime.valueOf();
  };

  const dismissModal = () => {
    if (!appointmentState.loading) dispatch(hideAppointmentModal());
  };

  const getHoursBySchedule = (
    startTime: moment.Moment,
    endTime: moment.Moment,
    interval: number
  ) => {
    let hours: moment.Moment[] = [];

    if (interval === 0) {
      return hours;
    }

    let i = moment(startTime);
    let f = moment(endTime);

    while (i <= f) {
      if (i.format("hh:mm a") !== "12:00 am") {
        hours.push(i);
      }
      i = moment(i).add(interval, "m");
    }

    let defaultHoraFin = moment(
      moment().format("DD/MM/YYYY") + " 23:59:59",
      "DD-MM-YYYY HH:mm:ss"
    );

    let onceNoche55 = moment("11:55pm", "h:mma");

    if (moment(endTime).isAfter(onceNoche55)) {
      hours.push(defaultHoraFin);
    }

    let motivoIntervalo =
      reasonsByAgenda.length > 0 ? getDurationTimeFromReason() : 0;
    if (interval !== motivoIntervalo) {
      //ahora con el nuevo cambio que se envia en vez del intervalo del motivo, el intervalo de la agenda
      //se debe agregar un ultimo slot al finald e la iteracion

      hours.push(i.clone());
    }

    return hours;
  };

  const onAgendaChange = (value: string) => {
    const [locationId, agendaId] = value.split("-").map((x) => Number(x));
    dispatch(changeAppointmentLocation(locationId, agendaId));
  };

  const onTimeSelect = (values: TimeChangedEvent) => {
    setAppointment({
      ...appointment,
      startTime: values.startTime.clone(),
      endTime: values.endTime.clone(),
    } as Appointment);

    setIsOnTimeSelect(true);
    setDateSelected(values.date);
    setIsChangeDateSelected(true);
    setIsLoadingTime(true);

    dispatch(changeTimeSlotIndex(values.slotIndex));
    dispatch(reloadTimeSlots(values.date))
  };

  const onReloadTimeSlots = (date: moment.Moment | null) => {
    if (date === null) return;
    setDateSelected(date);
    setIsLoadingTime(true);
    setIsChangeDateSelected(true);
    setTimeSlots(undefined);
    dispatch(reloadTimeSlots(date));
  };

  const onReschedule = () => {
    validateFields((err, values) => {
      if (err) return;

      if (appointment) {
        const request = {
          id: String(appointment.id),
          date: values.date,
          startTime: values.startTime,
          endTime: values.endTime,
          notes: values.notes,
          agendaId: appointment.agendaId,
          locationId: appointment.locationId,
          reasonID:
            reasonIdSelected > 0 && reasonsByAgenda.length > 0
              ? reasonIdSelected
              : undefined,
          modality: appointment.modality,
        };

        rescheduleAppointment(request);
      }
    });
  };

  const setTime = () => {
    const start = startTimes[0];
    const minutes = getInterval();
    const end = startTimes[startTimes.length - 1].clone().add(minutes, "m");

    let reasonGreatherThanInterval =
      getDurationTimeFromReason() >
      appointmentState.timeSlots?.AppointmentIntervalMinutes!;
    let tempStartTime = null;
    let tempEndTime = null;

    if (!isChangeDateSelected || isOnTimeSelect) {
      if (appointment?.startTime?.isValid()) {
        let existInStartTime = startTimes.some(
          (x) => x.format("HH:mm") === appointment?.startTime.format("HH:mm")
        );

        if (reasonGreatherThanInterval && !existInStartTime) {
          tempStartTime = start;
          tempEndTime = startTimes.length > 1 ? startTimes[1] : end;
        } else {
          tempStartTime = appointment?.startTime.clone();
          tempEndTime = tempStartTime.clone().add(minutes, "m");
        }
      } else {
        tempStartTime = start;
        tempEndTime = tempStartTime.clone().add(minutes, "m");
      }
      setIsOnTimeSelect(false);
      setIsChangeDateSelected(false);

    } else {
      tempStartTime = start;
      tempEndTime = tempStartTime.clone().add(minutes, "m");
      setIsChangeDateSelected(false);
    }

    setAppointment({
      ...appointment,
      startTime: moment(tempStartTime).clone(),
      endTime: moment(tempEndTime).clone(),
    } as Appointment);
  };

  const startTimesRegex = new RegExp(
    startTimes.map((time) => time.format("HH:mm")).join("|")
  );
  const endTimesRegex = new RegExp(
    endTimes.map((time) => time.format("HH:mm")).join("|")
  );

  const setTimesValue = (value: SelectValue) => {
    if (!value) return;

    const startTimeIndex = startTimes.findIndex(
      (item) => item.format("HH:mm") === value
    );

    const startTime = startTimes[startTimeIndex];

    const endTime = startTime.clone().add(getInterval(), "m");

    setAppointment({
      ...appointment,
      startTime: moment(startTime).clone(),
      endTime: moment(endTime).clone(),
    } as Appointment);
  };

  const onReasonSelect = (item: number) => {
    setIsLoadingTime(true);
    setReasonSelected(item);
    loadTimeSlots(getDurationTimeFromReason(item));
  };

  const getDurationTimeFromReason = (reasonId: number = 0) => {
    let id = reasonId > 0 ? reasonId : reasonIdSelected;
    return reasonsByAgenda.find((x) => x.ProviderAppointmentReasonID === id)
      ?.DurationMinutes!;
  };

  const getInterval = () => {
    return reasonIdSelected > 0 && reasonsByAgenda.length > 0
      ? getDurationTimeFromReason()
      : appointmentState.timeSlots?.AppointmentIntervalMinutes;
  };

  const clearSelects = () => {
    setStartTimes([]);
    setEndTimes([]);
    setAppointment({
      ...appointment,
      startTime: moment(null),
      endTime: moment(null),
    } as Appointment);

    setIsLoadingTime(false);
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().add(-1, "days");
  }
  return (
    <Modal
      centered
      destroyOnClose
      footer={
        <Row type="flex" justify="end" gutter={16}>
          <Col>
            <Button
              loading={appointmentState.loading}
              disabled={isLoadingTime || isLoading}
              onClick={onReschedule}
              type="primary"
            >
              <Trans
                render="span"
                id="AppointmentScheduleModal.RescheduleAppointmentButton"
              >
                Reschedule
              </Trans>
            </Button>
          </Col>
          <Col>
            <Button disabled={appointmentState.loading} onClick={dismissModal}>
              <Trans render="span" id="AppointmentScheduleModal.DismissButton">
                Dismiss
              </Trans>
            </Button>
          </Col>
        </Row>
      }
      onCancel={dismissModal}
      title={
        <Trans id="AppointmentScheduleModal.RescheduleTitle">
          Reschedule{" "}
          {`${accountState.account?.FirstName} ${accountState.account?.LastName} ${accountState.account?.SecondLastName}'s`}{" "}
          appointment
        </Trans>
      }
      visible={appointmentState.showScheduleModal}
    >
      <Row type="flex">
        <Typography.Title level={4}>
          {providerState.provider?.FullName}
        </Typography.Title>
      </Row>
      <Form colon={false} layout="vertical">
        <Skeleton loading={isLoading} active paragraph={{ rows: 3 }}>
          <Row>
            <Col span={24}>
              {reasonsByAgenda.length > 0 && (
                <Form.Item
                  label={
                    <Trans id="ProviderAppointmentReason.Name">Reason</Trans>
                  }
                >
                  {getFieldDecorator(nameof("reasonID"), {
                    initialValue:
                      reasonIdSelected === 0 ? null : reasonIdSelected,
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="Common.AppointmentReasonRequiredError">
                            The reason field is required
                          </Trans>
                        ),
                      },
                    ],
                  })(
                    <Select onChange={onReasonSelect}>
                      {reasonsByAgenda.map((item, index) => (
                        <Select.Option
                          value={item.ProviderAppointmentReasonID}
                          key={index}
                        >
                          {item.Name} ({item.DurationMinutes} min.)
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              )}
            </Col>
          </Row>
          {reasonsByAgenda.length === 0 ||
            (reasonsByAgenda.length > 0 && reasonIdSelected > 0) ? (
            <>
              <Skeleton loading={isLoadingTime} active paragraph={{ rows: 3 }}>
                <Row gutter={24}>
                  <Col span={10}>
                    <Form.Item
                      label={
                        <Trans id="AppointmentScheduleModal.UpdateDateLabel">
                          New date
                        </Trans>
                      }
                    >
                      {getFieldDecorator(nameof("date"), {
                        initialValue: dateSelected,
                        rules: [
                          {
                            required: true,
                            message: (
                              <Trans id="AppointmentScheduleModal.DateRequiredError">
                                This date field is required
                              </Trans>
                            ),
                          },
                        ],
                      })(
                        <DatePicker
                          disabledDate={disabledDate}
                          disabled={appointmentState.loadingTimeSlots}
                          format="L"
                          allowClear={false}
                          onChange={onReloadTimeSlots}
                        />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      label={
                        <Trans id="AppointmentScheduleModal.StartTimeLabel">
                          Start time
                        </Trans>
                      }
                    >
                      {getFieldDecorator(nameof("startTime"), {
                        initialValue: appointment?.startTime?.isValid()
                          ? appointment?.startTime?.format("HH:mm")
                          : "",
                        rules: [
                          {
                            required: true,
                            message: (
                              <Trans id="AppointmentScheduleModal.StartTimeRequiredError">
                                This start time field is required
                              </Trans>
                            ),
                          },
                          {
                            pattern: startTimesRegex,
                            message: (
                              <Trans id="AppointmentScheduleModal.StartTimeInvalidError">
                                The selected start time is not valid or is taken
                              </Trans>
                            ),
                          },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          loading={appointmentState.loadingTimeSlots}
                          onChange={setTimesValue}
                        >
                          {startTimes.map((time, index) => (
                            <Option key={index} value={time.format("HH:mm")}>
                              {time.format("hh:mm a")}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item
                      label={
                        <Trans id="AppointmentScheduleModal.EndTimeLabel">
                          End time
                        </Trans>
                      }
                    >
                      {getFieldDecorator(nameof("endTime"), {
                        initialValue: appointment?.endTime?.isValid()
                          ? appointment?.endTime?.format("HH:mm")
                          : "",
                        rules: [
                          {
                            required: true,
                            message: (
                              <Trans id="AppointmentScheduleModal.EndTimeRequiredError">
                                This end time field is required
                              </Trans>
                            ),
                          },
                          {
                            pattern: endTimesRegex,
                            message: (
                              <Trans id="AppointmentScheduleModal.EndTimeInvalidError">
                                The selected end time is not valid or is taken
                              </Trans>
                            ),
                          },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          loading={appointmentState.loadingTimeSlots}
                          disabled={
                            reasonsByAgenda.length > 0 && reasonIdSelected > 0
                          }
                        >
                          {endTimes.map((time, index) => (
                            <Option
                              key={index}
                              value={time.format("HH:mm")}
                              disabled={disableEndTimeOption(
                                getFieldValue(nameof("startTime")),
                                time
                              )}
                            >
                              {time.format("hh:mm a")}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Skeleton>

              <Form.Item label={<Trans id="Common.AgendaLabel">Agenda</Trans>}>
                {getFieldDecorator("locationAgenda", {
                  initialValue: `${appointment?.locationId}-${appointment?.agendaId}`,
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="Common.AgendaRequiredError">
                          The agenda field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Select allowClear disabled={true} onChange={onAgendaChange}>
                    {providerState.provider?.Locations?.map(
                      (location, index) => (
                        <Option
                          key={index}
                          value={`${location.LocationID}-${location.AgendaID}`}
                        >
                          {location.AgendaName}
                        </Option>
                      )
                    )}
                  </Select>
                )}
              </Form.Item>

              <Form.Item
                label={<Trans id="Common.SpecialtyLabel">Specialty</Trans>}
              >
                {getFieldDecorator(nameof("specialtyId"), {
                  initialValue: specialtyId || appointment?.specialtyId,
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="Common.SpecialtyRequiredError">
                          The specialty field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Select disabled={true}>
                    {providerState.provider?.Specialties?.map(
                      (specialty, index) => (
                        <Option key={index} value={specialty.SpecialtyID}>
                          {specialty.Name}
                        </Option>
                      )
                    )}
                  </Select>
                )}
              </Form.Item>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<Trans id="Common.ModalityLabel">Modality</Trans>}
                  >
                    {getFieldDecorator(nameof("modality"), {
                      initialValue: appointment?.modality,
                      rules: [
                        {
                          required: true,
                          message: (
                            <Trans id="Common.ModalitiesRequiredError">
                              The modality field is required
                            </Trans>
                          ),
                        },
                      ],
                    })(
                      <Select disabled={true}>
                        {providerModalities.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label={<Trans id="Common.NotesLabel">Notes</Trans>}>
                {getFieldDecorator("notes")(<Input.TextArea rows={3} />)}
              </Form.Item>
              {appointment.startTime.isValid() && (
                <Skeleton
                  loading={isLoadingTime || timeSlots === undefined}
                  active
                  paragraph={{ rows: 3 }}
                >
                  <React.Fragment>
                    <Divider />
                    <Form.Item>
                      <TimeSlots
                        onTimeSelect={onTimeSelect}
                        timeSlots={timeSlots}
                        interval={getInterval()}
                      />
                    </Form.Item>
                  </React.Fragment>
                </Skeleton>
              )}
            </>
          ) : null}
        </Skeleton>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  accountState: state.accountState,
  appointmentState: state.appointmentState,
  providerState: state.providerState,
});

interface StateToProps {
  accountState: AccountState;
  appointmentState: AppointmentState;
  providerState: ProviderState;
}

export default Form.create<AppointmentModalProps>()(AppointmentScheduleModal);
