import { Currency } from "../store/provider";

export const LocalStorageKeys = {
  AccessToken: "accessToken",
  AuthInfo: "authInfo",
  DirectoryTenantId: "directoryTenantId",
  SelectedLocale: "selectedLocale",
  baseApiUrl:"baseApiUrl",
  directoryName:"directoryName"
};

export const SearchQueryKeys = {
  ReturnUrl: "returnUrl"
};

export const currencys: Currency[] = [
  { CurrencyCode: "CRC", CurrencyName: "Colones", CurrencySymbol: "₡" },
  { CurrencyCode: "USD", CurrencyName: "Dolares", CurrencySymbol: "$" },
  { CurrencyCode: "EUR", CurrencyName: "Euros", CurrencySymbol: "€" },
];