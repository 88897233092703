import { Appointment, LocationTimeSlot, AppointmentLog } from "./Types";
import moment from "moment";

export const ADD_APPOINTMENT_LOGS = "ADD_APPOINTMENT_LOGS";
export const CANCELLING_APPOINTMENT_FINISHED =
  "CANCELLING_APPOINTMENT_FINISHED";
export const CHANGE_APPOINTMENT_LOCATION = "CHANGE_APPOINTMENT_LOCATION";
export const CHANGE_TIME_SLOT_INDEX = "CHANGE_TIME_SLOT_INDEX";
export const HIDE_APPOINTMENT_MODAL = "HIDE_APPOINTMENT_MODAL";
export const HIDE_CANCEL_APPOINTMENT_MODAL = "HIDE_CANCEL_APPOINTMENT_MODAL";
export const LOADED_APPOINTMENT = "LOADED_APPOINTMENT";
export const LOADING_APPOINTMENT = "LOADING_APPOINTMENT";
export const LOADED_TIME_SLOTS = "LOADING_TIME_SLOTS";
export const LOADING_TIME_SLOTS = "LOADING_TIME_SLOTS";
export const RELOAD_TIME_SLOTS = "RELOAD_TIME_SLOTS";
export const SCHEDULING_APPOINTMENT_FINISHED =
  "SCHEDULING_APPOINTMENT_FINISHED";
export const SHOW_MODAL_FOR_CANCEL = "SHOW_MODAL_FOR_CANCEL";
export const SHOW_MODAL_FOR_RESCHEDULE = "SHOW_MODAL_FOR_RESCHEDULE";
export const SHOW_MODAL_FOR_SCHEDULE = "SHOW_MODAL_FOR_SCHEDULE";

export const addAppointmentLogs = (logs: AppointmentLog[] | undefined) => ({
  type: ADD_APPOINTMENT_LOGS,
  logs,
});

export const cancellingAppointmentFinished = (result: boolean) => ({
  type: CANCELLING_APPOINTMENT_FINISHED,
  result,
});

export const changeAppointmentLocation = (
  locationId: number,
  agendaId: number
) => ({
  type: CHANGE_APPOINTMENT_LOCATION,
  locationId,
  agendaId,
});

export const changeTimeSlotIndex = (index: number) => ({
  type: CHANGE_TIME_SLOT_INDEX,
  index,
});

export const hideAppointmentModal = () => ({
  type: HIDE_APPOINTMENT_MODAL,
});
export const hideCancelAppointmentModal = () => ({
  type: HIDE_CANCEL_APPOINTMENT_MODAL,
});

export const loadedAppointment = () => ({
  type: LOADED_APPOINTMENT,
});

export const loadingAppointment = () => ({
  type: LOADING_APPOINTMENT,
});

export const loadedTimeSlots = (timeSlots: LocationTimeSlot) => ({
  type: LOADED_TIME_SLOTS,
  timeSlots,
});

export const loadingTimeSlots = () => ({
  type: LOADING_TIME_SLOTS,
});

export const reloadTimeSlots = (date: moment.Moment) => ({
  type: RELOAD_TIME_SLOTS,
  date,
});

export const schedulingAppointmentFinished = (result: boolean) => ({
  type: SCHEDULING_APPOINTMENT_FINISHED,
  result,
});

export const showModalForCancel = (
  appointment: Appointment,
  readonly: boolean = false
) => ({
  type: SHOW_MODAL_FOR_CANCEL,
  appointment,
  readonly,
});

export const showModalForReschedule = (
  appointment: Appointment,
  readonly: boolean = false
) => ({
  type: SHOW_MODAL_FOR_RESCHEDULE,
  appointment,
  readonly,
});

export const showModalForSchedule = (appointment: Appointment) => ({
  type: SHOW_MODAL_FOR_SCHEDULE,
  appointment,
});
