import { AppointmentState, Appointment } from "./Types";
import {
  SHOW_MODAL_FOR_RESCHEDULE,
  SHOW_MODAL_FOR_SCHEDULE,
  HIDE_APPOINTMENT_MODAL,
  LOADED_TIME_SLOTS,
  LOADING_TIME_SLOTS,
  RELOAD_TIME_SLOTS,
  CHANGE_TIME_SLOT_INDEX,
  CANCELLING_APPOINTMENT_FINISHED,
  SCHEDULING_APPOINTMENT_FINISHED,
  SHOW_MODAL_FOR_CANCEL,
  HIDE_CANCEL_APPOINTMENT_MODAL,
  LOADED_APPOINTMENT,
  LOADING_APPOINTMENT,
  ADD_APPOINTMENT_LOGS,
  CHANGE_APPOINTMENT_LOCATION,
} from "./Actions";
import { SET_ACCOUNT_FROM_TSE } from "../account";

const initialState: AppointmentState = {
  loading: false,
  loadingTimeSlots: false,
  scheduleModalType: "create",
  readonly: false,
  showCancelModal: false,
  showScheduleModal: false,
  timeSlotIndex: 0,
};

export const appointmentReducer = (
  state = initialState,
  action: any
): AppointmentState => {
  switch (action.type) {
    case ADD_APPOINTMENT_LOGS:
      if (state.appointment) {
        return {
          ...state,
          appointment: {
            ...state.appointment,
            logs: action.logs,
          },
        };
      }

      return state;

    case CANCELLING_APPOINTMENT_FINISHED:
      return {
        ...state,
        appointment: !action.result ? state.appointment : undefined,
        loading: false,
        showCancelModal: !action.result,
      };

    case CHANGE_APPOINTMENT_LOCATION:
      if (state.appointment) {
        return {
          ...state,
          appointment: {
            ...state.appointment,
            locationId: action.locationId,
            agendaId: action.agendaId,
          },
        };
      }

      return state;

    case CHANGE_TIME_SLOT_INDEX:
      return {
        ...state,
        timeSlotIndex: action.index,
      };

    case HIDE_APPOINTMENT_MODAL:
      return {
        ...state,
        appointment: undefined,
        readonly: false,
        showScheduleModal: false,
        timeSlotIndex: 0,
        timeSlots: undefined,
      };

    case HIDE_CANCEL_APPOINTMENT_MODAL:
      return {
        ...state,
        appointment: undefined,
        readonly: false,
        showCancelModal: false,
      };

    case LOADED_APPOINTMENT:
      return {
        ...state,
        loading: false,
      };

    case LOADING_APPOINTMENT:
      return {
        ...state,
        loading: true,
      };

    case LOADED_TIME_SLOTS:
      return {
        ...state,
        loadingTimeSlots: false,
        timeSlots: action.timeSlots,
      };

    case LOADING_TIME_SLOTS:
      return {
        ...state,
        loadingTimeSlots: true,
        timeSlotIndex: 0,
        timeSlots: undefined,
      };

    case RELOAD_TIME_SLOTS:
      return {
        ...state,
        appointment: {
          ...(state.appointment as Appointment),
          date: action.date,
        },
        loadingTimeSlots: true,
        timeSlotIndex: 0,
        timeSlots: undefined,
      };

    case SCHEDULING_APPOINTMENT_FINISHED:
      return {
        ...state,
        appointment: !action.result ? state.appointment : undefined,
        loading: false,
        showScheduleModal: !action.result,
      };

    case SET_ACCOUNT_FROM_TSE:
      return {
        ...state,
        appointment: {
          ...(state.appointment as Appointment),
          patientId: action.account.IdentificationNumber,
        },
      };

    case SHOW_MODAL_FOR_CANCEL:
      return {
        ...state,
        appointment: action.appointment,
        readonly: action.readonly,
        showCancelModal: true,
      };

    case SHOW_MODAL_FOR_RESCHEDULE:
      return {
        ...state,
        appointment: action.appointment,
        scheduleModalType: "edit",
        readonly: action.readonly,
        showCancelModal: false,
        showScheduleModal: true,
      };

    case SHOW_MODAL_FOR_SCHEDULE:
      return {
        ...state,
        appointment: action.appointment,
        scheduleModalType: "create",
        readonly: false,
        showScheduleModal: true,
      };

    default:
      return state;
  }
};
