import React, { useEffect } from "react";
import { Container, YesNoCell } from "../../components/utils";
import { Table, Row, Button, Col, Input, Typography } from "antd";
import { Trans, t } from "@lingui/macro";
import { useSelector, useDispatch } from "react-redux";
import {
  ProviderState,
  Provider,
  setProviders,
  showProviderCreateModal,
} from "../../store/provider";
import { i18n } from "../../utils";
import { loadProviders, createProvider } from "../../services/provider";
import { Link, useHistory } from "react-router-dom";
import debounce from "lodash/debounce";
import { DirectoryState } from "../../store/directory";
import { ProviderModal } from "../../components/provider-modal";

export const ProfessionalsPage: React.FC = () => {
  const pageSize = 20;
  const { directoryState, providersState } = useSelector(mapState);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => {
      dispatch(setProviders([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryState.selectorDirectory]);

  const columns = [
    {
      title: (
        <Trans render="strong" id="ProfessionalsPage.TableNameColumnTitle">
          Name
        </Trans>
      ),
      key: "Fullname",
      render: (provider: Provider) => (
        <Link to={`/professional/${provider.ProviderID}`}>
          {provider.FullName}
        </Link>
      ),
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalsPage.TableProfessionalCodeColumnTitle"
        >
          Professional Code
        </Trans>
      ),
      dataIndex: "PublicProviderID",
      key: "PublicProviderID",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ProfessionalsPage.TableProfessionalIsEnabledColumnTitle"
        >
          Is enabled
        </Trans>
      ),
      key: "IsEnabled",
      render: (provider: Provider) => (
        <YesNoCell value={provider.IsEnabled} showIcon={true} />
      )
    },
  ];

  const onCreateProvider = () => {
    dispatch(showProviderCreateModal());
  };

  const onCreate = (provider: Provider) => {
    createProvider(provider).then((result) => {
      if (result) history.push("/professional/" + result.data.Data);
    });
  };

  const searchProviders = debounce((value: string) => {
    if (value === null || value === "" || value === undefined) return false;
    loadProviders(value);
  }, 500);

  return (
    <Container>
      <Typography.Title level={2}>
        <Trans id="ProfessionalsPage.Title">Professionals</Trans>
      </Typography.Title>
      <Table
        dataSource={providersState.providers}
        columns={columns}
        loading={providersState.loading}
        rowKey="ProviderID"
        pagination={
          providersState.providers.length > pageSize && {
            pageSize,
            position: "bottom",
          }
        }
        title={() => (
          <Row type="flex" align="middle">
            <Col span={6}>
              <Input
                onChange={(e) => searchProviders(e.target.value)}
                placeholder={i18n._(
                  t(
                    "ProfessionalsPage.FilterProfessionalsPlaceholder"
                  )`Search profesionals`
                )}
              />
            </Col>
            <Col span={18}>
              <Row type="flex" justify="end" gutter={8}>
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    onClick={onCreateProvider}
                  >
                    <Trans id="ProfessionalsPage.NewProfessionalsButton">
                      New profesional
                    </Trans>
                  </Button>
                </Col>
                {directoryState.selectorDirectory?.CanImportProfiles && <Col>
                  <Link to="/import-professionals">
                    <Button type="default" size="large">
                      <Trans id="ProfessionalsPage.ImportProfessionalsButton">
                        Import
                      </Trans>
                    </Button>
                  </Link>
                </Col>}
              </Row>
            </Col>
          </Row>
        )}
      />

      {providersState.showModal && (
        <ProviderModal
          loading={providersState.loading}
          showModal={providersState.showModal}
          modalType={providersState.modalType}
          provider={providersState.provider}
          onCreate={onCreate}
        />
      )}
    </Container>
  );
};

const mapState = (state: any): State => ({
  directoryState: state.directoryState,
  providersState: state.providerState,
});

interface State {
  directoryState: DirectoryState;
  providersState: ProviderState;
}
