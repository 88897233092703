import React from "react";
import "./UserPasswordModal.css";
import { Form, Input, Modal, Typography } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";
import { UserPasswordModalProps } from "./Types";
import { useDispatch } from "react-redux";
import { hideUserPasswordModal, User } from "../../../store/user";

const UserPasswordModal: React.FC<UserPasswordModalProps> = (props) => {
  const dispatch = useDispatch();

  const { form, loading, onPasswordChange, showModal, user } = props;
  const { getFieldDecorator, validateFields } = form;
  const nameof = nameofFactory<User>();

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const passwordRequest = {
        ...values,
        UserID: user?.UserID,
      };

      onPasswordChange(passwordRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideUserPasswordModal());
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="user-modal"
        visible={showModal}
        onCancel={onModalDismiss}
        destroyOnClose
        title={
          <Trans id="Users.EditUserPasswordFormTitle">Edit password</Trans>
        }
        okText={
          <Trans render="span" id="Users.EditUserPasswordFormOkButton">
            Save
          </Trans>
        }
        onOk={handleSubmit}
        okButtonProps={{ loading: loading }}
      >
        <Form colon={false}>
          <Form.Item
            label={<Trans id="Users.Form.EditPasswordLabel">Password</Trans>}
          >
            {getFieldDecorator(nameof("Password"), {
              initialValue: user?.Password,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Users.Form.EditPasswordRequiredError">
                      This field is required
                    </Trans>
                  ),
                },
              ],
            })(<Input.Password maxLength={30} />)}
          </Form.Item>

          <Form.Item
            label={
              <Trans id="Users.Form.EditPasswordConfirmationLabel">
                Password Confirmation
              </Trans>
            }
          >
            {getFieldDecorator(nameof("PasswordConfirmation"), {
              initialValue: user?.PasswordConfirmation,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Users.Form.EditPasswordConfirmationRequiredError">
                      This field is required
                    </Trans>
                  ),
                },
              ],
            })(<Input.Password maxLength={30} />)}
          </Form.Item>
          <span>
            <h4>
              <Typography.Text strong>
                <Trans render="h4" id="Users.Form.PasswordRequirements">
                  Password requirements
                </Trans>
              </Typography.Text>
            </h4>
            <ul className="margin-top-30">
              <li>
                <Trans id="Users.Form.ListMinimunCaracters">
                  The password must have a minimum length of 10 characters
                </Trans>
              </li>
              <li>
                <Trans id="Users.Form.ListCapLetter">
                  Password must contain at least 1 capital letter
                </Trans>
              </li>
              <li>
                <Trans id="Users.Form.ListLowercase">
                  Password must contain at least 1 lowercase
                </Trans>
              </li>
              <li>
                <Trans id="Users.Form.ListSpecialChars">
                  Password must contain at least 1 special character
                </Trans>
              </li>
              <li>
                <Trans id="Users.Form.MaximunPasswordLengthLabel">
                  The password must have a Maximum length of 30 characters
                </Trans>
              </li>
            </ul>
          </span>
        </Form>
      </Modal>
    </React.Fragment>
  );
};
export default Form.create<UserPasswordModalProps>()(UserPasswordModal);
