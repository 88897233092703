import { ClinicState, Clinic } from "./Types";
import {
  LOADING_CLINICS,
  SET_CLINICS,
  CHANGING_CLINIC,
  CHANGING_CLINIC_FINISHED,
  SHOW_CLINIC_MODAL_FOR_CREATE,
  SHOW_CLINIC_MODAL_FOR_EDIT,
  HIDE_CLINIC_MODAL,
  SET_CLINIC_LOGO,
  DELETE_CLINIC_FINISHED,
  UPDATE_CLINICS,
  SET_CLINIC,
  FILTER_CLINICS,
  SET_CLINIC_BANNER,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: ClinicState = {
  filteredClinics: [],
  changing: false,
  clinics: [],
  count: 0,
  loading: false,
  modalType: "create",
  showModal: false,
  updateClinics: false,
};

export const clinicReducer = (
  state = initialState,
  action: any
): ClinicState => {
  switch (action.type) {
    case CHANGING_CLINIC:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_CLINIC_FINISHED:
      return {
        ...state,
        changing: false,
        showModal: !action.result,
        updateClinics: action.result
          ? !state.updateClinics
          : state.updateClinics,
      };

    case DELETE_CLINIC_FINISHED:
      return {
        ...state,
        changing: false,
        updateClinics: action.result
          ? !state.updateClinics
          : state.updateClinics,
      };

    case HIDE_CLINIC_MODAL:
      return {
        ...state,
        clinic: undefined,
        showModal: false,
      };

    case LOADING_CLINICS:
      return {
        ...state,
        count: 0,
        loading: true,
      };

    case SHOW_CLINIC_MODAL_FOR_CREATE:
      return {
        ...state,
        clinic: {
          About: "",
          ClinicID: 0,
          DirectoryTenantID: "",
          Name: "",
          UrlSlug: "",
          URLLogo: "",
          Providers: [],
          Lattitude: 0,
          Longitude: 0,
          Address: "",
          Phone: "",
          ShowInPatientProfile: false,
          Specialties: [],
          BannerURL: "",
          IsDeleted: false
        },
        modalType: "create",
        showModal: true,
      };

    case SHOW_CLINIC_MODAL_FOR_EDIT:
      return {
        ...state,
        clinic: action.clinic,
        modalType: "edit",
        showModal: true,
      };

    case SET_CLINIC_LOGO:
      return {
        ...state,
        clinic: {
          ...(state.clinic as Clinic),
          URLLogo: action.URLLogo,
        },
      };

    case SET_CLINIC_BANNER:
      return {
        ...state,
        clinic: {
          ...(state.clinic as Clinic),
          BannerURL: action.urlBanner,
        },
      };

    case SET_CLINIC:
      return {
        ...state,
        changing: false,
        clinic: action.clinic,
      };

    case SET_CLINICS:
      return {
        ...state,
        clinics: action.clinics,
        filteredClinics: action.clinics,
        count: action.clinics.length,
        loading: false,
      };

    case CHANGE_DIRECTORY:
    case UPDATE_CLINICS:
      return {
        ...state,
        clinic: undefined,
        clinics: [],
        updateClinics: !state.updateClinics,
      };
    case FILTER_CLINICS:
      return {
        ...state,
        filteredClinics: filterClinics(action.value, state.clinics),
      };

    default:
      return state;
  }
};

const filterClinics = (value: string, clinics: Clinic[]) => {
  if (value === "") return clinics;

  return clinics.filter((clinic) => isFilterMatch(value, clinic));
};

const isFilterMatch = (value: string, clinic: Clinic) => {
  return clinic.Name.toLowerCase().includes(value.toLowerCase());
};
