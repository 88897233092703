import React from "react";
import { Form, Modal, Row, Col, Button, Input, DatePicker, Rate } from "antd";
import { Trans } from "@lingui/macro";
import { ReviewModalProps } from "./Types";
import { useDispatch } from "react-redux";
import { Review, hideReviewModal } from "../../../store/review";
import moment from "moment";
import { nameofFactory } from "../../../utils";

const { TextArea } = Input;
const dateFormat = "DD/MM/YYYYY";

const FormStateless: React.FC<ReviewModalProps> = (props) => {
  const { loading, onApproved, onDeleted, showModal, review, form } = props;
  const dispatch = useDispatch();
  const {
    ProfessionalName,
    RatedBy,
    Date,
    Comments,
    Status,
    AccountAppointmentRatingReviews,
    ProviderReply,
  } = review!;
  const nameof = nameofFactory<Review>();

  const { getFieldDecorator, validateFields } = form;

  const handleApproved = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }
      const { ProviderReply } = values;

      onApproved(review?.AccountAppointmentReviewID!, ProviderReply);
    });
  };

  const handleDelete = () => {
    onDeleted(review?.AccountAppointmentReviewID!);
  };

  const onModalDismiss = () => {
    dispatch(hideReviewModal());
  };

  const feedbackText = (type: number) => {
    switch (type) {
      case 1:
        return (
          <Trans id="Review.Form.ProfessionalsAttention">
            Professional Attention
          </Trans>
        );
      case 2:
        return (
          <Trans id="Review.Form.QualityAndCleanlinessOfTheInstallations">
            Quality and cleanliness of the installations
          </Trans>
        );
      case 3:
        return (
          <Trans id="Review.Form.AttentionOfAdministrativeStaff">
            Attention Of Administrative Staff
          </Trans>
        );
      case 4:
        return (
          <Trans id="Review.Form.EaseOfObtainingAppointment">
            Ease of obtaining an appointment
          </Trans>
        );
    }
    return "Feedback type not found";
  };

  return (
    <React.Fragment>
      <Modal
        centered
        destroyOnClose
        visible={showModal}
        okButtonProps={{ loading: loading }}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              {review?.CanEdit === true && (
                <Button loading={loading} onClick={handleDelete} type="danger">
                  <Trans render="span" id="Review.ArchivedReviewFormOkButton">
                    Archived
                  </Trans>
                </Button>
              )}
              {review?.CanEdit === true && (
              <Button loading={loading} onClick={handleApproved} type="primary">
                <Trans render="span" id="Review.VerifyReviewFormOkButton">
                  Approved
                </Trans>
              </Button>
              )}
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="Review.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={<Trans id="Review.EditReviewFormTitle">Edit review</Trans>}
      >
        <Form colon={false}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label={
                  <Trans id="Review.Form.ProfessionalLabel">Professional</Trans>
                }
              >
                <Input defaultValue={ProfessionalName} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<Trans id="Review.Form.RatedByLabel">Rated by</Trans>}
              >
                <Input defaultValue={RatedBy} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label={<Trans id="Review.Form.DateLabel">Date</Trans>}>
                <DatePicker
                  defaultValue={moment(Date, dateFormat)}
                  format={dateFormat}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<Trans id="Review.Form.StatusLabel">Status</Trans>}
              >
                <Input defaultValue={Status} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            {AccountAppointmentRatingReviews?.map((item) => (
              <Col span={24} key={item.RatingReviewID}>
                <Form.Item label={feedbackText(item.RatingReviewID)}>
                  <Rate disabled defaultValue={item.Rating} />
                </Form.Item>
              </Col>
            ))}
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={<Trans id="Review.Form.CommentsLabel">Comments</Trans>}
              >
                <TextArea rows={5} defaultValue={Comments} disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={
                  <Trans id="Review.Form.ProviderReplyLabel">
                    Provider reply
                  </Trans>
                }
              >
                {getFieldDecorator(nameof("ProviderReply"), {
                  initialValue: ProviderReply,                  
                })(<TextArea rows={5} disabled={review?.CanEdit===false} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Form.create<ReviewModalProps>()(FormStateless);
