import { State, County, City, Location } from "./Types";

export const CHANGE_LOCATION_FIELD = "CHANGE_LOCATION_FIELD";
export const EDIT_LOCATION = "EDIT_LOCATION";
export const LOADING_CITIES = "LOADING_CITIES";
export const SET_CITIES = "SET_CITIES";
export const LOADING_COUNTIES = "LOADING_COUNTIES";
export const SET_COUNTIES = "SET_COUNTIES";
export const LOADING_STATES = "LOADING_STATES";
export const SET_STATES = "SET_STATES";
export const STOP_EDIT = "STOP_EDIT";
export const UPDATING_LOCATION = "UPDATING_LOCATION";
export const UPDATED_LOCATION = "UPDATED_LOCATION";
export const HIDE_NEW_LOCATION_MODAL = "HIDE_NEW_LOCATION_MODAL";
export const SHOW_NEW_LOCATION_CREATE_MODAL = "SHOW_NEW_LOCATION_CREATE_MODAL";
export const HIDE_EDIT_LOCATION_MODAL = "HIDE_EDIT_LOCATION_MODAL";
export const SHOW_EDIT_LOCATION_MODAL = "SHOW_EDIT_LOCATION_MODAL";
export const CHANGING_LOCATION_FINISHED = "CHANGING_LOCATION_FINISHED";
export const CHANGING_LOCATION = "CHANGING_LOCATION";


export const changeLocationField = (name: string, value: any) => ({
  type: CHANGE_LOCATION_FIELD,
  name,
  value,
});

export const editLocation = (location: Location) => ({
  type: EDIT_LOCATION,
  location,
});

export const loadingCities = () => ({ type: LOADING_CITIES });

export const setCities = (cities: City[]) => ({
  type: SET_CITIES,
  cities,
});

export const loadingCounties = () => ({ type: LOADING_COUNTIES });

export const setCounties = (counties: County[]) => ({
  type: SET_COUNTIES,
  counties,
});

export const loadingStates = () => ({ type: LOADING_STATES });

export const setStates = (states: State[]) => ({ type: SET_STATES, states });

export const stopEdit = (locationId: number) => ({
  type: STOP_EDIT,
  locationId,
});

export const updatingLocation = () => ({
  type: UPDATING_LOCATION,
});

export const updatedLocation = (result: boolean) => ({
  type: UPDATED_LOCATION,
  result,
});

export const changingLocation= () => ({ type: CHANGING_LOCATION });

export const changingLocationFinished = (result: boolean) => ({
  type: CHANGING_LOCATION_FINISHED,
  result,
});

export const hideNewLocationModal = () => ({ type: HIDE_NEW_LOCATION_MODAL });

export const showNewLocationCreateModal = () => ({
  type: SHOW_NEW_LOCATION_CREATE_MODAL,
});

export const hideEditLocationModal = () => ({ type: HIDE_EDIT_LOCATION_MODAL });

export const showEditLocationCreateModal = () => ({
  type: SHOW_EDIT_LOCATION_MODAL,
});

