import { createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { authReducer } from "./auth/Reducer";
import { localeReducer } from "./locale/Reducer";
import { directoryReducer } from "./directory/Reducer";
import { searchReducer } from "./search/Reducer";
import { locationReducer } from "./location/Reducer";
import { specialtyReducer } from "./specialty/Reducer";
import { clinicReducer } from "./clinic/Reducer";
import { providerReducer } from "./provider/Reducer";
import { calendarReducer } from "./calendar/Reducer";
import { appointmentReducer } from "./apointment/Reducer";
import { accountReducer } from "./account/Reducer";
import { userReducer } from "./user/Reducer";
import { patientReducer } from "./patient/Reducer";
import { advancedSearchReducer } from "./advanced-search/Reducer";
import { importProviderReducer } from "./import-provider/Reducer";
import { procedureReducer } from "./procedure/Reducer";
import { languageReducer } from "./language/Reducer";
import { keywordReducer } from "./keyword/Reducer";
import { providerFocusReducer } from "./providerFocus/Reducer";
import { customProfileReducer } from "./customProfile/Reducer";
import { providerEducationCourseReducer } from "./providerEducationCourse/Reducer";
import { providerEducationDegreeReducer } from "./providerEducationDegree/Reducer";
import { userPasswordResetReducer } from "./passwordReset/Reducer";
import { insuranceReducer } from "./insurance/Reducer";
import { providerAppointmentReasonReducer } from "./providerAppointmentReason/Reducer";
import { paymentMethodReducer } from "./paymentMethod/Reducer";
import { reviewReducer } from "./review/Reducer";

const AppStore = createStore(
  combineReducers({
    accountState: accountReducer,
    advancedSearchState: advancedSearchReducer,
    appointmentState: appointmentReducer,
    authState: authReducer,
    calendarState: calendarReducer,
    clinicState: clinicReducer,
    importProviderState: importProviderReducer,
    directoryState: directoryReducer,
    keywordState: keywordReducer,
    languageState: languageReducer,
    localeState: localeReducer,
    locationState: locationReducer,
    patientState: patientReducer,
    procedureState: procedureReducer,
    providerState: providerReducer,
    searchState: searchReducer,
    specialtyState: specialtyReducer,
    userState: userReducer,
    providerFocusState: providerFocusReducer,
    providerEducationCourseState: providerEducationCourseReducer,
    providerEducationDegreeState: providerEducationDegreeReducer,
    customProfileState: customProfileReducer,
    UserPasswordResetState: userPasswordResetReducer,
    insuranceState: insuranceReducer,
    providerAppointmentReasonState: providerAppointmentReasonReducer,
    paymentMethodState: paymentMethodReducer,
    reviewState: reviewReducer
  }),
  process.env.NODE_ENV === "development" ? composeWithDevTools() : undefined
);

export default AppStore;
