import { Directory } from "./Types";

export const CHANGE_DIRECTORY = "CHANGE_DIRECTORY";
export const HIDE_DIRECTORY_MODAL = "HIDE_DIRECTORY_MODAL";
export const LOADING_DIRECTORY = "LOADING_DIRECTORY";
export const LOADED_DIRECTORY = "LOADED_DIRECTORY";
export const LOADING_DIRECTORIES = "LOADING_DIRECTORIES";
export const LOADING_DIRECTORIES_SUCCEDED = "LOADING_DIRECTORIES_SUCCEDED";
export const LOADING_DIRECTORIES_FAILED = "LOADING_DIRECTORIES_FAILED";
export const LOAD_DIRECTORY_FROM_LOCALSTORAGE =
  "LOAD_DIRECTORY_FROM_LOCALSTORAGE";
export const OPEN_DIRECTORY_MODAL = "OPEN_DIRECTORY_MODAL";
export const SET_DIRECTORY = "SET_DIRECTORY";

export const changeDirectory = (directoryTenantId: string) => ({
  type: CHANGE_DIRECTORY,
  directoryTenantId
});

export const hideDirectoryModal = () => ({
  type: HIDE_DIRECTORY_MODAL
});

export const loadingDirectories = () => ({ type: LOADING_DIRECTORIES });

export const loadingDirectory = () => ({ type: LOADING_DIRECTORY });

export const loadedDirectory = (result: boolean) => ({
  type: LOADED_DIRECTORY,
  result
});

export const loadingDirectoriesSucceded = (directories: Directory[]) => ({
  type: LOADING_DIRECTORIES_SUCCEDED,
  directories
});

export const loadingDirectoriesFailed = () => ({
  type: LOADING_DIRECTORIES_FAILED
});

export const loadDirectoryFromLocalStorage = () => ({
  type: LOAD_DIRECTORY_FROM_LOCALSTORAGE
});

export const openDirectoryModal = (
  modalType: "create" | "edit",
  directoryId?: string
) => ({
  type: OPEN_DIRECTORY_MODAL,
  modalType,
  directoryId
});

export const setDirectory = (directory: Directory | undefined) => ({
  type: SET_DIRECTORY,
  directory
});
