import React, { useState } from "react";
import { Container } from "../../components/utils";
import {
  Typography,
  Form,
  Row,
  Input,
  Button,
  Divider,
  Table,
  Col,
  Radio,
  message,
} from "antd";
import { Trans, t } from "@lingui/macro";
import {
  ImportProviderState,
  Doctor,
  isHuliSelected,
  isZHSelected,
  isCnlpSelected,
} from "../../store/import-provider";
import { useSelector, useDispatch } from "react-redux";
import {
  searchProvidersToImport,
  importProvider,
  searchZHProvidersToImport,
  importzhProvider,
  searchCnlpProvidersToImport,
  importCnlpProvider
} from "../../services/import-professional";
import { i18n } from "../../utils";

export const ImportProfessionalsPage: React.FC = () => {
  const pageSize = 50;
  const [apiKey, setApiKey] = useState("");
  const [facility, setFacility] = useState("");
  const importProviderState = useSelector(mapState);
  const { organization } = importProviderState;
  const dispatch = useDispatch();

  const columns = [
    {
      title: (
        <Trans
          render="strong"
          id="ImportProfessionalsPage.TableNameColumnTitle"
        >
          Name
        </Trans>
      ),
      key: "name",
      render: (doctor: Doctor) => (
        <span>{`${doctor.FirstName} ${doctor.LastName}`}</span>
      ),
    },
    {
      title: (
        <Trans
          render="strong"
          id="ImportProfessionalsPage.TableClinicColumnLicenseNumber"
        >
          License
        </Trans>
      ),
      key: "LicenseNumber",
      dataIndex: "LicenseNumber",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ImportProfessionalsPage.TableClinicColumnTitle"
        >
          Clinic
        </Trans>
      ),
      key: "Clinic",
      dataIndex: "Clinic",
    },
    {
      title: (
        <Trans
          render="strong"
          id="ImportProfessionalsPage.TableActionsColumnTitle"
        >
          Actions
        </Trans>
      ),
      render: (doctor: Doctor) => (
        <div>
          {importProviderState.isZHSelected && (
            <Button
              loading={doctor.Loading}
              onClick={() => onImportZH(doctor.Id)}
            >
              <Trans render="span" id="ImportProfessionalsPage.ImporZHtButton">
                Import
              </Trans>
            </Button>
          )}

          {importProviderState.isCnlpSelected && (
            <Button
              loading={doctor.Loading}
              onClick={() => onImportCnlp(doctor.Id)}
            >
              <Trans render="span" id="ImportProfessionalsPage.ImporCnlptButton">
                Import
              </Trans>
            </Button>
          )}

          {importProviderState.isHuliSelected && (
            <Button
              loading={doctor.Loading}
              onClick={() => onImport(doctor.Id, doctor.ClinicId, facility)}
            >
              <Trans render="span" id="ImportProfessionalsPage.ImportButton">
                Import
              </Trans>
            </Button>
          )}
          
        </div>
      ),
    },
  ];

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(e.target.value);
  };

  const onImport = (doctorId: string, clinicId: string, facility: string) => {
    const request = {
      ClinicId: clinicId,
      DoctorId: doctorId,
      OrganizationId: organization.Id,
      Api_Key: apiKey,
    };

    importProvider(request);
  };

  const onImportZH = (doctorId: string) => {
    const request = {
      DoctorId: doctorId,
      Facility: facility,
    };

    importzhProvider(request);
  };
  const onImportCnlp = (doctorId: string) => {
    const request = {
      Id: doctorId
    };

    importCnlpProvider(request);
  };
  

  const searchDoctors = () => {
    if (apiKey === "") {
      message.error(
        i18n._(t("ImportProfessionalsPage.ApiKey")`The Api key is required`)
      );
    } else {
      searchProvidersToImport(apiKey);
    }
  };

  const searchZHDoctors = () => {
    if (facility === "") {
      message.error(
        i18n._(t("ImportProfessionalsPage.facility")`The facility is required`)
      );
    } else {
      searchZHProvidersToImport(facility);
    }
  };

  const searchCnlpDoctors = () => {    
      searchCnlpProvidersToImport();    
  };

  const onRadioChange = (e: any) => {
    switch (e.target.value) {
      case "H":
        dispatch(isHuliSelected());
        break;
      
      case "ZH":
          dispatch(isZHSelected());
          break;

      case "Cnlp":
            dispatch(isCnlpSelected());
            break;          
      default:
        break;
    }
  };

  const onZHImputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFacility(e.target.value);
  };

  return (
    <Container>
      <Typography.Title level={2}>
        <Trans id="ImportProfessionalsPage.Title">Import professionals</Trans>
      </Typography.Title>

      <Row>
        <Col>
          <Form layout="inline">
            <Form.Item>
              <Radio.Group onChange={onRadioChange}>
              <Radio key={1} value="ZH">
                  <Trans id="ImportProfessionalsPage.ZHLabel">ZH</Trans>
                </Radio>
                <Radio key={2} value="Cnlp">
                  CNLP
                </Radio>                
                <Radio key={3} value="H">
                  <Trans id="ImportProfessionalsPage.HuliLabel">Huli</Trans>
                </Radio>                
              </Radio.Group>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      {importProviderState.isHuliSelected && (
        <Row>
          <Col>
            <Form layout="inline">
              <Form.Item
                label={
                  <Trans id="ImportProfessionalsPage.ApiKeyLabel">
                    Api key
                  </Trans>
                }
              >
                <Input onChange={onChange} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={searchDoctors}
                  loading={importProviderState.loading}
                >
                  <Trans
                    render="span"
                    id="ImportProfessionalsPage.SearchButton"
                  >
                    Search
                  </Trans>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      {importProviderState.isZHSelected && (
        <Row>
          <Col>
            <Form layout="inline">
              <Form.Item
                label={
                  <Trans id="ImportProfessionalsPage.FacilityLabel">
                    Facility
                  </Trans>
                }
              >
                <Input onChange={onZHImputChange} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={searchZHDoctors}
                  loading={importProviderState.loading}
                >
                  <Trans
                    render="span"
                    id="ImportProfessionalsPage.SearchByFacilityButton"
                  >
                    Search
                  </Trans>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      {importProviderState.isCnlpSelected && (
        <Row>
          <Col>
            <Form layout="inline">              
              <Form.Item>
                <Button
                  type="primary"
                  onClick={searchCnlpDoctors}
                  loading={importProviderState.loading}
                >
                  <Trans
                    render="span"
                    id="ImportProfessionalsPage.SearchByFacilityButton"
                  >
                    Search
                  </Trans>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <span className="errorMessage">
            {importProviderState.errorMessage}
          </span>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" justify="center">
        <Typography.Title level={4}>{organization.Name}</Typography.Title>
      </Row>
      {(importProviderState.isHuliSelected === true ||
        importProviderState.isZHSelected === true ||
        importProviderState.isCnlpSelected === true) && (
        <Table
          dataSource={organization.Doctors}
          columns={columns}
          loading={importProviderState.loading}
          rowKey="Id"
          pagination={
            organization.Doctors.length > pageSize && {
              pageSize,
              position: "bottom",
            }
          }
        />
      )}
    </Container>
  );
};

const mapState = (state: any): ImportProviderState => state.importProviderState;
