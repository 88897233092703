import {
  AdvancedSearchParams,
  AdvancedSearchProvider,
  AdvancedSearchDate
} from "./Types";

export const BEGIN_ADVANCED_SEARCH = "BEGIN_ADVANCED_SEARCH";
export const CHANGE_ADVANCED_SEARCH_TYPE = "CHANGE_ADVANCED_SEARCH_TYPE";
export const COMPLETE_ADVANCED_DATE_SEARCH = "COMPLETE_ADVANCED_DATE_SEARCH";
export const COMPLETE_ADVANCED_SPECIALTY_SEARCH =
  "COMPLETE_ADVANCED_SPECIALTY_SEARCH";

export const beginAdvancedSearch = (params: AdvancedSearchParams) => ({
  type: BEGIN_ADVANCED_SEARCH,
  params
});

export const changeAdvancedSearchType = (searchType: "sepcialty" | "date") => ({
  type: CHANGE_ADVANCED_SEARCH_TYPE,
  searchType
});

export const completeAdvancedDateSearch = (dates: AdvancedSearchDate[]) => ({
  type: COMPLETE_ADVANCED_DATE_SEARCH,
  dates
});

export const completeAdvancedSpecialtySearch = (
  providers: AdvancedSearchProvider[]
) => ({
  type: COMPLETE_ADVANCED_SPECIALTY_SEARCH,
  providers
});
