import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingInsurances,
  Insurance,
  setInsurance,
  changingInsurance,
  changingInsuranceFinished,
  deleteInsuranceFinished,
  setInsurances,
} from "../../store/insurance";
import { getDirectoryTenantId, i18n } from "../../utils";
import { notification } from "antd";
import { t } from "@lingui/macro";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadInsurancesFromAdmin = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`insurance/directory/${getDirectoryTenantId()}`;

  try {
    store.dispatch(loadingInsurances());
    const response = await httpClient.get<GenericApiResponse<Insurance[]>>(url);
    store.dispatch(setInsurances(response.data.Data));
    return true;
  } catch {
    store.dispatch(setInsurances([]));
    return false;
  }
};

export const loadActiveInsurancesFromAdmin = async () => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `insurance/directory/${getDirectoryTenantId()}`;

  try {
    store.dispatch(loadingInsurances());
    const response = await httpClient.get<GenericApiResponse<Insurance[]>>(url);
    var activeInsurences: Insurance[] = [];
    response.data.Data.forEach((temporalInsurance)=>{
      if(temporalInsurance.IsActive)
        activeInsurences.push(temporalInsurance);
    });
    store.dispatch(setInsurances(activeInsurences));
    return true;
  } catch {
    store.dispatch(setInsurances([]));
    return false;
  }
};

export const loadInsurance = async (insuranceId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`insurance/${insuranceId}`;

  try {
    store.dispatch(changingInsurance());
    const response = await httpClient.get<GenericApiResponse<Insurance>>(url);
    store.dispatch(setInsurance(response.data.Data));
    return true;
  } catch {
    store.dispatch(setInsurance(undefined));
    return false;
  }
};

export const createInsurance = async (request: Insurance) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "insurance";

  try {
    store.dispatch(changingInsurance());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingInsuranceFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingInsuranceFinished(false));
    return false;
  }
};

export const updateInsurance = async (request: Insurance) => {
  const { InsuranceID, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) +`insurance/${InsuranceID}`;

  try {
    store.dispatch(changingInsurance());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    store.dispatch(changingInsuranceFinished(true));
    return true;
  } catch (message) {
    store.dispatch(changingInsuranceFinished(false));
    return false;
  }
};

export const deleteInsurance = async (insuranceID: number) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `insurance/${insuranceID}`;

  try {
    store.dispatch(changingInsurance());
    await httpClient.delete(url);
    store.dispatch(deleteInsuranceFinished(true));
    return true;
  } catch (message) {
    notification.error({
      message: i18n._(
        t("InsuranceService.CouldNotDeleteInsuranceErrorTitle")`Insurance not deleted`
      ),
      description: i18n._(
        t(
          "InsuranceService.CouldNotDeleteInsuranceErrorDescription"
        )`The insurance could not be deleted.`
      ),
    });
    store.dispatch(deleteInsuranceFinished(false));
    return false;
  }
};
