import React from "react";
import {
    Form,
    Input,
    Modal,
    Row,
    Col,
    Button,
    InputNumber,
    Skeleton,
    Checkbox
} from "antd";

import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../../utils";

import {
    hideProviderAppointmentReasonModal,
    ProviderAppointmentReasonState,
    ProviderAppointmentReason,
    AppointmentsReasonLocations,
} from "../../../store/providerAppointmentReason";

import { ProviderAppointmentReasonModalProps } from "./Types";
import { useSelector, useDispatch } from "react-redux";

const FormStateless: React.FC<ProviderAppointmentReasonModalProps> = (props) => {
    const { form, onCreate, showModal } = props;
    const { providerAppointmentReasonState } = useSelector(mapState);
    const { getFieldDecorator, validateFields } = form;
    const dispatch = useDispatch();
    const ProviderAppointmentReasonNameOf = nameofFactory<
        ProviderAppointmentReason
    >();

    const locations = providerAppointmentReasonState.providerAppointmentReason?.ProviderAppointmentReasonID ? providerAppointmentReasonState.providerAppointmentReason?.Locations
        : providerAppointmentReasonState.newProviderAppointmentReason?.Locations;

    const locationsList = locations?.map(item => {
        return { label: item.AgendaName, value: `${item.LocationID}-${item.AgendaID}` }
    }) || [];

    let defaultValues;

    if (providerAppointmentReasonState.modalType === "edit") {
        defaultValues = providerAppointmentReasonState.providerAppointmentReason?.LocationsSelected?.map(item => {
            return `${item.LocationID}-${item.AgendaID}`
        });


    }

    const onModalDismiss = () => {
        dispatch(hideProviderAppointmentReasonModal());
    };

    const onSubmit = () => {
        validateFields((err, values) => {
            if (err) {
                return;
            }

            const request = {
                ...values,
            };
            const id = providerAppointmentReasonState.providerAppointmentReason?.ProviderAppointmentReasonID;

            if (request.LocationsSelected) {
                request.LocationsSelected = [...locations?.filter(x => request.LocationsSelected.includes(`${x.LocationID}-${x.AgendaID}`)).map(item => {
                    return { LocationID: item.LocationID, AgendaID: item.AgendaID } as AppointmentsReasonLocations;
                }) || []];
            } else {
                request.LocationsSelected = [];
            }


            request.ProviderAppointmentReasonID = id ? id : 0;
            onCreate(request);
        });
    };



    return (

        <React.Fragment>
            <Modal
                centered
                className="provider-modal"
                destroyOnClose
                visible={showModal}
                footer={
                    <Row type="flex" justify="end" gutter={16}>
                        <Col>
                            <Button
                                loading={
                                    providerAppointmentReasonState.updatingProviderAppointmentReason
                                }
                                onClick={onSubmit}
                                type="primary"
                            >
                                {providerAppointmentReasonState.providerAppointmentReason?.ProviderAppointmentReasonID ?

                                    <Trans
                                        render="span"
                                        id="ProviderAppointmentReason.UpdateButton" >
                                        Update
                                    </Trans>
                                    :
                                    <Trans
                                        render="span"
                                        id="ProviderAppointmentReason.CreateButton"
                                    >
                                        Create
                                    </Trans>
                                }

                            </Button>
                        </Col>
                        <Col>
                            <Button
                                disabled={
                                    providerAppointmentReasonState.updatingProviderAppointmentReason
                                }
                                onClick={onModalDismiss}
                            >
                                <Trans
                                    render="span"
                                    id="ProviderAppointmentReason.CancelButton"
                                >
                                    Cancel
                                </Trans>
                            </Button>
                        </Col>
                    </Row>
                }
                onCancel={onModalDismiss}
                title={providerAppointmentReasonState.providerAppointmentReason?.ProviderAppointmentReasonID ?
                    <Trans id="ProviderAppointmentReason.UpdateFormTitle">
                        Update appointment reason
                    </Trans>
                    :
                    <Trans id="ProviderAppointmentReason.CreateFormTitle">
                        Create appointment reason
                    </Trans>

                }
            >
                <Skeleton
                    loading={providerAppointmentReasonState.updatingProviderAppointmentReason}
                >
                    <Form colon={false}>
                        <Row type="flex" gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Trans id="ProviderAppointmentReason.Name">
                                            Reason
                                        </Trans>
                                    }
                                >
                                    {getFieldDecorator(
                                        ProviderAppointmentReasonNameOf(
                                            "Name"
                                        ),
                                        {
                                            initialValue: providerAppointmentReasonState.providerAppointmentReason?.Name || "",
                                            rules: [
                                                {
                                                    required: true,
                                                    message: <Trans id="Common.RequiredFieldError" />,
                                                },
                                            ],
                                        }
                                    )(
                                        <Input
                                            name={ProviderAppointmentReasonNameOf(
                                                "Name"
                                            )}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Trans id="ProviderAppointmentReason.DurationMinutes">
                                            Duration minutes
                                        </Trans>
                                    }
                                >
                                    {getFieldDecorator(
                                        ProviderAppointmentReasonNameOf(
                                            "DurationMinutes"
                                        ),
                                        {
                                            initialValue: providerAppointmentReasonState?.providerAppointmentReason?.DurationMinutes || 5,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: <Trans id="Common.RequiredFieldError" />,
                                                },
                                            ],
                                        }
                                    )(
                                        <InputNumber min={5} style={{ width: "100%" }}
                                            name={ProviderAppointmentReasonNameOf(
                                                "DurationMinutes"
                                            )}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label={
                                        <Trans id="ProviderAppointmentReason.BasePricing">
                                            Base price
                                        </Trans>
                                    }
                                >
                                    {getFieldDecorator(
                                        ProviderAppointmentReasonNameOf(
                                            "BasePricing"
                                        ),
                                        {
                                            initialValue: providerAppointmentReasonState?.providerAppointmentReason?.BasePricing ?? null,
                                        }
                                    )(
                                        <InputNumber min={0} style={{ width: "100%" }}
                                            name={ProviderAppointmentReasonNameOf(
                                                "BasePricing"
                                            )}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                {locationsList?.length > 0 ?
                                    <Form.Item
                                        label={
                                            <Trans id="ProfessionalPage.LocationsTitle">Agendas</Trans>
                                        }
                                    >
                                        {getFieldDecorator(
                                            ProviderAppointmentReasonNameOf(
                                                "LocationsSelected"
                                            ),
                                            {
                                                initialValue: defaultValues?.map(opt => opt),
                                            }
                                        )(
                                            <Checkbox.Group style={{ display: "grid" }} options={locationsList} />
                                        )}
                                    </Form.Item> : null
                                }





                            </Col>
                        </Row>

                    </Form>
                </Skeleton>
            </Modal>
        </React.Fragment >
    );
};

const mapState = (state: any): State => ({
    providerAppointmentReasonState: state.providerAppointmentReasonState,
});

interface State {
    providerAppointmentReasonState: ProviderAppointmentReasonState;
}

export default Form.create<ProviderAppointmentReasonModalProps>()(FormStateless);
