import { changingReview, changingReviewFinished, loadingReviews, setReviews } from './../../store/review/Actions';
import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import { Review } from '../../store/review/Types';
import { SearchReviewParams } from '../../components/reviews/header/Types';
import { notification } from 'antd';
import { i18n } from '../../utils';
import { t } from '@lingui/macro';
import { LocalStorageKeys } from '../../utils/Constants';

export const loadReviewsFromAdmin = async (request: SearchReviewParams) => {
  const { status, dates } = request;
  let startDate = null;
  let endDate = null;
  let providerID = request.providerID ?? null;
  if (dates !== undefined) {
    startDate = dates[0].format("YYYY-MM-DD");
    endDate = dates[1].format("YYYY-MM-DD");
  }

  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/pendingReviews?status=${status}&providerID=${providerID}&startDate=${startDate}&endDate=${endDate}`;

  try {
    store.dispatch(loadingReviews());
    const response = await httpClient.get<GenericApiResponse<Review[]>>(url);
    store.dispatch(setReviews(response.data.Data));
    return true;
  } catch {
    store.dispatch(setReviews([]));
    return false;
  }
};

export const approvedReviewFromAdmin = async (id: string, providerReply: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/review/${id}/approve`;

  try {
    store.dispatch(changingReview());
    const response = await httpClient.put<GenericApiResponse<string>>(url, { ProviderReply: providerReply });
    if (!response.data.Error.HasError) {
      store.dispatch(changingReviewFinished(true));
      return true;
    } else {
      store.dispatch(changingReviewFinished(false));
      notification.error({
        message: i18n._(
          t(
            "ReviewService.CouldNotApprovedErrorTitle"
          )`Review not approved`
        ),
        description: response.data.Error.Message
      });
      return false;
    }
  } catch (message) {
    store.dispatch(changingReviewFinished(false));
    return false;
  }
};

export const deleteReviewFromAdmin = async (id: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/review/${id}/deleted`;

  try {
    store.dispatch(changingReview());
    const response = await httpClient.put<GenericApiResponse<string>>(url, null);
    if (!response.data.Error.HasError) {
      store.dispatch(changingReviewFinished(true));
      return true;
    } else {
      store.dispatch(changingReviewFinished(false));
      notification.error({
        message: i18n._(
          t(
            "ReviewService.CouldNotDeletedErrorTitle"
          )`Review not deleted`
        ),
        description: response.data.Error.Message
      });
      return false;
    }
  } catch (message) {
    store.dispatch(changingReviewFinished(false));
    return false;
  }
};


