import React, { useEffect } from "react";
import "./AuthPage.css";
import { AuthLogin } from "../../components/auth";
import { useSelector } from "react-redux";
import { AuthState, LoginForm } from "../../store/auth";
import { login } from "../../services/auth";
import { useHistory } from "react-router-dom";
import { useQuery, isNullOrEmpty } from "../../utils";
import { SearchQueryKeys } from "../../utils/Constants";
import { Layout, Row, Col } from "antd";
import { PoweredBySikuFooter } from "../../components/powered-by-siku-footer";

export const AuthPage: React.FC = () => {
  const authState = useSelector(mapStateToProps);
  const history = useHistory();
  const query = useQuery();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => redirect(), [authState.isAuthenticated]);

  const onLogin = (loginForm: LoginForm) => {
    login(loginForm);
  };

  const redirect = () => {
    if (authState.isAuthenticated) {
      const returnUrl = query.get(SearchQueryKeys.ReturnUrl);

      if (!isNullOrEmpty(returnUrl)) history.push(String(returnUrl));
      else history.push("/");
    }
  };

  return (
    <Layout className="auth-page">
      <Layout.Header>
        <Row type="flex" align="middle" className="logo-container">
          <a href="/">
            <img
              src="/assets/images/SikuWhiteLogoLogin.png"
              alt="Siku logo"
              className="logo"
            />
          </a>
        </Row>
      </Layout.Header>
      <Layout.Content className="content-container">
        <Col span={12} className="login-side">
          <AuthLogin authState={authState} onLogin={onLogin} />
          <Row
            type="flex"
            align="bottom"
            justify="center"
            className="login-side-footer"
          >
            <Layout.Footer>
              <PoweredBySikuFooter />
            </Layout.Footer>
          </Row>
        </Col>
        <Col span={12} className="sider">
          <Row
            type="flex"
            align="middle"
            justify="center"
            style={{ height: "100%" }}
          >
           
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

const mapStateToProps = (state: any): AuthState => state.authState;
