import { CustomProfile } from "./Types";

export const SET_CUSTOM_PROFILE = "SET_CUSTOM_PROFILE";
export const CHANGING_CUSTOM_PROFILE = "CHANGING_CUSTOM_PROFILE";
export const CHANGING_CUSTOM_PROFILE_FINISHED =
  "CHANGING_CUSTOM_PROFILE_FINISHED";
export const DELETE_CUSTOM_PROFILE_FINISHED = "DELETE_CUSTOM_PROFILE_FINISHED";
export const HIDE_CUSTOM_PROFILE_MODAL = "HIDE_CUSTOM_PROFILE_MODAL";
export const LOADING_CUSTOM_PROFILES = "LOADING_CUSTOM_PROFILES";
export const SHOW_CUSTOM_PROFILE_MODAL_FOR_CREATE =
  "SHOW_CUSTOM_PROFILE_MODAL_FOR_CREATE";
export const SHOW_CUSTOM_PROFILE_MODAL_FOR_EDIT =
  "SHOW_CUSTOM_PROFILE_MODAL_FOR_EDIT";
export const SET_CUSTOM_PROFILE_LOGO = "SET_CUSTOM_PROFILE_LOGO";
export const SET_CUSTOM_PROFILES = "SET_CUSTOM_PROFILES";
export const UPDATE_CUSTOM_PROFILES = "UPDATE_CUSTOM_PROFILES";
export const FILTER_CUSTOM_PROFILES = "FILTER_CUSTOM_PROFILES";

export const changingCustomProfile = () => ({ type: CHANGING_CUSTOM_PROFILE });

export const changingCustomProfileFinished = (result: boolean) => ({
  type: CHANGING_CUSTOM_PROFILE_FINISHED,
  result,
});
export const deleteCustomProfileFinished = (result: boolean) => ({
  type: DELETE_CUSTOM_PROFILE_FINISHED,
  result,
});

export const hideCustomProfileModal = () => ({
  type: HIDE_CUSTOM_PROFILE_MODAL,
});

export const loadingCustomProfiles = () => ({ type: LOADING_CUSTOM_PROFILES });

export const setCustomProfile = (customProfile: CustomProfile | undefined) => ({
  type: SET_CUSTOM_PROFILE,
  customProfile,
});

export const setCustomProfileLogo = (URLLogo: string) => ({
  type: SET_CUSTOM_PROFILE_LOGO,
  URLLogo,
});

export const setCustomProfiles = (customProfiles: CustomProfile[]) => ({
  type: SET_CUSTOM_PROFILES,
  customProfiles,
});

export const showCustomProfileModalForCreate = () => ({
  type: SHOW_CUSTOM_PROFILE_MODAL_FOR_CREATE,
});

export const showCustomProfileModalForEdit = (
  customProfile: CustomProfile
) => ({
  type: SHOW_CUSTOM_PROFILE_MODAL_FOR_EDIT,
  customProfile,
});

export const updateCustomProfiles = () => ({
  type: UPDATE_CUSTOM_PROFILES,
});
export const filterCustomProfiles = (value: string) => ({
  type: FILTER_CUSTOM_PROFILES,
  value,
});
