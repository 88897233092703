import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingProcedures,
  Procedure,
  setProcedures,
  changingProcedure,
  changingProcedureFinished
} from "../../store/procedure";
import { getDirectoryTenantId } from "../../utils";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadProceduresFromAdmin = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/procedure/${getDirectoryTenantId()}`;

  try {
    store.dispatch(loadingProcedures());
    const response = await httpClient.get<GenericApiResponse<Procedure[]>>(url);
    store.dispatch(setProcedures(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProcedures([]));
    return false;
  }
};

export const createProcedure = async (request: Procedure) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"admin/procedure";

  try {
    store.dispatch(changingProcedure());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingProcedureFinished(true));
    return true;
  } catch {
    store.dispatch(changingProcedureFinished(false));
    return false;
  }
};

export const deleteProcedure = async (procedureId: number) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/procedure/${procedureId}`;

  try {
    store.dispatch(changingProcedure());
    await httpClient.delete(url);
    store.dispatch(changingProcedureFinished(true));
    return true;
  } catch {
    store.dispatch(changingProcedureFinished(false));
    return false;
  }
};

export const updateProcedure = async (procedure: Procedure) => {
  const { ProcedureID, ...rest } = procedure;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`admin/procedure/${ProcedureID}`;

  try {
    store.dispatch(changingProcedure());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(changingProcedureFinished(true));
    return true;
  } catch {
    store.dispatch(changingProcedureFinished(false));
    return false;
  }
};
