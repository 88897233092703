import { KeywordState, Keyword } from "./Types";
import {
  LOADING_KEYWORDS,
  SET_KEYWORDS,
  UPDATE_KEYWORDS,
  CHANGING_KEYWORD,
  CHANGING_KEYWORD_FINISHED,
  SHOW_KEYWORD_MODAL_FOR_CREATE,
  SHOW_KEYWORD_MODAL_FOR_EDIT,
  HIDE_KEYWORD_MODAL,
  DELETE_KEYWORD_FINISHED,
  SET_KEYWORD,
  FILTER_KEYWORDS,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: KeywordState = {
  keywords: [],
  loading: false,
  updateKeywords: false,
  modalType: "create",
  showModal: false,
  changing: false,
  count: 0,
  filteredKeywords: [],
};

export const keywordReducer = (
  state = initialState,
  action: any
): KeywordState => {
  switch (action.type) {
    case CHANGING_KEYWORD:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_KEYWORD_FINISHED:
      return {
        ...state,
        changing: false,
        keyword: undefined,
        showModal: !action.result,
        updateKeywords: action.result
          ? !state.updateKeywords
          : state.updateKeywords,
      };

    case DELETE_KEYWORD_FINISHED:
      return {
        ...state,
        changing: false,
        updateKeywords: action.result
          ? !state.updateKeywords
          : state.updateKeywords,
      };

    case HIDE_KEYWORD_MODAL:
      return {
        ...state,
        keyword: undefined,
        showModal: false,
      };

    case LOADING_KEYWORDS:
      return {
        ...state,
        loading: true,
      };

    case SHOW_KEYWORD_MODAL_FOR_CREATE:
      return {
        ...state,
        keyword: {
          KeywordID: 0,
          Term: "",
        },
        modalType: "create",
        showModal: true,
      };

    case SHOW_KEYWORD_MODAL_FOR_EDIT:
      return {
        ...state,
        keyword: action.keyword,
        modalType: "edit",
        showModal: true,
      };

    case SET_KEYWORDS:
      return {
        ...state,
        keywords: action.keywords,
        filteredKeywords: action.keywords,
        loading: false,
      };

    case SET_KEYWORD:
      return {
        ...state,
        keywords: action.keywords,
        filteredKeywords: action.keywords,
        count: action.keywords.length,
        loading: false,
      };
    case CHANGE_DIRECTORY:
    case UPDATE_KEYWORDS:
      return {
        ...state,
        keyword: undefined,
        keywords: [],
        filteredKeywords: [],
        updateKeywords: !state.updateKeywords,
      };

    case FILTER_KEYWORDS:
      return {
        ...state,
        filteredKeywords: filterKeywords(action.value, state.keywords),
      };

    default:
      return state;
  }
};

const filterKeywords = (value: string, keywords: Keyword[]) => {
  if (value === "") return keywords;

  return keywords.filter((provider) => isFilterMatch(value, provider));
};

const isFilterMatch = (value: string, keyword: Keyword) => {
  return keyword.Term.toLowerCase().includes(value.toLowerCase());
};
