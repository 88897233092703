import React from "react";
import "./App.css";
import { BrowserRouter, Switch } from "react-router-dom";
import { EmptyLayoutRoute } from "./layout";
import AuthPage from "./pages/auth";
import { StandardLayoutRoute } from "./layout/standard";
import HomePage from "./pages/home";
import SearchPage from "./pages/search";
import ClinicsPage from "./pages/clinics";
import SpecialtiesPage from "./pages/specialties";
import UsersPage from "./pages/users";
import PatientsPage from "./pages/patients";
import { RegistrationPage } from "./pages/registration";
import AdvancedSearchPage from "./pages/advanced-search";
import ProfilePage from "./pages/profile";
import ProfessionalsPage from "./pages/professionals";
import ProfessionalPage from "./pages/professional";
import ImportProfessionalsPage from "./pages/import-professionals";
import DirectoriesPage from "./pages/directories";
import ProceduresPage from "./pages/procedures";
import CustomProfilePage from "./pages/customProfile";
import PasswordResetPage from "./pages/passwordReset";
import PasswordResetComplete from "./pages/passwordResetComplete";
import KeywordPage from "./pages/keywords";
import InsurancesPage from "./pages/insurances";
import { ReviewsPage } from "./pages/reviews/ReviewsPage";

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <StandardLayoutRoute exact path="/" component={HomePage} />
      <StandardLayoutRoute path="/search" component={SearchPage} />
      <StandardLayoutRoute path="/advanced-search" component={AdvancedSearchPage}/>
      <StandardLayoutRoute path="/patients" component={PatientsPage} />
      <StandardLayoutRoute path="/directories" component={DirectoriesPage} />
      <StandardLayoutRoute path="/users" component={UsersPage} />
      <StandardLayoutRoute path="/specialties" component={SpecialtiesPage} />
      <StandardLayoutRoute path="/clinics" component={ClinicsPage} />
      <StandardLayoutRoute path="/profile" component={ProfilePage} />
      <StandardLayoutRoute
        path="/professionals"
        component={ProfessionalsPage}
      />
      <StandardLayoutRoute
        path="/professional/:providerId"
        component={ProfessionalPage}
      />
      <StandardLayoutRoute
        path="/import-professionals"
        component={ImportProfessionalsPage}
      />
      <StandardLayoutRoute path="/procedures" component={ProceduresPage} />

      <StandardLayoutRoute
        path="/customProfile"
        component={CustomProfilePage}
      />

      <StandardLayoutRoute path="/reviews" component={ReviewsPage} />
      <StandardLayoutRoute path="/keywords" component={KeywordPage} />
      <StandardLayoutRoute path="/insurances" component={InsurancesPage} />

      <EmptyLayoutRoute
        path="/completeRegistration/:inviteUid"
        component={RegistrationPage}
      />

      <EmptyLayoutRoute path="/auth" component={AuthPage} />

      <EmptyLayoutRoute path="/passwordReset" component={PasswordResetPage} />
      <EmptyLayoutRoute
        path="/password-reset-complete/:id"
        component={PasswordResetComplete}
      />
    </Switch>
  </BrowserRouter>
);

export default App;
