import React, { useEffect, useState } from "react";
import {
    Table,
    Button,
    Tooltip,
    Typography,
    Row,
    Col,
    Rate,
} from "antd";
import { Trans } from "@lingui/macro";
import { Container } from "../../components/utils";
import { useSelector, useDispatch } from "react-redux";
import { Review, ReviewState, showReviewModalForEdit } from "../../store/review";
import { approvedReviewFromAdmin, deleteReviewFromAdmin, loadReviewsFromAdmin } from "../../services/reviews";
import { ReviewHeader } from "../../components/reviews/header";
import { ProviderState } from "../../store/provider/Types";
import { SearchReviewParams } from "../../components/reviews/header/Types";
import { ReviewModal } from "../../components/reviews/modal";

export const ReviewsPage: React.FC = () => {
    const pageSize = 20;
    const {
        reviewState,
        providerState,
    } = useSelector(mapStateToProps);

    const [reviewParams, setReviewParams] = useState<SearchReviewParams>({ status: 0 })
    const { updateReviews, loading, count, reviews } = reviewState;
    const dispatch = useDispatch();

    useEffect(() => {
        loadReviewsFromAdmin(reviewParams);
    }, [updateReviews, reviewParams]);

    const onSearch = (request: SearchReviewParams) => {
        setReviewParams(request);
    }

    const onApproved = (id: string, providerReply: string) => {
        approvedReviewFromAdmin(id, providerReply);
    };

    const onDeleted = (id: string) => {
        deleteReviewFromAdmin(id);
    };

    const onUpdateReview = (review: Review) => {
        dispatch(showReviewModalForEdit(review));
    }

    const columns = [
        {
            title: (
                <Trans render="strong" id="Reviews.ProfessionalColumnTitle">
                    Professional
                </Trans>
            ),
            dataIndex: "ProfessionalName",
            key: "ProfessionalName",
        },
        {
            title: (
                <Trans render="strong" id="Reviews.DateColumnTitle">
                    Date
                </Trans>
            ),
            dataIndex: "Date",
            key: "Date",
        },
        {
            title: (
                <Trans render="strong" id="Reviews.OverallRatingColumnTitle">
                    Overall rating
                </Trans>
            ),
            dataIndex: "OverallRating",
            key: "OverallRating",
            render: (value?: number) => <Rate disabled defaultValue={value} />

        },
        {
            title: (
                <Trans render="strong" id="Reviews.StatusColumnTitle">
                    Status
                </Trans>
            ),
            dataIndex: "Status",
            key: "Status",
        },
        {
            title: (
                <Trans render="strong" id="Reviews.ActionsColumnTitle">
                    Actions
                </Trans>
            ),
            key: "Actions",
            render: (review: Review) => (
                <span>
                    <Tooltip
                        title={<Trans id="Reviews.EditActionTooltip">Edit review</Trans>}
                    >
                        <Button icon="edit" onClick={() => onUpdateReview(review)} />
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <>
            <Container>
                <Typography.Title level={2}>
                    <Trans id="Reviews.Title">Reviews</Trans>
                </Typography.Title>
                <Table
                    dataSource={reviews}
                    columns={columns}
                    loading={loading}
                    bodyStyle={{ background: "#fff" }}
                    rowKey="AccountAppointmentReviewID"
                    pagination={
                        count > pageSize && {
                            pageSize,
                            position: "bottom",
                        }
                    }
                    title={() => (
                        <Row type="flex" align="middle">
                            <Col span={24}>
                                <ReviewHeader
                                    onCleanFilters={() => { }}
                                    onSearch={onSearch}
                                    providerState={providerState}
                                    isLoading={loading}
                                />
                            </Col>
                        </Row>
                    )}

                />
            </Container>
            {reviewState.review &&
                <ReviewModal
                    loading={reviewState.changing}
                    showModal={reviewState.showModal}
                    review={reviewState.review}
                    onDeleted={onDeleted}
                    onApproved={onApproved}
                />
            }
        </>
    );
};

const mapStateToProps = (state: any): StateToProps => ({
    reviewState: state.reviewState,
    providerState: state.providerState,

});

interface StateToProps {
    reviewState: ReviewState;
    providerState: ProviderState;
}
