import React from "react";
import "./AuthLogin.css";
import {
  Row,
  Col,
  Card,
  Form,
  Input,
  Button,
  Icon,
  Alert,
  Typography,
} from "antd";
import { AuthLoginProps } from "./Types";
import { nameofFactory } from "../../../utils";
import { LoginForm } from "../../../store/auth";
import { Trans } from "@lingui/macro";
import { LocalStorageKeys } from "../../../utils/Constants";

const AuthLogin: React.FC<AuthLoginProps> = (props) => {
  const { authState, form, onLogin } = props;
  const nameof = nameofFactory<LoginForm>();
  const { getFieldDecorator, validateFields } = form;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateFields((err, values) => {
      if (!err) {
        onLogin(values);
      }
    });
  };

  return (
    <Row type="flex" className="login-container">
      <Card title={<CardTitle />} className="login-card" bordered={false}>
        <Row type="flex" justify="center">
          <Col span={12}>
            <Form colon={false} onSubmit={handleSubmit}>
              <Form.Item label={<Trans id="Login.EmailLabel">Email</Trans>}>
                {getFieldDecorator(nameof("email"), {
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="Login.EmailRequiredError">
                          The email field is required
                        </Trans>
                      ),
                    },
                    {
                      type: "email",
                      message: (
                        <Trans id="Login.EmailInvalidError">
                          Please enter a valid email
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="user" />}
                    size="large"
                    type="email"
                    disabled={authState.loading}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={<Trans id="Login.PasswordLabel">Password</Trans>}
              >
                {getFieldDecorator(nameof("password"), {
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="Login.PasswordRequiredError">
                          The password field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Input.Password
                    prefix={<Icon type="lock" />}
                    size="large"
                    disabled={authState.loading}
                  />
                )}
              </Form.Item>
              
              <Form.Item
                label={<Trans id="Login.TenantLabel">Directory</Trans>}
              >
                {getFieldDecorator(nameof("directory"), {
                  initialValue: localStorage.getItem(LocalStorageKeys.directoryName),
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="Login.TenantRequiredError">
                          The directory field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Input
                    prefix={<Icon type="database" />}
                    size="large"
                    disabled={authState.loading}
                  />
                )}
              </Form.Item>

              <Row type="flex" justify="end">
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={authState.loading}
                  className="login-button"
                >
                  <Trans render="span" id="Login.SubmitButtonLabel">
                    Log in
                  </Trans>
                  <Icon type="right-circle" />
                </Button>
              </Row>
              {authState.loginFailed && (
                <div className="margin-top-15">
                  <Alert type="error" message={authState.loginError} showIcon />
                </div>
              )}
              <Row type="flex" justify="end" className="margin-top-15">
                <Col>
                  <a href="/passwordReset">
                    <Trans render="span" id="Login.ForgotPasswordLabel">
                      Forgot password
                    </Trans>
                  </a>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </Row>
  );
};

const CardTitle: React.FC = () => (
  <Row type="flex" justify="center" align="middle">
    <Typography.Text strong>
      <Trans render="h3" id="Login.Title">
        Log in
      </Trans>
    </Typography.Text>
  </Row>
);

export default Form.create<AuthLoginProps>()(AuthLogin);
