import React, { useEffect } from "react";
import "./SearchPage.css";
import { Row, Col, Spin } from "antd";
import { SearchHeader } from "../../components/search/header";
import {
  SearchState,
  selectState,
  selectCounty,
  SearchParams,
  filterProviders,
  checkProvider,
  uncheckProvider,
  cleanFilters,
  uncheckAllProviders,
  checkFourProviders,
} from "../../store/search";
import { useSelector, useDispatch } from "react-redux";
import {
  loadStates,
  loadStateCounties,
  loadCountyCities
} from "../../services/location";
import { DirectoryState } from "../../store/directory";
import { SpecialtyState } from "../../store/specialty";
import { LocationState } from "../../store/location";
import { loadSpecialties } from "../../services/specialty";
import { ClinicState } from "../../store/clinic";
import { ProviderState } from "../../store/provider";
import { searchProviders } from "../../services/search";
import { SearchSider } from "../../components/search/sider";
import { SearchCalendar } from "../../components/search/calendar";
import { loadResources, loadEvents } from "../../services/calendar";
import { CalendarState } from "../../store/calendar";
import {
  AppointmentScheduleModalCreate,
  AppointmentCancelModal,
  AppointmentScheduleModalUpdate,
} from "../../components/appointment-modal";
import {
  AppointmentState,
  showModalForSchedule,
  Appointment,
  showModalForCancel
} from "../../store/apointment";
import { AccountState } from "../../store/account";
import { AccountModal } from "../../components/account-modal";
import moment from "moment";

export const SearchPage: React.FC = () => {
  const {
    accountState,
    appointmentState,
    calendarState,
    clinicState,
    directoryState,
    locationState,
    providerState,
    searchState,
    specialtyState
  } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    if (directoryState.selectorDirectory !== undefined) {
      loadSpecialties();
      loadStates();
    }
  }, [directoryState.selectorDirectory]);

  useEffect(() => {
    if (searchState.params.stateId !== undefined)
      loadStateCounties(searchState.params.stateId);
  }, [searchState.params.stateId]);

  useEffect(() => {
    if (searchState.params.countyId !== undefined)
      loadCountyCities(
        Number(searchState.params.stateId),
        searchState.params.countyId
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.params.countyId]);

  useEffect(() => {
    loadCalendarInfo();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calendarState.date,
    calendarState.view,
    calendarState.reloadCalendar,
    searchState.providersCheckedCount
  ]);

  const filterFoundProviders = (value: string) => {
    dispatch(filterProviders(value));
  };

  const loadCalendarInfo = () => {
    const request = {
      start: calendarState.date,
      end:
        calendarState.view === "resourceTimeGridOneDay"
          ? calendarState.date
          : moment(calendarState.date).add(6, "d"),
      providers: searchState.filteredProviders
    };

    loadResources(request);
    loadEvents(request);
  };

  const onCleanFilters = () => {
    dispatch(cleanFilters());
  };

  const onCountyChange = (value: string) => {
    const countyId = Number(value);
    dispatch(selectCounty(countyId));
  };

  const onDateSelect = (appointment: Appointment) => {
    dispatch(showModalForSchedule(appointment));
  };

  const onEventSelect = (appointment: Appointment) => {
    dispatch(showModalForCancel(appointment, appointment.readOnly));
  };

  const onProviderChecked = (uId: string) => {
    dispatch(checkProvider(uId));
  };

  const onProviderUnchecked = (uId: string) => {
    dispatch(uncheckProvider(uId));
  };

  const onSearch = (params: SearchParams) => {
    searchProviders(params);
  };

  const onStateChange = (value: string) => {
    const stateId = Number(value);
    dispatch(selectState(stateId));
  };

  const onUncheckAllProviders = () => {
    dispatch(uncheckAllProviders());
  };

  const onCheckFourProviders = () => {
    dispatch(checkFourProviders());
  }

  return (
    <div className="search-page">
      <Row type="flex" align="middle" className="search-header-container">
        <SearchHeader
          clinicState={clinicState}
          directoryTenantId={directoryState.directory?.DirectoryTenantID}
          locationState={locationState}
          onCleanFilters={onCleanFilters}
          onCountyChange={onCountyChange}
          onSearch={onSearch}
          onStateChange={onStateChange}
          providerState={providerState}
          searchState={searchState}
          specialtyState={specialtyState}
        />
      </Row>
      <Row type="flex" className="search-body-container">
        <Col span={5} className="search-sider-container">
          <SearchSider
            calendarState={calendarState}
            onChecked={onProviderChecked}
            onSearch={filterFoundProviders}
            onUncheckAll={onUncheckAllProviders}
            onUnchecked={onProviderUnchecked}
            onCheckFourProviders={onCheckFourProviders}
            searchState={searchState}
          />
        </Col>
        <Col span={19} className="search-calendar-container">
          {searchState.providersCheckedCount > 0 && (
            <Spin
              spinning={
                calendarState.loadingEvents || calendarState.loadingResources
              }
            >
              <SearchCalendar
                date={calendarState.date}
                events={calendarState.events}
                onDateSelect={onDateSelect}
                onEventSelect={onEventSelect}
                resources={calendarState.resources}
                reloadCalendar={calendarState.reloadCalendar}
                view={calendarState.view}
              />
            </Spin>
          )}
        </Col>
      </Row>
      {appointmentState.showCancelModal && <AppointmentCancelModal />}
      {appointmentState.showScheduleModal && appointmentState.scheduleModalType === "create" && (
        <AppointmentScheduleModalCreate
          specialtyId={searchState.params.specialtyId}
        />
      )}
      {appointmentState.showScheduleModal && appointmentState.scheduleModalType === "edit" && (
        <AppointmentScheduleModalUpdate
          specialtyId={searchState.params.specialtyId}
        />
      )}
      {accountState.showModal && <AccountModal />}
    </div>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  accountState: state.accountState,
  appointmentState: state.appointmentState,
  calendarState: state.calendarState,
  clinicState: state.clinicState,
  directoryState: state.directoryState,
  locationState: state.locationState,
  providerState: state.providerState,
  searchState: state.searchState,
  specialtyState: state.specialtyState
});

interface StateToProps {
  accountState: AccountState;
  appointmentState: AppointmentState;
  calendarState: CalendarState;
  clinicState: ClinicState;
  directoryState: DirectoryState;
  locationState: LocationState;
  providerState: ProviderState;
  searchState: SearchState;
  specialtyState: SpecialtyState;
}
