import React, { useEffect } from "react";
import "./SpecialtiesPage.css";
import {
  Table,
  Divider,
  Button,
  Row,
  Tooltip,
  Popconfirm,
  message,
  Col,
  Input,
  Typography,
} from "antd";
import { Trans, t } from "@lingui/macro";
import { Container, YesNoCell } from "../../components/utils";
import {
  SpecialtyState,
  Specialty,
  showSpecialtyModalForCreate,
  showSpecialtyModalForEdit,
  filterSpecialties,
} from "../../store/specialty";
import { useSelector, useDispatch } from "react-redux";
import {
  loadSpecialties,
  updateSpecialty,
  createSpecialty,
  deleteSpecialty,
} from "../../services/specialty";
import { i18n } from "../../utils";
import { SpecialtyModal } from "../../components/specialty-modal";

export const SpecialtiesPage: React.FC = (props) => {
  const pageSize = 20;
  const specialtyState = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    loadSpecialties();
  }, [specialtyState.updateSpecialties]);

  const onCreateSpecialty = () => {
    dispatch(showSpecialtyModalForCreate());
  };

  const onCreate = (specialty: Specialty) => {
    createSpecialty(specialty);
  };

  const onUpdate = (specialty: Specialty) => {
    updateSpecialty(specialty);
  };

  const onDeleteSpecialty = (specialtyId: number) => {
    message.loading(
      i18n._(t("SpecialtyPage.DeletingSpecialty")`Deleting specialty...`),
      0.5,
      () => deleteSpecialty(specialtyId)
    );
  };

  const onUpdateSpecialty = (specialty: Specialty) => {
    dispatch(showSpecialtyModalForEdit(specialty));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Specialties.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="Specialties.PictureURLColumnTitle">
          Picture URL
        </Trans>
      ),
      dataIndex: "PictureURL",
      key: "PictureURL",
    },
    {
      title: (
        <Trans render="strong" id="Specialties.DescriptionColumnTitle">
          Description
        </Trans>
      ),
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: (
        <Trans render="strong" id="Specialties.IsMainColumnTitle">
          Is Main
        </Trans>
      ),
      dataIndex: "IsMain",
      key: "IsMain",
      render: (IsMain: boolean) => <YesNoCell value={IsMain} showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Specialties.OrderColumnTitle">
          Order
        </Trans>
      ),
      dataIndex: "Order",
      key: "Order",
    },
    {
      title: (
        <Trans render="strong" id="Specialties.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (specialty: Specialty) => (
        <span>
          <Tooltip
            title={
              <Trans id="Specialties.EditActionTooltip">Edit specialty</Trans>
            }
          >
            <Button icon="edit" onClick={() => onUpdateSpecialty(specialty)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Specialties.DeleteActionTooltip">
                Delete specialty
              </Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="Specialty.DeleteActionConfirmationText">
                  Do you wish to delete this specialty?
                </Trans>
              }
              okText={<Trans id="Specialty.DeleteActionButton">Delete</Trans>}
              onConfirm={() => onDeleteSpecialty(specialty.SpecialtyID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const filterFoundSpecialties = (value: string) => {
    dispatch(filterSpecialties(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundSpecialties(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography.Title level={2}>
          <Trans id="Specialties.Title">Specialties</Trans>
        </Typography.Title>
        <Table
          dataSource={specialtyState.filteredSpecialties}
          columns={columns}
          loading={specialtyState.loading}
          bodyStyle={{ background: "#fff" }}
          rowKey="SpecialtyID"
          pagination={
            specialtyState.count > pageSize && {
              pageSize,
              position: "bottom",
            }
          }
          title={() => (
            <Row type="flex" align="middle">
              <Col span={6}>
                <Input
                  onChange={onChange}
                  placeholder={i18n._(
                    t(
                      "Specialties.FilterSpecialtiesPlaceHolder"
                    )`Search by specialty name`
                  )}
                />
              </Col>
              <Col span={18}>
                <Row type="flex" justify="end" gutter={8}>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={onCreateSpecialty}
                    >
                      <Trans id="Specialties.CreateSpecialtyButton">
                        New Specialty
                      </Trans>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        />
      </Container>
      <SpecialtyModal
        loading={specialtyState.changing}
        modalType={specialtyState.modalType}
        onCreate={onCreate}
        onUpdate={onUpdate}
        showModal={specialtyState.showModal}
        specialty={specialtyState.specialty}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): SpecialtyState => state.specialtyState;
