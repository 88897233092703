import React from "react";
import "./TimeSlots.css";
import { Row, Col, List, Radio, Spin, Empty, Typography } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import moment from "moment";
import { TimeSlotsProps } from "./Types";
import { Trans } from "@lingui/macro";

export const TimeSlots: React.FC<TimeSlotsProps> = props => {
  const { onTimeSelect, timeSlots, interval } = props;

  const onTimeSlotChange = (e: RadioChangeEvent) => {
    const [slotIndex, startTimeIndex]: number[] = e.target.value.split("@");
    const timeSlot = timeSlots?.TimeSlots[slotIndex];
    const startTime = moment(timeSlot?.Hours[startTimeIndex]);

    const newTimes = {
      date: moment(timeSlot?.Date),
      slotIndex,
      startTime,
      endTime: moment(startTime).add(interval, "m")
    };

    onTimeSelect(newTimes);
  };

  return (
    <div className="time-slots">
      <Spin spinning={timeSlots === undefined}>
        <Radio.Group onChange={onTimeSlotChange} className="option-group">
          <Row className="row-max-height" type="flex" justify="space-between">
            {timeSlots?.TimeSlots.map((timeSlot, timeSlotIndex) => (
              <Col span={6} key={timeSlotIndex}>
                <Row type="flex" justify="center">
                  <List
                    header={
                      <Row type="flex" justify="center">
                        {timeSlot.Hours.length > 0 ? (
                          <Typography.Text strong>
                            {timeSlot.Date.format("ddd L")}
                          </Typography.Text>
                        ) : (
                          timeSlot.Date.format("ddd L")
                        )}
                      </Row>
                    }
                    size="small"
                    dataSource={timeSlot.Hours}
                    locale={{
                      emptyText: (
                        <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={
                            <Trans id="AppointmentScheduleModal.TimeSlots.NoTimeSpacesMessage">
                              There're no spaces
                            </Trans>
                          }
                        />
                      )
                    }}
                    renderItem={(item, index) => (
                      <List.Item>
                        <Radio.Button
                          value={`${timeSlotIndex}@${index}`}
                          key={`${timeSlotIndex}@${index}`}
                        >
                          {item.format("hh:mm a")}
                        </Radio.Button>
                      </List.Item>
                    )}
                  />
                </Row>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Spin>
    </div>
  );
};
