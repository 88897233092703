import React, { useEffect, useState } from "react";
import "./AppointmentScheduleModal.css";
import { AppointmentModalProps } from "./Types";
import {
  Modal,
  Form,
  Input,
  Col,
  Select,
  Row,
  Button,
  Spin,
  Typography,
  Skeleton,
} from "antd";
import { Trans } from "@lingui/macro";
import {
  Appointment,
  AppointmentState,
  hideAppointmentModal,
} from "../../../store/apointment";
import { nameofFactory } from "../../../utils";
import { searchAccountForAutocomplete } from "../../../services/account";
import {
  loadProviderTimeSlots,
  getProvider,
  loadProviderSpecialties,
} from "../../../services/provider";
import moment from "moment";
import { SelectValue } from "antd/lib/select";
import {
  Account,
  AccountState,
  selectAccount,
  setAccount,
  showModalForAccountCreation,
  showModalForAccountUpdate,
} from "../../../store/account";
import { ProviderState } from "../../../store/provider";
import { useSelector, useDispatch } from "react-redux";
import { scheduleAppointment } from "../../../services/appointment";
import debounce from "lodash/debounce";
import { ProviderAppointmentReason } from "../../../store/providerAppointmentReason";
import { TimeSlotsRequest } from "../../../services/provider/Types";

const { Option } = Select;

const AppointmentScheduleModalCreate: React.FC<AppointmentModalProps> = (
  props
) => {
  const { specialtyId } = props;
  const { accountState, appointmentState, providerState } =
    useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingTime, setIsLoadingTime] = useState<boolean>(true);

  const [reasonsByAgenda, setReasonByAgenda] = useState<
    ProviderAppointmentReason[]
  >([]);
  const [reasonIdSelected, setReasonSelected] = useState<number>(0);

  const [startTimes, setStartTimes] = useState<moment.Moment[]>([]);
  const [endTimes, setEndTimes] = useState<moment.Moment[]>([]);

  const [appointment, setAppointment] = useState<Appointment>(
    appointmentState.appointment!
  );

  moment.locale("es");
  const calendarDay = appointment?.date.format("dddd D [de] MMMM [de] YYYY")!;

  const { form } = props;
  const nameof = nameofFactory<Appointment>();
  const { getFieldDecorator, getFieldValue, validateFields } = form;

  const modalities = [
    {
      id: "P",
      label: <Trans id="Common.ModalityPresentialLabel">On site</Trans>,
    },
    {
      id: "D",
      label: <Trans id="Common.ModalityHomeCareLabel">Home care</Trans>,
    },
    {
      id: "T",
      label: <Trans id="Common.ModalityTeleHealtLabel">Telehealth</Trans>,
    },
  ];

  useEffect(() => {
    if (appointment?.patientId)
      searchAccountForAutocomplete(appointment?.patientId).then(() =>
        dispatch(selectAccount(String(appointment?.patientId)))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appointment?.providerId) {
      getProvider(appointment?.providerId).then(() => {
        loadProviderSpecialties(appointment.providerId);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (providerState.provider?.ProviderID === appointment.providerId) {
      getReasonsByAgenda();
    }

    // eslint-disable-next-line
  }, [providerState.provider]);

  useEffect(() => {
    if (reasonIdSelected > 0) {
      loadTimeSlots(getDurationTimeFromReason());
    }
    // eslint-disable-next-line
  }, [reasonIdSelected]);

  useEffect(() => {
    if (appointmentState.timeSlots) {
      let time =
        appointmentState.timeSlots?.TimeSlots[appointmentState.timeSlotIndex]
          .Hours || [];
      const minutes = getInterval()!;
      if (time.length > 0 && appointmentState.timeSlots.AppointmentIntervalMinutes < minutes) {
        time.push(time[time.length - 1].clone().add(minutes, "m"));

      }

      setStartTimes(time);

      if (time.length > 0) {
        const start = moment(time[0]);
        const end = moment(time[time.length - 1].clone().add(minutes, "m"));

        setEndTimes(getHoursBySchedule(start, end, Number(minutes)));
      } else {
        setEndTimes([]);
      }
    }
    // eslint-disable-next-line
  }, [appointmentState.timeSlots?.TimeSlots]);

  useEffect(() => {
    if (startTimes.length > 0) {
      setTime();
      setIsLoadingTime(false);
    }

    // eslint-disable-next-line
  }, [startTimes, endTimes]);

  const loadTimeSlots = async (durationMinutes: number) => {
    if (appointment) {
      const request: TimeSlotsRequest = {
        providerId: appointment.providerId,
        agendaId: appointment.agendaId,
        locationId: appointment.locationId,
        from: appointment.date,
        to: appointment.date,
        duration: durationMinutes,
      };

      let success = await loadProviderTimeSlots(request);

      if (!success) {
        clearSelects();
      }
    }
  };

  const getReasonsByAgenda = () => {
    if (providerState.provider?.AppointmentReason) {
      if (providerState.provider?.AppointmentReason.length > 0) {
        let reasonWithoutAgenda =
          providerState.provider?.AppointmentReason?.filter(
            (x) => x.LocationsSelected?.length === 0
          );
        let reasonWithAgenda =
          providerState.provider?.AppointmentReason?.filter(
            (x) => x.LocationsSelected?.length! > 0
          );
        reasonWithAgenda = reasonWithAgenda?.filter((x) =>
          x.LocationsSelected?.filter(
            (y) =>
              y.AgendaID === appointment?.agendaId &&
              y.LocationID === appointment?.locationId
          )
        );

        setReasonByAgenda(
          [...reasonWithoutAgenda, ...reasonWithAgenda].sort((a, b) =>
            a.Name.localeCompare(b.Name)
          )
        );
        setIsLoading(false);
      } else {
        loadTimeSlots(0);
        setIsLoading(false);
      }
    }
  };

  const disableEndTimeOption = (start: string, endTime: moment.Moment) => {
    const time = moment(start, "HH:mm");
    return time.valueOf() >= endTime.valueOf();
  };

  const dismissModal = () => {
    if (!appointmentState.loading) dispatch(hideAppointmentModal());
  };

  const getHoursBySchedule = (
    startTime: moment.Moment,
    endTime: moment.Moment,
    interval: number
  ) => {
    let hours: moment.Moment[] = [];

    if (interval === 0) {
      return hours;
    }

    let i = moment(startTime);
    let f = moment(endTime);

    while (i <= f) {
      if (i.format("hh:mm a") !== "12:00 am") {
        hours.push(i);
      }

      i = i.clone().add(interval, "m");
    }

    let defaultHoraFin = moment(
      moment().format("DD/MM/YYYY") + " 23:59:59",
      "DD-MM-YYYY HH:mm:ss"
    );

    let onceNoche55 = moment("11:55pm", "h:mma");

    if (moment(endTime).isAfter(onceNoche55)) {
      hours.push(defaultHoraFin);
    }

    return hours;
  };

  const onCreateAccount = () => {
    dispatch(showModalForAccountCreation());
  };

  const onLocalUpdateAccount = () => {
    if (onUpdateAccount) onUpdateAccount(accountState.account as Account);
  };

  const onPatientChange = (patientId: string | undefined) => {
    if (patientId && patientId !== "") dispatch(selectAccount(patientId));
    else dispatch(setAccount(undefined));
  };

  const onSchedule = () => {
    validateFields((err, values) => {
      if (err) return;

      var dateWithFormat = appointment?.date.format("YYYY-MM-DD")!;

      var newAppointmentRequest = {
        locationId: appointment?.locationId,
        agendaId: appointment?.agendaId,
        providerId: providerState.provider?.ProviderID,
        date: dateWithFormat,
        startTime: values.startTime,
        endTime: values.endTime,
        specialtyId: values.specialtyId,
        notes: values.notes,
        patientUID: accountState.account?.UID,
        reasonID: reasonIdSelected > 0 ? reasonIdSelected : undefined,
        modality: values.modality,
      };

      scheduleAppointment(newAppointmentRequest);
    });
  };

  const onSearchAccounts = debounce((value: string) => {
    if (!value || value === "") return;

    if (value.length > 3) {
      searchAccountForAutocomplete(value);
    }
  }, 1000);

  const onUpdateAccount = (account: Account) => {
    dispatch(showModalForAccountUpdate(account));
  };

  const setTime = () => {
    const start = startTimes[0];
    const minutes = getInterval();
    const end = startTimes[startTimes.length - 1].clone().add(minutes, "m");

    let reasonGreatherThanInterval =
      getDurationTimeFromReason() >
      appointmentState.timeSlots?.AppointmentIntervalMinutes!;
    let tempStartTime = null;
    let tempEndTime = null;

    if (appointment?.startTime?.isValid()) {
      let existInStartTime = startTimes.some(
        (x) => x.format("HH:mm") === appointment?.startTime?.format("HH:mm")
      );

      if (reasonGreatherThanInterval && !existInStartTime) {
        tempStartTime = start;
        tempEndTime = startTimes.length > 1 ? startTimes[1] : end;
      } else {
        tempStartTime = appointment?.startTime;
        tempEndTime = tempStartTime.clone().add(minutes, "m");
      }
    } else {
      tempStartTime = start;
      tempEndTime = start.clone().add(minutes, "m");
    }

    setAppointment({
      ...appointment,
      startTime: moment(tempStartTime).clone(),
      endTime: moment(tempEndTime).clone(),
    } as Appointment);
  };

  const startTimesRegex = new RegExp(
    startTimes.map((time) => time.format("HH:mm")).join("|")
  );
  const endTimesRegex = new RegExp(
    endTimes.map((time) => time.format("HH:mm")).join("|")
  );

  const setTimesValue = (value: SelectValue) => {
    if (!value) return;

    const startTimeIndex = startTimes.findIndex(
      (item) => item.format("HH:mm") === value
    );

    const startTime = startTimes[startTimeIndex];

    const endTime = startTime.clone().add(getInterval(), "m");
    setAppointment({
      ...appointment,
      startTime: moment(startTime).clone(),
      endTime: moment(endTime).clone(),
    } as Appointment);
  };

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const providerModalities = modalities
    .filter((r) => providerState.provider?.Modalities?.includes(r.id))
    .sort((a, b) => a.id.localeCompare(b.id));

  const onReasonSelect = (item: number) => {
    setIsLoadingTime(true);
    setReasonSelected(item);
  };

  const getDurationTimeFromReason = () => {
    return reasonsByAgenda.find(
      (x) => x.ProviderAppointmentReasonID === reasonIdSelected
    )?.DurationMinutes!;
  };

  const getInterval = () => {
    return reasonIdSelected > 0 && reasonsByAgenda.length > 0
      ? getDurationTimeFromReason()
      : appointmentState.timeSlots?.AppointmentIntervalMinutes;
  };

  const clearSelects = () => {
    setStartTimes([]);
    setEndTimes([]);
    setAppointment({
      ...appointment,
      startTime: moment(null),
      endTime: moment(null),
    } as Appointment);

    setIsLoadingTime(false);
  };

  const getInitialHour = () => {
    return startTimes.find(
      (x) => x.format("HH:mm") === appointment?.startTime?.format("HH:mm")
    );
  }
  const getStartTime = (): string | null => {
    if (!appointment?.startTime?.isValid()) return null;

    return getInitialHour() !== undefined ? appointment?.startTime.format("HH:mm") : null;
  }

  const getEndHour = () => {
    let initialHour = getInitialHour();
    if (initialHour === undefined) return null;

    return initialHour.clone().add(getInterval(), "m").format("HH:mm");
  }

  return (
    <Modal
      centered
      destroyOnClose
      footer={
        !isLoading ? (
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button
                loading={appointmentState.loading}
                disabled={isLoadingTime || isLoading}
                onClick={onSchedule}
                type="primary"
              >
                <Trans
                  render="span"
                  id="AppointmentScheduleModal.ScheduleAppointmentButton"
                >
                  Schedule
                </Trans>
              </Button>
            </Col>
            <Col>
              <Button
                disabled={appointmentState.loading}
                onClick={dismissModal}
              >
                <Trans
                  render="span"
                  id="AppointmentScheduleModal.DismissButton"
                >
                  Dismiss
                </Trans>
              </Button>
            </Col>
          </Row>
        ) : null
      }
      onCancel={dismissModal}
      title={
        <Trans id="AppointmentScheduleModal.ScheduleTitle">
          {`Schedule appointment on ${appointment?.agendaName}`}
        </Trans>
      }
      visible={appointmentState.showScheduleModal}
    >
      <Skeleton loading={isLoading} active paragraph={{ rows: 3 }}>
        <Row type="flex">
          <Typography.Title level={4}>
            {providerState.provider?.FullName}
          </Typography.Title>
        </Row>
        <Form colon={false} layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item
                label={<Trans id="Common.SpecialtyLabel">Specialty</Trans>}
              >
                {getFieldDecorator(nameof("specialtyId"), {
                  initialValue: specialtyId || appointment?.specialtyId,
                  rules: [
                    {
                      required: true,
                      message: (
                        <Trans id="Common.SpecialtyRequiredError">
                          The specialty field is required
                        </Trans>
                      ),
                    },
                  ],
                })(
                  <Select allowClear>
                    {providerState.provider?.Specialties?.map(
                      (specialty, index) => (
                        <Option key={index} value={specialty.SpecialtyID}>
                          {specialty.Name}
                        </Option>
                      )
                    )}
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              {reasonsByAgenda.length > 0 ? (
                <Form.Item
                  label={
                    <Trans id="ProviderAppointmentReason.Name">Reason</Trans>
                  }
                >
                  {getFieldDecorator(nameof("reasonID"), {
                    rules: [
                      {
                        required: true,
                        message: (
                          <Trans id="Common.AppointmentReasonRequiredError">
                            The reason field is required
                          </Trans>
                        ),
                      },
                    ],
                  })(
                    <Select onChange={onReasonSelect}>
                      {reasonsByAgenda.map((item, index) => (
                        <Select.Option
                          value={item.ProviderAppointmentReasonID}
                          key={index}
                        >
                          {item.Name} ({item.DurationMinutes} min.)
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          {reasonsByAgenda.length === 0 ||
            (reasonsByAgenda.length > 0 && reasonIdSelected > 0) ? (
            <>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<Trans id="Common.ModalityLabel">Modality</Trans>}
                  >
                    {getFieldDecorator(nameof("modality"), {
                      rules: [
                        {
                          required: true,
                          message: (
                            <Trans id="Common.ModalitiesRequiredError">
                              The modality field is required
                            </Trans>
                          ),
                        },
                      ],
                    })(
                      <Select allowClear>
                        {providerModalities.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Skeleton loading={isLoadingTime} active paragraph={{ rows: 3 }}>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      label={
                        <Trans id="AppointmentScheduleModal.DateLabel">
                          Date
                        </Trans>
                      }
                    >
                      <h3>{capitalizeFirstLetter(calendarDay)}</h3>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={
                        <Trans id="AppointmentScheduleModal.StartTimeLabel">
                          Start time
                        </Trans>
                      }
                    >
                      {getFieldDecorator(nameof("startTime"), {
                        initialValue: getStartTime(),
                        rules: [
                          {
                            required: true,
                            message: (
                              <Trans id="AppointmentScheduleModal.StartTimeRequiredError">
                                This start time field is required
                              </Trans>
                            ),
                          },
                          {
                            pattern: startTimesRegex,
                            message: (
                              <Trans id="AppointmentScheduleModal.StartTimeInvalidError">
                                The selected start time is not valid or is taken
                              </Trans>
                            ),
                          },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          loading={appointmentState.loadingTimeSlots}
                          onChange={setTimesValue}
                        >
                          {startTimes.map((time, index) => (
                            <Option key={index} value={time.format("HH:mm")}>
                              {time.format("hh:mm a")}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={
                        <Trans id="AppointmentScheduleModal.EndTimeLabel">
                          End time
                        </Trans>
                      }
                    >
                      {getFieldDecorator(nameof("endTime"), {
                        initialValue: getEndHour(),
                        rules: [
                          {
                            required: true,
                            message: (
                              <Trans id="AppointmentScheduleModal.EndTimeRequiredError">
                                This end time field is required
                              </Trans>
                            ),
                          },
                          {
                            pattern: endTimesRegex,
                            message: (
                              <Trans id="AppointmentScheduleModal.EndTimeInvalidError">
                                The selected end time is not valid or is taken
                              </Trans>
                            ),
                          },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          loading={appointmentState.loadingTimeSlots}
                          disabled={
                            reasonsByAgenda.length > 0 && reasonIdSelected > 0
                          }
                        >
                          {endTimes.map((time, index) => (
                            <Option
                              key={index}
                              value={time.format("HH:mm")}
                              disabled={disableEndTimeOption(
                                getFieldValue(nameof("startTime")),
                                time
                              )}
                            >
                              {time.format("hh:mm a")}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Skeleton>
              <Form.Item
                label={
                  <Trans id="AppointmentScheduleModal.PatientNameLabel">
                    Patient
                  </Trans>
                }
              >
                <Row>
                  <Col span={24}>
                    {getFieldDecorator(nameof("patientId"), {
                      initialValue: appointment?.patientId,
                      rules: [
                        {
                          required: true,
                          message: (
                            <Trans id="AppointmentScheduleModal.PatientNameRequiredError">
                              This patient field is required
                            </Trans>
                          ),
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        allowClear
                        filterOption={false}
                        loading={accountState.loading}
                        notFoundContent={
                          accountState.loading ? <Spin size="small" /> : null
                        }
                        onChange={onPatientChange}
                        onSearch={onSearchAccounts}
                        placeholder="Identificación o nombre-apellidos"
                      >
                        {accountState.accounts.map((account, index) => (
                          <Option
                            key={index}
                            value={account.IdentificationNumber}
                          >
                            {`${account.FirstName} ${account.LastName} ${account.SecondLastName || ""
                              }`}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Col>
                </Row>
              </Form.Item>
              <Row>
                <Col>
                  <Button
                    className="btn-new-patient"
                    type="link"
                    disabled={
                      accountState.account?.UID !== undefined &&
                      accountState.account?.UID !== "new" &&
                      accountState.account?.UID !== ""
                    }
                    onClick={
                      accountState.account?.UID === "new"
                        ? onLocalUpdateAccount
                        : onCreateAccount
                    }
                  >
                    <Trans id="AppointmentScheduleModal.CreatePatientButton">
                      Create patient
                    </Trans>
                  </Button>
                </Col>
              </Row>

              <Form.Item label={<Trans id="Common.NotesLabel">Notes</Trans>}>
                {getFieldDecorator("notes")(<Input.TextArea rows={3} />)}
              </Form.Item>
            </>
          ) : null}
        </Form>
      </Skeleton>
    </Modal>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  accountState: state.accountState,
  appointmentState: state.appointmentState,
  providerState: state.providerState,
});

interface StateToProps {
  accountState: AccountState;
  appointmentState: AppointmentState;
  providerState: ProviderState;
}

export default Form.create<AppointmentModalProps>()(
  AppointmentScheduleModalCreate
);
