import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingAccounts,
  loadedAccounts,
  Account,
  setAccount,
  searchingAccount,
  loadedLinkedAccounts,
  AccountLinkedAccountTable,
  addAccountInsurances, deleteAccountInsurances
} from "../../store/account";
import {
  SearchOnTSEResult,
  SearchAccountRequest,
  AccountRequest,
} from "./Types";
import moment from "moment";
import { searchingPatient, setPatient } from "../../store/patient/Actions";
import { notification } from "antd";
import { getDirectoryTenantId, i18n } from "../../utils";
import { t } from "@lingui/macro";
import { Insurance } from "../../store/insurance";
import { LocalStorageKeys } from "../../utils/Constants";


export const searchPatient = async (request: SearchAccountRequest) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "account/searchAppointment";

  try {
    store.dispatch(searchingPatient());
    const response = await httpClient.get<GenericApiResponse<Account>>(url, {
      params: request,
    });

    if (!response.data.Data) {
      notification.info({
        message: i18n._(
          t(
            "AccountService.PatientNotFound"
          )`No patient matched the search criteria`
        ),
      });
      throw Error();
    }

    const account = {
      ...response.data.Data,
      Appointments: response.data.Data.Appointments?.map((appointment) => ({
        ...appointment,
        Date: moment(appointment.Date),
        StartTime: moment(appointment.StartTime, "HH:mm:ss"),
        EndTime: moment(appointment.EndTime, "HH:mm:ss"),
      })),
    };

    store.dispatch(setPatient(account));
    return true;
  } catch {
    store.dispatch(setPatient(undefined));
    return false;
  }
};

export const searchAccountForAutocomplete = async (value: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"account/appointment/autocomplete";

  try {
    store.dispatch(loadingAccounts());
    const response = await httpClient.get<GenericApiResponse<Account[]>>(url, {
      params: { value },
    });
    store.dispatch(loadedAccounts(response.data.Data));
    return true;
  } catch {
    store.dispatch(loadedAccounts([]));
    return false;
  }
};

export const searchIdOnTSE = async (id: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`account/person/${id}`;

  try {
    store.dispatch(searchingAccount());
    const response = await httpClient.get<
      GenericApiResponse<SearchOnTSEResult>
    >(url);

    store.dispatch(setAccount(undefined));

    if (!response.data.Data) {
      notification.info({
        message: i18n._(
          t("AccountService.IdNotFoundOnTSE")`The given ID was not found`
        ),
      });
    }

    const { Birthday, Phone, ...rest } = response.data.Data;
    const account = {
      ...rest,
      Birthday: moment(Birthday),
      PhoneAreaCode: 506,
      PhoneNumber: Phone,
      UID: "new",
    };

    return account;
  } catch {
    store.dispatch(setAccount(undefined));
    return undefined;
  }
};

export const searchLinkedAccount = async (id: string, email: string) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"account/searchLinkedAccounts";

  var request = {
    id: id || "",
    email: email || "",
  };

  try {
    store.dispatch(loadingAccounts());

    const response = await httpClient.get<
      GenericApiResponse<AccountLinkedAccountTable[]>
    >(url, {
      params: request,
    });

    if (response.data.Data.length === 0) {
      notification.info({
        message: i18n._(
          t(
            "AccountService.AccountNotFound"
          )`No account matched the search criteria`
        ),
      });
      throw Error();
    }
    store.dispatch(loadedLinkedAccounts(response.data.Data));
    return true;
  } catch {
    store.dispatch(loadedLinkedAccounts([]));
    return false;
  }
};

export const createNewPatientAccount = async (request: AccountRequest) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"account";

  const formattedRequest = {
    account: {
      ...request,
    },
  };

  try {
    store.dispatch(loadingAccounts());
    const response = await httpClient.post(url, formattedRequest);
    notification.success({
      message: i18n._(
        t(
          "AccountService.CreateNewPatientAccount"
        )`The client account has been created`
      ),
    });

    return { success: true, data: response.data };
  } catch {
    return { success: false };
  }
};

export const createNewAccountInsurace = async (accountID: string, insurance: Insurance) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`account/insurance`;

  var request = {
    AccountUID: accountID || "",
    InsuranceID: insurance.InsuranceID || "",
  };

  try {
    store.dispatch(addAccountInsurances(insurance));
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId(),
    });
    return true;
  } catch (message) {
    return false;
  }
};

export const deletePatientAccount = async (accountID: string, insurance: Insurance) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`account/${accountID}/insurance/${insurance.InsuranceID}`;


  try {
    store.dispatch(deleteAccountInsurances(insurance));
    await httpClient.delete(url);
    return true;
  } catch (message) {
    notification.error({
      message: i18n._(
        t("AccountService.CouldNotDeleteInsuranceErrorTitle")`Insurance not deleted`
      ),
      description: i18n._(
        t(
          "AccountService.CouldNotDeleteInsuranceErrorDescription"
        )`The insurance could not be deleted.`
      ),
    });
    return false;
  }
};

export const UpdatePatientAccount = async (account: Account, request: AccountRequest) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`account/${request.account.UID}/update`;
  try {
    store.dispatch(loadingAccounts());
    const response = await httpClient.put(url, request.account);
    notification.success({
      message: i18n._(
        t(
          "AccountService.UpdatePatientAccount"
        )`The client account has been updated`
      ),
    });
    return { success: true, data: response.data };
  } catch {
    store.dispatch(setAccount(account));
    return { success: false };
  }
};