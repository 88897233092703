import { DirectoryState, Directory } from "./Types";
import {
  LOADING_DIRECTORIES,
  LOADING_DIRECTORIES_SUCCEDED,
  LOADING_DIRECTORIES_FAILED,
  CHANGE_DIRECTORY,
  LOAD_DIRECTORY_FROM_LOCALSTORAGE,
  HIDE_DIRECTORY_MODAL,
  OPEN_DIRECTORY_MODAL,
  LOADING_DIRECTORY,
  SET_DIRECTORY,
  LOADED_DIRECTORY
} from "./Actions";
import { LocalStorageKeys } from "../../utils/Constants";

const findDirectoryOrDefault = (
  directoryTenantId: string | null,
  directories: Directory[]
) => {
  const directory = directories.find(
    x => x.DirectoryTenantID === directoryTenantId
  );
  return directory === undefined ? directories[0] : directory;
};

const getDirectoryTenantIdFromLocalStorage = () =>
  localStorage.getItem(LocalStorageKeys.DirectoryTenantId);

const saveDirectoryTenantIdToLocalStorage = (directoryTenantId: string) =>
  localStorage.setItem(LocalStorageKeys.DirectoryTenantId, directoryTenantId);

const initialState: DirectoryState = {
  directories: [],
  loading: false,
  selectorLoading: false,
  modalType: "create",
  showModal: false,
  updateDirectories: false,
};

export const directoryReducer = (
  state = initialState,
  action: any
): DirectoryState => {
  let tempDirectory: Directory;

  switch (action.type) {
    case CHANGE_DIRECTORY:
      tempDirectory = findDirectoryOrDefault(
        action.directoryTenantId,
        state.directories
      );
      saveDirectoryTenantIdToLocalStorage(tempDirectory.DirectoryTenantID);

      return {
        ...state,
        selectorDirectory: tempDirectory
      };

    case HIDE_DIRECTORY_MODAL:
      return {
        ...state,
        showModal: false
      };

    case LOADING_DIRECTORIES:
      return {
        ...state,
        selectorLoading: true
      };

    case LOADING_DIRECTORY:
      return {
        ...state,
        loading: true
      };

    case LOADED_DIRECTORY:
      return {
        ...state,
        loading: false,
        showModal: !action.result,
        updateDirectories: action.result
          ? !state.updateDirectories
          : state.updateDirectories
      };

    case LOADING_DIRECTORIES_FAILED:
      return {
        ...state,
        selectorLoading: false
      };

    case LOADING_DIRECTORIES_SUCCEDED:
      return {
        ...state,
        directories: action.directories,
        selectorLoading: false
      };

    case LOAD_DIRECTORY_FROM_LOCALSTORAGE:
      tempDirectory = findDirectoryOrDefault(
        getDirectoryTenantIdFromLocalStorage(),
        state.directories
      );
      saveDirectoryTenantIdToLocalStorage(tempDirectory.DirectoryTenantID);

      return {
        ...state,
        selectorDirectory: tempDirectory
      };

    case OPEN_DIRECTORY_MODAL:
      return {
        ...state,
        showModal: true,
        modalType: action.modalType,
        directoryId: action.directoryId
      };

    case SET_DIRECTORY:
      return {
        ...state,
        directory: action.directory,
        loading: false
      };

    default:
      return state;
  }
};
