import React from "react";
import "./PoweredBySikuFooter.css";
import { Row } from "antd";

export const PoweredBySikuFooter: React.FC = () => {
  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      className="powered-by-siku-footer"
    >
      © {(new Date().getFullYear())} Siku. All rights reserved.
    </Row>
  );
};
