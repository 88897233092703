import React, { useEffect } from "react";
import "./InsurancesPage.css";
import {
  Table,
  Divider,
  Button,
  Row,
  Tooltip,
  Popconfirm,
  message,
  Col,
  Input,
  Typography,
} from "antd";
import { Trans, t } from "@lingui/macro";
import { Container, YesNoCell } from "../../components/utils";
import {
  InsuranceState,
  Insurance,
  showInsuranceModalForCreate,
  showInsuranceModalForEdit,
  filterInsurances,
} from "../../store/insurance";
import { useSelector, useDispatch } from "react-redux";
import {
  loadInsurancesFromAdmin,
  createInsurance,
  updateInsurance,
  deleteInsurance,
} from "../../services/insurance";
import { i18n } from "../../utils";
import { InsuranceModal } from "../../components/insurance-modal";

export const InsurancesPage: React.FC = (props) => {
  const pageSize = 5;
  const insuranceState = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    loadInsurancesFromAdmin();
  }, [insuranceState.updateInsurances]);

  const onCreateInsurance = () => {
    dispatch(showInsuranceModalForCreate());
  };

  const onCreate = (insurance: Insurance) => {
    createInsurance(insurance);
  };

  const onUpdate = (insurance: Insurance) => {
    updateInsurance(insurance);
  };

  const onDeleteInsurance = (insuranceId: number) => {
    
    message.loading(
      i18n._(t("InsurancePage.DeletingInsurance")`Deleting insurance...`),
      0.5,
      () => deleteInsurance(insuranceId)
    );
  };

  const onUpdateInsurance = (insurance: Insurance) => {
    dispatch(showInsuranceModalForEdit(insurance));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Insurances.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="Insurances.ActiveColumnTitle">
          Active
        </Trans>
      ),
      key: "IsActive",
      render: (insurance: Insurance) => <YesNoCell value={insurance.IsActive}  showIcon={true} />,
    },
    {
      title: (
        <Trans render="strong" id="Insurances.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (insurance: Insurance) => (
        <span>
          <Tooltip
            title={<Trans id="Insurances.EditActionTooltip">Edit insurance</Trans>}
          >
            <Button icon="edit" onClick={() => onUpdateInsurance(insurance)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Insurances.DeleteActionTooltip">Delete insurance</Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="Insurances.DeleteActionConfirmationText">
                  Do you wish to delete this insurance?
                </Trans>
              }
              okText={<Trans id="Insurances.DeleteActionButton">Delete</Trans>}
              onConfirm={() => onDeleteInsurance(insurance.InsuranceID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const filterFoundInsurances = (value: string) => {
    dispatch(filterInsurances(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundInsurances(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <Typography.Title level={2}>
          <Trans id="Insurances.Title">Insurances</Trans>
        </Typography.Title>
        <Table
          dataSource={insuranceState.filteredInsurances}
          columns={columns}
          loading={insuranceState.loading}
          bodyStyle={{ background: "#fff" }}
          rowKey="InsuranceID"
          pagination={
            insuranceState.count > pageSize && {
              pageSize,
              position: "bottom",
            }
          }
          title={() => (
            <Row type="flex" align="middle">
              <Col span={6}>
                <Input
                  onChange={onChange}
                  placeholder={i18n._(
                    t(
                      "Insurances.FilterInsurancesPlaceHolder"
                    )`Search by Insurance name`
                  )}
                />
              </Col>
              <Col span={18}>
                <Row type="flex" justify="end" gutter={8}>
                  <Col>
                    <Button
                      type="primary"
                      size="large"
                      onClick={onCreateInsurance}
                    >
                      <Trans id="Insurances.CreateInsuranceButton">
                        New Insurance
                      </Trans>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        />
      </Container>
      <InsuranceModal
        loading={insuranceState.changing}
        modalType={insuranceState.modalType}
        onCreate={onCreate}
        onUpdate={onUpdate}
        showModal={insuranceState.showModal}
        insurance={insuranceState.insurance}
        insuranceNames = {insuranceState.insurances.map(a=>a.Name)}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): InsuranceState => state.insuranceState;