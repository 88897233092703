import { PatientState } from "./Types";
import { SEARCHING_PATIENT, SET_PATIENT, CLEAN_PATIENT } from "./Actions";
import {
  SCHEDULING_APPOINTMENT_FINISHED,
  CANCELLING_APPOINTMENT_FINISHED
} from "../apointment";

const initialState: PatientState = {
  hasSearched: false,
  loading: false,
  updatePatient: false, 
  patient: undefined
};

export const patientReducer = (
  state = initialState,
  action: any
): PatientState => {
  switch (action.type) {
    case CLEAN_PATIENT:
      return {
        ...state,
        hasSearched: false,
        patient: undefined
      };

    case SEARCHING_PATIENT:
      return {
        ...state,
        loading: true,
        updatePatient: false
      };

    case SET_PATIENT:
      return {
        ...state,
        hasSearched: true,
        loading: false,
        patient: action.patient
      };

    case CANCELLING_APPOINTMENT_FINISHED:
    case SCHEDULING_APPOINTMENT_FINISHED:
      return {
        ...state,
        updatePatient: action.result
          ? !state.updatePatient
          : state.updatePatient
      };

    default:
      return state;
  }
};
