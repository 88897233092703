import { ProviderFocus } from "./Types";

export const LOADING_PROVIDER_FOCUS = "LOADING_PROVIDER_FOCUS";
export const SET_PROVIDER_FOCUS = "SET_PROVIDER_FOCUS";
export const UPDATE_PROVIDER_FOCUS = "UPDATE_PROVIDER_FOCUS";

export const loadingProviderFocus = () => ({ type: LOADING_PROVIDER_FOCUS });

export const setProviderFocus = (providerFocus: ProviderFocus[]) => ({
  type: SET_PROVIDER_FOCUS,
  providerFocus
});

export const updateProviderFocus = () => ({
  type: UPDATE_PROVIDER_FOCUS
});
