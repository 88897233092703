import { Account } from "../account";

export const CLEAN_PATIENT = "CLEAN_PATIENT";
export const SEARCHING_PATIENT = "SEARCHING_PATIENT";
export const SET_PATIENT = "SET_PATIENT";
export const PATIENT_UPDATE = "PATIENT_UPDATE";
export const cleanPatient = () => ({
  type: CLEAN_PATIENT
});

export const searchingPatient = () => ({
  type: SEARCHING_PATIENT
});

export const setPatient = (patient: Account | undefined) => ({
  type: SET_PATIENT,
  patient
});

