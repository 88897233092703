import httpClient from "../HttpClient";
import store from "../../store";
import {
  ProviderAppointmentReason,
  updatingProviderAppointmentReason,
  updatedProviderAppointmentReason
} from "../../store/providerAppointmentReason";
import { getDirectoryTenantId } from "../../utils";
import { LocalStorageKeys } from "../../utils/Constants";


export const createProviderAppointmentReason = async (
  providerID: string,
  request: ProviderAppointmentReason
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/appointmentReason`;

  try {
    store.dispatch(updatingProviderAppointmentReason());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(updatedProviderAppointmentReason(true));
    return true;
  } catch {
    store.dispatch(updatedProviderAppointmentReason(false));
    return false;
  }
};

export const updateProviderAppointmentReason = async (
  providerID: string,
  ProviderAppointmentReason: ProviderAppointmentReason
) => {
  const { ProviderAppointmentReasonID, ...rest } = ProviderAppointmentReason;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/appointmentReason/${ProviderAppointmentReasonID}`;

  try {
    store.dispatch(updatingProviderAppointmentReason());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(updatedProviderAppointmentReason(true));
    return true;
  } catch {
    store.dispatch(updatedProviderAppointmentReason(false));
    return false;
  }
};

export const deleteProviderAppointmentReason = async (
  providerID: string,
  ProviderAppointmentReasonID: number
) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/appointmentReason/${ProviderAppointmentReasonID}`;

  try {
    store.dispatch(updatingProviderAppointmentReason());
    await httpClient.delete(url);
    store.dispatch(updatedProviderAppointmentReason(true));
    return true;
  } catch {
    store.dispatch(updatedProviderAppointmentReason(false));
    return false;
  }
};
