import { ReviewState } from "./Types";
import {
  LOADING_REVIEWS,
  SET_REVIEWS,
  UPDATE_REVIEWS,
  CHANGING_REVIEW,
  CHANGING_REVIEW_FINISHED,
  SHOW_REVIEW_MODAL_FOR_EDIT,
  HIDE_REVIEW_MODAL,
  SET_REVIEW,
} from "./Actions";
import { CHANGE_DIRECTORY } from "../directory";

const initialState: ReviewState = {
  reviews: [],
  loading: false,
  updateReviews: false,
  modalType: "edit",
  showModal: false,
  changing: false,
  count: 0,
};

export const reviewReducer = (
  state = initialState,
  action: any
): ReviewState => {
  switch (action.type) {
    case CHANGING_REVIEW:
      return {
        ...state,
        changing: true,
      };

    case CHANGING_REVIEW_FINISHED:
      return {
        ...state,
        changing: false,
        review: undefined,
        showModal: !action.result,
        updateReviews: action.result
          ? !state.updateReviews
          : state.updateReviews,
      };
    case HIDE_REVIEW_MODAL:
      return {
        ...state,
        review: undefined,
        showModal: false,
      };

    case LOADING_REVIEWS:
      return {
        ...state,
        loading: true,
      };

    case SHOW_REVIEW_MODAL_FOR_EDIT:
      return {
        ...state,
        review: action.review,
        modalType: "edit",
        showModal: true,
      };

    case SET_REVIEWS:
      return {
        ...state,
        reviews: action.reviews,
        loading: false,
      };

    case SET_REVIEW:
      return {
        ...state,
        reviews: action.reviews,
        count: action.reviews.length,
        loading: false,
      };
    case CHANGE_DIRECTORY:
    case UPDATE_REVIEWS:
      return {
        ...state,
        review: undefined,
        reviews: [],
        updateReviews: !state.updateReviews,
      };

    default:
      return state;
  }
};

