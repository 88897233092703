import React from "react";
import { Tabs, Collapse, Row, Col, Button, Empty } from "antd";
import { AdvancedSearchDatesTabsProps } from "./Types";
import moment from "moment";
import { Appointment, showModalForSchedule } from "../../../store/apointment";
import { AdvancedProvider } from "../../../store/advanced-search";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";

export const AdvancedSearchDatesTabs: React.FC<AdvancedSearchDatesTabsProps> = (
  props
) => {
  const { advancedSearchState } = props;
  const dispatch = useDispatch();

  const onTimeSelected = (value: string) => {
    const [providerId, strDate, strTime] = value.split("@");
    const provider = advancedSearchState.dates
      .find((x) => x.Date.format("YYYY-MM-DD"))
      ?.Providers.find((x) => x.ProviderID === providerId) as AdvancedProvider;
    const date = moment(strDate, "YYYY-MM-DD");
    const startTime = moment(strTime, "HH:mm");
    const endTime = moment(strTime, "HH:mm").add(
      provider.AppointmentIntervalMinutes,
      "minutes"
    );

    const appointment: Appointment = {
      agendaId: provider.AgendaID,
      agendaName: provider.AgendaName,
      date,
      endTime,
      locationId: provider.LocationID,
      providerId,
      startTime,
      notes: "",
      allowReSchedule: true,
      isTelehealth: true,
    };

    dispatch(showModalForSchedule(appointment));
  };

  return (
    <Row
      type="flex"
      justify="center"
      style={{ marginTop: "10px", width: "100%" }}
    >
      {advancedSearchState.dates.length <= 0 ? (
        <Empty
          description={
            <Trans render="span" id="Common.NoData">
              There's no data
            </Trans>
          }
        />
      ) : (
        <Tabs style={{ width: "100%" }}>
          {advancedSearchState.dates.map((date, index) => (
            <Tabs.TabPane
              tab={date.Date.format("dddd LL")}
              key={index.toString()}
            >
              <Collapse>
                {date.Providers.map((provider, index) => (
                  <Collapse.Panel header={provider.FullName} key={index}>
                    <Row type="flex" gutter={[4, 8]}>
                      {provider.Hours.map((time, index) => (
                        <Col key={index}>
                          <Button
                            onClick={(e) =>
                              onTimeSelected(
                                `${provider.ProviderID}@${date.Date.format(
                                  "YYYY-MM-DD"
                                )}@${time.format("HH:mm")}`
                              )
                            }
                          >
                            {time.format("hh:mm a")}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Tabs.TabPane>
          ))}
        </Tabs>
      )}
    </Row>
  );
};
