import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import {
  loadingProviderEducationCourse,
  ProviderEducationCourse,
  setProviderEducationCourse,
  updatingProviderEducationCourse,
  updatedProviderEducationCourse
} from "../../store/providerEducationCourse";
import { getDirectoryTenantId } from "../../utils";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadProviderEducationCourses = async (providerID: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `${providerID}/educationCourse`;

  try {
    store.dispatch(loadingProviderEducationCourse());
    const response = await httpClient.get<
      GenericApiResponse<ProviderEducationCourse[]>
    >(url);
    store.dispatch(setProviderEducationCourse(response.data.Data));
    return true;
  } catch {
    store.dispatch(setProviderEducationCourse([]));
    return false;
  }
};

export const createProviderEducationCourse = async (
  providerID: string,
  request: ProviderEducationCourse
) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `provider/${providerID}/educationCourse`;

  try {
    store.dispatch(updatingProviderEducationCourse());
    await httpClient.post(url, {
      ...request,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(updatedProviderEducationCourse(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationCourse(false));
    return false;
  }
};

export const updateProviderEducationCourse = async (
  providerID: string,
  providerEducationCourse: ProviderEducationCourse
) => {
  const { ProviderEducationCourseID, ...rest } = providerEducationCourse;
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`${providerID}/educationCourse/${ProviderEducationCourseID}`;

  try {
    store.dispatch(updatingProviderEducationCourse());
    await httpClient.put(url, {
      ...rest,
      DirectoryTenantID: getDirectoryTenantId()
    });
    store.dispatch(updatedProviderEducationCourse(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationCourse(false));
    return false;
  }
};

export const deleteProviderEducationCourse = async (
  providerID: string,
  providerEducationCourseId: number
) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${providerID}/EducationCourse/${providerEducationCourseId}`;

  try {
    store.dispatch(updatingProviderEducationCourse());
    await httpClient.delete(url);
    store.dispatch(updatedProviderEducationCourse(true));
    return true;
  } catch {
    store.dispatch(updatedProviderEducationCourse(false));
    return false;
  }
};
