import React, { useEffect } from "react";
import "./AdvancedSearchPage.css";
import { Row } from "antd";
import { cleanFilters, selectState } from "../../store/search";
import { useSelector, useDispatch } from "react-redux";
import { loadStates, loadStateCounties } from "../../services/location";
import { DirectoryState } from "../../store/directory";
import { SpecialtyState } from "../../store/specialty";
import { LocationState } from "../../store/location";
import { loadSpecialties } from "../../services/specialty";
import {
  AppointmentCancelModal,
  AppointmentScheduleModalCreate
} from "../../components/appointment-modal";
import { AppointmentState } from "../../store/apointment";
import { AccountState } from "../../store/account";
import { AccountModal } from "../../components/account-modal";
import { AdvancedSearchHeader } from "../../components/advanced-search/header";
import {
  AdvancedSearchState,
  AdvancedSearchParams
} from "../../store/advanced-search";
import {
  advanceSearchBySpecialty,
  advanceSearchByDate
} from "../../services/search";
import { AdvancedSearchProvidersTabs } from "../../components/advanced-search/providers-tabs";
import { AdvancedSearchDatesTabs } from "../../components/advanced-search/dates-tabs";

export const AdvancedSearchPage: React.FC = () => {
  const {
    accountState,
    appointmentState,
    directoryState,
    locationState,
    advancedSearchState,
    specialtyState
  } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    if (directoryState.selectorDirectory !== undefined) {
      loadSpecialties();
      loadStates();
    }
  }, [directoryState.selectorDirectory]);

  useEffect(() => {
    if (advancedSearchState.params.stateId !== undefined)
      loadStateCounties(advancedSearchState.params.stateId);
  }, [advancedSearchState.params.stateId]);

  const onCleanFilters = () => {
    dispatch(cleanFilters());
  };

  const onSearch = (params: AdvancedSearchParams) => {
    if (params.type === "specialty") {
      advanceSearchBySpecialty(params);
    } else {
      advanceSearchByDate(params);
    }
  };

  const onStateChange = (value: number) => {
    dispatch(selectState(value));
  };

  return (
    <div className="advanced-search-page">
      <Row
        type="flex"
        align="middle"
        className="advanced-search-header-container"
      >
        <AdvancedSearchHeader
          directoryTenantId={directoryState.directory?.DirectoryTenantID}
          locationState={locationState}
          onCleanFilters={onCleanFilters}
          onSearch={onSearch}
          onStateChange={onStateChange}
          advancedSearchState={advancedSearchState}
          specialtyState={specialtyState}
        />
      </Row>
      <Row type="flex" className="advanced-search-body-container">
        {advancedSearchState.hasSearched ? (
          advancedSearchState.params.type === "specialty" ? (
            <AdvancedSearchProvidersTabs
              advancedSearchState={advancedSearchState}
            />
          ) : (
            <AdvancedSearchDatesTabs
              advancedSearchState={advancedSearchState}
            />
          )
        ) : null}
      </Row>
      {appointmentState.showCancelModal && <AppointmentCancelModal />}
      {appointmentState.showScheduleModal && (
        <AppointmentScheduleModalCreate
          specialtyId={advancedSearchState.params.specialtyId}
        />
      )}
      {accountState.showModal && <AccountModal />}
    </div>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  accountState: state.accountState,
  appointmentState: state.appointmentState,
  directoryState: state.directoryState,
  locationState: state.locationState,
  advancedSearchState: state.advancedSearchState,
  specialtyState: state.specialtyState
});

interface StateToProps {
  accountState: AccountState;
  appointmentState: AppointmentState;
  directoryState: DirectoryState;
  locationState: LocationState;
  advancedSearchState: AdvancedSearchState;
  specialtyState: SpecialtyState;
}
