import { EventInput } from "@fullcalendar/core";
import { ResourceInput } from "@fullcalendar/resource-common/structs/resource";
import moment from "moment";

export const CHANGE_CALENDAR_VIEW = "CHANGE_CALENDAR_VIEW";
export const LOADED_CALENDAR_EVENTS = "LOADED_CALENDAR_EVENTS";
export const LOADING_CALENDAR_EVENTS = "LOADING_CALENDAR_EVENTS";
export const LOADED_CALENDAR_RESOURCES = "LOADED_CALENDAR_RESOURCES";
export const LOADING_CALENDAR_RESOURCES = "LOADING_CALENDAR_RESOURCES";
export const RELOAD_CALENDAR = "RELOAD_CALENDAR";
export const SET_CALENDAR_DATE = "SET_CALENDAR_DATE";

export const changeCalendarView = (
  view: "resourceTimeGridOneDay" | "resourceTimeGridSevenDay"
) => ({
  type: CHANGE_CALENDAR_VIEW,
  view
});

export const loadedCalendarEvents = (events: EventInput[]) => ({
  type: LOADED_CALENDAR_EVENTS,
  events
});

export const loadingCalendarEvents = () => ({
  type: LOADING_CALENDAR_EVENTS
});

export const loadedCalendarResources = (resources: ResourceInput[]) => ({
  type: LOADED_CALENDAR_RESOURCES,
  resources
});

export const loadingCalendarResources = () => ({
  type: LOADING_CALENDAR_RESOURCES
});

export const reloadCalendar = () => ({ type: RELOAD_CALENDAR });

export const setCalendarDate = (date: moment.Moment) => ({
  type: SET_CALENDAR_DATE,
  date
});
