import React from "react";
import "./KeywordModal.css";
import { Form, Input, Modal, Row, Col, Button } from "antd";
import { Trans } from "@lingui/macro";
import { nameofFactory } from "../../utils";
import { Keyword, hideKeywordModal } from "../../store/keyword";
import { KeywordModalProps } from "./Types";
import { useDispatch } from "react-redux";

const FormStateless: React.FC<KeywordModalProps> = (props) => {
  const {
    form,
    loading,
    modalType,
    onCreate,
    onUpdate,
    showModal,
    keyword,
  } = props;
  const { getFieldDecorator, validateFields } = form;
  const dispatch = useDispatch();
  const nameof = nameofFactory<Keyword>();

  const handleSubmit = () => {
    validateFields((err, values) => {
      if (err) {
        return;
      }

      const keywordRequest = {
        ...values,
        KeywordID: keyword?.KeywordID,
      };

      if (modalType === "create") onCreate(keywordRequest);
      else onUpdate(keywordRequest);
    });
  };

  const onModalDismiss = () => {
    dispatch(hideKeywordModal());
  };

  return (
    <React.Fragment>
      <Modal
        centered
        className="keyword-modal"
        destroyOnClose
        visible={showModal}
        okButtonProps={{ loading: loading }}
        footer={
          <Row type="flex" justify="end" gutter={16}>
            <Col>
              <Button loading={loading} onClick={handleSubmit} type="primary">
                {modalType === "create" ? (
                  <Trans render="span" id="Keywords.CreateKeywordFormOkButton">
                    Create
                  </Trans>
                ) : (
                  <Trans render="span" id="Keywords.EditKeywordFormOkButton">
                    Update
                  </Trans>
                )}
              </Button>
            </Col>
            <Col>
              <Button disabled={loading} onClick={onModalDismiss}>
                <Trans render="span" id="Keywords.CancelButton">
                  Cancel
                </Trans>
              </Button>
            </Col>
          </Row>
        }
        onCancel={onModalDismiss}
        title={
          modalType === "create" ? (
            <Trans id="Keywords.CreateKeywordFormTitle">Create keyword</Trans>
          ) : (
            <Trans id="Keywords.EditKeywordFormTitle">Edit keyword</Trans>
          )
        }
      >
        <Form colon={false}>
          <Form.Item label={<Trans id="Keywords.Form.TermLabel">Term</Trans>}>
            {getFieldDecorator(nameof("Term"), {
              initialValue: keyword?.Term,
              rules: [
                {
                  required: true,
                  message: (
                    <Trans id="Keywords.Form.TermRequiredError">
                      This field is required
                    </Trans>
                  ),
                },
              ],
            })(<Input maxLength={60} />)}
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default Form.create<KeywordModalProps>()(FormStateless);
