import React, { useEffect } from "react";
import "./ClinicsPage.css";
import {
  ClinicState,
  Clinic,
  showClinicModalForCreate,
  showClinicModalForEdit,
  filterClinics,
} from "../../store/clinic";
import { useSelector, useDispatch } from "react-redux";
import { Trans, t } from "@lingui/macro";
import {
  Tooltip,
  Avatar,
  Button,
  Divider,
  Popconfirm,
  Row,
  Table,
  message,
  Typography,
  Col,
  Input,
} from "antd";
import {
  loadClinics,
  deleteClinic,
  createClinic,
  updateClinic,
} from "../../services/clinic";
import { Container } from "../../components/utils";
import { ClinicModal } from "../../components/clinic-modal";
import { i18n } from "../../utils";

export const ClinicsPage: React.FC = () => {
  const pageSize = 20;
  const clinicState = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    loadClinics();
  }, [clinicState.updateClinics]);

  const onCreateClinic = () => {
    dispatch(showClinicModalForCreate());
  };

  const onCreate = (clinic: Clinic) => {
    createClinic(clinic);
  };

  const onUpdate = (clinic: Clinic) => {
    updateClinic(clinic);
  };

  const onDeleteClinic = (clinicId: number) => {
    message.loading(
      i18n._(t("ClinicPage.DeletingClinic")`Deleting clinic...`),
      0.5,
      () => deleteClinic(clinicId)
    );
  };

  const onUpdateClinic = (clinic: Clinic) => {
    dispatch(showClinicModalForEdit(clinic));
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="Clinics.LogoColumnTitle">
          Logo
        </Trans>
      ),
      key: "Logo",
      render: (clinic: Clinic) => (
        <span>
          <Tooltip title={clinic.URLLogo}>
            <Avatar shape="square" size="large" src={clinic.URLLogo} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: (
        <Trans render="strong" id="Clinics.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="Clinics.UrlSlugColumnTitle">
          UrlSlug
        </Trans>
      ),
      dataIndex: "UrlSlug",
      key: "UrlSlug",
    },
    {
      title: (
        <Trans render="strong" id="Clinics.AboutColumnTitle">
          About
        </Trans>
      ),
      dataIndex: "About",
      key: "About",
    },
    {
      title: (
        <Trans render="strong" id="Clinics.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (clinic: Clinic) => (
        <span>
          <Tooltip
            title={<Trans id="Clinics.EditActionTooltip">Edit clinic</Trans>}
          >
            <Button icon="edit" onClick={() => onUpdateClinic(clinic)} />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="Clinics.DeleteActionTooltip">Delete clinic</Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="Clinic.DeleteActionConfirmationText">
                  Do you wish to delete this clinic?
                </Trans>
              }
              okText={<Trans id="Clinic.DeleteActionButton">Delete</Trans>}
              onConfirm={() => onDeleteClinic(clinic.ClinicID)}
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
      width: 150,
    },
  ];
  const filterFoundClinics = (value: string) => {
    dispatch(filterClinics(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundClinics(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <div className="clinics-page">
          <Typography.Title level={2}>
            <Trans id="ClinicsPage.Title">Clinics</Trans>
          </Typography.Title>
          <Table
            className="clinics-table"
            dataSource={clinicState.filteredClinics}
            columns={columns}
            loading={clinicState.loading}
            bodyStyle={{ background: "#fff" }}
            rowKey="ClinicID"
            pagination={
              clinicState.count > pageSize && {
                pageSize,
                position: "bottom",
              }
            }
            title={() => (
              <Row type="flex" align="middle">
                <Col span={6}>
                  <Input
                    onChange={onChange}
                    placeholder={i18n._(
                      t("Clinics.FilterClinicsPlaceholder")`Search clinics`
                    )}
                  />
                </Col>
                <Col span={18}>
                  <Row type="flex" justify="end">
                    <Button
                      type="primary"
                      size="large"
                      onClick={onCreateClinic}
                    >
                      <Trans id="Clinics.CreateClinicButton">New Clinic</Trans>
                    </Button>
                  </Row>
                </Col>
              </Row>
            )}
          />
        </div>
      </Container>
      <ClinicModal
        clinic={clinicState.clinic}
        loading={clinicState.changing}
        modalType={clinicState.modalType}
        onCreate={onCreate}
        onUpdate={onUpdate}
        showModal={clinicState.showModal}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): ClinicState => state.clinicState;
