import { UserPasswordResetState } from "./Types";
import { RESETING_PASSWORD, RESETING_PASSWORD_FINISHED } from "./Actions";

const initialState: UserPasswordResetState = {
  loading: false,
};

export const userPasswordResetReducer = (
  state = initialState,
  action: any
): UserPasswordResetState => {
  switch (action.type) {
    case RESETING_PASSWORD:
      return {
        ...state,
        loading: true,
      };

    case RESETING_PASSWORD_FINISHED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
