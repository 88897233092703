import React, { useEffect } from "react";
import { StandardLayoutRouteProps } from "./Types";
import { Route, useHistory, useLocation } from "react-router-dom";
import { StandardLayout } from "./StandardLayout";
import { useSelector } from "react-redux";
import { SearchQueryKeys } from "../../utils/Constants";

export const StandardLayoutRoute: React.FC<StandardLayoutRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const isAuthenticated = useSelector(mapStateToProps);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated)
      history.push(`/auth?${SearchQueryKeys.ReturnUrl}=${location.pathname}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <StandardLayout>
          <Component {...matchProps} />
        </StandardLayout>
      )}
    />
  );
};

const mapStateToProps = (state: any): boolean =>
  state.authState.isAuthenticated;
