import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import { ResourceInput } from "@fullcalendar/resource-common/structs/resource";
import { CalendarRequest } from "./Types";
import { getDirectoryTenantId } from "../../utils";
import axios from "axios";
import { SearchProvider } from "../../store/search";
import store from "../../store";
import {
  loadingCalendarResources,
  loadedCalendarResources,
  loadingCalendarEvents,
  loadedCalendarEvents
} from "../../store/calendar";
import { LocalStorageKeys } from "../../utils/Constants";

export const loadEvents = async (request: CalendarRequest) => {
  const { providers, ...rest } = request;
  const params = formatParams(rest);
  const resourcesRequests = generateEventsRequests(providers, params);

  if (resourcesRequests.length === 0) return [];

  try {
    store.dispatch(loadingCalendarEvents());
    const responses = await axios.all(resourcesRequests);
    store.dispatch(
      loadedCalendarEvents(responses.flatMap(response => response.data.Data))
    );
    return true;
  } catch (err) {
    store.dispatch(loadedCalendarEvents([]));
    return false;
  }
};

export const loadResources = async (request: CalendarRequest) => {
  const { providers, ...rest } = request;
  const params = formatParams(rest);
  const resourcesRequests = generateResourcesRequests(providers, params);

  if (resourcesRequests.length === 0) return [];

  try {
    store.dispatch(loadingCalendarResources());
    const responses = await axios.all(resourcesRequests);
    store.dispatch(
      loadedCalendarResources(responses.flatMap(response => response.data.Data))
    );
    return true;
  } catch (err) {
    store.dispatch(loadedCalendarResources([]));
    return false;
  }
};

const formatParams = (prevParams: any) => {
  return {
    from: prevParams.start.format("YYYY-MM-DD"),
    to: prevParams.end.format("YYYY-MM-DD")
  };
};

const generateEventsRequests = (providers: SearchProvider[], params: any) => {
  const requests: any[] = [];

  providers.forEach(provider => {
    if (provider.IsChecked)
      requests.push(
        httpClient.get<GenericApiResponse<ResourceInput>>(
          localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${getDirectoryTenantId()}/${
            provider.ProviderID
          }/appointments`,
          {
            params: {
              ...params,
              agendas: provider.Agendas.join(",")
            }
          }
        )
      );
  });

  return requests;
};

const generateResourcesRequests = (
  providers: SearchProvider[],
  params: any
) => {
  const requests: any[] = [];

  providers.forEach(provider => {
    if (provider.IsChecked)
      requests.push(
        httpClient.get<GenericApiResponse<ResourceInput>>(
          localStorage.getItem(LocalStorageKeys.baseApiUrl) +`provider/${getDirectoryTenantId()}/${provider.ProviderID}/timeslots`,
          {
            params: {
              ...params,
              agendas: provider.Agendas.join(",")
            }
          }
        )
      );
  });

  return requests;
};
