import React, { useEffect } from "react";
import "./PatientsPage.css";
import { searchPatient } from "../../services/account";
import { SearchAccountRequest } from "../../services/account/Types";
import { Row, Typography } from "antd";
import { PatientsHeader } from "../../components/patients/header";
import { PatientInfo } from "../../components/patients/patient-info";
import { useSelector, useDispatch } from "react-redux";
import {
  AppointmentCancelModal,
  AppointmentScheduleModalUpdate,
} from "../../components/appointment-modal";
import { AppointmentState } from "../../store/apointment";
import { PatientState } from "../../store/patient/Types";
import { cleanPatient } from "../../store/patient";
import { Trans } from "@lingui/macro";
import { Container } from "../../components/utils";

export const PatientsPage: React.FC = () => {
  const { patientState, appointmentState } = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(cleanPatient());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (request: SearchAccountRequest) => {
    searchPatient(request);
  };

  return (
    <Container>
      <div className="patients-page">
        <Typography.Title level={2}>
          <Trans id="PatientsPage.Title">Patients search</Trans>
          {patientState.updatePatient}
        </Typography.Title>

        <Row type="flex" align="middle" className="patients-header-container">
          <PatientsHeader
            loading={patientState.loading}
            onSearch={onSearch}
            updateAccount={patientState.updatePatient}
            refreshByID={patientState.patient?.IdentificationNumber}
          />
        </Row>
        <div className="patients-body-container">
          {patientState.hasSearched && patientState.patient && (
            <PatientInfo
              account={patientState.patient}
              loading={patientState.loading}
            />
          )}
        </div>
        {appointmentState.showCancelModal && <AppointmentCancelModal />}
        {appointmentState.showScheduleModal && <AppointmentScheduleModalUpdate />}
      </div>
    </Container>
  );
};

const mapStateToProps = (state: any): StateToProps => ({
  patientState: state.patientState,
  appointmentState: state.appointmentState,
});

interface StateToProps {
  patientState: PatientState;
  appointmentState: AppointmentState;
}
