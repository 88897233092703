import { ProviderEducationDegree } from "./Types";

export const CHANGE_PROVIDER_EDUCATION_DEGREE_FIELD =
  "CHANGE_PROVIDER_EDUCATION_DEGREE_FIELD";
export const SET_CALENDAR_DATE_FROM = "SET_CALENDAR_DATE_FROM";
export const SET_CALENDAR_DATE_TO = "SET_CALENDAR_DATE_TO";
export const CHANGING_PROVIDER_EDUCATION_DEGREE =
  "CHANGING_PROVIDER_EDUCATION_DEGREE";
export const CHANGING_PROVIDER_EDUCATION_DEGREE_FINISHED =
  "CHANGING_PROVIDER_EDUCATION_DEGREE_FINISHED";
export const LOADING_PROVIDER_EDUCATION_DEGREE =
  "LOADING_PROVIDER_EDUCATION_DEGREE";
export const SET_PROVIDER_EDUCATION_DEGREE = "SET_PROVIDER_EDUCATION_DEGREE";
export const UPDATE_PROVIDER_EDUCATION_DEGREE =
  "UPDATE_PROVIDER_EDUCATION_DEGREE";
export const EDIT_EDUCATION_DEGREE = "EDIT_EDUCATION_DEGREE";
export const STOP_EDIT_EDUCATION_DEGREE = "STOP_EDIT_EDUCATION_DEGREE";

export const UPDATING_PROVIDER_EDUCATION_DEGREE =
  "UPDATING_PROVIDER_EDUCATION_DEGREE";

export const UPDATED_PROVIDER_EDUCATION_DEGREE =
  "STOP_EDITUPDATED_PROVIDER_EDUCATION_DEGREE_EDUCATION_DEGREE";

export const HIDE_NEW_PROVIDER_EDUCATION_DEGREE_MODAL =
  "HIDE_NEW_PROVIDER_EDUCATION_DEGREE_MODAL";

export const SHOW_NEW_PROVIDER_EDUCATION_DEGREE_CREATE_MODAL =
  "SHOW_NEW_PROVIDER_EDUCATION_DEGREE_CREATE_MODAL";

export const changeProviderEducationDegreeField = (
  name: string,
  value: any
) => ({
  type: CHANGE_PROVIDER_EDUCATION_DEGREE_FIELD,
  name,
  value,
});

export const setCalendarDateEducationDegreeDateFrom = (date: any) => ({
  type: SET_CALENDAR_DATE_FROM,
  date,
});

export const setCalendarDateEducationDegreeDateTo = (date: any) => ({
  type: SET_CALENDAR_DATE_TO,
  date,
});

export const editProviderEducationDegree = (
  providerEducationDegree: ProviderEducationDegree
) => ({
  type: EDIT_EDUCATION_DEGREE,
  providerEducationDegree,
});

export const stopEditEducationDegree = (providerEducationDegreeID: number) => ({
  type: STOP_EDIT_EDUCATION_DEGREE,
  providerEducationDegreeID,
});

export const loadingProviderEducationDegree = () => ({
  type: LOADING_PROVIDER_EDUCATION_DEGREE,
});

export const setProviderEducationDegree = (
  providerEducationDegree: ProviderEducationDegree[]
) => ({
  type: SET_PROVIDER_EDUCATION_DEGREE,
  providerEducationDegree,
});

export const updateProviderEducationDegree = () => ({
  type: UPDATE_PROVIDER_EDUCATION_DEGREE,
});

export const updatingProviderEducationDegree = () => ({
  type: UPDATING_PROVIDER_EDUCATION_DEGREE,
});

export const updatedProviderEducationDegree = (result: boolean) => ({
  type: UPDATED_PROVIDER_EDUCATION_DEGREE,
  result,
});

export const hideNewProviderEducationDegreeModal = () => ({
  type: HIDE_NEW_PROVIDER_EDUCATION_DEGREE_MODAL,
});

export const showNewProviderEducationDegreeCreateModal = () => ({
  type: SHOW_NEW_PROVIDER_EDUCATION_DEGREE_CREATE_MODAL,
});
