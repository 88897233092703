import React from "react";
import "./PasswordResetCompletePage.css";
import { AuthPasswordResetComplete } from "../../components/auth/passwordResetComplete";
import { useSelector } from "react-redux";
import {
  UserPasswordResetState,
  UserPasswordResetComplete,
} from "../../store/passwordReset";
import { sendPasswordResetEmailComplete } from "../../services/user";
import { useHistory, useParams } from "react-router-dom";
import { Layout, Row, Col } from "antd";
import { PoweredBySikuFooter } from "../../components/powered-by-siku-footer";
import { PasswordResetCompletePageProps } from "./Types";

export const PasswordResetCompletePage: React.FC<PasswordResetCompletePageProps> = ({
  form,
}) => {
  const userPasswordResetState = useSelector(mapStateToProps);
  const history = useHistory();
  const { id } = useParams();

  //  const { validateFields } = form;

  const onPasswordResetComplete = (
    userPasswordResetComplete: UserPasswordResetComplete
  ) => {
    if (id) {
      sendPasswordResetEmailComplete(id, userPasswordResetComplete).then(
        (response) => {
          if (response) history.push("/");
        }
      );
    }
  };

  return (
    <Layout className="auth-page">
      <Layout.Header>
        <Row type="flex" align="middle" className="logo-container">
          <a href="/">
            <img
              src="/assets/images/SikuWhiteLogoLogin.png"
              alt="Siku logo"
              className="logo"
            />
          </a>
        </Row>
      </Layout.Header>
      <Layout.Content className="content-container">
        <Col span={24} className="login-side">
          <AuthPasswordResetComplete
            userPasswordResetState={userPasswordResetState}
            onPasswordResetComplete={onPasswordResetComplete}
          />
          <Row
            type="flex"
            align="bottom"
            justify="center"
            className="login-side-footer"
          >
            <Layout.Footer>
              <PoweredBySikuFooter />
            </Layout.Footer>
          </Row>
        </Col>
      </Layout.Content>
    </Layout>
  );
};

const mapStateToProps = (state: any): UserPasswordResetState =>
  state.UserPasswordResetState;
