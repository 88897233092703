import React, { useEffect } from "react";
import "./CustomProfilePage.css";
import {
  CustomProfileState,
  CustomProfile,
  showCustomProfileModalForCreate,
  showCustomProfileModalForEdit,
  filterCustomProfiles,
} from "../../store/customProfile";
import { useSelector, useDispatch } from "react-redux";
import { Trans, t } from "@lingui/macro";
import {
  Tooltip,
  Avatar,
  Button,
  Divider,
  Popconfirm,
  Row,
  Table,
  message,
  Col,
  Input,
  Typography,
} from "antd";
import {
  loadCustomProfiles,
  deleteCustomProfile,
  createCustomProfile,
  updateCustomProfile,
} from "../../services/customProfile";
import { Container } from "../../components/utils";
import { CustomProfileModal } from "../../components/customProfile-modal";
import { i18n } from "../../utils";

export const CustomProfilesPage: React.FC = () => {
  const pageSize = 20;
  const customProfileState = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  useEffect(() => {
    loadCustomProfiles();
  }, [customProfileState.updateCustomProfiles]);

  const onCreateCustomProfile = () => {
    dispatch(showCustomProfileModalForCreate());
  };

  const onCreate = (customProfile: CustomProfile) => {
    createCustomProfile(customProfile);
  };

  const onUpdate = (customProfile: CustomProfile) => {
    updateCustomProfile(customProfile);
  };

  const onDeleteCustomProfile = (customProfileId: number) => {
    message.loading(
      i18n._(
        t("CustomProfilePage.DeletingCustomProfile")`Deleting customProfile...`
      ),
      0.5,
      () => deleteCustomProfile(customProfileId)
    );
  };

  const onUpdateCustomProfile = (customProfile: CustomProfile) => {
    dispatch(showCustomProfileModalForEdit(customProfile));
  };

  const tipo = (value: string) => {
    switch (value) {
      case "P":
        return <span>Farmacia</span>;
      case "L":
        return <span>Laboratorio</span>;
      case "V":
        return <span>Veterinaria</span>;
      default:
        return <span></span>;
    }
  };

  const columns = [
    {
      title: (
        <Trans render="strong" id="CustomProfiles.LogoColumnTitle">
          Logo
        </Trans>
      ),
      key: "Logo",
      render: (customProfile: CustomProfile) => (
        <span>
          <Tooltip title={customProfile.URLLogo}>
            <Avatar shape="square" size="large" src={customProfile.URLLogo} />
          </Tooltip>
        </span>
      ),
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.NameColumnTitle">
          Name
        </Trans>
      ),
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.UrlSlugColumnTitle">
          UrlSlug
        </Trans>
      ),
      dataIndex: "UrlSlug",
      key: "UrlSlug",
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.TypeColumnTitle">
          Type
        </Trans>
      ),
      key: "Type",
      render: (customProfile: CustomProfile) => tipo(customProfile.Type),
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.Phone1ColumnTitle">
          Phone1
        </Trans>
      ),
      dataIndex: "Phone1",
      key: "Phone1",
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.Phone2ColumnTitle">
          Phone2
        </Trans>
      ),
      dataIndex: "Phone2",
      key: "Phone2",
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.EmailAddressColumnTitle">
          EmailAddress
        </Trans>
      ),
      dataIndex: "EmailAddress",
      key: "EmailAddress",
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.URLWebSiteColumnTitle">
          URLWebSite
        </Trans>
      ),
      dataIndex: "URLWebSite",
      key: "URLWebSite",
    },
    {
      title: (
        <Trans render="strong" id="CustomProfiles.ActionsColumnTitle">
          Actions
        </Trans>
      ),
      key: "Actions",
      render: (customProfile: CustomProfile) => (
        <span>
          <Tooltip
            title={
              <Trans id="CustomProfiles.EditActionTooltip">
                Edit customProfile
              </Trans>
            }
          >
            <Button
              icon="edit"
              onClick={() => onUpdateCustomProfile(customProfile)}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip
            title={
              <Trans id="CustomProfiles.DeleteActionTooltip">
                Delete customProfile
              </Trans>
            }
          >
            <Popconfirm
              arrowPointAtCenter
              placement="bottomRight"
              title={
                <Trans id="CustomProfile.DeleteActionConfirmationText">
                  Do you wish to delete this customProfile?
                </Trans>
              }
              okText={
                <Trans id="CustomProfile.DeleteActionButton">Delete</Trans>
              }
              onConfirm={() =>
                onDeleteCustomProfile(customProfile.CustomProfileID)
              }
            >
              <Button type="danger" icon="delete" />
            </Popconfirm>
          </Tooltip>
        </span>
      ),
    },
  ];

  const filterFoundCustomProfiles = (value: string) => {
    dispatch(filterCustomProfiles(value));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    filterFoundCustomProfiles(e.target.value);
  };

  return (
    <React.Fragment>
      <Container>
        <div className="customProfiles-page">
          <Typography.Title level={2}>
            <Trans id="CustomProfilesPage.Title">Other services</Trans>
          </Typography.Title>
          <Table
            className="customProfiles-table"
            dataSource={customProfileState.filteredCustomProfiles}
            columns={columns}
            loading={customProfileState.loading}
            bodyStyle={{ background: "#fff" }}
            rowKey="CustomProfileID"
            pagination={
              customProfileState.count > pageSize && {
                pageSize,
                position: "bottom",
              }
            }
            title={() => (
              <Row type="flex" align="middle">
                <Col span={6}>
                  <Input
                    onChange={onChange}
                    placeholder={i18n._(
                      t(
                        "CustomProfiles.FilterCustomProfilesPlaceholder"
                      )`Search Custom profiles`
                    )}
                  />
                </Col>
                <Col span={18}>
                  <Row type="flex" justify="end">
                    <Button
                      type="primary"
                      size="large"
                      onClick={onCreateCustomProfile}
                    >
                      <Trans id="CustomProfiles.CreateCustomProfileButton">
                        New Custom profile
                      </Trans>
                    </Button>
                  </Row>
                </Col>
              </Row>
            )}
          />
        </div>
      </Container>
      <CustomProfileModal
        customProfile={customProfileState.customProfile}
        loading={customProfileState.changing}
        modalType={customProfileState.modalType}
        onCreate={onCreate}
        onUpdate={onUpdate}
        showModal={customProfileState.showModal}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): CustomProfileState =>
  state.customProfileState;
