import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import {
  Directory,
  loadingDirectories,
  loadingDirectoriesSucceded,
  loadDirectoryFromLocalStorage,
  loadingDirectory,
  setDirectory,
  loadedDirectory
} from "../../store/directory";
import store from "../../store";
import { LocalStorageKeys } from "../../utils/Constants";

export const userDirectories = async () => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/user/DirectoryTenantUser";

  try {
    store.dispatch(loadingDirectories());
    const response = await httpClient.get<GenericApiResponse<Directory[]>>(url);
    store.dispatch(loadingDirectoriesSucceded(response.data.Data));
    store.dispatch(loadDirectoryFromLocalStorage());
    return true;
  } catch {
    return false;
  }
};

export const loadDirectory = async (directoryId: string) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/directory/${directoryId}`;

  try {
    store.dispatch(loadingDirectory());
    const response = await httpClient.get<GenericApiResponse<Directory>>(url);
    store.dispatch(setDirectory(response.data.Data));
    return true;
  } catch {
    store.dispatch(setDirectory(undefined));
    return false;
  }
};

export const createDirectory = async (request: Directory) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "admin/directory";

  try {
    store.dispatch(loadingDirectory());
    await httpClient.post(url, request);
    store.dispatch(loadedDirectory(true));
    return true;
  } catch {
    store.dispatch(loadedDirectory(false));
    return false;
  }
};

export const updateDirectory = async (request: Directory) => {
  const { DirectoryTenantID, ...rest } = request;
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + `admin/directory/${DirectoryTenantID}`;

  try {
    store.dispatch(loadingDirectory());
    await httpClient.put(url, rest);
    store.dispatch(loadedDirectory(true));
    return true;
  } catch {
    store.dispatch(loadedDirectory(false));
    return false;
  }
};
