import { PaymentMethodState } from "./Types";
import {
    LOADING_PAYMENT_METHODS,
    SET_PAYMENT_METHODS
} from "./Actions";

const initialState: PaymentMethodState = {
    paymentMethods: [],
    loading: false,
    updatePaymentMethods: false,
};

export const paymentMethodReducer = (
    state = initialState,
    action: any
): PaymentMethodState => {
    switch (action.type) {
        case LOADING_PAYMENT_METHODS:
            return {
                ...state,
                loading: true,
            };

        case SET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: action.paymentMethods,
                loading: false,
            };

        default:
            return state;
    }
};
