import { Insurance } from "../insurance";
import { Account, AccountLinkedAccountTable } from "./Types";

export const HIDE_ACCOUNT_MODAL = "HIDE_ACCOUNT_MODAL";
export const LOADED_ACCOUNTS = "LOADED_ACCOUNTS";
export const LOADING_ACCOUNTS = "LOADING_ACCOUNTS";
export const SEARCHING_ACCOUNT = "SEARCHING_ACCOUNT";
export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const SET_ACCOUNT = "SET_ACCOUNT";
export const SET_ACCOUNT_FROM_TSE = "SET_ACCOUNT_FROM_TSE";
export const SHOW_MODAL_FOR_ACCOUNT_CREATION =
  "SHOW_MODAL_FOR_ACCOUNT_CREATION";
export const SHOW_MODAL_FOR_ACCOUNT_UPDATE = "SHOW_MODAL_FOR_ACCOUNT_UPDATE";
export const SET_IS_LINKED_ACCOUNT = "SET_IS_LINKED_ACCOUNT";
export const LOADED_LINKED_ACCOUNTS = "LOADED_LINKED_ACCOUNTS";
export const SET_IS_PARENT_ACCOUNT_ID = "SET_IS_PARENT_ACCOUNT_ID";
export const ADD_ACCOUNT_INSURANCES = "ADD_ACCOUNT_INSURANCES";
export const DELETE_ACCOUNT_INSURANCES = "DELETE_ACCOUNT_INSURANCES";


export const hideAccountModal = () => ({
  type: HIDE_ACCOUNT_MODAL,
});

export const loadedAccounts = (accounts: Account[]) => ({
  type: LOADED_ACCOUNTS,
  accounts,
});

export const loadingAccounts = () => ({
  type: LOADING_ACCOUNTS,
});

export const selectAccount = (identificationNumber: string) => ({
  type: SELECT_ACCOUNT,
  identificationNumber,
});

export const setAccount = (account: Account | undefined) => ({
  type: SET_ACCOUNT,
  account,
});

export const setAccountFromTSE = (account: Account | undefined) => ({
  type: SET_ACCOUNT_FROM_TSE,
  account,
});

export const searchingAccount = () => ({
  type: SEARCHING_ACCOUNT,
});

export const showModalForAccountCreation = () => ({
  type: SHOW_MODAL_FOR_ACCOUNT_CREATION,
});

export const showModalForAccountUpdate = (account: Account) => ({
  type: SHOW_MODAL_FOR_ACCOUNT_UPDATE,
  account,
});

export const setIsLinkedAccount = (isLinkedAccount: Boolean) => ({
  type: SET_IS_LINKED_ACCOUNT,
  isLinkedAccount,
});

export const loadedLinkedAccounts = (
  accountLinkedAccountTable: AccountLinkedAccountTable[]
) => ({
  type: LOADED_LINKED_ACCOUNTS,
  accountLinkedAccountTable,
});

export const setParentAccountID = (parentAccountID: any) => ({
  type: SET_IS_PARENT_ACCOUNT_ID,
  parentAccountID,
});

export const addAccountInsurances = (insurance: Insurance) => ({
  type: ADD_ACCOUNT_INSURANCES,
  insurance,
});

export const deleteAccountInsurances = (insurance: Insurance) => ({
  type: DELETE_ACCOUNT_INSURANCES,
  insurance,
});