import axios from "axios";
import httpClient from "../HttpClient";
import { GenericApiResponse } from "../ServicesTypes";
import store from "../../store";
import { SearchParams, beginSearch, completeSearch } from "../../store/search";
import { SearchProvidersResponse } from "./Types";
import {
  AdvancedSearchParams,
  beginAdvancedSearch,
  AdvancedSearchProvider,
  completeAdvancedSpecialtySearch,
  AdvancedSearchDate,
  completeAdvancedDateSearch
} from "../../store/advanced-search";
import moment from "moment";
import { LocalStorageKeys } from "../../utils/Constants";

export const searchProviders = async (params: SearchParams) => {
  const url =localStorage.getItem(LocalStorageKeys.baseApiUrl) + "search/searchProviders";

  try {
    store.dispatch(beginSearch(params));
    const response = await httpClient.get<
      GenericApiResponse<SearchProvidersResponse>
    >(url, {
      params
    });
    store.dispatch(completeSearch(response.data.Data.Providers));
    return true;
  } catch {
    store.dispatch(completeSearch([]));
    return false;
  }
};

export const advanceSearchBySpecialty = async (
  params: AdvancedSearchParams
) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/specialty/timeslots";
  const realParams = {
    countyId: params.countyId,
    from: params.dates[0].format("YYYY-MM-DD"),
    specialtyId: params.specialtyId,
    stateId: params.stateId,
    to: params.dates[1].format("YYYY-MM-DD")
  };

  try {
    store.dispatch(beginAdvancedSearch(params));
    const response = await httpClient.get<
      GenericApiResponse<AdvancedSearchProvider[]>
    >(url, { params: realParams });

    const providersTimeSlots = response.data.Data;
    const result = providersTimeSlots.map(providerTimeSlot => ({
      ...providerTimeSlot,
      TimeSlots: providerTimeSlot.TimeSlots.map(timeSlot => ({
        Date: moment(timeSlot.Date, "YYYY-MM-DD"),
        Hours: timeSlot.Hours.map(hour => moment(hour, "HH:mm"))
      }))
    }));

    store.dispatch(completeAdvancedSpecialtySearch(result));
    return true;
  } catch {
    store.dispatch(completeAdvancedSpecialtySearch([]));
    return false;
  }
};

export const advanceSearchByDate = async (params: AdvancedSearchParams) => {
  const url = localStorage.getItem(LocalStorageKeys.baseApiUrl) +"search/dates/timeslots";
  const requests = createRequests(url, params);

  try {
    store.dispatch(beginAdvancedSearch(params));
    const response = await axios.all(requests);
    const datesTimeSlots = response.flatMap(x =>
      !x.data.Error.HasError ? x.data.Data : []
    );

    const result = datesTimeSlots.map(dateTimeSlot => ({
      Date: moment(dateTimeSlot.Date, "YYYY-MM-DD"),
      Providers: dateTimeSlot.Providers.map(provider => ({
        ...provider,
        Hours: provider.Hours.map(hour => moment(hour, "HH:mm"))
      }))
    }));

    store.dispatch(completeAdvancedDateSearch(result));
    return true;
  } catch {
    store.dispatch(completeAdvancedDateSearch([]));
    return false;
  }
};

const createRequests = (url: string, params: AdvancedSearchParams) => {
  const requests = [];
  const [startDate, endDate] = params.dates;
  let start = moment(startDate);

  while (start <= endDate) {
    const date = start.format("YYYY-MM-DD");

    const realParams = {
      countyId: params.countyId,
      from: date,
      specialtyId: params.specialtyId,
      stateId: params.stateId,
      to: date
    };

    requests.push(
      httpClient.get<GenericApiResponse<AdvancedSearchDate[]>>(url, {
        params: realParams
      })
    );

    start = start.add(1, "day");
  }

  return requests;
};
